var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-analytics-main dialer-analytics-summary"
  }, [_c('div', {
    staticClass: "dialer-box p-16-24-px"
  }, [_vm.getIsMobile ? [_c('header', {
    staticClass: "dialer-analytics-titleBar pb-1"
  }, [_c('div', {
    staticClass: "dialer-analytics-title calc-title-width-analytics"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h1', {}, [_vm._v("Call activity")])], 1)]), _c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterWithText mr-16px"
  }, [_c('b-dropdown', {
    staticClass: "ico-filter",
    attrs: {
      "varient": "link",
      "no-caret": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_vm.response.graph.date_label ? _c('span', {
          staticClass: "mr-12px"
        }, [_vm._v(_vm._s(_vm.response.graph.date_label))]) : _vm._e(), _c('b-icon', {
          staticClass: "fillBlack",
          attrs: {
            "icon": "three-dots-vertical",
            "variant": "dark",
            "scale": "1.5"
          }
        })];
      },
      proxy: true
    }], null, false, 510945226)
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('b-dropdown-item', {
      key: tab.value,
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.value;
          tab.value == _vm.tabs.custom.value ? _vm.$modal.show(`${_vm._uid}-dateFilterModal`) : _vm.fecthGraph();
        }
      }
    }, [_c('vb-icon', {
      staticClass: "mr-12px",
      style: `opacity:${_vm.selected.tab === tab.value ? '1' : '0'}`,
      attrs: {
        "icon": "markAsComplete-icon",
        "width": "14.49px",
        "height": "10.431px"
      }
    }), _c('span', [_vm._v(_vm._s(tab.text))])], 1);
  }), 1)], 1)])] : _c('div', {
    staticClass: "analytics-newHeader mb-32px"
  }, [_vm._m(0), _c('div', {
    staticClass: "analytics-newHeader-rightSide"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterWithText mr-16px"
  }, [_c('b-dropdown', {
    staticClass: "ico-filter",
    attrs: {
      "varient": "link",
      "no-caret": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_vm.response.graph.date_label ? _c('span', {
          staticClass: "mr-12px"
        }, [_vm._v(_vm._s(_vm.response.graph.date_label))]) : _vm._e(), _c('b-icon', {
          staticClass: "fillBlack",
          attrs: {
            "icon": "three-dots-vertical",
            "variant": "dark",
            "scale": "1.5"
          }
        })];
      },
      proxy: true
    }])
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('b-dropdown-item', {
      key: tab.value,
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.value;
          tab.value == _vm.tabs.custom.value ? _vm.$modal.show(`${_vm._uid}-dateFilterModal`) : _vm.fecthGraph();
        }
      }
    }, [_c('vb-icon', {
      staticClass: "mr-12px",
      style: `opacity:${_vm.selected.tab === tab.value ? '1' : '0'}`,
      attrs: {
        "icon": "markAsComplete-icon",
        "width": "14.49px",
        "height": "10.431px"
      }
    }), _c('span', [_vm._v(_vm._s(tab.text))])], 1);
  }), 1)], 1)])]), [_c('div', {
    staticClass: "analytics-summary-content"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-24px"
  }, [_c('div', {
    staticClass: "dialer-analytics-totals v2 updatedAgain mb-4"
  }, [_c('div', {
    staticClass: "dialer-call-total dialer-total"
  }, [_c('div', {
    staticClass: "dialer-call-total-inner"
  }, [_c('div', {
    staticClass: "dialer-call-total-top"
  }, [_c('div', {
    staticClass: "dialer-call-type"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "CallSummary-TotalCall-icon"
    }
  }), _c('div', [_vm._v("total Calls")])], 1)]), _c('div', {
    staticClass: "dialer-call-figures"
  }, [_c('h4', {
    staticClass: "dialer-call-number"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.graph, 'totalCalls', 0)))])])])]), _c('div', {
    staticClass: "dialer-call-total dialer-missed"
  }, [_c('div', {
    staticClass: "dialer-call-total-inner"
  }, [_c('div', {
    staticClass: "dialer-call-total-top"
  }, [_c('div', {
    staticClass: "dialer-call-type"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "CallSummary-MissedCall-icon"
    }
  }), _c('div', [_vm._v("missed Calls")])], 1), _c('span', {
    staticClass: "dialer-call-total-time"
  })]), _c('div', {
    staticClass: "dialer-call-figures"
  }, [_c('h4', {
    staticClass: "dialer-call-number"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.graph, 'totalMissCalls', 0)))]), _c('div', {
    staticClass: "newProgressBar"
  }, [_c('div', {
    style: `--value:${_vm.callsPercentage.missed};--fg:#dc3545`,
    attrs: {
      "role": "progressbar",
      "aria-valuenow": "65",
      "aria-valuemin": "0",
      "aria-valuemax": "100"
    }
  }, [_c('div', {
    staticClass: "newProgressBar-inner"
  }, [_vm._v(" " + _vm._s(_vm.callsPercentage.missed) + "% ")])])])])])]), _c('div', {
    staticClass: "dialer-call-total dialer-inbound"
  }, [_c('div', {
    staticClass: "dialer-call-total-inner"
  }, [_c('div', {
    staticClass: "dialer-call-total-top"
  }, [_c('div', {
    staticClass: "dialer-call-type"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "CallSummary-InboundCall-icon"
    }
  }), _c('div', [_vm._v("Received Calls")])], 1), _c('span', {
    staticClass: "dialer-call-total-time"
  })]), _c('div', {
    staticClass: "dialer-call-figures"
  }, [_c('h4', {
    staticClass: "dialer-call-number"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.graph, 'totalReceiveCalls', 0)))]), _c('div', {
    staticClass: "newProgressBar"
  }, [_c('div', {
    style: `--value:${_vm.callsPercentage.incomming};--fg:#28a745`,
    attrs: {
      "role": "progressbar",
      "aria-valuenow": "65",
      "aria-valuemin": "0",
      "aria-valuemax": "100"
    }
  }, [_c('div', {
    staticClass: "newProgressBar-inner"
  }, [_vm._v(" " + _vm._s(_vm.callsPercentage.incomming) + "% ")])])])])])]), _c('div', {
    staticClass: "dialer-call-total dialer-outbound"
  }, [_c('div', {
    staticClass: "dialer-call-total-inner"
  }, [_c('div', {
    staticClass: "dialer-call-total-top"
  }, [_c('div', {
    staticClass: "dialer-call-type"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "CallSummary-OutboundCall-icon"
    }
  }), _c('div', [_vm._v("outbound Calls")])], 1)]), _c('div', {
    staticClass: "dialer-call-figures"
  }, [_c('h4', {
    staticClass: "dialer-call-number"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.graph, 'totalOutgoingCalls', 0)))]), _c('div', {
    staticClass: "newProgressBar"
  }, [_c('div', {
    style: `--value:${_vm.callsPercentage.outgoing};--fg:#5576d1`,
    attrs: {
      "role": "progressbar",
      "aria-valuenow": "65",
      "aria-valuemin": "0",
      "aria-valuemax": "100"
    }
  }, [_c('div', {
    staticClass: "newProgressBar-inner"
  }, [_vm._v(" " + _vm._s(_vm.callsPercentage.outgoing) + "% ")])])])])])])]), _vm.api.fetch_graph.send && _vm.chart.graph == null ? _vm._l(7, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "latestShimmerDesign mb-2",
      staticStyle: {
        "height": "45px",
        "width": "100%"
      }
    });
  }) : _vm._e(), _vm._m(1)], 2)])]], 2), _c('modal', {
    staticClass: "callReportingFilterModal",
    attrs: {
      "name": `${_vm._uid}-dateFilterModal`
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0 position-relative"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Filters")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-dateFilterModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "analytics-newHeader-text"
  }, [_c('div', {
    staticClass: "d-flex customDate"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionLeftAligned w-50 pr-2"
  }, [_c('span', [_vm._v("From:")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input",
    attrs: {
      "id": "starttime"
    },
    model: {
      value: _vm.filter.graph.start_time,
      callback: function ($$v) {
        _vm.$set(_vm.filter.graph, "start_time", $$v);
      },
      expression: "filter.graph.start_time"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionRightAligned w-50 pl-2"
  }, [_c('span', [_vm._v("To:")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input",
    attrs: {
      "min-date": _vm.filter.graph.start_time,
      "id": "endtime"
    },
    model: {
      value: _vm.filter.graph.end_time,
      callback: function ($$v) {
        _vm.$set(_vm.filter.graph, "end_time", $$v);
      },
      expression: "filter.graph.end_time"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1)]), _c('div', {
    staticClass: "w-100 d-flex justify-content-end mt-20px"
  }, [_c('a', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        _vm.fecthGraph();
        _vm.$modal.hide(`${_vm._uid}-dateFilterModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-browse-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Fetch")])], 1)])])])])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "analytics-newHeader-leftSide"
  }, [_c('h1', [_vm._v("Call activity")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chart-container"
  }, [_c('canvas', {
    attrs: {
      "id": "planet-chart"
    }
  })]);

}]

export { render, staticRenderFns }