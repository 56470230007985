var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "PowerDialerContainer",
    staticClass: "PowerDialerContainer"
  }, [_c('h2', {
    staticClass: "d-flex flex-wrap align-items-center justify-content-between"
  }, [_c('span', {
    staticClass: "d-block title"
  }, [_vm._v("Power dialer")]), _c('div', {
    staticClass: "startStopDialing"
  }, [true ? _c('vb-icon', {
    attrs: {
      "icon": "PowerDialer-start-icon",
      "width": "11.79px",
      "height": "13.482px"
    }
  }) : _c('vb-icon', {
    attrs: {
      "icon": "PowerDialer-stop-icon",
      "width": "14px",
      "height": "14px"
    }
  }), _c('div', {
    staticClass: "startStopDialing-text"
  }, [true ? [_vm._v("Auto")] : [_vm._v("Stop")]], 2)], 1)]), _c('div', {
    staticClass: "PowerDialerContainer-inner"
  }, _vm._l(3, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "PowerDialer-row"
    }, [_c('div', {
      staticClass: "PowerDialer-row-leftSide"
    }, [_c('vb-icon', {
      staticClass: "PowerDialer-contact-icon",
      attrs: {
        "icon": "PowerDialer-contact-icon"
      }
    }), _vm._m(0, true)], 1), _c('div', {
      staticClass: "PowerDialer-row-rightSide"
    }, [_c('vb-icon', {
      staticClass: "PowerDialer-phone-icon",
      class: `${n == 2 ? 'disabled' : ''}`,
      attrs: {
        "icon": "PowerDialer-phone-icon",
        "width": "15.983px",
        "height": "16.002px"
      }
    }), _c('vb-icon', {
      staticClass: "PowerDialer-rightArrow-icon",
      attrs: {
        "icon": "PowerDialer-rightArrow-icon",
        "width": "8.526",
        "height": "14.113"
      }
    })], 1)]);
  }), 0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "PowerDialer-row-mainText"
  }, [_vm._v("Contact Name")]), _c('div', {
    staticClass: "PowerDialer-row-secondaryText"
  }, [_vm._v(" 02034322202 ")]), _c('div', {
    staticClass: "PowerDialer-row-verySmallText"
  }, [_c('span', [_vm._v("Dialing")]), _c('div', {
    staticClass: "dialingCircles"
  }, [_c('div', {
    staticClass: "dialingCircle"
  }), _c('div', {
    staticClass: "dialingCircle"
  }), _c('div', {
    staticClass: "dialingCircle"
  })])])]);

}]

export { render, staticRenderFns }