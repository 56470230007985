<template>
  <modal class="dialer_animation right_side_popup m-w-500 min-width-50percent-modal" width="500px" height="auto" :scrollable="true" :name="modalName" @closed="onClosed" @before-open="onBeforeOpen">
    <div class="dialer-form">
      <div v-if="$store.getters.getIsMobile" class="dialer-edit-header flex-column">
        <div class="d-flex w-100 justify-content-between align-items-center">
          <h2 class="dialer-edit-title">Edit pickup group</h2>
          <!-- <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="api_sent.delete ? '' : $modal.hide(modalName)" /> -->
          <button type="submit" class="newDoneButton" :disabled="api_sent.edit || api_sent.delete" @click="edit">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
              <vb-spinner v-if="api_sent.edit" />
              <template v-else>Done</template>
            </span>
          </button>
        </div>
        <!-- <div class="dialer-edit-actions d-flex w-100 justify-content-end mt-2">
          <button type="submit" class="newDoneButton" :disabled="api_sent.edit || api_sent.delete" @click="edit">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
              <vb-spinner v-if="api_sent.edit" />
              <template v-else>Done</template>
            </span>
          </button>
        </div> -->
      </div>
      <div v-else class="dialer-edit-header">
        <h2 class="dialer-settings-title newer mb-0">
          <vb-svg @click="$modal.hide(modalName)" class="cursor_pointer_no_hover" name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
          Edit pickup group
        </h2>
        <div class="dialer-edit-actions">
          <!-- <a class="newCloseButton" @click="api_sent.delete ? '' : $modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a> -->
          <button type="submit" class="newDoneButton" :disabled="api_sent.edit || api_sent.delete" @click="edit">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
              <vb-spinner v-if="api_sent.edit" />
              <template v-else>Done</template>
            </span>
          </button>
        </div>
      </div>
      <b-alert :dismissible="true" :fade="true" :show="!!error" @dismissed="error=''" class="alert-danger">{{ error }}</b-alert>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <b-form @submit.prevent="edit">
          <b-form-group>  
            <div class="whiteBGinputWithGreyRoundedBorder">
              <label>Name</label>
              <b-form-input :disabled="api_sent.edit || api_sent.delete" type="text" placeholder="Enter Name" v-model="forms.update.name" />
              <template v-if="submitted.edit">
                <p v-if="!$v.forms.update.name.required" class="text text-danger animated bounceIntop">*Name is required</p>
              </template>
            </div>
          </b-form-group>
        </b-form>
        <div class="d-flex justify-content-between align-items-center mt-20px">
          <h3 class="latestGreyBox-heading-main mb-0">Extension assign</h3>
          <div class="lg-mar-left">
            <button class="newButton" type="button" :disabled="api_sent.delete" @click="$modal.show('AddMemberInPickupGroupsModal', {pickupgroup_id: pickupgroup.id,})">
              <vb-icon icon="squared-add-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Add/Remove extension</span>
            </button>
          </div>
        </div>
        <div v-if="api_sent.list" class="whiteBoxWithBorderInsideGreyBox mt-20px">
          <div v-for="n in 3" :key="n.id" class="whiteBoxWithBorderInsideGreyBox-row justify-content-start">
            <div class="latestShimmerDesign" style="height: 45px;width: 45px"></div>
            <div class="d-flex flex-column sm-mar-left sm-mar-right">
              <div class="latestShimmerDesign" style="height: 22px;width: 200px"></div>
              <div class="latestShimmerDesign mt-1" style="height: 22px;width: 100px"></div>
            </div>
          </div>
        </div>
        <div v-else-if="Object.keys(response.selected).length > 0" class="whiteBoxWithBorderInsideGreyBox mt-20px">
          <div v-for="data in  response.selected" :key="data.accountcode" class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-unset">
            <!-- <vb-avatar :id="data.accountcode" :is_blf="false" />
            <div class="d-flex flex-column">
              <span class="sm-mar-left sm-mar-right font-weight-bold">{{ data.label }}</span>
              <span class="sm-mar-left sm-mar-right"> {{ data.extn }} </span>
            </div> -->
            <Info 
              :id="data.accountcode" 
              :is_blf="false" 
            />
          </div>
        </div>
        <div v-else class="whiteBoxWithBorderInsideGreyBox mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start">
            <div class="latestGreyBox-descriptionText mt-0">No extension assigned</div>
          </div>
        </div>
        <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div class="latestGreyBox-heading-main">Delete this pickup group?</div>
            <button class="newDeleteButton" :disabled="api_sent.delete" @click="deletePickGroup()">
              <vb-icon icon="squared-delete-icon" height="38px" width="38px" />
              <span class="newDeleteButton-textPart d-flex justify-content-center position-relative">
                <vb-spinner v-if="api_sent.delete" />
                <span v-else>Delete</span>
              </span>
            </button>
          </div>
        </div>
      </div>




      <!--<div class="dialer-input-field input-consistent-inner-shadows md-mar-vertical">
        <b-alert :dismissible="true" :fade="true" :show="!!error" @dismissed="error=''" class="alert-danger">{{ error }}</b-alert>
        <b-form @submit.prevent="edit">
          <b-form-group>
            <label>Name</label>
            <b-form-input :disabled="api_sent.edit || api_sent.delete" type="text" placeholder="Enter Name" v-model="forms.update.name" />
            <template v-if="submitted.edit">
              <p v-if="!$v.forms.update.name.required">*Name is Required</p>
            </template>
          </b-form-group>
        </b-form>
      </div>
      <div class="mt-5 dialer-assigned w-100">
        <div class="w-100">
          <div class="d-flex justify-content-between align-items-center">
            <h3 class="dialer-box-header mb-0">Extension Assign</h3>
            <div class="lg-mar-left">
              <button class="dialer-button dialer-button-primary md-mar-left" type="button" :disabled="api_sent.delete" @click="$modal.show('AddMemberInPickupGroupsModal', {pickupgroup_id: pickupgroup.id,})">
                <span>Add/Remove Extension</span>
              </button>
            </div>
          </div>
          <ul class="list-unstyled mt-3 d-flex flex-wrap" v-if="response.selected">
            <li v-for="data in  response.selected" :key="data.accountcode" class="dialer-box-text highlight alt table py-1 px-3 mr-2 telephone-number-box d-flex align-items-center">
              <vb-avatar :id="data.accountcode" />
              <div class="d-flex flex-column">
                <span class="sm-mar-left sm-mar-right font-weight-bold">{{ data.label }}</span>
                <span class="sm-mar-left sm-mar-right"> {{ data.extn }} </span>
              </div>
            </li>
          </ul>
          <vb-loading v-else-if="api_sent.list" />
          <template v-else>
            <p class="dialer-box-text highlight">No extension assigned</p>
          </template>
        </div>
      </div>
      <div class="dialer-box alt" style="padding:32px;">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="delete-text">Delete this Pickup Group?</div>
          <button class="dialer-button dialer-button-delete-alt" :disabled="api_sent.delete" @click="deletePickGroup()">
            <vb-spinner v-if="api_sent.delete" />
            Delete
          </button>
        </div>
      </div>-->
      <AddMemberInPickupGroups @updated="getmembers()" />
    </div>
  </modal>
</template>

<script>
import AddMemberInPickupGroups from "../../Pickupgroups/AddMemberInPickupGroups.vue";
import { required } from "vuelidate/lib/validators";
import { VOIP_API } from '../../../utils';
import Info from "../../Lists/Info.vue";
import _ from 'lodash'
export default {
  name: "EditPickUpGroupModal",
  components: {
    AddMemberInPickupGroups,
    Info
  },
  props: {
    modalName: {
      type: String,
      default: 'EditPickUpGroupModal',
    },
  },
  data(){
    return {
      forms: {
        update: {
          name: '',
          reset(){
            this.name=''
          }
        }
      },
      error: "",
      submitted: {
        edit: false,
      },
      api_sent: {
        delete: false,
        edit: false,
        list: false,
      },
      pickupgroup: {},
      response: {
        available: {},
        selected: {},
      },
    }
  },
  inject: [
    'appNotify',
  ],
  validations: {
    forms: {
      update: {
        name: {
          required,
        }
      }
    }
  },
  methods: {
    edit(){
      var app = this
      this.submitted.edit=true
      this.$v.$touch();
      if (this.$v.forms.update.$invalid || this.api_sent.edit) return;
      this.api_sent.edit=true
      VOIP_API.endpoints.pickupgroup.updatelabel({
        id: this.pickupgroup.id,
        label: this.forms.update.name
      }).then(()=>{
        app.$emit('load')
        app.$modal.hide(app.modalName)
        app.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
      }).catch((ex)=>{
        app.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        app.api_sent.edit=false
        app.submitted.edit=false
      })
      setTimeout(()=>{
        
      },3 * 1000)
    },
    getmembers(){
      var app = this
      if (this.api_sent.list) return;
      this.api_sent.list=true
      VOIP_API.endpoints.pickupgroup
        .members(this.pickupgroup.id)
        .then(({ data: { selected, available } })=>{
          app.response.available=_.isEmpty(available) ? {} : available
          app.response.selected=_.isEmpty(selected) ? {} : selected
        })
        .finally(()=>{
          app.api_sent.list=false
        })
    },
    onClosed(){
      this.submitted.edit=false
      this.error=''
      this.pickupgroup={}
      this.response.available={}
      this.response.selected={}
    },
    onBeforeOpen(event){
      this.pickupgroup = event.params.pickupgroup
      this.forms.update.name=this.pickupgroup.name
      this.getmembers()
    },
    deletePickGroup(){
      let vm = this
      if(vm.api_sent.delete) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api_sent.delete=true
            VOIP_API.endpoints.pickupgroup.delete(vm.pickupgroup.id)
            .then(()=>{
              vm.appNotify({
                message: `Successfully Deleted ${vm.name} Pickup Group`,
                type: 'success',
              })
              vm.$emit('load')
              vm.$modal.hide(vm.modalName)
            })
            .catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(()=>{
              vm.api_sent.delete=false
            })
          }
        },
      });
      
    },
  },
}
</script>

<style>

</style>