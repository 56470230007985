var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: `sidebar right-sidebar position-relative ${_vm.toggleRadius ? 'zeroRadius' : ''}`,
    attrs: {
      "id": "right-side-bar-334"
    }
  }, [!_vm.$store.getters.getIsMobile && !_vm.$store.getters.isIntegration ? [_c('div', {
    staticClass: "rightSideBar-inner"
  }, [!_vm.$store.state.css_break_points.b3_rightSideBar ? [_c('nav', {
    staticClass: "nav nav-menu"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    staticClass: "audioSettingIcon-container mt-8px"
  }, [_c('a', {
    class: `w-100 d-flex align-items-center justify-content-center`,
    attrs: {
      "id": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AudioSettingModal');
      }
    }
  }, [_c('vb-icon', {
    staticClass: "rightSidebar_icon",
    attrs: {
      "id": "soundSetting-icon",
      "icon": "soundSetting-icon",
      "width": "50",
      "height": "50"
    }
  })], 1), _vm.media_devices_setting.isJabra ? _c('vb-icon', {
    staticClass: "jabra-icon v2 rightSidebar_icon position-absolute",
    attrs: {
      "icon": "jabra-v2-icon",
      "width": "50",
      "height": "20"
    }
  }) : _vm._e(), _vm.media_devices_setting.isLenovo ? _c('vb-icon', {
    staticClass: "jabra-icon v2 rightSidebar_icon position-absolute",
    attrs: {
      "icon": "lenovo-icon",
      "width": "50",
      "height": "20"
    }
  }) : _vm._e(), _c('b-popover', {
    attrs: {
      "target": "soundSetting-icon",
      "placement": "left",
      "content": "Audio Settings",
      "triggers": "hover focus"
    }
  }), !_vm.$store.state.sip.state.is_connected ? _c('div', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Sip Socket OR Web Socket not connected',
      expression: "'Sip Socket OR Web Socket not connected'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "indicator-light bill-223344-reload"
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-clockwise"
    },
    on: {
      "click": function ($event) {
        return _vm.reConnectUserAgent();
      }
    }
  })], 1) : _vm._e(), !_vm.$store.state.sip.state.is_register ? _c('div', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Sip User is not register',
      expression: "'Sip User is not register'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "indicator-light bill-223344-reload",
    staticStyle: {
      "background-color": "green",
      "right": "20px"
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-clockwise"
    },
    on: {
      "click": function ($event) {
        return _vm.reRegisterUserAgent();
      }
    }
  })], 1) : _vm._e()], 1), _vm.isElectron ? _c('li', {
    staticClass: "mobileViewCheckBox"
  }, [_c('span', {
    staticClass: "mobileViewCheckBox-text"
  }, [_vm._v("Minimize")]), _vm.isElectron ? _c('b-checkbox', {
    staticClass: "minimizedSwitch",
    attrs: {
      "switch": "",
      "value": "mob",
      "unchecked-value": "web"
    },
    model: {
      value: _vm.$store.state.electron.app_view,
      callback: function ($$v) {
        _vm.$set(_vm.$store.state.electron, "app_view", $$v);
      },
      expression: "$store.state.electron.app_view"
    }
  }) : _vm._e()], 1) : _vm._e(), _c('li', [_c('a', {
    class: `w-100 d-flex align-items-center justify-content-center ${_vm.selectedPanel === 'Notifications' || _vm.hoverPanel === 'Notifications' ? 'active' : ''}`,
    attrs: {
      "id": "tooltip-notifications"
    },
    on: {
      "mouseover": function ($event) {
        _vm.hoverPanel = 'Notifications';
      },
      "mouseout": function ($event) {
        _vm.hoverPanel = '';
      },
      "click": function ($event) {
        _vm.selectedPanel = 'Notifications';
      }
    }
  }, [_c('vb-icon', {
    staticClass: "Notifications-icon rightSidebar_icon",
    attrs: {
      "icon": "Notifications-icon",
      "width": "50",
      "height": "50"
    }
  })], 1), _c('b-popover', {
    attrs: {
      "target": "tooltip-notifications",
      "placement": "left",
      "content": "Notifications",
      "triggers": "hover focus"
    }
  })], 1), _c('li', [_c('a', {
    class: `w-100 d-flex align-items-center justify-content-center ${_vm.selectedPanel === 'PowerDialer' || _vm.hoverPanel === 'PowerDialer' ? 'active' : ''}`,
    attrs: {
      "id": "tooltip-notifications"
    },
    on: {
      "mouseover": function ($event) {
        _vm.hoverPanel = 'PowerDialer';
      },
      "mouseout": function ($event) {
        _vm.hoverPanel = '';
      },
      "click": function ($event) {
        _vm.selectedPanel = 'PowerDialer';
      }
    }
  }, [_c('vb-icon', {
    staticClass: "Notifications-icon rightSidebar_icon",
    attrs: {
      "icon": "Notifications-icon",
      "width": "50",
      "height": "50"
    }
  })], 1), _c('b-popover', {
    attrs: {
      "target": "tooltip-notifications",
      "placement": "left",
      "content": "Notifications",
      "triggers": "hover focus"
    }
  })], 1)])]), _c('nav', {
    staticClass: "nav nav-menu"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    attrs: {
      "app-tour-web-speed-dials": ""
    }
  }, [_c('a', {
    class: `w-100 d-flex align-items-center justify-content-center ${_vm.selectedPanel === 'SpeedDials' || _vm.hoverPanel === 'SpeedDials' ? 'active' : ''}`,
    attrs: {
      "id": "tooltip-Speed Dials"
    },
    on: {
      "mouseover": function ($event) {
        _vm.hoverPanel = 'SpeedDials';
      },
      "mouseout": function ($event) {
        _vm.hoverPanel = '';
      },
      "click": function ($event) {
        _vm.selectedPanel = 'SpeedDials';
      }
    }
  }, [_c('vb-icon', {
    staticClass: "speedDial-icon speedDial-icon-v3 rightSidebar_icon",
    attrs: {
      "icon": "speedDial-icon-v3",
      "width": "50",
      "height": "50"
    }
  })], 1), _c('b-popover', {
    attrs: {
      "target": "tooltip-Speed Dials",
      "placement": "left",
      "content": "Favourites",
      "triggers": "hover focus"
    }
  })], 1), _c('li', {
    attrs: {
      "app-tour-web-dashboard-users": ""
    }
  }, [_c('a', {
    class: `w-100 d-flex align-items-center justify-content-center ${_vm.selectedPanel === 'Users' || _vm.hoverPanel === 'Users' ? 'active' : ''}`,
    attrs: {
      "id": "tooltip-Users"
    },
    on: {
      "mouseover": function ($event) {
        _vm.hoverPanel = 'Users';
      },
      "mouseout": function ($event) {
        _vm.hoverPanel = '';
      },
      "click": function ($event) {
        _vm.selectedPanel = 'Users';
      }
    }
  }, [_c('vb-icon', {
    staticClass: "users-icon rightSidebar_icon",
    attrs: {
      "icon": "users-icon",
      "width": "50",
      "height": "50"
    }
  })], 1), _c('b-popover', {
    attrs: {
      "target": "tooltip-Users",
      "placement": "left",
      "content": "Users",
      "triggers": "hover focus"
    }
  })], 1), _c('li', {
    attrs: {
      "app-tour-web-teams": ""
    }
  }, [_c('a', {
    class: `w-100 d-flex align-items-center justify-content-center ${_vm.selectedPanel === 'Departments' || _vm.hoverPanel === 'Departments' ? 'active' : ''}`,
    attrs: {
      "id": "tooltip-Departments"
    },
    on: {
      "mouseover": function ($event) {
        _vm.hoverPanel = 'Departments';
      },
      "mouseout": function ($event) {
        _vm.hoverPanel = '';
      },
      "click": function ($event) {
        _vm.selectedPanel = 'Departments';
      }
    }
  }, [_c('vb-icon', {
    staticClass: "departments-icon rightSidebar_icon",
    attrs: {
      "icon": "chat-rightSideBarTaemIcon",
      "width": "50",
      "height": "50"
    }
  })], 1), _c('b-popover', {
    attrs: {
      "target": "tooltip-Departments",
      "placement": "left",
      "content": "Teams",
      "triggers": "hover focus"
    }
  })], 1), _c('li', {
    attrs: {
      "app-tour-web-dashboard-addressbook": ""
    }
  }, [_c('a', {
    class: `w-100 d-flex align-items-center justify-content-center ${_vm.selectedPanel === 'Addressbooks' || _vm.hoverPanel === 'Addressbooks' ? 'active' : ''}`,
    attrs: {
      "id": "tooltip-Addressbooks"
    },
    on: {
      "mouseover": function ($event) {
        _vm.hoverPanel = 'Addressbooks';
      },
      "mouseout": function ($event) {
        _vm.hoverPanel = '';
      },
      "click": function ($event) {
        _vm.selectedPanel = 'Addressbooks';
      }
    }
  }, [_c('vb-icon', {
    staticClass: "addressBook-icon rightSidebar_icon",
    attrs: {
      "icon": "addressBook-icon",
      "width": "50",
      "height": "50"
    }
  })], 1), _c('b-popover', {
    attrs: {
      "target": "tooltip-Addressbooks",
      "placement": "left",
      "content": "Address book",
      "triggers": "hover focus"
    }
  })], 1)])]), _c('nav', {
    staticClass: "nav nav-menu"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    attrs: {
      "app-tour-web-support-ticket": ""
    }
  }, [_c('a', {
    staticClass: "w-100 d-flex align-items-center justify-content-center",
    attrs: {
      "id": "tooltip-Support-Team"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('KbInfoModal');
      }
    }
  }, [_c('vb-icon', {
    staticClass: "rightSidebar_icon",
    attrs: {
      "icon": "helpSideBar-icon-v2",
      "width": "50",
      "height": "50"
    }
  })], 1), _c('b-popover', {
    attrs: {
      "target": "tooltip-Support-Team",
      "placement": "left",
      "content": "Help",
      "triggers": "hover focus"
    }
  })], 1), _c('li', [!_vm.$store.getters.isIntegration ? _c('div', {
    staticClass: "betaInRightSideBar position-relative",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        _vm.feed_back = true;
      }
    }
  }, [_c('div', {
    staticClass: "mr-1",
    attrs: {
      "id": "popover-beta-bill"
    }
  }, [_vm._v("Beta")]), _c('b-popover', {
    attrs: {
      "target": "popover-beta-bill",
      "triggers": "hover",
      "placement": "left"
    }
  }, [_c('div', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.left",
      value: 'Register',
      expression: "'Register'",
      modifiers: {
        "hover": true,
        "left": true
      }
    }],
    staticClass: "rightSideBArTimerText b-0 text-center",
    on: {
      "click": function ($event) {
        return _vm.sipRegister();
      }
    }
  }, [_vm._v("Register")]), _c('div', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.left",
      value: 'Un Register',
      expression: "'Un Register'",
      modifiers: {
        "hover": true,
        "left": true
      }
    }],
    staticClass: "rightSideBArTimerText b-0 text-center",
    on: {
      "click": function ($event) {
        return _vm.sipUnRegister();
      }
    }
  }, [_vm._v("Un Register")]), _c('div', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.left",
      value: 'Register Notification',
      expression: "'Register Notification'",
      modifiers: {
        "hover": true,
        "left": true
      }
    }],
    staticClass: "rightSideBArTimerText b-0 text-center"
  }, [_vm._v(_vm._s(_vm.$store.state.sip.state.sip_register_notification))]), _c('div', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.left",
      value: 'Last Register',
      expression: "'Last Register'",
      modifiers: {
        "hover": true,
        "left": true
      }
    }],
    staticClass: "rightSideBArTimerText b-0 text-center"
  }, [_vm._v(_vm._s(_vm.$store.state.sip.phone.last_register_time))]), _c('div', [_c('div', {
    staticStyle: {
      "height": "100px",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm.$store.state.sip.phone.register_logs, function (log, index) {
    return _c('div', {
      key: index
    }, [_vm._v(" " + _vm._s(log.register ? 'Register' : 'Un Register') + " " + _vm._s(log.time) + " ")]);
  }), 0), _c('div', {
    staticStyle: {
      "height": "100px",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm.$store.state.sip.phone.reconnectLogs, function (data, index) {
    return _c('div', {
      key: index
    }, [_vm._v(" " + _vm._s(data.time) + " " + _vm._s(data.attempt) + " " + _vm._s(data.delay) + " ")]);
  }), 0)])]), _vm.isElectron ? _c('b-button', {
    staticClass: "p-0 electronReloadButton",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        return _vm.reload();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latest-reload-icon",
      "width": "16px",
      "height": "16px"
    }
  })], 1) : _vm._e()], 1) : _vm._e()])])]), _vm.feed_back ? _c('div', {
    class: ['betaInRightSideBar-wala new-error-template position-absolute tun', _vm.feed_back ? ' width' : '']
  }, [_c('div', {
    staticClass: "error-message-new"
  }, [_c('b-icon', {
    staticClass: "cross-icon",
    attrs: {
      "icon": "x",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        _vm.feed_back = false;
      }
    }
  }), _c('div', {
    staticClass: "beta-container z-index-0-imp"
  }, [_c('div', {
    staticClass: "beta-text"
  }, [_vm._m(0), _c('span', {
    staticClass: "mt-1"
  }, [_vm._v(" Your "), _c('a', {
    staticStyle: {
      "color": "black",
      "text-decoration": "underline"
    },
    on: {
      "click": function ($event) {
        _vm.$modal.show('FeedBackCommonComponentModal'), _vm.feed_back = false;
      }
    }
  }, [_vm._v("feedback")]), _vm._v(" will help us improve it. ")])])])], 1)]) : _vm._e()] : _vm._e(), _vm.$store.state.css_break_points.b3_rightSideBar ? [_c('nav', {
    staticClass: "nav nav-menu"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    staticClass: "audioSettingIcon-container mt-8px"
  }, [_c('a', {
    class: `w-100 d-flex align-items-center justify-content-center`,
    attrs: {
      "id": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AudioSettingModal');
      }
    }
  }, [_c('vb-icon', {
    staticClass: "rightSidebar_icon",
    attrs: {
      "id": "soundSetting-icon",
      "icon": "soundSetting-icon",
      "width": "50",
      "height": "50"
    }
  })], 1), _vm.media_devices_setting.isJabra ? _c('vb-icon', {
    staticClass: "jabra-icon v2 rightSidebar_icon position-absolute",
    attrs: {
      "icon": "jabra-v2-icon",
      "width": "50",
      "height": "20"
    }
  }) : _vm._e(), _vm.media_devices_setting.isLenovo ? _c('vb-icon', {
    staticClass: "jabra-icon v2 rightSidebar_icon position-absolute",
    attrs: {
      "icon": "lenovo-icon",
      "width": "50",
      "height": "20"
    }
  }) : _vm._e(), _c('b-popover', {
    attrs: {
      "target": "soundSetting-icon",
      "placement": "left",
      "content": "Audio Settings",
      "triggers": "hover focus"
    }
  }), !_vm.$store.state.sip.state.is_connected ? _c('div', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Sip Socket OR Web Socket not connected',
      expression: "'Sip Socket OR Web Socket not connected'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "indicator-light bill-223344-reload"
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-clockwise"
    },
    on: {
      "click": function ($event) {
        return _vm.reConnectUserAgent();
      }
    }
  })], 1) : _vm._e(), !_vm.$store.state.sip.state.is_register ? _c('div', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Sip User is not register',
      expression: "'Sip User is not register'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "indicator-light bill-223344-reload",
    staticStyle: {
      "background-color": "green",
      "right": "20px"
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-clockwise"
    },
    on: {
      "click": function ($event) {
        return _vm.reRegisterUserAgent();
      }
    }
  })], 1) : _vm._e()], 1), _vm.isElectron ? _c('li', {
    staticClass: "mobileViewCheckBox"
  }, [_c('span', {
    staticClass: "mobileViewCheckBox-text"
  }, [_vm._v("Minimize")]), _vm.isElectron ? _c('b-checkbox', {
    staticClass: "minimizedSwitch",
    attrs: {
      "switch": "",
      "value": "mob",
      "unchecked-value": "web"
    },
    model: {
      value: _vm.$store.state.electron.app_view,
      callback: function ($$v) {
        _vm.$set(_vm.$store.state.electron, "app_view", $$v);
      },
      expression: "$store.state.electron.app_view"
    }
  }) : _vm._e()], 1) : _vm._e(), _c('li', [_c('a', {
    class: `w-100 d-flex align-items-center justify-content-center ${_vm.selectedPanel === 'Notifications' || _vm.hoverPanel === 'Notifications' ? 'active' : ''}`,
    attrs: {
      "id": "tooltip-notifications"
    },
    on: {
      "mouseover": function ($event) {
        _vm.hoverPanel = 'Notifications';
      },
      "mouseout": function ($event) {
        _vm.hoverPanel = '';
      },
      "click": function ($event) {
        _vm.selectedPanel = 'Notifications';
      }
    }
  }, [_c('vb-icon', {
    staticClass: "Notifications-icon rightSidebar_icon",
    attrs: {
      "icon": "Notifications-icon",
      "width": "50",
      "height": "50"
    }
  })], 1), _c('b-popover', {
    attrs: {
      "target": "tooltip-notifications",
      "placement": "left",
      "content": "Notifications",
      "triggers": "hover focus"
    }
  })], 1)])]), _c('nav', {
    staticClass: "nav nav-menu"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    attrs: {
      "app-tour-web-dashboard-users": ""
    }
  }, [_c('a', {
    class: `w-100 d-flex align-items-center justify-content-center ${_vm.selectedPanel === 'Users' || _vm.hoverPanel === 'Users' ? 'active' : ''}`,
    attrs: {
      "id": "tooltip-Users"
    },
    on: {
      "mouseover": function ($event) {
        _vm.hoverPanel = 'Users';
      },
      "mouseout": function ($event) {
        _vm.hoverPanel = '';
      },
      "click": function ($event) {
        _vm.selectedPanel = 'Users';
      }
    }
  }, [_c('vb-icon', {
    staticClass: "users-icon rightSidebar_icon",
    attrs: {
      "icon": "users-icon",
      "width": "50",
      "height": "50"
    }
  })], 1), _c('b-popover', {
    attrs: {
      "target": "tooltip-Users",
      "placement": "left",
      "content": "Users",
      "triggers": "hover focus"
    }
  })], 1), _c('li', [_c('b-dropdown', {
    staticClass: "VerticalAppDrawerDD-inRightSideBar",
    attrs: {
      "dropleft": "",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          staticClass: "rightSideBar-hamBurger-icon",
          attrs: {
            "icon": "rightSideBar-hamBurger-icon",
            "width": "50",
            "height": "50"
          }
        })];
      },
      proxy: true
    }], null, false, 3417330806)
  }, [_c('b-dropdown-item', {
    on: {
      "mouseover": function ($event) {
        _vm.hoverPanel = 'SpeedDials';
      },
      "mouseout": function ($event) {
        _vm.hoverPanel = '';
      },
      "click": function ($event) {
        _vm.selectedPanel = 'SpeedDials';
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "speedDial-icon-v3",
      "width": "50",
      "height": "50"
    }
  }), _c('span', [_vm._v("Favourites")])], 1), _c('b-dropdown-item', {
    on: {
      "mouseover": function ($event) {
        _vm.hoverPanel = 'Departments';
      },
      "mouseout": function ($event) {
        _vm.hoverPanel = '';
      },
      "click": function ($event) {
        _vm.selectedPanel = 'Departments';
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "chat-rightSideBarTaemIcon",
      "width": "50",
      "height": "50"
    }
  }), _c('span', [_vm._v("Teams")])], 1), _c('b-dropdown-item', {
    on: {
      "mouseover": function ($event) {
        _vm.hoverPanel = 'Addressbooks';
      },
      "mouseout": function ($event) {
        _vm.hoverPanel = '';
      },
      "click": function ($event) {
        _vm.selectedPanel = 'Addressbooks';
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "addressBook-icon",
      "width": "50",
      "height": "50"
    }
  }), _c('span', [_vm._v("Address book")])], 1)], 1)], 1)])]), _c('nav', {
    staticClass: "nav nav-menu"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    attrs: {
      "app-tour-web-support-ticket": ""
    }
  }, [_c('a', {
    staticClass: "w-100 d-flex align-items-center justify-content-center",
    attrs: {
      "id": "tooltip-Support-Team"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('KbInfoModal');
      }
    }
  }, [_c('vb-icon', {
    staticClass: "rightSidebar_icon",
    attrs: {
      "icon": "helpSideBar-icon-v2",
      "width": "50",
      "height": "50"
    }
  })], 1), _c('b-popover', {
    attrs: {
      "target": "tooltip-Support-Team",
      "placement": "left",
      "content": "Help",
      "triggers": "hover focus"
    }
  })], 1), _c('li', [!_vm.$store.getters.isIntegration ? _c('div', {
    staticClass: "betaInRightSideBar position-relative",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        _vm.feed_back = true;
      }
    }
  }, [_c('div', {
    attrs: {
      "id": "popover-beta-bill"
    }
  }, [_vm._v("Beta")]), _c('b-popover', {
    attrs: {
      "target": "popover-beta-bill",
      "triggers": "hover",
      "placement": "left"
    }
  }, [_c('div', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.left",
      value: 'Register',
      expression: "'Register'",
      modifiers: {
        "hover": true,
        "left": true
      }
    }],
    staticClass: "rightSideBArTimerText b-0 text-center",
    on: {
      "click": function ($event) {
        return _vm.sipRegister();
      }
    }
  }, [_vm._v("Register")]), _c('div', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.left",
      value: 'Un Register',
      expression: "'Un Register'",
      modifiers: {
        "hover": true,
        "left": true
      }
    }],
    staticClass: "rightSideBArTimerText b-0 text-center",
    on: {
      "click": function ($event) {
        return _vm.sipUnRegister();
      }
    }
  }, [_vm._v("Un Register")]), _c('div', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.left",
      value: 'Register Notification',
      expression: "'Register Notification'",
      modifiers: {
        "hover": true,
        "left": true
      }
    }],
    staticClass: "rightSideBArTimerText b-0 text-center"
  }, [_vm._v(_vm._s(_vm.$store.state.sip.state.sip_register_notification))]), _c('div', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.left",
      value: 'Last Register',
      expression: "'Last Register'",
      modifiers: {
        "hover": true,
        "left": true
      }
    }],
    staticClass: "rightSideBArTimerText b-0 text-center"
  }, [_vm._v(_vm._s(_vm.$store.state.sip.phone.last_register_time))]), _c('div', [_c('div', {
    staticStyle: {
      "height": "100px",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm.$store.state.sip.phone.register_logs, function (log, index) {
    return _c('div', {
      key: index
    }, [_vm._v(" " + _vm._s(log.register ? 'Register' : 'Un Register') + " " + _vm._s(log.time) + " ")]);
  }), 0), _c('div', {
    staticStyle: {
      "height": "100px",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm.$store.state.sip.phone.reconnectLogs, function (data, index) {
    return _c('div', {
      key: index
    }, [_vm._v(" " + _vm._s(data.time) + " " + _vm._s(data.attempt) + " " + _vm._s(data.delay) + " ")]);
  }), 0)])])], 1) : _vm._e()])])]), _vm.feed_back ? _c('div', {
    class: ['betaInRightSideBar-wala new-error-template position-absolute tun', _vm.feed_back ? ' width' : '']
  }, [_c('div', {
    staticClass: "error-message-new"
  }, [_c('b-icon', {
    staticClass: "cross-icon",
    attrs: {
      "icon": "x",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        _vm.feed_back = false;
      }
    }
  }), _c('div', {
    staticClass: "beta-container z-index-0-imp"
  }, [_c('div', {
    staticClass: "beta-text"
  }, [_vm._m(1), _c('span', {
    staticClass: "mt-1"
  }, [_vm._v(" Your "), _c('a', {
    staticStyle: {
      "color": "black",
      "text-decoration": "underline"
    },
    on: {
      "click": function ($event) {
        _vm.$modal.show('FeedBackCommonComponentModal'), _vm.feed_back = false;
      }
    }
  }, [_vm._v("feedback")]), _vm._v(" will help us improve it. ")])])])], 1)]) : _vm._e()] : _vm._e()], 2)] : _vm._e(), _vm.$store.getters.getIsMobile && !['settings'].includes(_vm.$store.state.global_conditions.tab) ? _c('div', {
    staticClass: "display-only-mobile"
  }, [_c('div', {
    staticClass: "top-icons-container"
  }, [_vm.$store.getters.getIsMobile ? _c('UserProfile') : _vm._e(), _c('div', {
    staticClass: "d-flex position-relative logo_mobile_container"
  }, [!_vm.$store.state.sip.state.is_connected ? _c('div', [_c('div', {
    staticClass: "indicator-light",
    attrs: {
      "id": "indicator-light-tooltip"
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-clockwise"
    },
    on: {
      "click": function ($event) {
        return _vm.reload();
      }
    }
  })], 1), _c('b-popover', {
    attrs: {
      "target": "indicator-light-tooltip",
      "content": "Sip Socket OR Web Socket not connected",
      "triggers": "hover"
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "d-flex align-items-center soundSetting-icon-container"
  }, [!_vm.$store.getters.isIntegration ? _c('vb-icon', {
    attrs: {
      "id": "soundSetting-icon",
      "icon": "mobileSoundSettings-updated-icon",
      "width": "34.337",
      "height": "34.337"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AudioSettingModal');
      }
    }
  }) : _vm._e()], 1)], 1)]) : _vm._e(), _c('ContactPanel', {
    ref: "ContactRef",
    on: {
      "interface": function ($event) {
        return _vm.RightPanel();
      },
      "dial": function ($event) {
        return _vm.dialNow($event);
      },
      "videocall": function ($event) {
        return _vm.videoCall($event);
      }
    }
  }), _c('AudioSettingModal'), _c('vb-icon', {
    staticClass: "mainLogoOverlay",
    attrs: {
      "icon": "voipBusiness-mainLogo",
      "height": "38px",
      "width": "180px"
    }
  })], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "beta-button"
  }, [_vm._v("Beta")]), _c('span', [_vm._v("This is a new service. ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "beta-button"
  }, [_vm._v("Beta")]), _c('span', [_vm._v("This is a new service. ")])]);

}]

export { render, staticRenderFns }