var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-analytics-main dialer-analytics-users"
  }, [_c('div', {
    staticClass: "dialer-box p-16-24-px"
  }, [_vm.getIsMobile ? [_c('header', {
    staticClass: "dialer-analytics-titleBar pb-1"
  }, [_c('div', {
    staticClass: "dialer-analytics-title calc-title-width-analytics"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h1', {}, [_vm._v("Users")])], 1)]), _c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterWithText mr-16px"
  }, [_c('b-dropdown', {
    staticClass: "ico-filter",
    attrs: {
      "varient": "link",
      "no-caret": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_vm.response.users.data && _vm.response.users.data.date_label ? _c('span', {
          staticClass: "mr-12px"
        }, [_vm._v(_vm._s(_vm.response.users.data.date_label))]) : _vm._e(), _c('b-icon', {
          staticClass: "fillBlack",
          attrs: {
            "icon": "three-dots-vertical",
            "variant": "dark",
            "scale": "1.5"
          }
        })];
      },
      proxy: true
    }], null, false, 265551099)
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('b-dropdown-item', {
      key: tab.value,
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.value;
          tab.value == _vm.tabs.custom.value ? _vm.$modal.show(`${_vm._uid}-dateFilterModal`) : _vm.fetchUsers();
        }
      }
    }, [_c('vb-icon', {
      staticClass: "mr-12px",
      style: `opacity:${_vm.selected.tab === tab.value ? '1' : '0'}`,
      attrs: {
        "icon": "markAsComplete-icon",
        "width": "14.49px",
        "height": "10.431px"
      }
    }), _c('span', [_vm._v(_vm._s(tab.text))])], 1);
  }), 1)], 1)]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder seachBox w-100 mb-16px mt-8px"
  }, [_c('b-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Search user"
    },
    model: {
      value: _vm.filter.users.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.users, "search", $$v);
      },
      expression: "filter.users.search"
    }
  })], 1)] : _c('div', {
    staticClass: "analytics-newHeader mb-32px"
  }, [_vm._m(0), _c('div', {
    staticClass: "analytics-newHeader-rightSide"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterWithText mr-16px"
  }, [_c('b-dropdown', {
    staticClass: "ico-filter",
    attrs: {
      "varient": "link",
      "no-caret": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_vm.response.users.data && _vm.response.users.data.date_label ? _c('span', {
          staticClass: "mr-12px"
        }, [_vm._v(_vm._s(_vm.response.users.data.date_label))]) : _vm._e(), _c('b-icon', {
          staticClass: "fillBlack",
          attrs: {
            "icon": "three-dots-vertical",
            "variant": "dark",
            "scale": "1.5"
          }
        })];
      },
      proxy: true
    }])
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('b-dropdown-item', {
      key: tab.value,
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.value;
          tab.value == _vm.tabs.custom.value ? _vm.$modal.show(`${_vm._uid}-dateFilterModal`) : _vm.fetchUsers();
        }
      }
    }, [_c('vb-icon', {
      staticClass: "mr-12px",
      style: `opacity:${_vm.selected.tab === tab.value ? '1' : '0'}`,
      attrs: {
        "icon": "markAsComplete-icon",
        "width": "14.49px",
        "height": "10.431px"
      }
    }), _c('span', [_vm._v(_vm._s(tab.text))])], 1);
  }), 1)], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder seachBox"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search user"
    },
    model: {
      value: _vm.filter.users.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.users, "search", $$v);
      },
      expression: "filter.users.search"
    }
  })], 1)])]), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again",
    attrs: {
      "isListEmpty": _vm.users.length == 0,
      "listLength": _vm.users.length,
      "loading": _vm.api.fetch_users.send,
      "noRecordWholePage": true
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [!_vm.getIsMobile ? _c('tr', [_c('th', {
          staticClass: "dialer-has-sort"
        }, [_c('span', [_vm._v("User")])]), _c('th', {
          staticClass: "dialer-has-sort dialer-col-center"
        }, [_c('span', [_vm._v("Total")])]), _c('th', {
          staticClass: "dialer-has-sort dialer-col-center dialer-inbound"
        }, [_c('span', [_vm._v("Received")])]), _c('th', {
          staticClass: "dialer-has-sort dialer-col-center dialer-outbound"
        }, [_c('span', [_vm._v("Outbound")])]), _c('th', {
          staticClass: "dialer-has-sort dialer-col-center dialer-missed"
        }, [_c('span', [_vm._v("Missed")])]), !_vm.getIsMobile ? _c('th', {
          staticClass: "dialer-has-sort dialer-col-right"
        }, [_c('span', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover.hover.focus.top.html",
            value: '<small>Total call duration</small>',
            expression: "'<small>Total call duration</small>'",
            modifiers: {
              "hover": true,
              "focus": true,
              "top": true,
              "html": true
            }
          }],
          attrs: {
            "id": "tcdTooltip"
          }
        }, [_vm._v("TCD")])]) : _vm._e(), !_vm.getIsMobile ? _c('th', {
          staticClass: "dialer-has-sort dialer-col-right"
        }, [_c('span', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover.hover.focus.top.html",
            value: '<small>Average call duration</small>',
            expression: "'<small>Average call duration</small>'",
            modifiers: {
              "hover": true,
              "focus": true,
              "top": true,
              "html": true
            }
          }],
          attrs: {
            "id": "tcdTooltip"
          }
        }, [_vm._v("ACD")])]) : _vm._e(), !_vm.getIsMobile ? _c('th', {
          staticClass: "dialer-has-sort dialer-col-right"
        }, [_c('span', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover.hover.focus.top.html",
            value: '<small>Time to answer</small>',
            expression: "'<small>Time to answer</small>'",
            modifiers: {
              "hover": true,
              "focus": true,
              "top": true,
              "html": true
            }
          }],
          attrs: {
            "id": "tcdTooltip"
          }
        }, [_vm._v("TTA")])]) : _vm._e()]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return [!_vm.getIsMobile ? _vm._l(_vm.users, function (user, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: user.accountcode,
            staticClass: "dialer-row-select",
            on: {
              "click": function ($event) {
                return _vm.$modal.show('NumberAnalyticsModal', {
                  accountcode: user.accountcode,
                  user: user.user,
                  time_line: user.time_line
                });
              }
            }
          }, [_c('td', {
            staticClass: "dialer-col-name-width wd-75"
          }, [_c('div', {
            staticClass: "dialer-wrap-initials d-flex"
          }, [_c('Info', {
            attrs: {
              "id": user.accountcode,
              "is_blf": false
            }
          })], 1)]), _c('td', {
            staticClass: "dialer-col-center wd-25"
          }, [_c('span', {
            staticClass: "display-responsive mr-2"
          }, [_vm._v("TOTAL :")]), _vm._v(" " + _vm._s(user.user.totalCalls) + " ")]), _c('td', {
            staticClass: "dialer-col-center dialer-inbound wd-25"
          }, [_c('span', {
            staticClass: "display-responsive mr-2"
          }, [_vm._v("INBOUND :")]), _vm._v(" " + _vm._s(user.user.totalReceiveCalls) + " ")]), _c('td', {
            staticClass: "dialer-col-center dialer-outbound wd-25"
          }, [_c('span', {
            staticClass: "display-responsive mr-2"
          }, [_vm._v("OUTBOUND :")]), _vm._v(" " + _vm._s(user.user.totalOutgoingCalls) + " ")]), _c('td', {
            staticClass: "dialer-col-center dialer-missed wd-25"
          }, [_c('span', {
            staticClass: "display-responsive mr-2"
          }, [_vm._v("MISSED :")]), _vm._v(" " + _vm._s(user.user.totalMissCalls) + " ")]), !_vm.$store.getters.getIsMobile ? _c('td', {
            staticClass: "dialer-col-right wd-25"
          }, [_c('span', {
            staticClass: "display-responsive mr-2"
          }, [_vm._v("TCD :")]), _vm._v(" " + _vm._s(_vm._f("time_audio")(user.kpis.tcd)) + " ")]) : _vm._e(), !_vm.$store.getters.getIsMobile ? _c('td', {
            staticClass: "dialer-col-right wd-25"
          }, [_c('span', {
            staticClass: "display-responsive mr-2"
          }, [_vm._v("ACD :")]), _vm._v(" " + _vm._s(_vm._f("time_audio")(user.kpis.acd)) + " ")]) : _vm._e(), !_vm.$store.getters.getIsMobile ? _c('td', {
            staticClass: "dialer-col-right wd-25"
          }, [_c('span', {
            staticClass: "display-responsive mr-2"
          }, [_vm._v("TTA :")]), _vm._v(" " + _vm._s(_vm._f("time_audio")(user.kpis.tta)) + " ")]) : _vm._e()]);
        }) : _vm._l(_vm.users, function (user, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: user.accountcode,
            staticClass: "dialer-row-select",
            on: {
              "click": function ($event) {
                return _vm.$modal.show('NumberAnalyticsModal', {
                  accountcode: user.accountcode,
                  user: user.user,
                  time_line: user.time_line
                });
              }
            }
          }, [_c('td', {
            staticClass: "left"
          }, [_c('div', {
            staticClass: "dialer-wrap-initials d-flex"
          }, [_c('Info', {
            attrs: {
              "id": user.accountcode,
              "is_blf": false
            }
          })], 1)]), _c('td', {
            staticClass: "dialer-col-right right"
          }, [_c('div', {
            staticClass: "d-flex align-items-center justify-content-end"
          }, [_c('div', {
            staticClass: "d-flex mr-6px"
          }, [_c('div', {
            staticClass: "textInsideCard onRightSide textAlignRight",
            staticStyle: {
              "color": "#87868C"
            }
          }, [_vm._v(_vm._s(_vm._f("get_property")(user, 'user.totalCalls', 0)))]), _c('div', {
            staticClass: "textInsideCard onRightSide textAlignRight mx-1"
          }, [_vm._v("/")]), _c('div', {
            staticClass: "textInsideCard onRightSide textAlignRight",
            staticStyle: {
              "color": "#FF1900"
            }
          }, [_vm._v(_vm._s(_vm._f("get_property")(user, 'user.totalMissCalls', 0)))])]), _c('vb-icon', {
            staticClass: "rightFacingArrow",
            attrs: {
              "icon": "rightFacingArrow-IOSIcon",
              "width": "16.642",
              "height": "15.275"
            }
          })], 1)])]);
        })];
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "35px",
              "width": "35px"
            }
          }), _c('div', {
            staticClass: "d-flex flex-column justify-content-between"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "16px",
              "width": "60px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign mt-1",
            staticStyle: {
              "height": "16px",
              "width": "30px"
            }
          })])])]), _c('td', [_c('div', {
            staticClass: "d-flex flex-column align-items-center w-100"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mt-2",
            staticStyle: {
              "height": "16px",
              "width": "50px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex flex-column align-items-center w-100"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mt-2",
            staticStyle: {
              "height": "16px",
              "width": "50px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex flex-column align-items-center w-100"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mt-2",
            staticStyle: {
              "height": "16px",
              "width": "50px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex flex-column align-items-center w-100"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mt-2",
            staticStyle: {
              "height": "16px",
              "width": "50px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex flex-column align-items-center w-100"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mt-2",
            staticStyle: {
              "height": "16px",
              "width": "100%"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex flex-column align-items-center w-100"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mt-2",
            staticStyle: {
              "height": "16px",
              "width": "100%"
            }
          })])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "16px",
              "width": "35px"
            }
          })])]);
        });
      },
      proxy: true
    }, {
      key: "no-record",
      fn: function () {
        return [_c('div', {
          staticClass: "latestGreyBox-9-9-2023 mb-32px"
        }, [_c('div', {
          staticClass: "emptyScreenContainer"
        }, [_vm.GET_LOCAL_SETTING_IS_DARK_MODE ? _c('img', {
          staticClass: "mt-50px w-50 h-auto",
          attrs: {
            "src": require('@/assets/images/emptyScreenImages/darkMode/usersanalytics.png')
          }
        }) : _c('img', {
          staticClass: "mt-50px w-50 h-auto",
          attrs: {
            "src": require('@/assets/images/emptyScreenImages/usersanalytics.png')
          }
        }), _c('div', {
          staticClass: "emptyScreenContainer-heading"
        }, [_vm._v(" User analytics: discover user activity insights ")]), _c('div', {
          staticClass: "emptyScreenContainer-text w-75"
        }, [_vm._v(" Uncover valuable insights into user call patterns, durations, and more with our detailed analytics. ")]), _c('button', {
          staticClass: "dialer-button dialer-button-primary mt-20px"
        }, [_vm._v(" Analyze users ")])])])];
      },
      proxy: true
    }])
  })], 2), _c('modal', {
    staticClass: "callReportingFilterModal",
    attrs: {
      "name": `${_vm._uid}-dateFilterModal`
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0 position-relative"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Filters")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-dateFilterModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "analytics-newHeader-text"
  }, [_c('div', {
    staticClass: "d-flex customDate"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionLeftAligned w-50 pr-2"
  }, [_c('span', [_vm._v("From:")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input",
    attrs: {
      "id": "starttime"
    },
    model: {
      value: _vm.filter.users.start_time,
      callback: function ($$v) {
        _vm.$set(_vm.filter.users, "start_time", $$v);
      },
      expression: "filter.users.start_time"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionRightAligned w-50 pl-2"
  }, [_c('span', [_vm._v("To:")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input",
    attrs: {
      "min-date": _vm.filter.users.start_time,
      "id": "endtime"
    },
    model: {
      value: _vm.filter.users.end_time,
      callback: function ($$v) {
        _vm.$set(_vm.filter.users, "end_time", $$v);
      },
      expression: "filter.users.end_time"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1)]), _c('div', {
    staticClass: "w-100 d-flex justify-content-end mt-20px"
  }, [_c('a', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        _vm.fetchUsers();
        _vm.$modal.hide(`${_vm._uid}-dateFilterModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-browse-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Fetch")])], 1)])])])]), _c('NumberAnalyticsModal')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "analytics-newHeader-leftSide"
  }, [_c('h1', [_vm._v("Users")])]);

}]

export { render, staticRenderFns }