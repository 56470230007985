var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content-area d-flex h-100 dialer_main_body forTODOList-main"
  }, [_c('div', {
    staticClass: "dialer_main_body_inner w-100"
  }, [_c('div', {
    class: `dialer_inner_left_side forTODOList-sidebar d-r-768px-none ${_vm.conditions.show_side_bar ? 'open' : ''}`
  }, [_vm.getIsMobile ? _c('h2', {
    staticClass: "dialer_main_title d-flex justify-content-between align-items-center"
  }, [_vm._v(" Auto Dialer "), _c('div', {
    on: {
      "click": function ($event) {
        _vm.conditions.show_side_bar = false;
      }
    }
  }, [_c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x-lg"
    }
  })], 1)]) : _c('h2', {
    staticClass: "dialer_main_title d-flex justify-content-between align-items-center"
  }, [_vm._v(" Auto Dialer "), _c('div', {
    staticClass: "showOnlyOnSmallerScreens",
    on: {
      "click": function ($event) {
        _vm.conditions.show_side_bar = false;
      }
    }
  }, [_c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.5"
    }
  })], 1)]), _c('div', {
    staticClass: "dialer_inner_left_side_inner allow-scroll"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    class: `dialer_tab_btn active`
  }, [_c('a', [_c('span', {
    staticClass: "dialer-settingPanel-icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "PowerDialer-ListOfContents-icon",
      "width": "19.996",
      "height": "20"
    }
  })], 1), _c('span', {
    staticClass: "dialer-settingPanel-text"
  }, [_vm._v("List of Contacts")])])])])])]), _c('div', {
    staticClass: "dialer_inner_right_side"
  }, [_c('div', {
    staticClass: "dialer-settings-main"
  }, [_vm.getIsMobile ? _c('section', {
    staticClass: "dialer-settings-section locationSettings"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-width"
  }, [_c('vb-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "latest-sideBarOpenIconForTodoInMobileView-icon",
      "width": "19.979px",
      "height": "16px"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.show_side_bar = true;
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center flex-1"
  }, [_vm._v("List of contacts")])], 1), _c('div', [_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia rutrum quam, at viverra urna tincidunt in. Suspendisse mattis lacus dapibus leo iaculis consectetur. ")])]) : _c('section', {
    staticClass: "dialer-settings-section locationSettings newTasksModule"
  }, [_c('div', [_c('div', {
    staticClass: "settingsFirstSection"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "settings-back-icon cursor_pointer showOnlyOnSmallerScreens mr-12px",
    attrs: {
      "icon": "latest-sideBarOpenIconForTodoInMobileView-icon",
      "width": "19.979px",
      "height": "16px"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.show_side_bar = true;
      }
    }
  }), _vm._v(" List of contacts ")], 1)]), _c('div', {
    staticClass: "settingsFirstSection-right"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end align-items-center mt-0"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder sm-mar-left sm-mar-right seachBox"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search for contacts"
    }
  })], 1), _c('button', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddListModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart position-relative d-flex justify-content-center"
  }, [[_vm._v("Add list")]], 2)], 1)])])]), _vm._m(0)]), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again IVRTable",
    attrs: {
      "id": "dddd-table",
      "textNoRecord": " ",
      "isListEmpty": false,
      "listLength": 10,
      "loading": false,
      "noRecordWholePage": true
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return [_vm._l(10, function (n, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: index
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('div', {
            staticClass: "d-flex align-items-center justify-content-between"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('vb-icon', {
            staticClass: "mr-16px",
            attrs: {
              "icon": "PowerDialer-NumberListTD-icon",
              "width": "35px",
              "height": "35px"
            }
          }), _c('span', [_vm._v("Number List " + _vm._s(n))])], 1)])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "id": _vm.getCurrentUser.account,
              "is_suspended_show": ""
            }
          })], 1), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "d-flex justify-content-end"
          }, [_c('div', {
            staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD"
          }, [_c('b-dropdown', {
            attrs: {
              "right": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('b-icon', {
                  attrs: {
                    "icon": "three-dots-vertical",
                    "scale": "1.5"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', [_vm._v("Edit")]), _c('b-dropdown-item', [_vm._v("Delete")])], 1)], 1)])])]);
        })];
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "35px",
              "width": "35px"
            }
          }), _c('div', {
            staticClass: "d-flex flex-column justify-content-between"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "16px",
              "width": "60px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign mt-1",
            staticStyle: {
              "height": "16px",
              "width": "30px"
            }
          })])])]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "50%"
            }
          })]), _c('td', [_c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "85px"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "48px",
              "width": "48px"
            }
          })])]);
        });
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "header"
  }, [!_vm.$store.getters.getIsMobile ? _c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("List name")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Assigned to")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right"
  }, [_c('span', {
    staticClass: "for-flex-column"
  }, [_vm._v("Action")])])]) : _vm._e()])], 2)], 1)])])]), _c('AddListModal')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settingsSecondSection"
  }, [_c('span', {
    staticClass: "dialer-settings-intro newerTextVersion mt-0 mb-0"
  }, [_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia rutrum quam, at viverra urna tincidunt in. Suspendisse mattis lacus dapibus leo iaculis consectetur. ")])]);

}]

export { render, staticRenderFns }