var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content-area d-flex h-100 dialer_main_body forTODOList-main"
  }, [_c('div', {
    staticClass: "dialer_main_body_inner w-100"
  }, [_c('div', {
    class: `dialer_inner_left_side forTODOList-sidebar d-r-768px-none ${_vm.conditions.show_side_bar ? 'open' : ''}`
  }, [_vm.getIsMobile ? _c('h2', {
    staticClass: "dialer_main_title d-flex justify-content-between align-items-center"
  }, [_vm._v(" Task hub "), _c('div', {
    on: {
      "click": function ($event) {
        _vm.conditions.show_side_bar = false;
      }
    }
  }, [_c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x-lg"
    }
  })], 1)]) : _c('h2', {
    staticClass: "dialer_main_title d-flex justify-content-between align-items-center"
  }, [_vm._v(" Task hub "), _c('div', {
    staticClass: "showOnlyOnSmallerScreens",
    on: {
      "click": function ($event) {
        _vm.conditions.show_side_bar = false;
      }
    }
  }, [_c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.5"
    }
  })], 1)]), _c('div', {
    staticClass: "dialer_inner_left_side_inner allow-scroll"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, _vm._l(_vm.topFlags, function (flag) {
    return _c('li', {
      key: flag.value,
      class: `dialer_tab_btn ${_vm.selected.flag == flag.value ? 'active' : ''}`,
      on: {
        "click": function ($event) {
          _vm.selected.flag = flag.value;
        }
      }
    }, [_c('a', [_c('span', {
      staticClass: "dialer-settingPanel-icon"
    }, [_c('vb-icon', {
      attrs: {
        "icon": flag.icon,
        "width": "19.996",
        "height": "20"
      }
    })], 1), _c('span', {
      staticClass: "dialer-settingPanel-text"
    }, [_vm._v(" " + _vm._s(flag.text) + " ")])])]);
  }), 0), _c('h2', {
    staticClass: "dialer_main_title d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "todo-allSpaces-icon",
      "width": "23.725px",
      "height": "22.076px"
    }
  }), _vm._v(" Spaces ")], 1), _c('ul', {
    staticClass: "list-unstyled TODOListVersion"
  }, [_vm._l(_vm.response.spaces, function (space, space_id) {
    return _c('li', {
      key: space_id,
      class: `dialer_tab_btn ${_vm.selected.space_ids.includes(space_id) ? 'active' : ''}`,
      on: {
        "click": function ($event) {
          _vm.selected.space_ids.includes(space_id) ? _vm.selected.space_ids.splice(_vm.selected.space_ids.indexOf(space_id), 1) : _vm.selected.space_ids.push(space_id);
        }
      }
    }, [_c('a', [_c('span', {
      staticClass: "dialer-settingPanel-icon"
    }, [_c('div', {
      staticClass: "smallColoredTag twentyPX ml-0",
      style: `background-color: ${space.color};`
    })]), _c('span', {
      staticClass: "dialer-settingPanel-text"
    }, [_vm._v(" " + _vm._s(space.name) + " ")]), _c('div', {
      staticClass: "d-flex align-items-center color-white"
    }, [space.status != 'private' && space.created_by == _vm.getCurrentUser.account ? _c('b-icon', {
      staticClass: "ml-2 darker-chevron-down withoutPath makeItRed showThisSpecificallyOnlyOnHover",
      attrs: {
        "icon": "trash",
        "variant": "danger"
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.removeSpaces(space);
        }
      }
    }) : _vm._e(), (space.status == 'private' && space.type != 'public' ? _vm.getCurrentUser.administrator_account : space.created_by == _vm.getCurrentUser.account) ? _c('b-icon', {
      staticClass: "ml-2 darker-chevron-down withoutPath showThisSpecificallyOnlyOnHover",
      attrs: {
        "icon": "pencil-square"
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.$modal.show('EditSpace', {
            space_id: space_id
          });
        }
      }
    }) : _vm._e(), _c('b-icon', {
      staticClass: "darker-chevron-down withoutPath",
      style: `opacity: ${_vm.selected.space_ids.includes(space_id) ? 1 : 0}`,
      attrs: {
        "icon": "check-lg"
      }
    })], 1)])]);
  }), _vm.response.spaces.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.spaces.send ? '' : 'There is no category',
      "design": 3
    }
  }) : _vm._e(), _vm.response.spaces.length == 0 && _vm.api.spaces.send ? _vm._l(3, function (n) {
    return _c('li', {
      key: n.id,
      staticClass: "w-100 mb-3 px-4"
    }, [_c('div', {
      staticClass: "latestShimmerDesign mt-2",
      staticStyle: {
        "height": "32px",
        "width": "100%"
      }
    })]);
  }) : _vm._e(), _c('li', {
    staticClass: "dialer_tab_btn",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddTaskSpace');
      }
    }
  }, [_c('a', {}, [_c('span', {
    staticClass: "dialer-settingPanel-icon"
  }, [_c('b-icon', {
    attrs: {
      "icon": "plus-lg"
    }
  })], 1), _c('span', {
    staticClass: "dialer-settingPanel-text TODO-smallInnerHeading"
  }, [_vm._v(" New space ")])])])], 2)]), _c('ul', {
    staticClass: "list-unstyled archivedList-container"
  }, _vm._l(_vm.bottomFlags, function (flag) {
    return _c('li', {
      key: flag.value,
      class: `dialer_tab_btn ${_vm.selected.flag == flag.value ? 'active' : ''}`,
      on: {
        "click": function ($event) {
          _vm.selected.flag = flag.value;
        }
      }
    }, [_c('a', [_c('span', {
      staticClass: "dialer-settingPanel-icon"
    }, [_c('vb-icon', {
      attrs: {
        "icon": flag.icon,
        "width": "19.996",
        "height": "20"
      }
    })], 1), _c('span', {
      staticClass: "dialer-settingPanel-text"
    }, [_vm._v(" " + _vm._s(flag.text) + " ")])])]);
  }), 0)]), _c('div', {
    staticClass: "dialer_inner_right_side"
  }, [_vm.selected.space_ids.length == 0 ? _c('div', {
    staticClass: "dialer-settings-main"
  }, [_vm.getIsMobile ? _c('section', {
    staticClass: "dialer-settings-section locationSettings"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-width"
  }, [_c('vb-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "latest-sideBarOpenIconForTodoInMobileView-icon",
      "width": "19.979px",
      "height": "16px"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.show_side_bar = true;
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center flex-1"
  }, [_vm._v("My tasks")])], 1), _c('div', [_vm._v("Please select a space in order to see your list of tasks")])]) : _c('section', {
    staticClass: "dialer-settings-section locationSettings newTasksModule"
  }, [_c('div', [_c('div', {
    staticClass: "dialer-flex"
  }, [_c('h2', {
    staticClass: "dialer-settings-title d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "settings-back-icon cursor_pointer showOnlyOnSmallerScreens mr-12px",
    attrs: {
      "icon": "latest-sideBarOpenIconForTodoInMobileView-icon",
      "width": "19.979px",
      "height": "16px"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('openSideBar');
      }
    }
  }), _vm._v(" My tasks ")], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-32px"
  }, [_c('div', {
    staticClass: "emptyScreenContainer"
  }, [_vm.GET_LOCAL_SETTING_IS_DARK_MODE ? _c('img', {
    staticClass: "mt-12px wd-30 h-auto",
    attrs: {
      "src": require('@/assets/images/emptyScreenImages/darkMode/chat.png')
    }
  }) : _c('img', {
    staticClass: "mt-12px wd-30 h-auto",
    attrs: {
      "src": require('@/assets/images/emptyScreenImages/chat.png')
    }
  }), _c('div', {
    staticClass: "emptyScreenContainer-heading"
  }, [_vm._v(" Select a space to view tasks ")]), _c('div', {
    staticClass: "emptyScreenContainer-text w-75"
  }, [_vm._v(" Seamlessly manage tasks, assign call-related actions, and track priorities within our task module. Streamline your workflow for enhanced productivity. ")])])])])])]) : _c('NewTasks', {
    ref: `${_vm._uid}-tasks`,
    attrs: {
      "flag": _vm.selected.flag,
      "spaces": _vm.selectedSpaces
    },
    on: {
      "openSideBar": function ($event) {
        _vm.conditions.show_side_bar = true;
      }
    }
  })], 1)]), _c('AddTaskSpaceModal', {
    on: {
      "created": function ($event) {
        return _vm.getSpaces();
      }
    }
  }), _c('EditSpaceModal', {
    on: {
      "update-list": function ($event) {
        return _vm.getSpaces();
      },
      "group-update": function ($event) {
        _vm.$refs[`${_vm._uid}-tasks`].fetchGroupsAndStatuses();
      },
      "group-create": function ($event) {
        _vm.$refs[`${_vm._uid}-tasks`].fetchGroupsAndStatuses();
      },
      "group-remove": function ($event) {
        _vm.$refs[`${_vm._uid}-tasks`].fetchGroupsAndStatuses();
      },
      "status-update": function ($event) {
        _vm.$refs[`${_vm._uid}-tasks`].fetchGroupsAndStatuses();
      },
      "status-create": function ($event) {
        _vm.$refs[`${_vm._uid}-tasks`].fetchGroupsAndStatuses();
      },
      "status-remove": function ($event) {
        _vm.$refs[`${_vm._uid}-tasks`].fetchGroupsAndStatuses();
      }
    }
  }), _c('SelectSpaceGroupAndStatusModal', {
    attrs: {
      "modalName": `TodoTaskMove`
    },
    on: {
      "moved": function ($event) {
        _vm.$refs[`${_vm._uid}-tasks`].getTasks(true);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }