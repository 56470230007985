<template>
  <div class="dialer-analytics-main dialer-analytics-teams">
    <div class="dialer-box p-16-24-px"> 
      <!-- <header class="dialer-analytics-titleBar">
        <template v-if="getIsMobile">
          <div class="dialer-analytics-title calc-title-width-analytics">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h1 class="">Teams</h1>
          </div>
        </template>
        <div v-else class="dialer-analytics-title">
          <b-icon icon="people" variant="primary"></b-icon>
          <h1 class="md-mar-left">Teams</h1>
        </div>
        <div class="TabsDesignWithIcon-container">
          <div class="TabsDesignWithIcon-item mr-8px" 
            v-for="tab in tabs" :key="tab.value" 
            @click="selected.tab=tab.value;tab.value==tabs.custom.value ? '' : fetchTeams()" 
            :class="{'active': selected.tab === tab.value}"
          >
            <vb-icon :icon="tab.icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">{{tab.text}}</span>
          </div>
        </div>
      </header>
      <div class="dialer-analytics-datescroller">
        <h3 class="dialer-analytics-subtitle mb-0">
          <span v-if="selected.tab==tabs.custom.value">Select dates</span>
          <span v-else-if="response.teams.data && response.teams.data.date_label">{{ response.teams.data.date_label }}</span>
        </h3>
        <div style="flex-direction: column;align-items: flex-end;flex-direction: column;" class="d-flex">
          <div v-if="selected.tab==tabs.custom.value" class="d-flex customDate">
            <div class="d-inline-flex align-items-center mr-3 whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker">
              <span>From:</span>
              <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" class="ml-2 date-time-input" v-model="filter.teams.start_time" id="starttime" />
            </div>
            <div class="d-inline-flex align-items-center mr-4 whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionRightAligned">
              <span>To:</span>
              <vue-ctk-date-time-picker :min-date="filter.teams.start_time" v-bind="ctkDateTimePickerOptions" class="ml-2 date-time-input" v-model="filter.teams.end_time" id="endtime" />
            </div>
            <a class="newButton" @click="fetchTeams()">
              <vb-icon icon="squared-browse-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Fetch</span>
            </a>
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder seachBox sm-mar-left mt-3">
            <b-input type="text" placeholder="Search user" v-model="filter.teams.search" />
          </div>
        </div>
      </div> -->
      <template v-if="getIsMobile">
        <header class="dialer-analytics-titleBar pb-1">
          <div class="dialer-analytics-title calc-title-width-analytics">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h1 class="">Teams</h1>
          </div>
        </header>
        <div class="w-100 d-flex align-items-center justify-content-end">
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterWithText mr-16px">
            <b-dropdown varient="link" no-caret class="ico-filter" right> 
              <template #button-content>
                <span class="mr-12px" v-if="response.teams.data && response.teams.data.date_label">{{ response.teams.data.date_label }}</span>
                <b-icon icon="three-dots-vertical" variant="dark" class="fillBlack" scale="1.5" />
              </template>
              <b-dropdown-item v-for="tab in tabs" :key="tab.value" @click="selected.tab=tab.value;tab.value==tabs.custom.value ? $modal.show(`${_uid}-dateFilterModal`) : fetchTeams()" >
                <vb-icon icon="markAsComplete-icon" :style="`opacity:${selected.tab === tab.value ? '1' : '0'}`" class="mr-12px" width="14.49px" height="10.431px"/>
                <span >{{tab.text}}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="whiteBGinputWithGreyRoundedBorder seachBox w-100 mb-16px mt-8px">
          <b-input type="text" placeholder="Search team" class="w-100" v-model="filter.teams.search" />
        </div>
      </template>
      <div v-else class="analytics-newHeader mb-32px">
        <div class="analytics-newHeader-leftSide">
          <!-- <b-icon icon="people" variant="primary"></b-icon> -->
          <!-- class="md-mar-left" -->
          <h1 >Teams</h1>
        </div>
        <div class="analytics-newHeader-rightSide">
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterWithText mr-16px">
            <b-dropdown varient="link" no-caret class="ico-filter" right> 
              <template #button-content>
                <span class="mr-12px" v-if="response.teams.data && response.teams.data.date_label">{{ response.teams.data.date_label }}</span>
                <b-icon icon="three-dots-vertical" variant="dark" class="fillBlack" scale="1.5" />
              </template>
              <b-dropdown-item v-for="tab in tabs" :key="tab.value" @click="selected.tab=tab.value;tab.value==tabs.custom.value ? $modal.show(`${_uid}-dateFilterModal`) : fetchTeams()" >
                <vb-icon icon="markAsComplete-icon" :style="`opacity:${selected.tab === tab.value ? '1' : '0'}`" class="mr-12px" width="14.49px" height="10.431px"/>
                <span >{{tab.text}}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder seachBox">
            <b-input type="text" placeholder="Search team" v-model="filter.teams.search" />
          </div>
        </div>
      </div>
      <!-- <template v-if="getIsMobile">
        <div class="latestGreyBox-9-9-2023 mt-16px mb-16px">
          <div class="basicWhiteIOScard">
            <div class="basicWhiteIOScard-item" 
              v-for="team in teams"
              :key="team.accountcode" 
            >
              <div class="w-100 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <Info class="InfoComponentInsideAnroidIOSApp" :id="team.accountcode" :is_blf="false" /> 
                </div>
                <div class="d-flex align-items-center">
                  <div class="d-flex mr-6px">
                    <div class="textInsideCard onRightSide textAlignRight" style="color: #87868C;">{{ team | get_property('team.totalCalls',0) }}</div>
                    <div class="textInsideCard onRightSide textAlignRight mx-1">/</div>
                    <div class="textInsideCard onRightSide textAlignRight" style="color: #FF1900;">{{ team | get_property('team.totalMissCalls',0) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template> -->
      <vb-table 
        class="latestTableDesign-withBlackBorders-again"
        :isListEmpty="teams.length==0"
        :listLength="teams.length"
        :loading="api.fetch_teams.send"
        :noRecordWholePage="true"
      >
        <tr slot="header" v-if="!getIsMobile">
          <th class="dialer-has-sort">
            <span>Team</span>
          </th>
          <th class="dialer-has-sort dialer-col-center">
            <span>Total</span>
          </th>
          <th class="dialer-has-sort dialer-col-center dialer-inbound">
            <span>Received</span>
          </th>
          <th class="dialer-has-sort dialer-col-center dialer-missed">
            <span>Missed</span>
          </th>
          <th class="dialer-has-sort dialer-col-center" v-if="!getIsMobile">
            <span id="teamsTcdTooltip" v-b-popover.hover.focus.top.html="'<small>Total call duration</small>'">TCD</span>
          </th>
          <th class="dialer-has-sort dialer-col-center" v-if="!getIsMobile">
            <span id="teamsAcdTooltip" v-b-popover.hover.focus.top.html="'<small>Average call duration</small>'">ACD</span>
          </th>
          <th class="dialer-has-sort dialer-col-center" v-if="!getIsMobile">
            <span id="teamsTtaTooltip" v-b-popover.hover.focus.top.html="'<small>Time to answer</small>'">TTA</span>
          </th>
        </tr>
        <template #body="{ start, end }">
          <template v-if="!getIsMobile">
            <tr
              class="dialer-row-select"
              v-for="(team, index) in teams"
              :key="team.accountcode"
              v-show="index >= start && index <= end"
            >
              <td class="dialer-row-title dialer-col-name-width wd-75">
                <span class="display-responsive mr-2">TEAM :</span>
                <Info :id="team.accountcode" :is_blf="false" />
              </td>
              <td class="dialer-col-center wd-25">
                <span class="display-responsive mr-2">TOTAL :</span>
                {{ team.team.totalCalls }}
                <!-- <template v-if="team.team.totalCalls == 0"> - </template>
                <template v-else>{{ team.team.totalCalls }}</template> -->
              </td>
              <td class="dialer-col-center dialer-inbound wd-25">
                <span class="display-responsive mr-2">INBOUND :</span>
                {{ team.team.totalReceiveCalls }}
                <!-- <template v-if="team.team.totalReceiveCalls == 0"> - </template>
                <template v-else>{{ team.team.totalReceiveCalls }}</template> -->
              </td>
              <td class="dialer-col-center dialer-missed wd-25">
                <span class="display-responsive mr-2">MISSED :</span>
                {{ team.team.totalMissCalls }}
                <!-- <template v-if="team.team.totalMissCalls == 0"> - </template>
                <template v-else>{{ team.team.totalMissCalls }}</template> -->
              </td>
              <td class="dialer-col-center dialer-col-tcd-width wd-25" v-if="!getIsMobile">
                <span class="display-responsive mr-2">TCD :</span>
                {{ team.kpis.tcd | to_fixed(0) }}s
                <!-- <template v-if="team.kpis.tcd == 0"> - </template>
                <template v-else>{{ team.kpis.tcd | to_fixed(0) }}s</template> -->
              </td>
              <td class="dialer-col-center dialer-col-acd-width wd-25" v-if="!getIsMobile">
                <span class="display-responsive mr-2">ACD :</span>
                {{ team.kpis.acd | to_fixed(0) }}s
                <!-- <template v-if="team.kpis.acd == 0"> - </template>
                <template v-else>{{ team.kpis.acd | to_fixed(0) }}s</template> -->
              </td>
              <td class="dialer-col-center wd-25" v-if="!getIsMobile">
                <span class="display-responsive mr-2">TTA :</span>
                {{ team.kpis.tta | to_fixed(0) }}s
                <!-- <template v-if="team.kpis.tta == 0"> - </template>
                <template v-else>{{ team.kpis.tta | to_fixed(0) }}s</template> -->
              </td>
            </tr>
          </template>
          <template v-else>
            <tr
              class="dialer-row-select"
              v-for="(team, index) in teams"
              :key="team.accountcode"
              v-show="index >= start && index <= end"
            >
              <td class="left">
                <Info class="InfoComponentInsideAnroidIOSApp" :id="team.accountcode" :is_blf="false" /> 
              </td>
              <td class="dialer-col-right right">
                <div class="d-flex mr-6px justify-content-end">
                  <div class="textInsideCard onRightSide textAlignRight" style="color: #87868C;">{{ team | get_property('team.totalCalls',0) }}</div>
                  <div class="textInsideCard onRightSide textAlignRight mx-1">/</div>
                  <div class="textInsideCard onRightSide textAlignRight" style="color: #FF1900;">{{ team | get_property('team.totalMissCalls',0) }}</div>
                </div>
              </td>
            </tr>
          </template>
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="d-flex">
                <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                <div class="d-flex flex-column justify-content-between">
                  <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                  <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
            <td class="dialer-col-right">
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
          </tr>
        </template>
        <template #no-record>
          <div class="latestGreyBox-9-9-2023 mb-32px">
            <div class="emptyScreenContainer">
              <img class="mt-50px w-50 h-auto" :src="require('@/assets/images/emptyScreenImages/teamAnalytics.png')"/>
              <div class="emptyScreenContainer-heading">
                Team analytics: dive into team performance
              </div>
              <div class="emptyScreenContainer-text w-75">
                Explore comprehensive analytics for your teams' call activities, including call durations, volumes, and more.
              </div>
              <button class="dialer-button dialer-button-primary mt-20px">
                <!-- <b-icon icon="plus-lg" class="mr-3" /> -->
                Get team insights
              </button>
            </div>
          </div>
        </template>
      </vb-table>

    </div>
    <modal :name="`${_uid}-dateFilterModal`" class="callReportingFilterModal">
      <div class="dialer-edit-header pt-0 position-relative">
        <div>
          <h2 class="dialer-settings-title newer mb-0">Filters</h2>
        </div>
        <div class="dialer-edit-actions">
          <button class="newDoneButton" type="button" @click="$modal.hide(`${_uid}-dateFilterModal`)">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023">
        <div class="analytics-newHeader-text">
          <div class="d-flex customDate">
            <div class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionLeftAligned w-50 pr-2">
              <span>From:</span>
              <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" class="date-time-input" v-model="filter.teams.start_time" id="starttime" />
            </div>
            <div class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionRightAligned w-50 pl-2">
              <span>To:</span>
              <vue-ctk-date-time-picker :min-date="filter.teams.start_time" v-bind="ctkDateTimePickerOptions" class="date-time-input" v-model="filter.teams.end_time" id="endtime" />
            </div>
          </div>
          <div class="w-100 d-flex justify-content-end mt-20px">
            <a class="newButton" @click="fetchTeams();$modal.hide(`${_uid}-dateFilterModal`)">
              <vb-icon icon="squared-browse-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Fetch</span>
            </a>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from "../../utils";
import Info from '../Lists/Info.vue';
import { ctkDateTimePicker } from '../../mixin';
import { mapGetters } from 'vuex';
const tabs = {
  today: {
    text: "Today",
    value: "today",
    icon: 'squared-today-icon'
  },
  week: {
    text: "This week",
    value: "week",
    icon: 'squared-thisWeek-icon'
  },
  month: {
    text: "This month",
    value: "month",
    icon: 'squared-thisMonth-icon'
  },
  custom: {
    text: "Custom",
    value: "custom",
    icon: 'squared-customDate-icon'
  },
}
export default {
  name: 'Teams',
  mixins: [ctkDateTimePicker],
  components: {
    Info,
  },
  inject:['isEmpty'],
  data() {
    return {
      api: {
        fetch_teams: this.$helperFunction.apiInstance({ source: true, }),
      },
      response: {
        teams: {},
      },
      filter:  {
        teams: {
          start_time: '',
          end_time: '',
          search: '',
        },
      },
      selected: {
        tab: tabs.today.value,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getIsMobile',
    ]),
    teams(){
      const time_line = this.response.teams.timeLineData ?? {}
      const teams = this.response.teams?.data?.teams ?? {}
      const kpis = this.response.teams.kpis ?? {}
      const search = this.filter.teams.search.trim().toLowerCase()
      return Object.keys(teams).map(accountcode=>{
        return {
          accountcode: accountcode,
          time_line: time_line[accountcode],
          team: teams[accountcode],
          kpis: kpis[accountcode],
        }
      }).filter(item=>`${item.team.name}`.trim().toLowerCase().includes(search))
    },
    tabs(){ return tabs },
  },
  methods: {
    async fetchTeams(){
      try {
        this.api.fetch_teams.send=true
        const { data } = await VOIP_API.endpoints.analytics.list({
          type: this.selected.tab,
          section: 'teams',
          start: this.filter.teams.start_time,
          end: this.filter.teams.end_time,
        })
        this.response.teams = data
      } finally {
        this.api.fetch_teams.send=false
      }
    },
  },
  mounted() {
    this.fetchTeams();
  },
};
</script>
