<template>
  <div>
    <div v-if="conditions.screen==screens.list.value" class="mt-24px w-100">
      <div class="d-flex justify-content-between align-items-center">
        <div class="dialer-edit-title">Statuses</div>
        <b-button class="newButton" variant="primary" @click="conditions.screen=screens.add.value">
          <vb-icon icon="squared-add-icon" height="38px" width="38px" />
          <span class="newButton-textPart">Add status</span>
        </b-button>
      </div>
      <vb-table 
        :isListEmpty="response.status.length==0"
        :listLength="response.status.length"
        :loading="api.get_status.send" 
        class="latestTableDesign-withBlackBorders-again hasSoundFiles w-100"
      >
        <tr slot="header">
          <th class="dialer-has-sort py-3 border-0">
            <span>Title</span>
          </th>
          <th class="dialer-has-sort py-3 text-center dialer-col-center">
            <span>Color</span>
          </th>
          <th class="dialer-has-sort py-3 text-center dialer-col-center">
            <span>Type</span>
          </th>
          <th class="dialer-has-sort py-3 dialer-col-right">
            
          </th>
        </tr>
        <template #body="{ start, end }">
          <tr v-for="(status, index) in response.status" :key="status.id" v-show="index >= start && index <= end">
            <td class="dialer-row-title">{{ status.title }}</td>
            <td class="dialer-has-sort text-center dialer-col-center">
              <div class="createSpace-selectColor-container justify-content-center">
                <div 
                  class="createSpace-selectColor-item selected "
                  :class="{
                    'TransparentSelected': status.color == 'rgba(0,0,0,0)'
                  }" 
                  :style="`background-color:${status.color};`"
                >
                </div>
              </div>
            </td>
            <td class="dialer-has-sort text-center dialer-col-center">{{ status.type }}</td>
            <td class="dialer-has-sort dialer-col-right">
              <div class="d-flex justify-content-end filterDD dropdownVersion whiteBGinputWithGreyRoundedBorder buttonHeightAuto buttonWidthAuto">
                <!-- <b-dropdown toggle-class="p-0" right no-caret :disabled="!!api.remove_status.send" class="filterDD inEditSpaceModal"> -->
                <b-dropdown toggle-class="p-0" right no-caret :disabled="!!api.remove_status.send" class="w-auto">
                  <template #button-content>
                    <vb-icon icon="todo-three-dots-vertical-icon" variant="dark" class="mx-auto todo-three-dots-vertical-icon" width="6px" height="18px"/>
                  </template>
                  <b-dropdown-item @click="setUpdate(status)">Edit</b-dropdown-item>
                  <b-dropdown-item 
                    @click="$modal.show('TodoTaskMove',{ 
                      moved: true, 
                      select: 'status',  
                      space_id: space_id,
                      payload: { 
                        status_id: status.id, 
                        flag: 'status' 
                      } 
                    })"
                  >Move task</b-dropdown-item>
                  <b-dropdown-item v-if="status.is_global!='yes'" @click="remove(status)">Delete</b-dropdown-item>
                </b-dropdown>
              </div>
            </td>
          </tr>
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="d-flex align-items-center w-fit-content">
                <div class="latestShimmerDesign" style="height:21px;width:80px;"></div>
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center w-fit-content mx-auto">
                <div class="md-mar-left latestShimmerDesign" style="height:21px;width:21px;"></div>
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center w-fit-content mx-auto">
                <div class="latestShimmerDesign md-mar-left" style="height:21px;width:21px;"></div>
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center justify-sontent-end w-fit-content ml-auto">
                <div class="latestShimmerDesign" style="height:16px;width:70px;"></div>
              </div>
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
    <form @submit.prevent="create()" v-if="conditions.screen==screens.add.value" class="createSpace-greyBox mt-24px">
      <b-alert :show="!!api.create_status.error_message" variant="danger">{{ api.create_status.error_message }}</b-alert>
      <div class="whiteBGinputWithGreyRoundedBorder">
        <label>Status title</label>
        <input 
          class="w-100 bg-white" 
          type="text" 
          placeholder="Status Title" 
          v-model="forms.create_status.name"
          :disabled="api.create_status.send"
          :maxlength="$v.forms.create_status.name.$params.maxLength.max"
        />
        <p 
          v-if="(forms.create_status.submitted && $v.forms.create_status.name.$invalid) || api.create_status.validation_errors.name"
          class="mb-1 text-small text text-danger animated bounceIntop"
        >
          <span v-if="!$v.forms.create_status.name.required">* Status title is required</span>
          <span v-else-if="!$v.forms.create_status.name.valid">* Status title can be only alphabet or number</span>
          <span v-else-if="!$v.forms.create_status.name.minLength">* Status title should be minimum {{ $v.forms.create_status.name.$params.minLength.min }} character</span>
          <span v-else-if="!$v.forms.create_status.name.maxLength">* Status title can be maximum {{ $v.forms.create_status.name.$params.maxLength.max }} character</span>
          <span v-for="(em, index) in api.create_status.validation_errors.name" :key="index">* {{ em }}</span>
        </p>
      </div>
      <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px">
        <label>Type</label>
        <b-dropdown no-caret :disabled="api.create_status.send">
          <template #button-content>
            <span>
              {{ types | get_property(`${forms.create_status.type}.text`,'Select type') }}
            </span>
            <b-icon icon="chevron-down" class="darker-chevron-down" />
          </template>
          <b-dropdown-item v-for="type in types" :key="type.value" @click="forms.create_status.type=type.value">{{ type.text }}</b-dropdown-item>
        </b-dropdown>
        <p 
          v-if="(forms.create_status.submitted && $v.forms.create_status.type.$invalid) || api.create_status.validation_errors.type" 
          class="mb-1 text-small text text-danger animated bounceIntop"
        >
          <span v-if="!$v.forms.create_status.type.required">* Type is required</span>
          <span v-else-if="!$v.forms.create_status.type.valid">* Type is invalid</span>
          <span v-for="em in api.create_status.validation_errors.type" :key="em">* {{ em }}</span>
        </p>
      </div>
      <div class="whiteBGinputWithGreyRoundedBorder mt-20px">
        <label>Choose color</label>
        <div class="createSpace-selectColor-container">
          <div v-for="(color,i) in colors" :key="i" class="createSpace-selectColor-item-container" @click="api.create_status.send ? '' : forms.create_status.color=color">
            <div 
              class="createSpace-selectColor-item"
              :class="{
                'selected': forms.create_status.color==color,
                'TransparentSelected': color == 'rgba(0,0,0,0)',
              }"
              :style="`background-color:${color};`"
            >
              <vb-icon v-if="color=='rgba(0,0,0,0)'" icon="todo-transparentColored-icon" height="28px" width="30px" />
            </div>
          </div>
        </div>
        <p 
          v-if="(forms.create_status.submitted && $v.forms.create_status.color.$invalid) || api.create_status.validation_errors.color" 
          class="mb-1 text-small text text-danger animated bounceIntop"
        >
          <span v-if="!$v.forms.create_status.color.required">* Color is required</span>
          <span v-else-if="!$v.forms.create_status.color.validColor">* Color is invalid</span>
          <span v-for="em in api.create_status.validation_errors.color" :key="em">* {{ em }}</span>
        </p>
      </div>
      <div class="w-100 d-flex justify-content-end mt-20px">
        <button type="submit" class="fullWidthDoneButton">
          Add
        </button>
      </div>
    </form>
    <form @submit.prevent="update()" v-if="conditions.screen==screens.update.value" class="createSpace-greyBox mt-24px">
      <b-alert :show="!!api.update_status.error_message" variant="danger">{{ api.update_status.error_message }}</b-alert>
      <div class="whiteBGinputWithGreyRoundedBorder">
        <label>Status title</label>
        <input 
          class="w-100 bg-white" 
          type="text" 
          placeholder="Status title" 
          v-model="forms.update_status.name"
          :disabled="api.update_status.send"
          :maxlength="$v.forms.update_status.name.$params.maxLength.max"
        />
        <p 
          v-if="(forms.update_status.submitted && $v.forms.update_status.name.$invalid) || api.update_status.validation_errors.name"
          class="mb-1 text-small text text-danger animated bounceIntop"
        >
          <span v-if="!$v.forms.update_status.name.required">* Status title is required</span>
          <span v-else-if="!$v.forms.update_status.name.valid">* Status title can be only alphabet or number</span>
          <span v-else-if="!$v.forms.update_status.name.minLength">* Status title should be minimum {{ $v.forms.update_status.name.$params.minLength.min }} character</span>
          <span v-else-if="!$v.forms.update_status.name.maxLength">* Status title can be maximum {{ $v.forms.update_status.name.$params.maxLength.max }} character</span>
          <span v-for="(em, index) in api.update_status.validation_errors.name" :key="index">* {{ em }}</span>
        </p>
      </div>
      <div v-if="conditions.type_update" class="whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px">
        <label>Type</label>
        <b-dropdown no-caret :disabled="api.update_status.send">
          <template #button-content>
            <span>
              {{ types | get_property(`${forms.update_status.type}.text`,'Select type') }}
            </span>
            <b-icon icon="chevron-down" class="darker-chevron-down" />
          </template>
          <b-dropdown-item v-for="type in types" :key="type.id" @click="forms.update_status.type">{{ type.text }}</b-dropdown-item>
        </b-dropdown>
        <p 
          v-if="(forms.update_status.submitted && $v.forms.update_status.type.$invalid) || api.update_status.validation_errors.type" 
          class="mb-1 text-small text text-danger animated bounceIntop"
        >
          <span v-if="!$v.forms.update_status.type.required">* Type is required</span>
          <span v-else-if="!$v.forms.update_status.type.valid">* Type is invalid</span>
          <span v-for="em in api.update_status.validation_errors.type" :key="em">* {{ em }}</span>
        </p>
      </div>
      <div class="whiteBGinputWithGreyRoundedBorder mt-20px">
        <label>Choose color</label>
        <div class="createSpace-selectColor-container">
          <div v-for="(color,i) in colors" :key="i" class="createSpace-selectColor-item-container" @click="api.update_status.send ? '' : forms.update_status.color=color">
            <div 
              class="createSpace-selectColor-item"
              :class="{
                'selected': forms.update_status.color==color,
                'TransparentSelected': color == 'rgba(0,0,0,0)',
              }"
              :style="`background-color:${color};`"
            >
              <vb-icon v-if="color=='rgba(0,0,0,0)'" icon="todo-transparentColored-icon" height="28px" width="30px" />
            </div>
          </div>
        </div>
        <p 
          v-if="(forms.update_status.submitted && $v.forms.update_status.color.$invalid) || api.update_status.validation_errors.color" 
          class="mb-1 text-small text text-danger animated bounceIntop"
        >
          <span v-if="!$v.forms.update_status.color.required">* Color is required</span>
          <span v-else-if="!$v.forms.update_status.color.validColor">* Color is invalid</span>
          <span v-for="em in api.update_status.validation_errors.color" :key="em">* {{ em }}</span>
        </p>
      </div>
      <div class="w-100 d-flex justify-content-end mt-20px">
        <button type="submit" class="fullWidthDoneButton">
          Update
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils'
import { mapGetters } from 'vuex'
import required from 'vuelidate/lib/validators/required'
import minLength from 'vuelidate/lib/validators/minLength'
import maxLength from 'vuelidate/lib/validators/maxLength'
import validateColor from 'validate-color'
import { APP_COLORS } from '@/constants'
import { helpers } from 'vuelidate/lib/validators'
const types = {
  done: {
    text: 'Done',
    value: 'done',
  },
  active: {
    text: 'Active',
    value: 'active',
  },
}
const screens = {
  add: {
    text: 'Add',
    value: 'add',
  },
  update: {
    text: 'Update',
    value: 'update',
  },
  list: {
    text: 'List',
    value: 'list',
  },
}
export default {
  name: 'Status',
  props: {
    space_id: {
      type: [String,Number],
      default: '',
      require: true,
    },
  },
  data() {
    return {
      forms: {
        create_status: this.$helperFunction.formInstance({
          data: {
            name: '',
            color: APP_COLORS[0],
            type: types.active.value,
          },
        }),
        update_status: this.$helperFunction.formInstance({
          data: {
            name: '',
            color: APP_COLORS[0],
            type: types.active.value,
          },
        }),
      },
      api: {
        get_status: this.$helperFunction.apiInstance({  }),
        create_status: this.$helperFunction.apiInstance({ 
          validation_errors: true, 
          error_message: true, 
        }),
        update_status: this.$helperFunction.apiInstance({
          validation_errors: true, 
          error_message: true, 
        }),
        remove_status: this.$helperFunction.apiInstance({
          send: '',
        }),
      },
      response: {
        status: [],
      },
      conditions: {
        screen: screens.list.value,
        updated_id: '',
        type_update: true,
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    types() { return types },
    colors(){ return APP_COLORS },
    screens(){ return screens },
  },
  validations: {
    forms: {
      create_status: {
        name: {
          required: required,
          valid: helpers.regex('valid',/^[a-z0-9 ]*$/i),
          minLength: minLength(3),
          maxLength: maxLength(15),
        },
        color: {
          required: required,
          validColor: (value) => { return validateColor(value) },
        },
        type: {
          required: required,
          valid: (value) => { return types[value] },
        },
      },
      update_status: {
        name: {
          required: required,
          valid: helpers.regex('valid',/^[a-z0-9 ]*$/i),
          minLength: minLength(3),
          maxLength: maxLength(15),
        },
        color: {
          required: required,
          validColor: (value) => { return validateColor(value) },
        },
        type: {
          required: required,
          valid: (value) => { return !!types[value] || value=='closed' },
        },
      },
    }
  },
  watch: {
    "conditions.screen"(screen,old_screen){
      if(old_screen==screens.update.value){
        this.conditions.updated_id=''
        this.conditions.type_update=true
        this.forms.update_status.reset()
        this.api.update_status.reset()
      } else if(old_screen==screens.add.value) {
        this.api.create_status.reset()
        this.forms.create_status.reset()
      }
    },
  },
  methods: {
    async getStatus(){
      if(this.api.get_status.send) return;
      try {
        this.api.get_status.send=true
        const { data } = await VOIP_API.endpoints.task_status.list({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          space_id: this.space_id,
        })
        this.response.status = data ?? []
        this.$emit('status-count',this.response.status.length ?? 0)
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.get_status.send=false
      }
    },
    async create(){
      this.forms.create_status.submitted=true
      this.$v.forms.create_status.$touch()
      if(this.$v.forms.create_status.$invalid || this.api.create_status.send) return;
      try {
        this.api.create_status.send=true
        this.api.create_status.validation_errors={}
        this.api.create_status.error_message=''
        await VOIP_API.endpoints.task_status.create({
          title: this.forms.create_status.name,
          color: this.forms.create_status.color,
          type: this.forms.create_status.type,
          uid: this.getCurrentUser.uid,
          created_by: this.getCurrentUser.account,
          space_id: this.space_id,
        })
        this.getStatus()
        this.conditions.screen=screens.list.value
        this.appNotify({
          message: 'Successfully created',
          type: 'success',
        })
        this.$emit('create')
      } catch (ex) {
        this.api.create_status.error_message=ex.own_message ?? ''
        this.api.create_status.validation_errors=ex.own_errors ?? []
      } finally {
        this.api.create_status.send=false
        this.forms.create_status.submitted=false
      }
    },
    async remove(status){
      const status_id = status.id
      if(this.api.remove_status.send) return;
      try {
        this.api.remove_status.send=status.id
        const { confirm } = await this.$appConfirmation({
          title: "WARNING",
          message: `Are you sure you want to delete?`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        await VOIP_API.endpoints.task_status.delete(status_id,{
          space_id: this.space_id,
        })
        this.getStatus()
        this.$emit('remove')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.remove_status.send=''
      }
    },
    async update(){
      this.forms.update_status.submitted=true
      this.$v.forms.update_status.$touch()
      if(this.$v.forms.update_status.$invalid || this.api.update_status.send) return;
      try {
        this.api.update_status.send=true
        this.api.update_status.error_message=''
        this.api.update_status.validation_errors={}
        await VOIP_API.endpoints.task_status.update(this.conditions.updated_id,{
          title: this.forms.update_status.name,
          color: this.forms.update_status.color,
          type: this.forms.update_status.type,
          created_by: this.getCurrentUser.account,
          uid: this.getCurrentUser.uid,
          space_id: this.space_id,
        })
        this.conditions.screen=screens.list.value
        this.getStatus()
        this.$emit('update')
      } catch (ex) {
        this.api.update_status.error_message=ex.own_message ?? ''
        this.api.update_status.validation_errors=ex.own_errors ?? {}
      } finally {
        this.forms.update_status.submitted=false
        this.api.update_status.send=false
      }
    },
    setUpdate(status){
      if(!status || this.api.remove_status.send) return;
      this.forms.update_status.name=status.title ?? ''
      this.forms.update_status.type=status.type ?? ''
      this.forms.update_status.color=status.color ?? ''
      this.conditions.type_update=status.is_global!='yes'
      this.conditions.updated_id=status.id
      this.conditions.screen=screens.update.value
    },
    back(){
      if(this.api.create_status.send) return;
      else if(this.api.update_status.send) return;
      else if(this.api.remove_status.send) return;
      else if(this.conditions.screen==screens.add.value) this.conditions.screen=screens.list.value
      else if(this.conditions.screen==screens.update.value) this.conditions.screen=screens.list.value
      else if(this.conditions.screen==screens.list.value) this.$emit('back')
    },
  },
  mounted () {
    this.getStatus();
  },
}
</script>

<style>

</style>