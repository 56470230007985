<template>
  <div ref="PowerDialerContainer" class="PowerDialerContainer">
    <h2 class="d-flex flex-wrap align-items-center justify-content-between">
      <span class="d-block title">Power dialer</span>
      <div class="startStopDialing">
        <vb-icon v-if="true" icon="PowerDialer-start-icon" width="11.79px" height="13.482px" />
        <vb-icon v-else icon="PowerDialer-stop-icon" width="14px" height="14px" />
        <div class="startStopDialing-text">
          <template v-if="true">Auto</template> 
          <template v-else>Stop</template>
        </div>
      </div>
    </h2>
    <div class="PowerDialerContainer-inner">
      <div class="PowerDialer-row" v-for="n in 3" :key="n.id">
        <div class="PowerDialer-row-leftSide">
          <vb-icon icon="PowerDialer-contact-icon" class="PowerDialer-contact-icon"  />
          <div>
            <div class="PowerDialer-row-mainText">Contact Name</div>
            <div class="PowerDialer-row-secondaryText">
              02034322202
            </div>
            <div class="PowerDialer-row-verySmallText">
              <span>Dialing</span>
              <div class="dialingCircles">
                <div class="dialingCircle"></div>
                <div class="dialingCircle"></div>
                <div class="dialingCircle"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="PowerDialer-row-rightSide">
          <vb-icon icon="PowerDialer-phone-icon" class="PowerDialer-phone-icon" :class="`${n==2 ? 'disabled' : ''}`" width="15.983px" height="16.002px" />
          <vb-icon icon="PowerDialer-rightArrow-icon" class="PowerDialer-rightArrow-icon" width="8.526" height="14.113" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PowerDialer'
}
</script>

<style>

</style>