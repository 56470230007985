<template>
  <div>
    <modal
      class="dialer_animation right_side_popup m-w-500 AssignNumberTo min-width-50percent-modal"
      width="500px"
      height="auto"
      :scrollable="true"
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose()"
      :name="modalName"
    >
      <div class="dialer-assign-to md-pad-bottom dialer-edit">
        <div v-if="$store.getters.getIsMobile" class="dialer-edit-header flex-column">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <h2 class="dialer-edit-title">Assigned telephone numbers</h2>
            <!-- <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" /> -->
            <button class="newDoneButton" @click="assign()">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
          <!-- <div class="dialer-edit-actions">
          </div>
          <div class="dialer-edit-actions w-100 justify-content-end d-flex mt-2" >
            <button class="newDoneButton" @click="assign()">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div> -->
        </div>
        <div v-else class="dialer-edit-header d-block">
          <div class="d-flex justify-content-between align-items-center w-100">
            <h2 class="dialer-settings-title newer mb-0">
              <vb-svg @click="$modal.hide(modalName)" class="cursor_pointer_no_hover" name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
              Assigned telephone numbers
            </h2>
            <div class="dialer-edit-actions d-flex">
              <button :disabled="api.fetch_numbers.send || !isAnyChange" class="newDoneButton" @click="assign()">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart">Done</span>
              </button>
            </div>
          </div>
          <!-- <div class="vm--modal-text">Choose or buy a telephone number to link to this user.</div> -->
          <div class="vm--modal-text">Choose a telephone number to link to this user.</div>
        </div>

        <div class="whiteBGinputWithGreyRoundedBorder seachBox w-100 d-flex justify-content-end">
          <b-input class="wd-45-imp" type="text" placeholder="Search numbers" v-model="filter.numbers.search" onkeypress="return /([0-9])/i.test(event.key)" />
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <template v-if="api.fetch_numbers.send">
            <div class="whiteBoxWithBorderInsideGreyBox mt-12px">
              <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset" v-for="n in 3" :key="n.id">
                <div class="latestShimmerDesign" style="height: 24px;width: 180px;"></div>
                <div class="latestShimmerDesign" style="height: 40px;width: 40px;border-radius: 50%;"></div>
                <div class="latestShimmerDesign" style="height: 45px;width: 180px;"></div>
              </div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox mt-12px">
              <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset" v-for="n in 3" :key="n.id">
                <div class="latestShimmerDesign" style="height: 24px;width: 180px;"></div>
                <div class="latestShimmerDesign" style="height: 40px;width: 40px;border-radius: 50%;"></div>
                <div class="latestShimmerDesign" style="height: 45px;width: 180px;"></div>
              </div>
            </div>
          </template>
          <template v-if="!api.fetch_numbers.send && numbers.length==0">
            <div class="latestGreyBox-heading-main text-center">Available numbers</div>
            <div class="d-flex flex-column align-items-center">
              <vb-icon class="mt-3" icon="AssignTelePhoneNumbers-addNumbersIcon-icon" height="99px" width="102px" />
              <p class="mt-3 mb-4 w-50 text-center">
                It seems you don't have any telephone numbers, You can purchase one or make one available.  
              </p>
              <button class="newButton mb-3" type="button" @click="$modal.show(`${_uid}-AddNumbersModalFromAssignNumber`)">
                <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                <span class="newButton-testPart">Add telephone number</span>
              </button>
            </div>
          </template>
          <template v-if="numbers.length > 0">
            <div class="latestGreyBox-heading-main">Available numbers</div>
            <div class="whiteBoxWithBorderInsideGreyBox mt-12px">
              <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset" v-for="(number, index) in filterNumbers" :key="index">
                <b-form-checkbox 
                  :class="selected.numbers.indexOf(number) > -1 ? 'active' : ''" 
                  :checked="selected.numbers.indexOf(number) > -1" 
                  @change="selected.numbers.indexOf(number) > -1 ? selected.numbers.splice(selected.numbers.indexOf(number), 1) : selected.numbers.push(number)" 
                  class="dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare less-padding leastPadding"
                >
                  <div class="d-flex align-items-center">
                    <img 
                      class="country-img mr-16px" 
                      width="35px" 
                      :src="number | number_formater | flag_icon"  
                    />
                    <span class="latestGreyBox-descriptionText mt-0">{{ number | number_formater }}</span>
                  </div>
                </b-form-checkbox>
              </div>
            </div>
          </template>
          <vb-no-record v-if="filterNumbers.length==0 && Object.keys(diffAccountAssignNumbers).length==0" :text="api.fetch_numbers.send ? '' : 'No record'" :design="3" />
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <template v-if="Object.keys(diffAccountAssignNumbers).length>0">
            <div class="latestGreyBox-heading-main">Unavailable numbers</div>
            <div class="whiteBoxWithBorderInsideGreyBox mt-12px">
              <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset UnavailableNumbers-row" v-for="(data, number) in diffAccountAssignNumbers" :key="number">
                <div class="UnavailableNumbers-left">
                  <div class="d-flex align-items-center minWidth220px maxWidth220px">
                    <img 
                      class="country-img mr-16px" 
                      width="35px" 
                      :src="number | number_formater | flag_icon"  
                    />
                    <span class="latestGreyBox-descriptionText mt-0">{{ number | number_formater }}</span>
                  </div>
                </div>
                <div class="assignToMiddleSection UnavailableNumbers-middle">
                  <vb-icon class="mb-0" icon="AssignTelePhoneNumbers-arrow-icon" height="35px" width="35px"/>
                </div>
                <div class="UnavailableNumbers-right">
                  <div v-if="data.members.length>0"> 
                    <!-- <Info v-for="member in data.members" :key="member.id" :id="member.cg_item" :is_blf="false" /> -->
                    <div class="d-flex align-items-center">
                      <Info 
                        :id="data.members[0].cg_item"
                        :is_blf="false"
                      >
                        <template #sub-info>
                          <small class="cursor_pointer_no_hover" v-if="data.members.length>1" @click="conditions.more=conditions.more==data.accountcode?'':data.accountcode">
                            {{conditions.more==data.accountcode ? '-' : '+'}} {{ data.members.length-1 }} more
                          </small>
                        </template>
                      </Info>
                      <!-- <div class="w-auto dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1 ml-1 cursor_pointer_no_hover" v-if="data.members.length>1" @click="conditions.more=conditions.more==data.accountcode?'':data.accountcode">
                        <div class="px-2 rounded more-numbers-tag">
                          <small>{{conditions.more==data.accountcode ? '-' : '+'}} {{ data.members.length-1 }} more</small>
                        </div>
                      </div> -->
                    </div>
                    <template v-if="conditions.more==data.accountcode">
                      <template v-for="(member) in data.members.filter((item,index)=>index>0)" >
                        <div class="mt-2" :key="member.cg_item" >
                          <Info 
                            :id="member.cg_item"  
                            :is_blf="false"
                          />
                        </div>
                      </template>
                    </template>
                  </div>
                  <Info v-else :id="data.accountcode" :is_blf="false" />
                </div>
              </div>
            </div>
          </template>
          <template v-if="api.fetch_numbers.send">
            <div class="whiteBoxWithBorderInsideGreyBox mt-12px">
              <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset" v-for="n in 3" :key="n.id">
                <div class="latestShimmerDesign" style="height: 24px;width: 180px;"></div>
                <div class="latestShimmerDesign" style="height: 40px;width: 40px;border-radius: 50%;"></div>
                <div class="latestShimmerDesign" style="height: 45px;width: 180px;"></div>
              </div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox mt-12px">
              <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset" v-for="n in 3" :key="n.id">
                <div class="latestShimmerDesign" style="height: 24px;width: 180px;"></div>
                <div class="latestShimmerDesign" style="height: 40px;width: 40px;border-radius: 50%;"></div>
                <div class="latestShimmerDesign" style="height: 45px;width: 180px;"></div>
              </div>
            </div>
          </template>
          <template v-if="!api.fetch_numbers.send && Object.keys(diffAccountAssignNumbers).length==0">
            <div class="latestGreyBox-heading-main text-center">Unavailable numbers</div>
            <div class="d-flex flex-column align-items-center">
              <vb-icon class="mt-3" icon="AssignTelePhoneNumbers-addNumbersIcon-icon" height="99px" width="102px" />
              <p class="mt-3 mb-4 w-50 text-center">
                It seems you don't have any telephone numbers, You can purchase one or un assign an existing one.  
              </p>
              <button class="newButton mb-3" type="button" @click="$modal.show(`${_uid}-AddNumbersModalFromAssignNumber`)">
                <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                <span class="newButton-testPart">Add telephone number</span>
              </button>
            </div>
          </template>
        </div>


        <!-- <div class="dialer-tabs">
          <div class="dialer-tab-content">
            <div class="whiteBGinputWithGreyRoundedBorder seachBox w-100 d-flex justify-content-end">
              <b-input type="text" style="width:40%;" placeholder="Search Numbers" v-model="filter.numbers.search" onkeypress="return /([0-9])/i.test(event.key)" />
            </div>
            <ul class="list-unstyled dialer-asssignee-list sm-mar-top">
              <div v-if="!api.fetch_numbers.send && numbers.length==0" >
                <h2 class="dialer-edit-title mt-5 mb-3">Available numbers</h2>
                <div class="dialer-box d-flex flex-column align-items-center">
                  <vb-icon class="mt-3" icon="AssignTelePhoneNumbers-addNumbersIcon-icon" height="99px" width="102px" />
                  <p class="mt-3 mb-4 w-50 text-center">
                    It seems you don't have any telephone numbers, You can purchase one or make one available.  
                  </p>
                  <button class="newButton mb-3" type="button" @click="$modal.show(`${_uid}-AddNumbersModalFromAssignNumber`)">
                    <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                    <span class="newButton-testPart">Add telephone number</span>
                  </button>
                </div>
              </div>
              <h2 class="dialer-edit-title mt-5 mb-3" v-if="Object.keys(numbers).length > 0">Available numbers</h2>
              <div class="dialer-box" v-if="numbers.length > 0">
                <li class="dialer-assignee p-0 cursor_auto border-radius-0" v-for="(number, index) in filterNumbers" :key="index">
                  <b-form-checkbox 
                    :class="selected.numbers.indexOf(number) > -1 ? 'active' : ''" 
                    :checked="selected.numbers.indexOf(number) > -1" 
                    @change="selected.numbers.indexOf(number) > -1 ? selected.numbers.splice(selected.numbers.indexOf(number), 1) : selected.numbers.push(number)" 
                    class="dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight big-circle-checkbox py-3 px-3 border-radius-0 makeCheckBoxesSquare"
                  >
                    <div class="dialer-assignee-name">
                      <span >
                        <img 
                          class="country-img" 
                          width="35px" 
                          :src="number | number_formater | flag_icon"  
                        />
                      </span>
                      <span class="sm-mar-left">{{ number | number_formater }}</span>
                    </div>
                  </b-form-checkbox>
                </li>
              </div>
              <div class="my-5" />
              <template v-if="Object.keys(diffAccountAssignNumbers).length>0">
                <h2 class="dialer-edit-title mt-5 mb-3">Allocated numbers</h2>
                <div class="dialer-box">
                  <li class="dialer-assignee p-0 cursor_auto alreadyAssigned border-radius-0" v-for="(data, number) in diffAccountAssignNumbers" :key="number">
                    <div class="dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight big-circle-checkbox px-3 border-radius-0 makeCheckBoxesSquare">
                      <div class="dialer-assignee-name">
                        <div class="d-flex align-items-center">
                          <span >
                            <img 
                              class="country-img" 
                              width="35px" 
                              :src="number | number_formater | flag_icon"  
                            />
                          </span>
                          <span class="sm-mar-left">{{ number | number_formater }}</span>
                        </div>
                        <div class="assignToMiddleSection">
                          <vb-icon class="mb-0" icon="AssignTelePhoneNumbers-arrow-icon" height="80px" width="80px"/>
                        </div>
                        <Info :id="data.extn" :is_blf="false" />
                      </div>
                    </div>
                  </li>
                </div>
              </template>
              <template v-if="api.fetch_numbers.send">
                <div class="latestGreyBox-9-9-2023 mt-20px">
                  <div class="px-4 px-3 w-100 d-flex justify-content-between align-items-center mb-3" v-for="n in 3" :key="n.id">
                    <div class="latestShimmerDesign" style="height: 24px;width: 180px;"></div>
                    <div class="latestShimmerDesign" style="height: 40px;width: 40px;border-radius: 50%;"></div>
                    <div class="latestShimmerDesign" style="height: 45px;width: 180px;"></div>
                  </div>
                </div>
                <div class="latestGreyBox-9-9-2023 d-flex flex-column mt-20px mt-20px">
                  <div class="px-4 px-3 w-100 d-flex justify-content-between align-items-center mb-3" v-for="n in 3" :key="n.id">
                    <div class="latestShimmerDesign" style="height: 24px;width: 180px;"></div>
                    <div class="latestShimmerDesign" style="height: 40px;width: 40px;border-radius: 50%;"></div>
                    <div class="latestShimmerDesign" style="height: 45px;width: 180px;"></div>
                  </div>
                </div>
              </template>
              <vb-no-record v-if="filterNumbers.length==0 && Object.keys(diffAccountAssignNumbers).length==0" :text="api.fetch_numbers.send ? '' : 'No record'" :design="3" />
            </ul>
          </div>
        </div> -->
      </div>
    </modal>
    <AddNumbersModal :modalName="`${_uid}-AddNumbersModalFromAssignNumber`" @interface="getNumbersDebounce()" />
  </div>
</template>

<script>
import { VOIP_API } from "../../utils";
import Info from '../Lists/Info.vue';
import AddNumbersModal from "../Modals/AddNumbersModal.vue";
import _ from 'lodash';
import { AMI_EVENTS, AMI_SOCKET } from '@/ami';
// import { centrifugo } from '@/Centrifuge';
export default {
  name: "AssignNumberModal",
  components: {
    AddNumbersModal,
    Info
  },
  inject:[
    'isEmpty',
    'getNumbers',
    'appNotify'
  ],
  props: {
    modalName: {
      type: String,
      default: "AssignNumberModal",
    },
  },
  data() {
    return {
      api: {
        fetch_numbers: this.$helperFunction.apiInstance(),
        assugn_numbers: this.$helperFunction.apiInstance(),
      },
      filter: {
        numbers: this.$helperFunction.stateInstance({
          data: {
            search: "",
          }
        }),
      },
      response: this.$helperFunction.stateInstance({
        data: {
          numbers: {},
        }
      }),
      selected: this.$helperFunction.stateInstance({
        data: {
          numbers: [],
        }
      }),
      data: this.$helperFunction.stateInstance({
        data: {
          accountcode: "",
        }
      }),
      conditions: {
        more: '',
      },
    };
  },
  computed: {
    diffAccountAssignNumbers(){ return this.response.numbers?.data?.assigned_to_different_account ?? {} },
    numbers(){
      const available = this.response.numbers?.data?.available ?? []
      const assigned_to_same_account = this.response.numbers?.data?.assigned_to_same_account ?? []
      return [...available,...assigned_to_same_account]
    },
    isAnyChange(){
      const new_selected = this.selected.numbers ?? []
      const prev_selected = this.response.numbers?.data?.assigned_to_same_account ?? []
      return !(new_selected.every(i=>prev_selected.includes(i)) && new_selected.length==prev_selected.length)
    },
    filterNumbers() {
      const search = `${this.filter.numbers.search}`.trim().toLowerCase();
      return this.numbers.filter((number) => `${number}`.replace(/-/g,'').trim().toLowerCase().includes(search));
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.accountcode = event.params.accountcode || "";
      this.fetchNumbers();
      AMI_SOCKET.on(AMI_EVENTS.broadcast,this.onNotification.bind(this))
      // centrifugo.notification.on(centrifugo.notification.events.message,this.onNotification.bind(this))
      // centrifugo.notification_admin.on(centrifugo.notification_admin.events.message,this.onNotification.bind(this))
    },
    onBeforeClose() {
      this.response.reset();
      this.selected.reset();
      this.filter.numbers.reset();
      this.data.reset();
      AMI_SOCKET.off(AMI_EVENTS.broadcast,this.onNotification.bind(this))
      // centrifugo.notification.off(centrifugo.notification.events.message,this.onNotification.bind(this))
      // centrifugo.notification_admin.off(centrifugo.notification_admin.events.message,this.onNotification.bind(this))
    },
    onNotification(event){
      if(event.data.flag=='NUMBER_ADDED'){
        this.getNumbersDebounce()
      }
    },
    getNumbersDebounce: _.debounce(function(){
      this.getNumbers()
    },3*1000),
    async assign() {
      if (this.api.assugn_numbers.send) return;
      try {
        this.api.assugn_numbers.send = true;
        await VOIP_API.endpoints.telephone_number.assign(this.data.accountcode, {
          numbers: this.selected.numbers.join("&"),
          account: this.data.accountcode,
        })
        this.appNotify({
          message: "Successfully Updated",
          type: "success",
        })
        this.$emit("added", {
          selected_list: this.selected.numbers,
        });
        this.$modal.hide(this.modalName);
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.assugn_numbers.send = false;
      }
    },
    async fetchNumbers() {
      try {
        this.api.fetch_numbers.send = true;
        const { data } = await VOIP_API.endpoints.telephone_number.availablelist(this.data.accountcode)
        this.response.numbers=data
        this.selected.numbers=JSON.parse(JSON.stringify(data?.data?.assigned_to_same_account ?? []))
      } finally {
        this.api.fetch_numbers.send = false;
      }
    },
  },
};
</script>
