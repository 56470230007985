var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-main-wrapper dialer-activeCall-wrapper"
  }, [_c('div', {
    staticClass: "MonitorDashboard-12-10-2023"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-start w-100 MonitorDashboard-TopSection"
  }, [_vm._m(0), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "userDashboard-heading mr-16px"
  }, [_vm._v("Call dashboard")]), _c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.conditions.user_mode,
      callback: function ($$v) {
        _vm.$set(_vm.conditions, "user_mode", $$v);
      },
      expression: "conditions.user_mode"
    }
  }), _c('div', {
    staticClass: "userDashboard-heading ml-16px"
  }, [_vm._v("User dashboard")])], 1)]), _c('div', {
    staticClass: "mt-30px MonitorDashboard-section1"
  }, [_c('div', {
    staticClass: "d-flex wd-83"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20prcntOnly mr-16px"
  }, [_c('label', [_vm._v("Filter by number")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "valueField": 'did',
      "textField": 'did',
      "options": _vm.voipnumbers
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function (_ref) {
        var option = _ref.option;
        return [_c('div', {
          staticClass: "d-flex align-content-center w-100 h-100"
        }, [_c('span', {
          staticClass: "p-0"
        }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm._f("get_property")(option, 'did', 'Select Number'))))]), option ? _c('b-button', {
          staticClass: "align-content-center justify-content-center p-0 m-0 mr-2",
          staticStyle: {
            "line-height": "0"
          },
          attrs: {
            "variant": "link"
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "x",
            "scale": "1.5"
          },
          on: {
            "click": function ($event) {
              _vm.filters.calls.voip_number = '';
            }
          }
        })], 1) : _vm._e()], 1)];
      }
    }, {
      key: "option",
      fn: function (_ref2) {
        var option = _ref2.option;
        return [_vm._v(" " + _vm._s(_vm._f("number_formater")(_vm._f("get_property")(option, 'did'))) + " ")];
      }
    }, {
      key: "no-record",
      fn: function () {
        return [_vm._v("There is no number")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.calls.voip_number,
      callback: function ($$v) {
        _vm.$set(_vm.filters.calls, "voip_number", $$v);
      },
      expression: "filters.calls.voip_number"
    }
  })], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20prcntOnly mr-16px"
  }, [_c('label', [_vm._v("Filter by user")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "options": _vm.voipusers,
      "textField": "user.display_name",
      "valueField": "voipaccount"
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function (_ref3) {
        var option = _ref3.option;
        return [_c('div', {
          staticClass: "d-flex align-content-center justify-content-between w-100 h-100"
        }, [option ? _c('Info', {
          attrs: {
            "id": option.voipaccount,
            "is_blf": false,
            "is_suspended_show": false,
            "type": 'user'
          }
        }) : _c('span', {
          staticClass: "p-0"
        }, [_vm._v("Select user")]), option ? _c('b-button', {
          staticClass: "align-content-center justify-content-center p-0 m-0 mr-2",
          staticStyle: {
            "line-height": "0"
          },
          attrs: {
            "variant": "link"
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "x",
            "scale": "1.5"
          },
          on: {
            "click": function ($event) {
              _vm.filters.calls.user = '';
            }
          }
        })], 1) : _vm._e()], 1)];
      }
    }, {
      key: "option",
      fn: function (_ref4) {
        var option = _ref4.option;
        return [_c('Info', {
          attrs: {
            "id": option.voipaccount,
            "is_blf": false,
            "is_suspended_show": false,
            "type": 'user'
          }
        })];
      }
    }, {
      key: "no-record",
      fn: function () {
        return [_vm._v("There is no user")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.calls.user,
      callback: function ($$v) {
        _vm.$set(_vm.filters.calls, "user", $$v);
      },
      expression: "filters.calls.user"
    }
  })], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20prcntOnly mr-16px"
  }, [_c('label', [_vm._v("Filter by team")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "options": _vm.teams,
      "textField": "name",
      "valueField": "real_id"
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function (_ref5) {
        var option = _ref5.option;
        return [_c('div', {
          staticClass: "d-flex align-content-center justify-content-between w-100 h-100"
        }, [option ? _c('Info', {
          attrs: {
            "id": option.real_id,
            "is_blf": false,
            "is_suspended_show": false,
            "type": 'team'
          }
        }) : _c('span', {
          staticClass: "p-0"
        }, [_vm._v("Select team")]), option ? _c('b-button', {
          staticClass: "align-content-center justify-content-center p-0 m-0 mr-2",
          staticStyle: {
            "line-height": "0"
          },
          attrs: {
            "variant": "link"
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "x",
            "scale": "1.5"
          },
          on: {
            "click": function ($event) {
              _vm.filters.calls.team = '';
            }
          }
        })], 1) : _vm._e()], 1)];
      }
    }, {
      key: "option",
      fn: function (_ref6) {
        var option = _ref6.option;
        return [_c('Info', {
          attrs: {
            "id": option.real_id,
            "is_blf": false,
            "is_suspended_show": false,
            "type": 'team'
          }
        })];
      }
    }, {
      key: "no-record",
      fn: function () {
        return [_vm._v("There is no team")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.calls.team,
      callback: function ($$v) {
        _vm.$set(_vm.filters.calls, "team", $$v);
      },
      expression: "filters.calls.team"
    }
  })], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20prcntOnly mr-16px"
  }, [_c('label', [_vm._v("Filter by call type")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "options": Object.values(_vm.callTypes)
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function (_ref7) {
        var option = _ref7.option;
        return [_c('div', {
          staticClass: "d-flex align-content-center w-100 h-100"
        }, [_c('span', {
          staticClass: "p-0"
        }, [_vm._v(_vm._s(_vm._f("get_property")(option, 'text', 'Select call type')))]), option ? _c('b-button', {
          staticClass: "align-content-center justify-content-center p-0 m-0 mr-2",
          staticStyle: {
            "line-height": "0"
          },
          attrs: {
            "variant": "link"
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "x",
            "scale": "1.5"
          },
          on: {
            "click": function ($event) {
              _vm.filters.calls.call_type = '';
            }
          }
        })], 1) : _vm._e()], 1)];
      }
    }, {
      key: "option",
      fn: function (_ref8) {
        var option = _ref8.option;
        return [_vm._v(" " + _vm._s(_vm._f("get_property")(option, 'text')) + " ")];
      }
    }, {
      key: "no-record",
      fn: function () {
        return [_vm._v("There is no number")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.calls.call_type,
      callback: function ($$v) {
        _vm.$set(_vm.filters.calls, "call_type", $$v);
      },
      expression: "filters.calls.call_type"
    }
  })], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20prcntOnly mr-16px"
  }, [_c('label', [_vm._v("Filter by time")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "options": Object.values(_vm.dates)
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function (_ref9) {
        var option = _ref9.option;
        return [_c('div', {
          staticClass: "d-flex align-content-center w-100 h-100"
        }, [_c('span', {
          staticClass: "p-0"
        }, [_vm._v(_vm._s(_vm._f("get_property")(option, 'text', 'Select time')))])])];
      }
    }, {
      key: "option",
      fn: function (_ref10) {
        var option = _ref10.option;
        return [_vm._v(" " + _vm._s(_vm._f("get_property")(option, 'text')) + " ")];
      }
    }, {
      key: "no-record",
      fn: function () {
        return [_vm._v("There is no times")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.calls.date,
      callback: function ($$v) {
        _vm.$set(_vm.filters.calls, "date", $$v);
      },
      expression: "filters.calls.date"
    }
  })], 1)])]), _c('div', {
    staticClass: "d-flex justify-content-between mt-38px w-100 MonitorDashboard-section2"
  }, [_c('div', {
    staticClass: "monitorDashboard-analytics-box align-self-stretch"
  }, [_c('div', {
    staticClass: "monitorDashboard-newProgressBar"
  }, [_c('div', {
    style: `--value:${_vm.getProperty(_vm.response.calls, 'data.service_level', 0)};--fg:#249CE0`,
    attrs: {
      "role": "progressbar",
      "aria-valuenow": _vm._f("get_property")(_vm.response.calls, 'data.service_level', 0),
      "aria-valuemin": "0",
      "aria-valuemax": "100"
    }
  }, [_c('div', {
    staticClass: "newProgressBar-inner"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('div', {
    staticClass: "newProgressBar-smallText"
  }, [_vm._v("Service level")]), _c('div', {
    staticClass: "newProgressBar-bigText"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.calls, 'data.service_level', 0)) + "%")]), _c('div', {
    staticClass: "newProgressBar-smallText"
  }, [_vm._v("Target: " + _vm._s(_vm._f("get_property")(_vm.response.calls, 'data.service_level', 0)) + "%")])])])])])]), _c('div', {
    staticClass: "monitorDashboard-analytics-box"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "monitorDashboard-callWaiting",
      "width": "52.005px",
      "height": "52.005px"
    }
  }), _c('div', {
    staticClass: "monitorDashboard-analytics-heading"
  }, [_vm._v("Calls waiting")]), _c('div', {
    staticClass: "monitorDashboard-analytics-number callWaiting"
  }, [_vm._v(_vm._s(_vm._f("numberPad")(_vm._f("get_property")(_vm.response.calls, 'data.calls_wating', 0))))]), _c('div', {
    staticClass: "monitorDashboard-analytics-text"
  }, [_vm._v("Now")])], 1), _c('div', {
    staticClass: "monitorDashboard-analytics-box"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "monitorDashboard-missedCalls",
      "width": "52.005px",
      "height": "52.005px"
    }
  }), _c('div', {
    staticClass: "monitorDashboard-analytics-heading"
  }, [_vm._v("Missed calls")]), _c('div', {
    staticClass: "monitorDashboard-analytics-number missedCalls"
  }, [_vm._v(_vm._s(_vm._f("numberPad")(_vm._f("get_property")(_vm.response.calls, 'data.missed_call', 0))))]), _c('div', {
    staticClass: "monitorDashboard-analytics-text"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.selectedDate, 'text')))])], 1), _c('div', {
    staticClass: "monitorDashboard-analytics-box"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "monitorDashboard-longestWaitingTime",
      "width": "52.005px",
      "height": "52.005px"
    }
  }), _c('div', {
    staticClass: "monitorDashboard-analytics-heading"
  }, [_vm._v("Longest waiting time")]), _c('div', {
    staticClass: "monitorDashboard-analytics-number longestWaitingTime"
  }, [_vm._v(_vm._s(_vm._f("filter_date_current")(_vm._f("get_property")(_vm.response.calls, 'data.longest_waiting_time', 0), '', {
    seconds: true,
    formate: 'mm:ss'
  })))]), _c('div', {
    staticClass: "monitorDashboard-analytics-text"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.selectedDate, 'text')))])], 1), _c('div', {
    staticClass: "monitorDashboard-analytics-box"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "monitorDashboard-averageWaitingTime",
      "width": "52.005px",
      "height": "52.005px"
    }
  }), _c('div', {
    staticClass: "monitorDashboard-analytics-heading"
  }, [_vm._v("Average waiting time")]), _c('div', {
    staticClass: "monitorDashboard-analytics-number averageWaitingTime"
  }, [_vm._v(_vm._s(_vm._f("filter_date_current")(_vm._f("get_property")(_vm.response.calls, 'data.average_waiting_time', 0), '', {
    seconds: true,
    formate: 'mm:ss'
  })))]), _c('div', {
    staticClass: "monitorDashboard-analytics-text"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.selectedDate, 'text')))])], 1), _c('div', {
    staticClass: "monitorDashboard-analytics-box align-self-stretch"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "monitorDashboard-availableUsers",
      "width": "52.005px",
      "height": "52.005px"
    }
  }), _c('div', {
    staticClass: "monitorDashboard-analytics-heading"
  }, [_vm._v("Available users")]), _c('div', {
    staticClass: "monitorDashboard-analytics-number availableUsers"
  }, [_vm._v(_vm._s(_vm._f("numberPad")(_vm._f("get_property")(_vm.response.calls, 'data.available_users', 0))))]), _c('div', {
    staticClass: "monitorDashboard-analytics-text"
  }, [_vm._v("Now")])], 1), _c('div', {
    staticClass: "monitorDashboard-analytics-box align-self-stretch"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "monitorDashboard-incommingCalls",
      "width": "52.005px",
      "height": "52.005px"
    }
  }), _c('div', {
    staticClass: "monitorDashboard-analytics-heading"
  }, [_vm._v("Incoming calls")]), _c('div', {
    staticClass: "monitorDashboard-analytics-number IncomingCalls"
  }, [_vm._v(_vm._s(_vm._f("numberPad")(_vm._f("get_property")(_vm.response.calls, 'calls_type.incoming', 0))))]), _c('div', {
    staticClass: "monitorDashboard-analytics-text"
  }, [_vm._v("Now")])], 1), _c('div', {
    staticClass: "monitorDashboard-analytics-box align-self-stretch"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "monitorDashboard-outgoingCalls",
      "width": "52.005px",
      "height": "52.005px"
    }
  }), _c('div', {
    staticClass: "monitorDashboard-analytics-heading"
  }, [_vm._v("Outgoing calls")]), _c('div', {
    staticClass: "monitorDashboard-analytics-number OutgoingCalls"
  }, [_vm._v(_vm._s(_vm._f("numberPad")(_vm._f("get_property")(_vm.response.calls, 'calls_type.outgoing', 0))))]), _c('div', {
    staticClass: "monitorDashboard-analytics-text"
  }, [_vm._v("Now")])], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center my-25px"
  }, [_c('div', {
    staticClass: "MonitorDashboard-heading"
  }, [_vm._v("Live filtered calls")]), _c('div', {
    staticClass: "wd-17",
    staticStyle: {
      "min-width": "270px"
    }
  }, [_c('div', {
    staticClass: "liveFeed-switch-container"
  }, [_c('div', {
    staticClass: "liveFeed-ststus-circle",
    staticStyle: {
      "background-color": "#00C267"
    }
  }), _c('div', {
    staticClass: "liveFeed-text"
  }, [_vm._v("Live feed")]), _c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.conditions.live_feed,
      callback: function ($$v) {
        _vm.$set(_vm.conditions, "live_feed", $$v);
      },
      expression: "conditions.live_feed"
    }
  })], 1)])]), _c('div', {
    staticClass: "monitoringTable-container mb-5"
  }, [_vm.api.fetch_calls.send && _vm.prevCalls.length == 0 && _vm.liveCalls.length == 0 ? _c('div', {
    staticClass: "latestMonitorDashboardBox mb-20px"
  }, _vm._l(6, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "latestMonitorDashboardBox-row"
    }, [_vm._m(1, true), _vm._m(2, true), _vm._m(3, true), _vm._m(4, true), _vm._m(5, true)]);
  }), 0) : _vm.conditions.user_mode ? [_c('div', {
    staticClass: "latestMonitorDashboardBox"
  }, _vm._l(_vm.usersCalls, function (data) {
    return _c('Item', {
      key: data.accountcode,
      attrs: {
        "call": Object.assign({
          user_accountcode: data.accountcode
        }, data.call),
        "extension_data": _vm.response.extension_data,
        "userDashboard": true
      },
      on: {
        "click-play": function ($event) {
          return _vm.playAudio($event);
        },
        "clicked": function ($event) {
          return _vm.$modal.show(`${_vm._uid}-UserExtensionCallsModal`, {
            accountcode: data.accountcode
          });
        },
        "ai-modal": function ($event) {
          return _vm.$modal.show(`${_vm._uid}-AITranscriptModal`, {
            call: Object.assign({}, data.call, {
              call_type: 'audio',
              status: ''
            })
          });
        }
      }
    });
  }), 1)] : [_vm.liveCalls.length > 0 ? _c('div', {
    staticClass: "latestMonitorDashboardBox mb-20px"
  }, _vm._l(_vm.liveCalls, function (call) {
    return _c('Item', {
      key: call.id,
      attrs: {
        "call": call,
        "extension_data": _vm.response.extension_data
      },
      on: {
        "click-play": function ($event) {
          return _vm.playAudio($event);
        }
      }
    });
  }), 1) : _vm._e(), _vm.prevCalls.length > 0 ? _c('div', {
    staticClass: "latestMonitorDashboardBox"
  }, _vm._l(_vm.prevCalls, function (call) {
    return _c('Item', {
      key: call.uniqueid,
      attrs: {
        "call": call,
        "extension_data": _vm.response.extension_data
      },
      on: {
        "click-play": function ($event) {
          return _vm.playAudio($event);
        },
        "ai-modal": function ($event) {
          return _vm.$modal.show(`${_vm._uid}-AITranscriptModal`, {
            call: Object.assign({}, call, {
              call_type: 'audio',
              status: ''
            })
          });
        }
      }
    });
  }), 1) : _vm.prevCalls.length == 0 ? [_c('div', {
    staticClass: "emptyScreenContainer allTransparent pt-0"
  }, [_c('img', {
    staticClass: "mt-20px",
    staticStyle: {
      "max-width": "380px"
    },
    attrs: {
      "width": "65%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/forCallLogs.png')
    }
  }), _c('div', {
    staticClass: "emptyScreenContainer-heading inCallLogs mt-24px"
  }, [_vm._v(" Welcome aboard to VoIP Business! ")]), _vm._m(6)])] : _vm._e()]], 2), _vm.selected.sound_file ? [_c('div', {
    staticClass: "AudioPlayerShownInsideCallLogsList"
  }, [_c('div', {
    staticClass: "AudioPlayerShownInsideCallLogsList-inner"
  }, [_c('vb-audio-player', {
    attrs: {
      "src": _vm.selected.sound_file,
      "autoPlay": true,
      "design": 'NoTranscript'
    }
  }), _c('b-button', {
    staticClass: "p-0 bg-transparent border-0",
    on: {
      "click": function ($event) {
        _vm.selected.sound_file = null;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "squared-close-icon",
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  })], 1)], 1)])] : _vm._e()], 2), _c('modal', {
    staticClass: "monitorCallItemsModal",
    attrs: {
      "name": `${_vm._uid}-UserExtensionCallsModal`
    },
    on: {
      "before-open": function ($event) {
        _vm.selected.user_accountcode = $event.params.accountcode;
      },
      "before-close": function ($event) {
        _vm.selected.user_accountcode = '';
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("List")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-UserExtensionCallsModal`);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-UserExtensionCallsModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "monitoringTable-container"
  }, [_c('div', {
    staticClass: "latestMonitorDashboardBox"
  }, [_vm._l(_vm.specificUserCalls, function (data) {
    var _data$call;
    return _c('Item', {
      key: data.accountcode,
      attrs: {
        "call": Object.assign({
          user_accountcode: _vm.selected.user_accountcode,
          extension_accountcode: data.accountcode
        }, (_data$call = data.call) !== null && _data$call !== void 0 ? _data$call : {}),
        "extension_data": _vm.response.extension_data,
        "userDashboard": true
      },
      on: {
        "click-play": function ($event) {
          return _vm.playAudio($event);
        },
        "ai-modal": function ($event) {
          return _vm.$modal.show(`${_vm._uid}-AITranscriptModal`, {
            call: Object.assign({}, data.call, {
              call_type: 'audio',
              status: ''
            })
          });
        }
      }
    });
  }), _vm.specificUserCalls.length == 0 ? [_c('div', {
    staticClass: "emptyScreenContainer allTransparent pt-0"
  }, [_c('img', {
    staticClass: "mt-20px",
    staticStyle: {
      "max-width": "380px"
    },
    attrs: {
      "width": "65%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/forCallLogs.png')
    }
  }), _c('div', {
    staticClass: "emptyScreenContainer-heading inCallLogs mt-24px"
  }, [_vm._v(" Welcome aboard to VoIP Business! ")]), _c('div', {
    staticClass: "emptyScreenContainer-text inCallLogs w-100"
  }, [_vm._v(" Ready to boost your productivity?"), _c('br'), _vm._v(" Experience now by making your first call ")])])] : _vm._e()], 2)])]), _c('AITranscriptModal', {
    attrs: {
      "CallItemShow": false,
      "modalName": `${_vm._uid}-AITranscriptModal`
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "MonitorDashboard-heading"
  }, [_vm._v("Monitor dashboard")]), _c('div', {
    staticClass: "MonitorDashboard-heading-text"
  }, [_vm._v(" View recent call activities and monitor live calls with our real time monitoring feature. ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "latestMonitorDashboardBox-column one"
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "19px",
      "width": "50%"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "latestMonitorDashboardBox-column two"
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "19px",
      "width": "50%"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "latestMonitorDashboardBox-column three"
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "19px",
      "width": "50%"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "latestMonitorDashboardBox-column four"
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "19px",
      "width": "50%"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "latestMonitorDashboardBox-column five"
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "19px",
      "width": "50%"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "emptyScreenContainer-text inCallLogs w-100"
  }, [_vm._v(" Are you ready to unleash unstoppable productivity?"), _c('br'), _vm._v(" Take the plunge and make your first call now! ")]);

}]

export { render, staticRenderFns }