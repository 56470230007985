<template>
  <div class="insideMobileApp">
    <div class="d-flex flex-column dialer-parent dialer-light-mode insideAnroidIOSApp">
      <div id="dialer-parent-inner" class="dialer-parent-inner" style="height: 100vh;">
        <div class="d-flex h-100">
          <div class="dialer-main-center-area d-flex flex-column h-100 settingsIsOpened insideAnroidIOSApp">
            <CommonCalls id="videocomon" />
            <loader-main v-if="is_loading" />
            <template v-else>
              <CommonChat ref="common_chat" />
              <HomeCopy4 v-if="page=='call-logs'" :dialer="false" />
              <VideoCall v-else-if="page=='video-call'" />
              <keep-alive v-else>
                <MobileSettings class="insideAnroidIOSApp" />
              </keep-alive>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TokenService } from '../../services'
import { $fn, VOIP_API, /*URLS,*/ LOGGER, files, events } from '../../utils'
import LoaderMain from '../../components/LoaderMain.vue'
import MobileSettings from './MobileSetting.vue'
// import MobileSettings from '../MobileSettings.vue'
import HomeCopy4 from '../HomeCopy4.vue'
// import { centrifugo } from '@/Centrifuge'
import _ from 'lodash';
import { electron_events } from '@/electron/events'
import { AMI_EVENTS, AMI_SOCKET } from '@/ami'
import VideoCall from '../VideoCall.vue'
import CommonCalls from "../../components/Calls/index.vue";
import CommonChat from '../../components/CommonChat.vue';
import { MOBILE_APP } from '@/mobile-app';
import { MOBILE_APP_EVENTS, MOBILE_APP_SEND_EVENTS } from '@/mobile-app/events'
const moment = require("moment-timezone");
export default {
  name: 'MobileSetting',
  components: { 
    LoaderMain,
    MobileSettings,
    HomeCopy4,
    VideoCall,
    CommonCalls,
    CommonChat,
  },
  data(){
    return {
      vb_token: '',
      account: '',
      username: '',
      page: '',
      is_loading: true,
    }
  },
  inject: [
    'appNotify',
  ],
  provide(){
    return{
      local_filess: files,
      logout: this.logout,
      getVoipUsers: this.getVoipUsers,
      getVoipTeams: this.getVoipTeamss,
      getCallQueue: this.getCallQueue,
      getIvrs: this.getIvr,
      getNumbers: this.getNumbers,
      getCallTags: this.getCallTags,
    }
  },
  watch: {
    "$store.state.common.user"(user){
      const l_user = TokenService.USER.get()
      let new_user = {
        ...l_user,
        ...user,
      }
      TokenService.USER.set(new_user)
      if(process.env.IS_ELECTRON){
        window.electron.send(electron_events.USER_UPDATEED,new_user)
      }
      if(this.$store?.state?.common?.state?.voipusers?.[user.account]){
        this.$store.state.common.state.voipusers[user.account].status=user.status_id
      }
    },
    
    "$store.state.common.voipusers"(users) {
      if (typeof users === "object") {
        this.getStatuses()
      }
    },
  },
  methods: {
    async login(){
      try {
        const { data: { data } } = await VOIP_API.endpoints.auth.getLoginResponseByVBToken({
          username: this.username,
          uuid: this.vb_token,
          accountcode: this.account,
        });
        $fn.setUserLocally(data)
        this.load()
      } catch(err) {
        this.logout()
      } finally {
        this.is_loading=false
      }
    },
    logout(...args){ return $fn.logout(...args) },
    getStatuses(){
      let vm=this;
      VOIP_API.endpoints.user_statuses.usersStatus().then(({ data: statuses })=>{
        for (let index = 0; index < statuses.length; index++) {
          const { accountcode, status_id } = statuses[index];
          if(!vm.$store.state.common.voipusers[accountcode]) continue;
          vm.$set(vm.$store.state.common.voipusers[accountcode],'status',status_id)
        }
      })
    },
    getNumbers() {
      let vm = this
      return new Promise((resolve,reject)=>{
        VOIP_API.endpoints.telephone_number.numbers()
        .then(({ data })=>{
          const numbers = data?.data ?? data ?? []
          resolve(numbers)
          vm.$store.commit("setVoipNumbers", numbers);
        })
        .catch((ex)=>{
          reject(ex)
        })
      })
    },
    getVoipUsers(){
      let vm = this
      const current_user = TokenService.USER.get()
      VOIP_API.endpoints.users.list()
      .then(({ data: { data: voipusers } })=>{
        if(_.isEmpty(voipusers)) throw new Error('Users is Empty')
        return $fn.manipulateVoipUsers(voipusers,vm.$store.state.common.voipusers)
      })
      .then((voipusers)=>{
        return _.keyBy(voipusers,'voipaccount')
      })
      .then((voipusers)=>{
        vm.$store.commit("setVoipUsers", voipusers);
      })
      .catch(ex=>{
        if(ex.message=='Users is Empty'){
          vm.$store.commit("setVoipUsers", {});
        }
        LOGGER.danger('error in voipusers api',ex)
      })
      .finally(()=>{
        VOIP_API.endpoints.users.deletedlist(current_user.uid)
        .then(({ data: deleted_voipusers })=>{
          return deleted_voipusers?.map?.((user)=>{
            return {
              voipaccount: user.accountcode,
              user: user.voip_user,
              extn: user.extn,
              profile_img: '',
              status: '',
              state: 'Not online',
              deleted: true,
            }
          }) ?? []
        })
        .then((deleted_voipusers)=>{
          return _.keyBy(deleted_voipusers,'voipaccount')
        })
        .then((deleted_voipusers)=>{
          vm.$store.commit("setVoipDeletedUsers", deleted_voipusers);
        })
      });
    },
    getCallQueue(){
      let vm = this;
      return new Promise((resolve,reject)=>{
        let __call_queues = [], page = 1, total_rec = 0;
        const getData = () => {
          const onComplete = (type='success',data) => {
            __call_queues = _.orderBy(__call_queues, ['real_id'], ['asc'])
            VOIP_API.endpoints.call_queues.getstatuses()
            .then((res)=>{
              const statuses = res?.data ?? {}
              LOGGER.log('statuses,__call_queues',statuses,__call_queues)
              return __call_queues?.map?.(call_queue=>{
                return {
                  ...call_queue,
                  call_queue_status: statuses?.[call_queue.voipaccount],
                }
              })
            })
            .then((cal_queues)=>{
              vm.$store.commit("setCallQueues", {
                data: cal_queues,
                total: total_rec
              });
            })
            .catch(()=>{
              vm.$store.commit("setCallQueues", {
                data: __call_queues,
                total: total_rec
              });
            })
            .finally(()=>{
              if(type=='success'){
                resolve(__call_queues)
              } else {
                reject(data)
              }
            })
          }
          VOIP_API.endpoints.call_queues.list({
            page: page,
          })
          .then((res)=>{
            const _call_queues = res?.data?.data ?? []
            total_rec = res?.data?.meta?.pagination?.total ?? (__call_queues?.length + _call_queues?.length)
            __call_queues=[...__call_queues,..._call_queues]
            if(__call_queues.length!=total_rec){
              page=page+1
              getData()
            } else {
              onComplete()
            }
          })
          .catch((ex)=>{
            onComplete('error',ex)
          })
        }
        getData()
      })
    },
    getIvr(){
      let vm = this;
      return new Promise((resolve,reject)=>{
        let __ivrs = [], page = 1, total_rec = 0;
        const getData = () => {
          const onComplete = (type='success',data) => {
            vm.$store.commit("setIVRS", {
              data: __ivrs,
              total: total_rec
            });
            if(type=='success'){
              resolve(__ivrs)
            } else {
              reject(data)
            }
          }
          VOIP_API.endpoints.menu.list({
            page,
          })
          .then((res) => {
            const _ivrs = res?.data?.data ?? {}
            total_rec = res?.data?.total ?? (Object.keys(__ivrs)?.length + Object.keys(_ivrs)?.length)
            __ivrs={
              ...__ivrs,
              ..._ivrs
            }
            if(Object.keys(__ivrs).length!=total_rec){
              page=page+1
              getData()
            } else {
              onComplete()
            }
          }).catch((ex)=>{
            onComplete('error',ex)
          })
        }
        getData()
      })
    },
    getVoipTeamss(){
      let vm = this, __teams = [], page = 1, total_rec = 0;
      const getTeams = () => {
        VOIP_API.endpoints.teams.list({
          fid: 12,
          page: page,
        })
        .then((res)=>{
          const _teams = res?.data?.data ?? []
          total_rec = res?.data?.meta?.pagination?.total ?? (__teams?.length + _teams?.length)
          __teams=[...__teams,..._teams]
          if(__teams.length!=total_rec){
            page=page+1
            getTeams()
          } else {
            vm.$store.commit("setVoipTeams", {
              data: __teams,
              total: total_rec
            });
          }
        })
        .catch((ex)=>{
          vm.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        })
        .finally(()=>{
          // TODO
        })
      }
      getTeams()
    },
    getCallTags(){
      let vm = this
      return new Promise((resolve,reject)=>{
        const current_user = TokenService.USER.get()
        VOIP_API.endpoints.tags.list({
          uid: current_user.uid,
          accountcode: current_user.account,
        })
        .then(({ data }) => {
          const tags = data?.data ?? []
          vm.$store.commit('setCallTags',tags)
          resolve(tags)
        })
        .catch((ex)=>{
          reject(ex)
        })
      })
    },
    setStore(){
      let vm = this
      const current_user = TokenService.USER.get()
      const user_statuses = TokenService.USER_STATUSES.get() ?? {}
      const appData = TokenService.APP_DATA.get()
      if(appData){
        this.$store.commit("setCommonState",appData);
        TokenService.APP_DATA.remove()
      }
      // time zone
      VOIP_API.endpoints.users.accountdetail()
      .then(({ data: accountdetail }) => {
        const timezone = accountdetail?.user_detail?.timezone ?? ''
        if(timezone) moment.tz.setDefault(timezone);
        else moment.tz.setDefault();
        vm.$store.state.common.user.country = accountdetail?.user_detail?.country ?? moment.tz(moment().tz())._z.countries()?.[0] ?? ''
      })
      // statuses
      VOIP_API.endpoints.statuses.list({
        uid: current_user.uid,
      })
      .then(({ data: statuses })=>{
        return statuses.map((statuses) => {
          return { ...statuses, allow_call: statuses.allow_call == "Y" };
        });
      })
      .then((statuses)=>{
        vm.$store.state.common.statuses = statuses;
        const available = statuses?.find?.(status=>status.status=='Available' && status.accountcode==null)
        const currently = statuses?.find?.(status=>status.id==current_user.status_id)
        const before = statuses?.find?.(status=>status.id==user_statuses[current_user.account])
        return currently ?? before ?? available
      })
      .then(()=>{ // status
        // vm.$store.dispatch('setStatus',status.id || 56)
        return $fn.sleep(3*1000)
      });
      // organization list
      VOIP_API.endpoints.user.organizationlist({
        accountcode: current_user.account,
        uid: current_user.uid
      })
      .then(({ data: { data: organizations } })=>{
        const new_organizations_array = organizations.map(organization=>{
          return {
            id: organization.origanization.origanization_id,
            uid: organization.origanization.origanization_uid,
            label: organization.origanization.origanization_label,
            logo: organization.origanization.origanization_logo,
          }
        })
        LOGGER.log('new_organizations_array',new_organizations_array)
        return $fn.sleep(2*1000,new_organizations_array)
      })
      .then((new_organizations_array)=>{
        vm.$store.state.common.user.organizations = new_organizations_array;
        vm.$store.state.common.user.selected_organization_id = vm.$store.state.common.user.selected_organization_id || new_organizations_array[0] ? new_organizations_array[0].id : '';
      })
      vm.getCallTags()
      vm.getVoipUsers()
      vm.getVoipTeamss(true)
      vm.getCallQueue()
      vm.getIvr()
      vm.getNumbers()
    },
    socketConnection(){
      try {
        // centrifugo.mothership.connect();
        // centrifugo.mothershipadmin.connect();
        // centrifugo.notification_admin.connect();
        // centrifugo.notification.connect();
        // centrifugo.calls.connect();
        AMI_SOCKET.connect();
      } catch(error) {
        LOGGER.danger('error in socketConnection function')
      }
    },
    load(){
      // this.socketConnection()
      this.setStore()
    },
    //--events--
    STATUS_BROADCAST(message){
      const { data: { accountcode, status } } = message
      if(!this.$store.state.common.voipusers[accountcode]) return;
      this.$set(this.$store.state.common.voipusers[accountcode],'status',status.id)
    },
    updateOnlineStatus(){
      if(window.navigator.onLine){
        // this.socketConnection()
        this.setStore()
      } else {
        //TODO
      }
    },
    onDialCall({ accountcodes }){
      this.$root.$emit(events.video_call, {
        accounts: accountcodes,
        call_type: "video",
        onAbort(error){
          console.log('run error',error)
        }
      });
    },
    onLoginResponse(data){
      $fn.setUserLocally(data)
      this.load()
      this.is_loading=false
      MOBILE_APP.sendMessage(MOBILE_APP_SEND_EVENTS.LOGGED_IN)
    },
    onNewToken({ access_token, refresh_token }){
      TokenService.TOKEN.set(access_token);
      TokenService.REFRESH_TOKEN.set(refresh_token);
    },
  },
  created(){
    const { page, cp_domain } = this.$route.query
    TokenService.CP_DOMAIN.set(cp_domain)
    this.page=page
    this.$store.state.global_conditions.is_mobile_setting=true
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    AMI_SOCKET.on(AMI_EVENTS.STATUS_BROADCAST,this.STATUS_BROADCAST)
    MOBILE_APP.init()
    MOBILE_APP.on(MOBILE_APP_EVENTS.DIAL,this.onDialCall.bind(this))
    MOBILE_APP.on(MOBILE_APP_EVENTS.LOGIN_RESPONSE,this.onLoginResponse.bind(this))
    MOBILE_APP.on(MOBILE_APP_EVENTS.NEW_TOKEN,this.onNewToken.bind(this))
  },
  mounted(){
    MOBILE_APP.sendMessage(MOBILE_APP_SEND_EVENTS.LOADED)
  },
  beforeDestroy(){
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
    AMI_SOCKET.off(AMI_EVENTS.STATUS_BROADCAST,this.STATUS_BROADCAST)
    MOBILE_APP.off(MOBILE_APP_EVENTS.DIAL,this.onDialCall.bind(this))
    MOBILE_APP.off(MOBILE_APP_EVENTS.LOGIN_RESPONSE,this.onLoginResponse.bind(this))
    MOBILE_APP.off(MOBILE_APP_EVENTS.NEW_TOKEN,this.onNewToken.bind(this))
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/mobileAppSettingsStyle.scss";
</style>