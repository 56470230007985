<template>
  <div class="dialer-analytics-main dialer-analytics-summary">
    <div class="dialer-box p-16-24-px">
      <!-- <header class="dialer-analytics-titleBar">
        <template v-if="getIsMobile">
          <div class="dialer-analytics-title calc-title-width-analytics">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h1 class="">Call activity</h1>
          </div>
        </template>
        <div v-else class="dialer-analytics-title">
          <vb-icon icon="analytics-CallSummary-icon" width="23" height="23"/>
          <h1 class="md-mar-left">Call activity</h1>
        </div>
        <div class="TabsDesignWithIcon-container">
          <div class="TabsDesignWithIcon-item mr-8px" 
            v-for="tab in tabs" :key="tab.value" 
            @click="selected.tab=tab.value;tab.value!=tabs.custom.value?fecthGraph():''" 
            :class="{'active': selected.tab === tab.value}"
          >
            <vb-icon :icon="tab.icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">{{tab.text}}</span>
          </div>
        </div>
      </header>
      <div class="dialer-analytics-datescroller justify-content-end box-shadow-none">
        <div style="flex-direction: column;align-items: flex-end;flex-direction: column;" class="d-flex">
          <div v-if="selected.tab==tabs.custom.value" class="d-flex customDate">
            <div class="d-inline-flex whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker align-items-center mr-3">
              <span>From:</span>
              <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" class="ml-2 date-time-input" v-model="filter.graph.start_time" id="starttime" />
            </div>
            <div class="d-inline-flex whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionRightAligned align-items-center mr-4">
              <span>To:</span>
              <vue-ctk-date-time-picker :min-date="filter.graph.start_time" v-bind="ctkDateTimePickerOptions" class="ml-2 date-time-input" v-model="filter.graph.end_time" id="endtime" />
            </div>
            <a class="newButton" @click="fecthGraph()">
              <vb-icon icon="squared-browse-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Fetch</span>
            </a>
          </div>
        </div>
      </div> -->
      <template v-if="getIsMobile">
        <header class="dialer-analytics-titleBar pb-1">
          <div class="dialer-analytics-title calc-title-width-analytics">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h1 class="">Call activity</h1>
          </div>
        </header>
        <div class="w-100 d-flex align-items-center justify-content-end">
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterWithText mr-16px">
            <b-dropdown varient="link" no-caret class="ico-filter" right> 
              <template #button-content>
                <span class="mr-12px" v-if="response.graph.date_label">{{ response.graph.date_label }}</span>
                <b-icon icon="three-dots-vertical" variant="dark" class="fillBlack" scale="1.5" />
              </template>
              <b-dropdown-item v-for="tab in tabs" :key="tab.value" @click="selected.tab=tab.value;tab.value==tabs.custom.value ? $modal.show(`${_uid}-dateFilterModal`) : fecthGraph()" >
                <vb-icon icon="markAsComplete-icon" :style="`opacity:${selected.tab === tab.value ? '1' : '0'}`" class="mr-12px" width="14.49px" height="10.431px"/>
                <span >{{tab.text}}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
      <div v-else class="analytics-newHeader mb-32px">
        <div class="analytics-newHeader-leftSide">
          <h1 >Call activity</h1>
        </div>
        <div class="analytics-newHeader-rightSide">
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterWithText mr-16px">
            <b-dropdown varient="link" no-caret class="ico-filter" right> 
              <template #button-content>
                <span class="mr-12px" v-if="response.graph.date_label">{{ response.graph.date_label }}</span>
                <b-icon icon="three-dots-vertical" variant="dark" class="fillBlack" scale="1.5" />
              </template>
              <b-dropdown-item v-for="tab in tabs" :key="tab.value" @click="selected.tab=tab.value;tab.value==tabs.custom.value ? $modal.show(`${_uid}-dateFilterModal`) : fecthGraph()" >
                <vb-icon icon="markAsComplete-icon" :style="`opacity:${selected.tab === tab.value ? '1' : '0'}`" class="mr-12px" width="14.49px" height="10.431px"/>
                <span >{{tab.text}}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
      <!-- <vb-loading class="md-mar-top text-center w-100" v-if="api.fetch_graph.send" /> -->
      <template>
        <!-- <vb-no-record class="text-center w-100" v-if="isEmpty(response.graph)" :text="api.fetch_graph.send ? '' : 'No record'" :design="3" /> -->
        <div class="analytics-summary-content">
          <!-- v-show="!isEmpty(response.graph) && !api.fetch_graph.send" -->
          <div class="latestGreyBox-9-9-2023 mt-24px">
            <!-- <h3 class="dialer-analytics-subtitle mb-0">
              <span v-if="response.graph.date_label">{{ response.graph.date_label }}</span>
              <span v-else>Select a date and time</span>
            </h3> -->
             <!-- mt-24px -->
            <div class="dialer-analytics-totals v2 updatedAgain mb-4"> 
              <div class="dialer-call-total dialer-total">
                <div class="dialer-call-total-inner">
                  <div class="dialer-call-total-top">
                    <div class="dialer-call-type">
                      <vb-icon icon="CallSummary-TotalCall-icon"/>
                      <div>total Calls</div>
                    </div>
                  </div>
                  <div class="dialer-call-figures">
                    <h4 class="dialer-call-number">{{ response.graph | get_property('totalCalls',0) }}</h4>
                    
                  </div>
                </div>
              </div>
              <div class="dialer-call-total dialer-missed">
                <div class="dialer-call-total-inner">
                  <div class="dialer-call-total-top">
                    <div class="dialer-call-type">
                      <vb-icon icon="CallSummary-MissedCall-icon"/>
                      <div>missed Calls</div>
                    </div>
                    <span class="dialer-call-total-time"></span>
                  </div>
                  <div class="dialer-call-figures">
                    <h4 class="dialer-call-number">{{ response.graph | get_property('totalMissCalls',0) }}</h4>
                    <div class="newProgressBar">
                      <div role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" :style="`--value:${callsPercentage.missed};--fg:#dc3545`">
                        <div class="newProgressBar-inner">
                          {{callsPercentage.missed}}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dialer-call-total dialer-inbound">
                <div class="dialer-call-total-inner">
                  <div class="dialer-call-total-top">
                    <div class="dialer-call-type">
                      <vb-icon icon="CallSummary-InboundCall-icon"/>
                      <div>Received Calls</div>
                    </div>
                    <span class="dialer-call-total-time"></span>
                  </div>
                  <div class="dialer-call-figures">
                    <h4 class="dialer-call-number">{{ response.graph | get_property('totalReceiveCalls',0) }}</h4>
                    <div class="newProgressBar">
                      <div role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" :style="`--value:${callsPercentage.incomming};--fg:#28a745`">
                        <div class="newProgressBar-inner">
                          {{callsPercentage.incomming}}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dialer-call-total dialer-outbound">
                <div class="dialer-call-total-inner">
                  <div class="dialer-call-total-top">
                    <div class="dialer-call-type">
                      <vb-icon icon="CallSummary-OutboundCall-icon"/>
                      <div>outbound Calls</div>
                    </div>
                  </div>
                  <div class="dialer-call-figures">
                    <h4 class="dialer-call-number">{{ response.graph | get_property('totalOutgoingCalls',0) }}</h4>
                    <div class="newProgressBar">
                      <div role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" :style="`--value:${callsPercentage.outgoing};--fg:#5576d1`">
                        <div class="newProgressBar-inner">
                          {{callsPercentage.outgoing}}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="api.fetch_graph.send && chart.graph==null">
              <div v-for="n in 7" :key="n.id" class="latestShimmerDesign mb-2" style="height: 45px;width: 100%;"></div>
            </template>
            <div class="chart-container">
              <canvas id="planet-chart"></canvas>
            </div>
          </div>
        </div>
      
      </template>
    
    </div>
    <modal :name="`${_uid}-dateFilterModal`" class="callReportingFilterModal">
      <div class="dialer-edit-header pt-0 position-relative">
        <div>
          <h2 class="dialer-settings-title newer mb-0">Filters</h2>
        </div>
        <div class="dialer-edit-actions">
          <button class="newDoneButton" type="button" @click="$modal.hide(`${_uid}-dateFilterModal`)">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023">
        <div class="analytics-newHeader-text">
          <div class="d-flex customDate">
            <div class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionLeftAligned w-50 pr-2">
              <span>From:</span>
              <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" class="date-time-input" v-model="filter.graph.start_time" id="starttime" />
            </div>
            <div class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionRightAligned w-50 pl-2">
              <span>To:</span>
              <vue-ctk-date-time-picker :min-date="filter.graph.start_time" v-bind="ctkDateTimePickerOptions" class="date-time-input" v-model="filter.graph.end_time" id="endtime" />
            </div>
          </div>
          <div class="w-100 d-flex justify-content-end mt-20px">
            <a class="newButton" @click="fecthGraph();$modal.hide(`${_uid}-dateFilterModal`)">
              <vb-icon icon="squared-browse-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Fetch</span>
            </a>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Chart from "chart.js";
import { VOIP_API } from "../../utils";
import { ctkDateTimePicker } from '../../mixin';
import { mapGetters } from 'vuex';
const tabs = {
  today: {
    text: "Today",
    value: "today",
    icon: 'squared-today-icon'
  },
  week: {
    text: "This week",
    value: "week",
    icon: 'squared-thisWeek-icon'
  },
  month: {
    text: "This month",
    value: "month",
    icon: 'squared-thisMonth-icon'
  },
  custom: {
    text: "Custom",
    value: "custom",
    icon: 'squared-customDate-icon'
  },
}
export default {
  name: 'Summary',
  mixins: [ctkDateTimePicker],
  inject:['isEmpty'],
  data() {
    return {
      api: {
        fetch_graph: this.$helperFunction.apiInstance({ source: true, }),
      },
      response: {
        graph: {},
      },
      chart: {
        graph: null,
      },
      filter:  {
        graph: {
          start_time: '',
          end_time: '',
        },
      },
      selected: {
        tab: tabs.today.value,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getIsMobile'
    ]),
    callsPercentage() {
      return {
        incomming: this.response.graph.totalReceiveCalls > 0 ? Math.ceil((this.response.graph.totalReceiveCalls / this.response.graph.totalCalls) * 100) : 0,
        outgoing: this.response.graph.totalOutgoingCalls > 0 ? Math.ceil((this.response.graph.totalOutgoingCalls / this.response.graph.totalCalls) * 100) : 0,
        missed: this.response.graph.totalMissCalls > 0 ? Math.ceil((this.response.graph.totalMissCalls / this.response.graph.totalCalls) * 100) : 0,
      };
    },
    tabs(){ return tabs },
  },
  methods: {
    async fecthGraph(){
      try {
        this.api.fetch_graph.source?.cancel?.("Requesting Again");
        this.api.fetch_graph.source = require("axios").default.CancelToken.source()
        this.api.fetch_graph.send=true
        const { data } = await VOIP_API.endpoints.analytics.list({
          type: this.selected.tab,
          start: this.filter.graph.start_time,
          end: this.filter.graph.end_time,
        },this.api.fetch_graph.source.token)
        this.response.graph = data
        if(this.chart.graph){
          this.chart.graph.data.labels=data.labels
          this.chart.graph.data.datasets.forEach(dataset => {
            if(dataset.label=='Missed'){
              dataset.data=data.missedhours
            } else if(dataset.label=='Inbound'){
              dataset.data=data.inhours
            } else {
              dataset.data=data.outhours
            }
          });
        } else {
          this.chart.graph = new Chart(document.getElementById("planet-chart"), {
            type: "line",
            data: {
              labels: data.labels,
              datasets: [
                {
                  label: "Missed",
                  data: data.missedhours,
                  borderColor: ["#da3444"],
                  borderWidth: 2,
                  backgroundColor: ["#da344410"],
                },
                {
                  label: "Inbound",
                  data: data.inhours,
                  borderColor: ["#269d41"],
                  borderWidth: 2,
                  backgroundColor: ["#269d4110"],
                },
                {
                  label: "Outbound",
                  data: data.outhours,
                  borderColor: ["#5272ca"],
                  borderWidth: 2,
                  backgroundColor: ["#5272ca10"],
                },
              ],
            },
            options: {
              responsive: true,
              lineTension: 1,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      stepSize: 1,
                    },
                    scaleLabel: {
                      display: true,
                      labelString: 'Number of Calls'
                    },
                  }
                ],
                xAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: 'Time Slot'
                    },
                  }
                ]
              },
            },
          });
        }
        this.chart.graph.update();
      } finally {
        this.api.fetch_graph.send=false
      }
    },
  },
  mounted() {
    this.fecthGraph();
  },
  beforeDestroy(){
    this.chart.graph?.destroy?.();
  },
};
</script>

<style lang="scss">
.dialer-call-total {
  &.dialer-inbound {
    .dialer-circularProgressBar {
      .circularProgressBar {
        .circularProgressBar-fill {
          background: $dialer-inboundCalls-circularProgressBarColor;
          &[data-v-555be7c7]{
            background: $dialer-inboundCalls-circularProgressBarColor;
          }
        }
      }
      .dialer-circularProgressBar-text {
        span {
          color: $dialer-inboundCalls-circularProgressBarColor;
        }
      }
    }
  }
  &.dialer-outbound {
    .dialer-circularProgressBar {
      .circularProgressBar {
        .circularProgressBar-fill {
          background: $dialer-outboundCalls-circularProgressBarColor;
          &[data-v-555be7c7]{
            background: $dialer-outboundCalls-circularProgressBarColor;
          }
        }
      }
      .dialer-circularProgressBar-text {
        span {
          color: $dialer-outboundCalls-circularProgressBarColor;
        }
      }
    }
  }
  &.dialer-missed {
    .dialer-circularProgressBar {
      .circularProgressBar {
        .circularProgressBar-fill {
          background: $dialer-missedCalls-circularProgressBarColor;
        }
      }
      .dialer-circularProgressBar-text {
        span {
          color: $dialer-missedCalls-circularProgressBarColor;
        }
      }
    }
  }
}
</style>
