<template>
  <div v-if="!isEmpty(track)" class="remote-track-container">
    <div v-if="track.type === 'video'" class="video-container">
      <video playsinline :id="id" autoplay="1" :class="{
        'share-screen': track.videoType=='desktop',
        'mirror-video': participant._properties.isMirrorVideo
      }" />
    </div>
    <div v-if="track.type === 'audio'" class="audio-container">
      <audio :id="id"  autoplay="1" v-mute="!videoCall.isPlay" />
    </div>
  </div>
</template>

<script>
import JitsiMeetJS from "@/Jitsi";
import { LOGGER } from "../../../../utils";
// import { common } from "../../../../mixin";
export default {
  name: "RemoteTrack",
  // mixins: [common],
  props: {
    remoteTrack: {
      type: Object,
    },
    index: {
      type: Number,
    },
    participant: {
      type: Object,
    },
    JitsiConference: {
      type: Object,
    },
    speaker_device_id: {
      type: String,
      default: ''
    },
    
    videoCall:{
      type: Object
    },
  },
  data() {
    return {
      track: null,

      id: "",
    };
  },
  inject:['isEmpty'],
  watch: {
    track: "watchingTrack",
    "participant._properties.audioLevel": "wacthingAudioLevel",
    "speaker_device_id": "watchingSpeakerID",
  },
  directives: {
    
    'mute': {
      inserted(el,binding){
        el.muted=!!binding.value
      },
      update(el,binding){
        el.muted=!!binding.value
      },
    }
  },
  methods: {
    //--watching--
    watchingTrack(track) {
      LOGGER.watch('track',track)
      if (track !== null) {
        const { events: { track: events }, } = JitsiMeetJS;
        track.addEventListener(events.LOCAL_TRACK_STOPPED,this.LOCAL_TRACK_STOPPED);
        track.addEventListener(events.NO_AUDIO_INPUT, this.NO_AUDIO_INPUT);
        track.addEventListener(events.NO_DATA_FROM_SOURCE,this.NO_DATA_FROM_SOURCE);
        track.addEventListener(events.TRACK_AUDIO_LEVEL_CHANGED,this.TRACK_AUDIO_LEVEL_CHANGED);
        track.addEventListener(events.TRACK_AUDIO_OUTPUT_CHANGED,this.TRACK_AUDIO_OUTPUT_CHANGED);
        track.addEventListener(events.TRACK_MUTE_CHANGED,this.TRACK_MUTE_CHANGED);
        track.addEventListener(events.TRACK_VIDEOTYPE_CHANGED,this.TRACK_VIDEOTYPE_CHANGED);
      }
    },
    wacthingAudioLevel(audioLevel) {
      LOGGER.info("watching audioLevel", { track: this.track });
      if (this.track.type === "audio") {
        this.$el.querySelector(`#${this.id}`).volume = audioLevel;
      }
    },
    watchingSpeakerID(speaker_device_id){
      const el = /* document.getElementById(this.id); */ this.$el.querySelector(`#${this.id}`)
      el.setSinkId(speaker_device_id)
    },

    //--events--
    LOCAL_TRACK_STOPPED() {
      LOGGER.event("LOCAL_TRACK_STOPPED");
    },
    NO_AUDIO_INPUT() {
      LOGGER.event("NO_AUDIO_INPUT");
    },
    NO_DATA_FROM_SOURCE() {
      LOGGER.event("NO_DATA_FROM_SOURCE");
    },
    TRACK_AUDIO_LEVEL_CHANGED() {
      LOGGER.event("TRACK_AUDIO_LEVEL_CHANGED");
    },
    TRACK_AUDIO_OUTPUT_CHANGED() {
      LOGGER.event("TRACK_AUDIO_OUTPUT_CHANGED");
    },
    TRACK_MUTE_CHANGED(JitsiRemoteTrack) {
      LOGGER.event("TRACK_MUTE_CHANGED", { JitsiRemoteTrack });
    },
    TRACK_VIDEOTYPE_CHANGED(videoType) {
      LOGGER.event("TRACK_VIDEOTYPE_CHANGED", { videoType });
    },
  },
  created() {
    try {
      LOGGER.hook('Created')
      this.track = this.remoteTrack;
      this.id = `remote_${this.track.type}_${
        this.track.videoType
      }_${this.track.isMuted()}_${this.track.getId()}_${this.participant._id}_${
        this.index
      }`;
      LOGGER.log({id:this.id,track:this.track})
    } catch(error) {
      LOGGER.danger('error in remoteTrack component created')
    }
  },
  mounted() {
    try {
      this.$el.querySelector(`#${this.id}`)
      const el = /* document.getElementById(this.id); */ this.$el.querySelector(`#${this.id}`)
      LOGGER.hook('id, el & track',{id:this.id,el,track:this.track})
      this.track.attach(el);
      el.setSinkId(this.speaker_device_id)
    } catch (error) {
      LOGGER.danger("error in remoteTrack component mounted", error);
    }
  },
  destroyed(){
    LOGGER.hook('destroyed remotetrack')
  }
};
</script>

<style lang="scss" scoped>
.remote-track-container {
  height: inherit;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  .video-container {
    height: 100%;
    video.mirror-video {
      transform: rotateY(180deg);
      -webkit-transform: rotateY(180deg);
    }
    video {
      height: 100%;
      width: 100%;
      // object-fit: cover;
      object-fit: contain;
    }
  }
  .audio-container {
    audio {
      height: auto;
    }
  }
}
</style>