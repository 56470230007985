<template>
  <div class="w-100">
    <input 
      ref="upload_input" 
      type="file" 
      v-show="false" 
      :multiple="false" 
      accept="audio/mpeg, audio/wav" 
      @change="selectFile(getProperty($event,'target.files[0]'))" 
    />
    <div class="latestGreyBox-heading-main" v-if="!isNumber">Welcome greeting</div>
    <div class="latestGreyBox-descriptionText">Customise the first impression of your business by uploading or recording a Personalised welcome greeting.</div>
    <div class="newIVRLayout-WelcomeGreetingsSection-theSection">
      <div v-if="conditions.screen==screens.default.value">
        <!-- <div class="dialer-audio-playback theSection-AudioSection">
          <div class="dialer-audio-playback-inner">
            <vb-audio-player :src="audio.src" />
          </div>
        </div> -->
        <div class="d-flex align-items-center justify-content-between w-100 mb-16px">
          <vb-audio-player :src="audio.src" :design="'whiteBgBlackBorder'" class="theSection-AudioSection mb-0" />
          <vb-icon icon="latestPencilIcon" height="38px" width="38px" @click="conditions.edit=!conditions.edit" class="ml-2" />
          <!-- <vb-icon v-if="isNumber && !conditions.edit && response.number_setting.announcement_type=='custom'" icon="latestPencilIcon" height="38px" width="38px" @click="removeWelcomeGreeting()" class="ml-2" /> -->
          <vb-icon 
            v-if="isNumber && !conditions.edit && response.number_setting.announcement_type=='custom'"
            class="cursor_pointer ml-16px" 
            height="22px" 
            width="22px" 
            icon="trash-IOSIcon" 
            @click="removeWelcomeGreeting()"
          />
        </div>
        <div v-if="conditions.edit" class="theSection-buttonsSection">
          <b-button class="blueRecordingButton mr-12px" @click="conditions.screen=screens.before_record.value">
            <vb-icon icon="recordModal-record-icon" class="recordIcon" width="38px" height="38px"/>
            Record
          </b-button>
          <b-button class="whiteRecordingButton mr-12px" @click="$modal.show(`SelectTextToSpeechModal_${_uid}`);">
            <!-- @click="conditions.screen=screens.text_to_speech.value" -->
            <vb-icon icon="recordModal-textToSpeech-icon" class="uploadIcon" width="38px" height="38px"/>
            Text to speech
          </b-button>
          <b-button 
            class="whiteRecordingButton mr-12px" 
            @click="$modal.show(`${_uid}-WelcomeGreetingRecordingSelect`,{
              group: 'announcement',
              select_category: !isNumber ? false : null
            })"
          >
            <vb-icon icon="recordModal-library-icon" class="uploadIcon" width="38px" height="38px"/>
            Library
          </b-button>
          <b-button class="whiteRecordingButton" @click="conditions.screen=screens.upload.value">
            <vb-icon icon="recordModal-upload-icon" class="uploadIcon" width="38px" height="38px"/>
            Upload
          </b-button>
        </div>
      </div>
      <div v-if="conditions.screen==screens.before_record.value">
        <div class="d-flex align-items-center w-100">
          <b-button class="blueRecordingButton mr-12px w-25" @click="recorded.audio.is_start=true;">
            <vb-icon icon="recordModal-record-icon" class="recordIcon" width="38px" height="38px"/>
          </b-button>
          <b-button class="whiteCancelButton" @click="conditions.screen=screens.default.value">
            <vb-icon icon="recordModal-cancel-icon" class="uploadIcon" width="38px" height="38px"/>
            Cancel
          </b-button>
        </div>
      </div>
      <div v-if="conditions.screen==screens.record_start.value">
        <div class="d-flex align-items-center w-100">
          <div class="divLike-blueRecordingButton mr-12px w-25">
            <vb-icon icon="recordModal-stopRecording-icon" class="recordIcon" width="38px" height="38px" @click="recorded.audio.is_start=false" />
            <div :id="`${_uid}-frequency-wave`" class="frequency-wave">
              <div v-for="(data,index) in waves" :key="index" :id="`${_uid}-frequency-wave-${index}`" :data-value="data" />
            </div>
          </div>
          <b-button class="whiteCancelButton" @click="conditions.screen=screens.default.value;recorded.audio.is_start=false;">
            <vb-icon icon="recordModal-cancel-icon" class="uploadIcon" width="38px" height="38px"/>
            Cancel
          </b-button>
        </div>
      </div>
      <div v-if="conditions.screen==screens.file_ready.value">
        <div class="d-flex align-items-center w-100">
          <vb-audio-player class="mr-12px w-25" :src="audio.file | fileToSrc" :design="'blueAudio'" /> 
          <!-- <div class="divLike-blueRecordingButton mr-12px w-25">
            <vb-audio-player :src="audio.file | fileToSrc" /> 
          </div> -->
          <b-button class="GreenSaveButton mr-12px" @click="uploadFile()">
            <vb-icon icon="recordModal-save-icon" class="recordIcon" width="38px" height="38px"/>
            Save
          </b-button>
          <b-button class="whiteCancelButton" @click="conditions.screen=screens.default.value">
            <vb-icon icon="recordModal-cancel-icon" class="uploadIcon" width="38px" height="38px"/>
            Cancel
          </b-button>
        </div>
      </div>
      <!-- <div v-if="screen=='done_record'">
        <div class="d-flex align-items-center w-100">
          <div class="divLike-blueRecordingButton mr-12px w-auto">
            <vb-audio-player :src="audio.src" />
          </div>
          <b-button class="redRedoButton mr-12px" @click="screen='before_record'">
            <vb-icon icon="recordModal-redo-icon" class="recordIcon" width="38px" height="38px"/>
            redo
          </b-button>
          <b-button class="whiteCancelButton" @click="screen='none'">
            <vb-icon icon="recordModal-cancel-icon" class="uploadIcon" width="38px" height="38px"/>
            Cancel
          </b-button>
        </div>
      </div> -->
      <div v-if="conditions.screen==screens.text_to_speech.value">
        <div class="flex-fill wecomeGreetingsTextBoxSection">
          <div class="whiteBGinputWithGreyRoundedBorder w-100">
            <textarea rows="2" class="w-100" v-model="forms.text_to_audio.text" @input="updateIVRTextDebounce()" />
          </div>
        </div>
        <div class="theSection-buttonsSection align-items-start">
          <b-button 
            class="whiteCancelButton d-flex align-items-center mr-12px" 
            type="button" 
            @click="$modal.show(`SelectVoiceForTextToSpeechModal_${_uid}`)" 
          >
            <Info 
              v-if="forms.text_to_audio.voice" 
              class="mr-12px" 
              :image="'GB' | flag_icon" 
              :name="forms.text_to_audio.voice" 
            />
            <template v-else>Select Voice</template>
            <!-- <b-icon icon="chevron-down" /> -->
          </b-button>
          <b-button class="GreenSaveButton mr-12px" @click="preview()">
            <vb-icon icon="recordModal-save-icon" class="recordIcon" width="38px" height="38px"/>
            Preview
          </b-button>
          <!-- <b-button :disabled="api.text_to_audio_preview.send || api.text_to_audio.send" class="GreenSaveButton mr-12px" @click="convert()">
            <vb-icon icon="recordModal-save-icon" class="recordIcon" width="38px" height="38px"/>
            Save
          </b-button> -->
          <b-button class="whiteCancelButton" @click="conditions.screen=screens.default.value">
            <vb-icon icon="recordModal-cancel-icon" class="uploadIcon" width="38px" height="38px"/>
            Cancel
          </b-button>
        </div>
      </div>
      <div v-if="conditions.screen==screens.text_to_speech.value">
        <div class="d-flex align-items-center w-100">
          <vb-audio-player class="mr-12px w-25" :src="response.preview.sound | fileToSrc" :design="'blueAudio'" /> 
          <!-- <div class="divLike-blueRecordingButton mr-12px w-25">
            <vb-audio-player :src="audio.file | fileToSrc" /> 
          </div> -->
          <b-button class="GreenSaveButton mr-12px" @click="convert()">
            <vb-icon icon="recordModal-save-icon" class="recordIcon" width="38px" height="38px"/>
            Save
          </b-button>
          <b-button class="whiteCancelButton" @click="conditions.screen=screens.default.value">
            <vb-icon icon="recordModal-cancel-icon" class="uploadIcon" width="38px" height="38px"/>
            Cancel
          </b-button>
        </div>
      </div>
      <!-- <div v-if="conditions.screen==screens.upload.value">
        <div class="uploadSoundFileDesign-latest py-3 position-relative" @dragenter.prevent="conditions.drag_and_drop=true">
          <div 
            v-if="conditions.drag_and_drop" 
            @dragover.prevent="conditions.drag_and_drop=true" 
            @dragenter.prevent="conditions.drag_and_drop=true" 
            @dragleave.prevent="conditions.drag_and_drop=false" 
            @drop.prevent="conditions.drag_and_drop=false;selectFile(getProperty($event,'dataTransfer.files[0]'))"
          >
            Drag and Drop
          </div>
          <template v-else>
            <div class="uploadSoundFileDesign-heading">
              Drop your file here, or 
              <span class="bluer" @click="$refs['upload_input'].click()">Browse</span>
            </div>
            <div class="uploadSoundFileDesign-text mt-10px">
              5 KB-5 MB, MP3 / WAV
            </div>
            <vb-icon 
              icon="circularCross-icon" 
              class="circularCross-icon position-absolute right-15 vertical-centring" 
              @click="conditions.screen=screens.default.value" 
              width="29.25px" 
              height="29.25px"
            />
          </template>
        </div>
      </div> -->
      <div v-if="conditions.screen==screens.upload.value">
        <div class="uploadSoundFileDesign-latest py-3 position-relative" @dragenter.prevent="conditions.drag_and_drop=true">
          <div 
            v-if="conditions.drag_and_drop" 
            @dragover.prevent="conditions.drag_and_drop=true" 
            @dragenter.prevent="conditions.drag_and_drop=true" 
            @dragleave.prevent="conditions.drag_and_drop=false" 
            @drop.prevent="conditions.drag_and_drop=false;selectFile(getProperty($event,'dataTransfer.files[0]'))"
          >
            Drag and drop
          </div>
          <template v-else>
            <div class="uploadSoundFileDesign-heading">
              Drop your file here, or 
              <span class="bluer cursor_pointer_no_hover" @click="$refs['upload_input'].click()">Browse</span>
            </div>
            <div class="uploadSoundFileDesign-text mt-10px">
              5 KB-5 MB, MP3 / WAV
            </div>
            <vb-icon 
              icon="circularCross-icon" 
              class="circularCross-icon position-absolute right-15 vertical-centring" 
              @click="conditions.screen=screens.default.value" 
              width="29.25px" 
              height="29.25px"
            />
          </template>
        </div>
      </div>
    </div>
    <RecordingListSelect :modalName="`${_uid}-WelcomeGreetingRecordingSelect`" @selectedRecordings="onSelectedLibrary($event)" :is_single_select="true" />
    <modal class="SelectTextToSpeechModal" :name="`SelectTextToSpeechModal_${_uid}`">
      <div class="dialer-edit-header position-relative top-0-imp w-100 flex-column align-items-start pt-0 mb-0">
        <div class="flex-fill d-flex py-0 justify-content-between align-items-center w-100">
          <h2 class="dialer-edit-title newer mb-0 ">Text to speech</h2>
          <div class="dialer-edit-actions">
            <button class="newCloseButton" :disabled="api.text_to_audio_preview.send" @click="$modal.hide(`SelectTextToSpeechModal_${_uid}`)" type="button" >
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </button>
          </div>
        </div>
        <div class="vm--modal-text">Turn your written words into spoken messages. Just type, and our text-to-speech feature will bring them to life with a click.</div>
      </div>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <div class="newIVRLayout-WelcomeGreetingsSection-theSection mt-0">
          <!-- <div v-if="api.text_to_audio_preview.status==1">
            <div class="theSection-buttonsSection align-items-start pl-0">
              <vb-audio-player class="mr-8px w-auto" :src="response.preview.sound" :design="'OnlyPreviewAudio'" /> 
              <b-button :disabled="api.text_to_audio.send" class="GreenSaveButton mr-8px" @click="convert()">
                <vb-loading v-if="api.text_to_audio.send" />
                <template v-else>
                  <vb-icon icon="recordModal-save-icon" class="recordIcon" width="38px" height="38px"/>
                  Save
                </template>
              </b-button>
            </div>
          </div> -->
          <div>
            <div class="flex-fill wecomeGreetingsTextBoxSection">
              <div class="whiteBGinputWithGreyRoundedBorder w-100">
                <label>Write your message here</label>
                <textarea rows="4" :disabled="api.text_to_audio_preview.send || api.text_to_audio.send" class="w-100" v-model="forms.text_to_audio.text" @input="updateIVRTextDebounce();response.preview={};" />
              </div>
            </div>
            <!-- <div class="theSection-buttonsSection align-items-start pl-0"> -->
              <ul class="list-unstyled theSection-buttonsSection align-items-start pl-0">
                <li>
                  <b-button
                    :disabled="api.text_to_audio_preview.send || api.text_to_audio.send"
                    class="whiteCancelButton d-flex align-items-center mr-8px" 
                    type="button" 
                    @click="$modal.show(`SelectVoiceForTextToSpeechModal_${_uid}`)" 
                  >
                    <Info 
                      v-if="selectedSpeaker" 
                      :hide_popover="true"
                      class="mr-12px" 
                      :image="selectedSpeaker.avatar_url" 
                      :name="selectedSpeaker.sudo_name || forms.text_to_audio.voice" 
                    />
                    <template v-else>Select voice</template>
                  </b-button>
                </li>
                <li>
                  <vb-audio-player v-if="response.preview.sound" class="mr-8px w-auto" :src="response.preview.sound" :design="'OnlyPreviewAudio'" /> 
                  <!-- <b-button v-else :disabled="api.text_to_audio_preview.send || api.text_to_audio.send" class="GreenSaveButton mr-8px" @click="preview()">
                    <vb-icon icon="recordModal-save-icon" class="recordIcon" width="38px" height="38px"/>
                    <vb-spinner v-if="api.text_to_audio_preview.send" />
                    <template v-else>
                      Load Preview
                    </template>
                  </b-button> -->
                  <b-button class="blueRecordingButton mr-12px" v-else :disabled="api.text_to_audio_preview.send || api.text_to_audio.send" @click="preview()">
                    <vb-icon icon="circular-play-icon" class="recordIcon" width="38px" height="38px"/>
                    <vb-spinner v-if="api.text_to_audio_preview.send" />
                    <template v-else>
                      Generate preview
                    </template>
                  </b-button>
                </li>
                <li>
                  <b-button v-if="response.preview.sound" :disabled="api.text_to_audio.send" class="GreenSaveButton mr-8px" @click="convert()">
                    <vb-icon icon="recordModal-save-icon" class="recordIcon" width="38px" height="38px"/>
                    Save
                  </b-button>
                  <!-- <b-button :disabled="api.text_to_audio_preview.send" class="whiteCancelButton" @click="conditions.screen=screens.default.value">
                    <vb-icon icon="recordModal-cancel-icon" class="uploadIcon" width="38px" height="38px"/>
                    Cancel
                  </b-button> -->
                </li>
              </ul>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </modal>
    <modal class="SelectVoiceForTextToSpeechModal" :name="`SelectVoiceForTextToSpeechModal_${_uid}`">
      <div class="dialer-edit-header position-relative top-0-imp w-100 flex-column align-items-start pt-0 mb-0">
        <div class="flex-fill d-flex py-0 justify-content-between align-items-center w-100">
          <h2 class="dialer-edit-title newer mb-0 ">Select your perfect voice</h2>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" @click="$modal.hide(`SelectVoiceForTextToSpeechModal_${_uid}`)" type="button" >
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <!-- <div class="vm--modal-text">Personalise your IVR messages with the perfect voice that suits your brand and connects with your callers. </div> -->
        <div class="vm--modal-text">Personalise your welcome greeting messages with the perfect voice that suits your brand and connects with your callers. </div>
      </div>
      <div class="latestGreyBox-9-9-2023 hasScrollBarInsideIt" style="--total: 95vh; --minusValue: 110px;">
        <div class="SelectVoiceForTextToSpeechModal-whiteborderbox-container">
          <template v-if="api.fetch_voices.send">
            <div v-for="n in 8" :key="n.id" class="SelectVoiceForTextToSpeechModal-whiteborderbox">
              <div class="voiceImage-container">
                <div class="latestShimmerDesign" style="height: 55px;width: 55px;border-radius: 40% 40% 40% 40% / 40% 40% 40% 40%;"></div>
              </div>
              <div class="latestShimmerDesign w-100" style="height: 18px;margin-top: 12px;"></div>
              <div class="latestShimmerDesign" style="height: 20px;width: 40px;margin-top: 10px;"></div>
            </div>
          </template>
          <template v-else>
            <div class="SelectVoiceForTextToSpeechModal-whiteborderbox" v-for="speaker in speakers" :key="`${speaker.name}`">
              <div class="voiceImage-container">
                <vb-audio-player :src="speaker.sound" :design="'onlyPlayPause'" /> 
                <vb-avatar :image="speaker.avatar_url" class="size55px" />
              </div>
              <div class="SelectVoiceForTextToSpeechModal-whiteborderbox-heading">{{speaker.sudo_name}}</div>
              <img width="45px" :src="speaker.flag | flagsByCountryCode" class="country-img">
              <!-- <div class="SelectVoiceForTextToSpeechModal-whiteborderbox-youngAdultText">Young adult</div> -->
              <button 
                class="newBlackButton w-100 mt-auto"
                :class="{
                  'selected': speaker.name==forms.text_to_audio.voice
                }" 
                type="button"
                @click="forms.text_to_audio.voice=speaker.name;response.preview={};"
              >
                {{speaker.name==forms.text_to_audio.voice?'Selected':'Select'}}
              </button>
              <b-icon class="selectedVoiceIndicator" v-if="speaker.name==forms.text_to_audio.voice" icon="check-circle" />
            </div>
          </template>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { $fn, flagsCountryCode, VOIP_API } from "../../utils";
import RecordingListSelect from "../Modals/RecordingListSelect.vue";
import required from "vuelidate/lib/validators/required";
import Info from '../Lists/Info.vue';
import _ from "lodash";
import { welcomgreetingsrc } from '@/filter';
import { mapGetters } from 'vuex';
let audio_recording_interval_id;
const frequency_data_map = [15,13,10,8,5,2,1,0,1,2,5,8,10,13,15];
const screens = {
  default: {
    value: 'default',
  },
  before_record: {
    value: 'before_record',
  },
  record_start: {
    value: 'record_start',
  },
  file_ready: {
    value: 'file_ready',
  },
  done_record: {
    value: 'done_record',
  },
  text_to_speech: {
    value: 'text_to_speech',
  },
  text_to_speech_preview: {
    value: 'text_to_speech_preview',
  },
  upload: {
    value: 'upload',
  },
}
export default {
  name: "WelcomeGreeting",
  components: {
    RecordingListSelect,
    Info
  },
  inject: [
    'getProperty','appNotify'
  ],
  props: {
    menu: {
      type: Object,
      default: () => ({}),
    },
    number: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      recorded: {
        audio: {
          is_start: false,
          time: 0,
          recorder: null,
          audio: {},
          reset(){
            this.is_start=false
            this.time=0
            this.recorder=null
            this.audio={}
          },
        },
      },
      conditions: {
        screen: screens.default.value,
        drag_and_drop: false,
        edit: false,
      },
      audio: {
        src: '',
        file: null,
        type: '',
      },
      api: {
        fetch_number_settings: this.$helperFunction.apiInstance(),
        remove_welcome_greeting: this.$helperFunction.apiInstance(),
        select_library: this.$helperFunction.apiInstance(),
        upload_file: this.$helperFunction.apiInstance(),
        update_ivr_text: this.$helperFunction.apiInstance({
          validation_errors: true,
        }),
        text_to_audio: this.$helperFunction.apiInstance({
          validation_errors: true,
        }),
        text_to_audio_preview: this.$helperFunction.apiInstance({
          validation_errors: true,
          status: true,
        }),
        fetch_voices: this.$helperFunction.apiInstance(),
      },
      forms: {
        text_to_audio: this.$helperFunction.formInstance({ 
          data: {
            text: "",
            voice: "",
          },
        }),
      },
      response: {
        voices: [],
        preview: {},
        number_setting: {},
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    screens(){ return screens },
    languages(){ return this.response.voices?.lang ?? [] },
    speakers(){ return this.response.voices?.speakers ?? [] },
    selectedSpeaker(){ return this.speakers.find((speaker)=>this.forms.text_to_audio.voice==speaker.name) },
    models(){ return this.response.voices?.models ?? [] },
    isNumber(){ return _.isEmpty(this.menu) && !_.isEmpty(this.number) },
    accountcode(){ return this.isNumber ? this.number.did : this.menu.voipaccount },
    waves(){ return frequency_data_map },
  },
  validations: {
    forms: {
      text_to_audio: {
        text: {
          required,
        },
        voice: {
          required,
        },
      },
    },
  },
  watch: {
    async "recorded.audio.is_start"(is_start) {
      let vm = this;
      function visulizing(frequency_array){
        const values = Object.values(frequency_array);
        frequency_data_map.forEach((val,i)=>{
          const el = document.getElementById(`${vm._uid}-frequency-wave-${i}`)
          if(el){
            el.style.height = `calc(${values[val]/4}px - 16px)`;
          }
        })
      }
      if (is_start) {
        this.recorded.audio.recorder = await $fn.recordAudio({
          visulizing,
          blob: {
            type: 'audio/mp3',
            name: 'Recording.mp3',
          },
        });
        this.recorded.audio.time = 0;
        this.recorded.audio.recorder.start();
        audio_recording_interval_id = setInterval(() => {
          if(vm.recorded.audio.time>60){
            vm.recorded.audio.is_start=false
          } else {
            vm.recorded.audio.time = vm.recorded.audio.time + 1;
          }
        }, 1 * 1000);
        this.conditions.screen=screens.record_start.value
      } else if(this.recorded.audio.recorder) {
        const audio = await this.recorded.audio.recorder.stop();
        visulizing({})
        clearInterval(audio_recording_interval_id);
        if(this.conditions.screen!=screens.default.value){
          this.audio.file = audio.audioBlob
          this.audio.type='recorded'
          this.conditions.screen=screens.file_ready.value
        }
      }
    },
    "conditions.screen"(screen){
      if(screen==screens.default.value) {
        // console.log({
        //   el: this.$refs['upload_input']
        // })
        this.$refs['upload_input'].value=''
      }
    },
  },
  filters: {
    flagsByCountryCode(code){ return flagsCountryCode[code] ?? flagsCountryCode['UN'] },
    fileToSrc(file){
      return URL.createObjectURL(file);
    },
    voiceImage(value){
      if(value=='Male') {
        return required('@/assets/images/user-male.png')
      } else {
        return required('@/assets/images/user-avatar.jpg')
      }
    },
  },
  methods: {
    async getNumberSetting(){
      if(this.api.fetch_number_settings.send) return;
      try {
        this.api.fetch_number_settings.send=true
        const { data } = await VOIP_API.endpoints.telephone_number.getNumberSettings({
          did: this.number.did,
        })
        this.response.number_setting=data
        this.audio.src = data?.announcement_url || ''
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_number_settings.send=false
      }
    },
    selectFile(file) {
      const max = 5 * 1024 * 1024; // 5 MB
      const min = 5 * 1024; // 5 kb
      const allow_file_types = ['audio/mpeg','audio/wav']
      const allow_file_type = 'audio' 
      if(!file) return;
      if(allow_file_type && !file.type.includes(allow_file_type)){
        this.appNotify({
          message: 'File should be audio',
          type: 'danger',
        })
        return;
      } else if(allow_file_types.length!=0 && !allow_file_types.includes(file.type)){
        this.appNotify({
          message: 'File can be mpeg or wav',
          type: 'danger',
        })
        return;
      } else if(file?.size > max){
        this.appNotify({
          message: 'File can be maximum 5MB',
          type: 'danger',
        }) 
        return;
      } else if(file?.size < min){
        this.appNotify({
          message: 'File should be minimum 5kb',
          type: 'danger',
        }) 
        return;
      }
      this.audio.file=file
      this.audio.type='upload'
      this.conditions.screen=screens.file_ready.value
    },
    async onSelectedLibrary(Event) {
      if (this.api.select_library.send || _.isEmpty(Event)) return;
      try {
        this.api.select_library.send = true;
        const form_data = new FormData();
        form_data.append("accountcode", this.getCurrentUser?.default_accountcode);
        form_data.append("uid", this.getCurrentUser?.uid);
        form_data.append("id", Event.id);
        form_data.append("useraccount", this.getCurrentUser?.account);
        form_data.append("ivraccount", this.accountcode);
        if(this.isNumber) {
          form_data.append("did_message", true);
          form_data.append("did", this.accountcode);
        }
        form_data.append("flag", "library");
        if(Event.type=='system'){
          form_data.append("system", "yes");
        }
        await VOIP_API.endpoints.menu.uploadivrimage(form_data);
        this.audio.src = Event.sound_file;
        this.appNotify({
          message: "Successfully converted",
          type: "success",
        }) 
        this.$emit("fileChange");
        if(this.isNumber) {
          this.getNumberSetting()
        }
      } catch(ex) {
        this.appNotify({
          message: ex.own_message,
          type: "danger",
        }) 
      } finally {
        this.api.select_library.send = false;
      }
    },
    async uploadFile() {
      if (this.api.upload_file.send) return;
      try {
        const file = this.audio.file
        const url = URL.createObjectURL(file);
        this.api.upload_file.send = true;
        const form_data = new FormData();
        form_data.append("accountcode", this.getCurrentUser?.default_accountcode);
        form_data.append("uid", this.getCurrentUser?.uid);
        form_data.append("file", file);
        form_data.append("useraccount", this.getCurrentUser?.account);
        if(this.audio.type=='upload'){
          form_data.append("flag", "upload");
        } else {
          form_data.append("flag", "recording");
        }
        if(this.isNumber == true){
          form_data.append("did", this.accountcode);
          await VOIP_API.endpoints.menu.uploadivrimagefromDID(form_data);
          this.audio.src = url;
          this.appNotify({
            message: "Successfully converted",
            type: "success",
          }) 
          this.$emit("fileChange");
          if(this.isNumber) {
            this.getNumberSetting()
          }
        }
        else{
          form_data.append("ivraccount", this.accountcode);
          await VOIP_API.endpoints.menu.uploadivrimage(form_data);
          this.audio.src = url;
          this.appNotify({
            message: "Successfully converted",
            type: "success",
          })
          this.$emit("fileChange");
          if(this.isNumber) {
            this.getNumberSetting()
          }
        }
        this.conditions.screen=screens.default.value
      } catch(ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.upload_file.send = false;
      }
    },
    async convert() {
      this.forms.text_to_audio.submitted = true;
      this.$v.forms.text_to_audio.$touch();
      if (this.api.text_to_audio.send || this.$v.forms.text_to_audio.$invalid) return;
      try {
        this.api.text_to_audio.send = true;
        this.api.text_to_audio.validation_errors = {};
        const { data } = await VOIP_API.endpoints.menu.convertTextToAudio({
          voice: this.forms.text_to_audio.voice,
          model: 'tts_models/multilingual/multi-dataset/xtts_v2',
          speaker: this.forms.text_to_audio.voice,
          lang: this.languages?.[0] ?? '',
          text: this.forms.text_to_audio.text,
          label: 'basicIVR',
          filename: this.response.preview.filename,
          accountcode: this.accountcode,
          ...this.isNumber ? {
            did_message: true,
            default_accountcode: this.getCurrentUser?.default_accountcode,
            uid: this.getCurrentUser?.uid,
            useraccount: this.getCurrentUser?.account,
            did: this.accountcode,
          } : {},
        })
        if (!data.file_exists) {
          this.appNotify({
            message: "Try after 5 second",
            type: "info",
          });
        } else {
          // this.audio.src = data.sound;
          this.audio.src = this.response.preview.sound;
          // this.forms.text_to_audio.text = data.text;
          this.appNotify({
            message: "Successfully converted",
            type: "success",
          })
          this.$emit("fileChange");
          if(this.isNumber) {
            this.getNumberSetting()
          }
          this.$modal.hide(`SelectTextToSpeechModal_${this._uid}`)
          this.conditions.screen=screens.default.value
        }
        
      } catch (ex) {
        this.api.text_to_audio.validation_errors = ex.own_errors;
        this.appNotify({
          message: ex.own_message,
          type: "danger",
        })
      } finally {
        this.api.text_to_audio.send = false;
        this.forms.text_to_audio.submitted = false;
      }
    },
    async preview() {
      this.forms.text_to_audio.submitted = true;
      this.$v.forms.text_to_audio.$touch();
      if (this.api.text_to_audio_preview.send || this.$v.forms.text_to_audio.$invalid) return;
      try {
        this.response.preview={}
        this.api.text_to_audio_preview.send = true;
        this.api.text_to_audio_preview.status = 0;
        this.api.text_to_audio_preview.validation_errors = {};
        const { data } = await VOIP_API.endpoints.menu.convertTextToAudioPreview({
          voice: this.forms.text_to_audio.voice,
          model: 'tts_models/multilingual/multi-dataset/xtts_v2',
          speaker: this.forms.text_to_audio.voice,
          lang: this.languages?.[0] ?? '',
          text: this.forms.text_to_audio.text,
          label: 'basicIVR',
          accountcode: this.accountcode,
          ...this.isNumber ? {
            did_message: true
          } : {},
        })
        this.response.preview=data
        this.api.text_to_audio_preview.status = 1;
      } catch (ex) {
        this.api.text_to_audio_preview.status = 2;
        this.api.text_to_audio_preview.validation_errors = ex.own_errors;
        this.appNotify({
          message: ex.own_message,
          type: "danger",
        })
      } finally {
        this.api.text_to_audio_preview.send = false;
        this.forms.text_to_audio.submitted = false;
      }
    },
    async updateIVRText(){
      try {
        this.api.update_ivr_text.send = true;
        this.api.update_ivr_text.validation_errors = {};
        await VOIP_API.endpoints.menu.updateivrtext({
          text: this.forms.text_to_audio.text,
          uid: this.getCurrentUser?.uid,
          id: this.accountcode,
          ...this.isNumber ? {
            did_message: true
          } : {},
        })
        this.appNotify({
          message: "Successfully updated",
          type: "success",
        })
        
      } catch (ex) {
        this.api.update_ivr_text.validation_errors = ex.own_errors || {};
        this.appNotify({
          message: ex.own_message,
          type: "danger",
        })
        
      } finally {
        this.api.update_ivr_text.send = false;
      }
    },
    updateIVRTextDebounce: _.debounce(function () {
      this.updateIVRText()
    }, 2 * 1000),
    async fetchVoices() {
      try {
        this.api.fetch_voices.send = true;
        const { data } = await VOIP_API.endpoints.voicemail.getVoices()
        this.response.voices = data;
        this.forms.text_to_audio.voice = data?.speakers?.[0]?.name || '';
      } finally {
        this.api.fetch_voices.send = false;
      }
    },
    async removeWelcomeGreeting(){
      if(this.api.remove_welcome_greeting.send) return;
      try {
        this.api.remove_welcome_greeting.send=true
        const { confirm } = await this.$appConfirmation({
          title: "Warning",
          message: `Are you sure you want to remove the welcome greeting`,
          button: {
            no: "no",
            yes: "yes",
          },
        });
        if(!confirm) return;
        await VOIP_API.endpoints.menu.removeWelcomeGreeting(this.getCurrentUser.uid,this.accountcode)
        this.getNumberSetting()
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: "danger",
        })
      } finally {
        this.api.remove_welcome_greeting.send=false
      }
    },
  },
  mounted() {
    this.fetchVoices();
    if(this.isNumber) {
      this.getNumberSetting()
      this.forms.text_to_audio.text = this.number?.sound_text || "";
    } else {
      this.audio.src = welcomgreetingsrc(this.menu)
      this.forms.text_to_audio.text = this.menu.schedules?.[0]?.soundText || "";
    }
  },
};
</script>

<style lang="scss" scoped>
.dialer-audio-playback {
  @include border-radius($border-radius);
  overflow: hidden;
  margin-top: 5px;
  display: flex;
  align-items: center;
  font-size: 12px;
  flex-grow: 1;
  .dialer-audio-playback-inner {
    width: 100%;
    background-color: $white;
    padding: $sidebarTop;
    audio {
      min-width: 220px;
      max-width: 250px;
      max-height: 30px;

      &::-webkit-media-controls-panel {
        background-color: $gray-300;
      }
    }
    .dialer-audio-message {
      width: 100%;
      svg {
        @include border-radius(0px);
        background-color: transparent;
        color: $body-color;
        margin-right: 8px;
      }
      .dialer-audio-progress {
        position: relative;
        margin: 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 24px);
        .progress {
          background-color: $progressBar_bg-color;
          width: calc(100% - 28px);
          .progress-bar {
            transition: width 0.1s ease;
          }
        }
        small {
          &:first-child {
            width: 39px;
            margin-right: 8px;
          }
          &:last-child {
            margin-left: 8px;
          }
        }
        input {
          &[type="range"] {
            width: calc(100% - 84px);
            background-color: transparent;
            -webkit-appearance: none;
            position: absolute;
            left: 42px;
            &::-webkit-slider-runnable-track {
              height: 4px;
              @include border-radius(50px);
              background: transparent;
              cursor: pointer;
            }
            &::-moz-range-track {
              height: 4px;
              @include border-radius(50px);
              background: transparent;
              cursor: pointer;
            }
            &::-ms-track {
              height: 4px;
              @include border-radius(50px);
              background: transparent;
              cursor: pointer;
            }
            &::-webkit-slider-thumb {
              width: 12px;
              height: 12px;
              margin-top: -5px;
              background: $dialer-progressBarThumb-color;
              border: 1px solid $dialer-progressBarThumb-color;
              @include border-radius(50%);
              -webkit-appearance: none;
              cursor: pointer;
            }
            &::-moz-range-thumb {
              width: 12px;
              height: 12px;
              margin-top: -5px;
              background: $dialer-progressBarThumb-color;
              border: 1px solid $dialer-progressBarThumb-color;
              @include border-radius(50%);
              cursor: pointer;
            }
            &::-ms-thumb {
              width: 12px;
              height: 12px;
              background: $dialer-progressBarThumb-color;
              border: 1px solid $dialer-progressBarThumb-color;
              @include border-radius(50%);
              cursor: pointer;
              margin-top: -5px;
            }
            &::-ms-fill-lower {
              background: $black;
              border: 0.2px solid $black;
              @include border-radius(2.6px);
            }
            &::-ms-fill-lower {
              background: $black;
              border: 0.2px solid $black;
              @include border-radius(2.6px);
            }
            &:focus {
              &::-ms-fill-lower {
                background: $black;
              }
              &::-ms-fill-upper {
                background: $black;
              }
            }
          }
        }
      }
    }
    .dialer-play-button {
      outline: none;
      border: none;
      background: none;
      padding: 2px 6px 3px;
    }

    .dialer-playback-time {
      margin: 0 5px;
      min-width: 25px;
      color: $secondary;
    }
  }
}

</style>