
import { VOIP_API } from "../index"

export const POWER_DIALER_API_ENDPOINTS = {
  createSheet(data={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'POST',
      url: 'autodialer/files',
      data: data,
    })
  },
}