<template>
  <div class="content-area d-flex h-100 dialer_main_body forTODOList-main">
    <div class="dialer_main_body_inner w-100">
      <div :class="`dialer_inner_left_side forTODOList-sidebar d-r-768px-none ${conditions.show_side_bar?'open':''}`">
        <h2 v-if="getIsMobile" class="dialer_main_title d-flex justify-content-between align-items-center">
          Auto Dialer
          <div @click="conditions.show_side_bar=false">
            <b-icon icon="x-lg" class="cursor_pointer" />
          </div>
        </h2>
        <h2 v-else class="dialer_main_title d-flex justify-content-between align-items-center">
          Auto Dialer
          <div class="showOnlyOnSmallerScreens" @click="conditions.show_side_bar=false">
            <b-icon icon="x" font-scale="1.5"  class="cursor_pointer" />
          </div>
        </h2>
        <div class="dialer_inner_left_side_inner allow-scroll">
          <ul class="list-unstyled">
            <li 
              :class="`dialer_tab_btn active`" 
            >
              <a>
                <span class="dialer-settingPanel-icon">
                  <vb-icon icon="PowerDialer-ListOfContents-icon" width="19.996" height="20" />
                </span>
                <span class="dialer-settingPanel-text">List of Contacts</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="dialer_inner_right_side">
        <div class="dialer-settings-main">
          <section v-if="getIsMobile" class="dialer-settings-section locationSettings">
            <div class="d-flex align-items-center mb-3 calc-title-width">
              <vb-icon icon="latest-sideBarOpenIconForTodoInMobileView-icon" width="19.979px" height="16px" class="settings-back-icon cursor_pointer" @click="conditions.show_side_bar=true" />
              <h2 class="dialer-settings-title w-100 mb-0 text-center flex-1">List of contacts</h2>
            </div>
            <div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia rutrum quam, at viverra urna 
              tincidunt in. Suspendisse mattis lacus dapibus leo iaculis consectetur.
            </div>
          </section>
          <section v-else class="dialer-settings-section locationSettings newTasksModule">
            <div>
              <div class="settingsFirstSection">
                <div>
                  <h2 class="dialer-settings-title d-flex align-items-center">
                    <vb-icon icon="latest-sideBarOpenIconForTodoInMobileView-icon" width="19.979px" height="16px" class="settings-back-icon cursor_pointer showOnlyOnSmallerScreens mr-12px" style="" @click="conditions.show_side_bar=true" />
                    List of contacts
                  </h2>
                </div>
                <div class="settingsFirstSection-right">
                  <div class="d-flex justify-content-end align-items-center mt-0">
                    <div class="whiteBGinputWithGreyRoundedBorder sm-mar-left sm-mar-right seachBox">
                      <b-input type="text" placeholder="Search for contacts"  />
                    </div>
                    <button class="newButton" @click="$modal.show('AddListModal')">
                      <vb-icon icon="squared-add-icon" height="38px" width="38px"  />
                      <span class="newButton-textPart position-relative d-flex justify-content-center">
                        <template>Add list</template>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="settingsSecondSection">
                <span class="dialer-settings-intro newerTextVersion mt-0 mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia rutrum quam, at viverra urna 
                  tincidunt in. Suspendisse mattis lacus dapibus leo iaculis consectetur.
                </span>
              </div>
            </div>
            <vb-table
              class="latestTableDesign-withBlackBorders-again IVRTable"
              id="dddd-table" 
              textNoRecord=" "
              :isListEmpty="false"
              :listLength="10"
              :loading="false"
              :noRecordWholePage="true"
            >
              <template slot="header">
                <tr v-if="!$store.getters.getIsMobile">
                  <th class="dialer-has-sort">
                    <span class="dialer-sorted-by ascending">List name</span>
                  </th>
                  <th class="dialer-has-sort">
                    <span>Assigned to</span>
                  </th>
                  <th class="dialer-has-sort dialer-col-right">
                    <span class="for-flex-column">Action</span>
                  </th>
                </tr>
              </template>
              <template #body="{ start, end }">
                <!-- <template v-if="$store.getters.getIsMobile">
                  <tr 
                    v-for="(n,index) in 10" 
                    v-show="index >= start && index <= end" 
                    :key="index" 
                  >
                    <td class="left">
                      <div>
                        
                      </div>
                    </td>
                    <td class="right dialer-col-right">
                      
                    </td>
                  </tr>
                </template> -->
                <template >
                  <!-- v-else -->
                  <tr 
                    v-for="(n,index) in 10" 
                    v-show="index >= start && index <= end" 
                    :key="index" 
                  >
                    <td class="dialer-row-title">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <vb-icon icon="PowerDialer-NumberListTD-icon" class="mr-16px" width="35px" height="35px" />
                          <span>Number List {{n}}</span>
                        </div>
                      </div>
                    </td>
                    <td class="dialer-row-title">
                      <Info class="w-fit-content" :id="getCurrentUser.account" is_suspended_show />
                    </td>
                    <td class="dialer-col-right">
                      <div class="d-flex justify-content-end">
                        <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD">
                          <b-dropdown right >
                            <template #button-content>
                              <b-icon icon="three-dots-vertical" scale="1.5"></b-icon>
                            </template>
                            <b-dropdown-item >Edit</b-dropdown-item>
                            <b-dropdown-item >Delete</b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>
              <template #loading>
                <tr v-for="n in 5" :key="n">
                  <td>
                    <div class="d-flex">
                      <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                      <div class="d-flex flex-column justify-content-between">
                        <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                        <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="latestShimmerDesign" style="height:20px;width:50%;"></div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <!-- <div class="latestShimmerDesign mr-2" style="height:20px;min-width:40px;"></div> -->
                      <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
                    </div>
                  </td>
                  <td>
                    <div class="latestShimmerDesign" style="height:20px;width:85px;"></div>
                  </td>
                  <td class="dialer-col-right">
                    <div class="latestShimmerDesign ml-auto" style="height:48px;width:48px;"></div>
                  </td>
                </tr>
              </template>
            </vb-table>
          </section>
        </div>
      </div>
    </div>
    <AddListModal />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
import Info from "../Lists/Info.vue";
import AddListModal from "./AddListModal.vue"


export default {
  name: 'PowerDialerFile',
  data() {
    return {
      
      conditions: {
        show_side_bar: false,
      },
    };
  },
  components:{
    Info,
    AddListModal
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
      GET_LOCAL_SETTING_IS_DARK_MODE
    ]),
  }
}
</script>

<style>

</style>