var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.openedFromModal ? _c('div', {
    staticClass: "dialer-edit-header w-100 d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_c('vb-svg', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "name": "leftLongArrow-icon",
      "width": "20",
      "height": "12",
      "viewBox": "0 0 30 16",
      "stroke-width": "1",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('closeModal');
      }
    }
  }), _vm._v(" Number blocking ")], 1), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: {
      'for-disable': !_vm.getUserPermissions.block_and_privacy
    },
    attrs: {
      "id": "NumberBlockButton",
      "title": _vm.getUserPermissions.block_and_privacy ? '' : 'Your package does not support this feature'
    }
  }, [_c('button', {
    staticClass: "newButton mwd-200px",
    attrs: {
      "disabled": !_vm.getUserPermissions.block_and_privacy
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddBlockNumbersModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add blocked numbers")])], 1)])])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v(" Block unwanted or spam incoming calls by adding the phone number below. ")]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder seachBox mt-20px d-flex justify-content-end w-100"
  }, [_c('b-input', {
    attrs: {
      "placeholder": "Search numbers",
      "type": "text"
    },
    model: {
      value: _vm.filter.rules.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.rules, "search", $$v);
      },
      expression: "filter.rules.search"
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "dialer-settings-main b-0"
  }, [_c('section', {
    staticClass: "dialer-settings-section PrivacySettings p-0"
  }, [!_vm.openedFromModal ? _c('div', {
    staticClass: "d-flex align-items-start justify-content-between md-mar-top md-mar-bottom"
  }, [_c('span', [_vm._v("Block unwanted or spam incoming calls by adding the phone number below.")]), _c('div', {
    staticClass: "d-flex flex-column justify-content-end align-items-end w-50"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: {
      'for-disable': !_vm.getUserPermissions.block_and_privacy
    },
    attrs: {
      "id": "NumberBlockButton",
      "title": _vm.getUserPermissions.block_and_privacy ? '' : 'Your package does not support this feature'
    }
  }, [_c('button', {
    staticClass: "newButton mwd-200px",
    attrs: {
      "disabled": !_vm.getUserPermissions.block_and_privacy
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddBlockNumbersModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add blocked numbers")])], 1)])]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder seachBox mt-20px d-flex justify-content-end w-100"
  }, [_c('b-input', {
    attrs: {
      "placeholder": "Search numbers",
      "type": "text"
    },
    model: {
      value: _vm.filter.rules.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.rules, "search", $$v);
      },
      expression: "filter.rules.search"
    }
  })], 1)])]) : _vm._e(), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again mb-0 mt-2",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.filterRules),
      "listLength": _vm.pagination.rules.total,
      "perPage": _vm.pagination.rules.per_page,
      "loading": _vm.api.rules.send,
      "conditions": {
        pagination: {
          per_page: false
        }
      },
      "page": _vm.pagination.rules.page,
      "noRecordWholePage": true
    },
    on: {
      "page-changed": function ($event) {
        _vm.pagination.rules.page = $event;
        _vm.fetchRules();
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [!_vm.$store.getters.getIsMobile ? _vm._l(_vm.filterRules, function (rule) {
          return _c('tr', {
            key: rule.id,
            staticClass: "dialer-row-select",
            on: {
              "click": function ($event) {
                !_vm.getUserPermissions.block_and_privacy ? _vm.appNotify({
                  message: 'Your package does not support this feature',
                  type: 'danger'
                }) : _vm.api.delete.send == rule.id ? '' : _vm.$modal.show('EditNumberBlockModal', {
                  number: rule
                });
              }
            }
          }, [_c('td', {
            staticClass: "dialer-row-title one w-75"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', {
            staticClass: "bg-transparent"
          }, [rule.type == 'both' ? _c('vb-icon', {
            staticClass: "redC",
            attrs: {
              "icon": "NumberBlocking-inOut-icon",
              "height": "13.922",
              "width": "13.922"
            }
          }) : _vm._e(), rule.type == 'in' ? _c('vb-icon', {
            attrs: {
              "icon": "analyticsReporting-receiveCall-icon",
              "height": "13.922",
              "width": "13.922"
            }
          }) : _vm._e(), rule.type == 'out' ? _c('vb-icon', {
            attrs: {
              "icon": "analyticsReporting-dialledCall-icon",
              "height": "13.922",
              "width": "13.922"
            }
          }) : _vm._e()], 1), _c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: rule.callerid,
              expression: "rule.callerid",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "ml-2"
          }, [_vm._v(_vm._s(_vm._f("number_formater")(rule.callerid_format || rule.callerid)))])])]), _c('td', {
            staticClass: "dialer-col-right three w-25"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', [_vm._v(_vm._s(rule.displayname))])])]), _c('td', {
            staticClass: "dialer-row-title dialer-col-right",
            staticStyle: {
              "max-width": "115px",
              "width": "115px"
            }
          }, [_c('span', {
            staticClass: "newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content w-fit-content ml-0 mr-0 showOnly-OnHover-insideTable"
          }, [_vm._v(" Configure ")])])]);
        }) : _vm._l(_vm.filterRules, function (rule) {
          return _c('tr', {
            key: rule.id,
            staticClass: "dialer-row-select",
            on: {
              "click": function ($event) {
                !_vm.getUserPermissions.block_and_privacy ? _vm.appNotify({
                  message: 'Your package does not support this feature',
                  type: 'danger'
                }) : _vm.api.delete.send == rule.id ? '' : _vm.$modal.show('EditNumberBlockModal', {
                  number: rule
                });
              }
            }
          }, [_c('td', {
            staticClass: "left"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', {
            staticClass: "bg-transparent"
          }, [rule.type == 'both' ? _c('vb-icon', {
            staticClass: "redC",
            attrs: {
              "icon": "NumberBlocking-inOut-icon",
              "height": "25px",
              "width": "25px"
            }
          }) : _vm._e(), rule.type == 'in' ? _c('vb-icon', {
            attrs: {
              "icon": "analyticsReporting-receiveCall-icon",
              "height": "25px",
              "width": "25px"
            }
          }) : _vm._e(), rule.type == 'out' ? _c('vb-icon', {
            attrs: {
              "icon": "analyticsReporting-dialledCall-icon",
              "height": "25px",
              "width": "25px"
            }
          }) : _vm._e()], 1), _c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: rule.callerid,
              expression: "rule.callerid",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "headingInsideTable ml-2"
          }, [_vm._v(_vm._s(_vm._f("number_formater")(rule.callerid_format || rule.callerid)))])])]), _c('td', {
            staticClass: "dialer-col-right right"
          }, [_c('div', {
            staticClass: "textInsideCard"
          }, [_c('div', [_vm._v(_vm._s(rule.displayname))])])])]);
        })];
      },
      proxy: true
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "21px",
              "width": "21px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "21px",
              "width": "154px"
            }
          })])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "21px",
              "width": "150px"
            }
          })])]);
        });
      },
      proxy: true
    }, {
      key: "no-record",
      fn: function () {
        return [_c('div', {
          staticClass: "latestGreyBox-9-9-2023 mb-32px"
        }, [_c('div', {
          staticClass: "emptyScreenContainer"
        }, [_vm.GET_LOCAL_SETTING_IS_DARK_MODE ? _c('img', {
          staticClass: "mt-12px wd-30 h-auto",
          attrs: {
            "src": require('@/assets/images/emptyScreenImages/darkMode/numberBlocking.png')
          }
        }) : _c('img', {
          staticClass: "mt-12px wd-30 h-auto",
          attrs: {
            "src": require('@/assets/images/emptyScreenImages/numberBlocking.png')
          }
        }), _c('div', {
          staticClass: "emptyScreenContainer-heading mt-32px"
        }, [_vm._v(" No blocked numbers yet ")]), _c('div', {
          staticClass: "emptyScreenContainer-text w-75"
        }, [_vm._v(" Start managing your blocked numbers to control your call experience ")]), _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip"
          }],
          class: {
            'for-disable': !_vm.getUserPermissions.block_and_privacy
          },
          attrs: {
            "title": _vm.getUserPermissions.block_and_privacy ? '' : 'Your package does not support this feature'
          }
        }, [_c('button', {
          staticClass: "newButton mwd-200px mt-20px",
          attrs: {
            "disabled": !_vm.getUserPermissions.block_and_privacy
          },
          on: {
            "click": function ($event) {
              return _vm.$modal.show('AddBlockNumbersModal');
            }
          }
        }, [_c('vb-icon', {
          attrs: {
            "icon": "squared-add-icon",
            "height": "38px",
            "width": "38px"
          }
        }), _c('span', {
          staticClass: "newButton-textPart"
        }, [_vm._v("Block a number")])], 1)])])])];
      },
      proxy: true
    }])
  }, [!_vm.$store.getters.getIsMobile ? _c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort one"
  }, [_c('span', [_vm._v("Number")])]), _c('th', {
    staticClass: "dialer-has-sort three"
  }, [_vm._v("Label")]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right",
    staticStyle: {
      "max-width": "115px",
      "width": "115px"
    }
  })])]) : _vm._e()], 2), _c('AddBlockNumbersModal', {
    on: {
      "interface": _vm.afterAdding
    }
  }), _c('EditBlockNumberModal', {
    on: {
      "interface": _vm.afterAdding
    }
  }), _c('UsersCallRecordingModal')], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }