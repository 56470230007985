<template>
  <div v-if="!isEmpty(JitsiConference)" class="conference-container">
    
    <div class="activeCall-videoArea">
      <div class="video-container">
        <template v-if="$store.getters.getIsMobile">
          <div class="videoCallTopBar">
            <div class="videoCallTopBar-inner">
              <div class="dialer-incommingVideoCall-info">
                <div class="dialer-caller-dp d-flex align-items-center mr-3" id="video-icon-dp">
                  <b-icon icon="chevron-left" font-scale="1.9" class="settings-back-icon cursor_pointer mr-2" style="margin-left:-18px;" @click="$emit('to-dashboard')" />
                  <div v-if="videoCall.images.length === 0" class="dialer-user-dp">
                    <img class="object-fit-cover" width="40" height="40" :src="$store.getters.getVoipUsersAlisesImages[videoCall.data.callee] || ''" @error="$event.target.src = userDp" :alt="$store.getters.getVoipUsersAlisesDisplayName[videoCall.data.callee]" />
                  </div>
                  <template v-if="videoCall.images.length === 1">
                    <div v-for="image in videoCall.images" :key="image.key" class="dialer-user-dp">
                      <img class="object-fit-cover" width="40" height="40" :src="image.src || ''" @error="$event.target.src = userDp" :alt="image.alt" />
                    </div>
                  </template>
                  <div v-else-if="videoCall.images.length > 1" class="image-length-container">
                    <div class="image-length">{{videoCall.images.length}}</div>
                  </div>
                </div>
                <b-popover v-if="videoCall.isChat && $store.state.global_conditions.is_right_bar_open"  target="video-icon-dp" placement="top" triggers="hover focus">
                  <div class="dialer-user-bio">
                    <h6 class="text-capitalize mb-0 text-dark">{{ videoCall.data.display_name || videoCall.name }}</h6>
                    <template v-if="$store.state.calls.outgoing_calls[videoCall.room]">
                      <div class="d-flex align-items-center" v-if="videoCall.outgoing && videoCall.outgoing.time">
                        <p class="m-0 pr-3  text-dark">Time Left: {{ videoCall.outgoing.time | time_audio }}</p>
                      </div>
                      <div class="d-flex align-items-center" v-if="videoCall.outgoing && videoCall.outgoing.status">
                        <p class="m-0 pr-3 text-dark">Status: {{ videoCall.outgoing.status }}</p>
                      </div>
                    </template>
                    <div class="d-flex align-items-center">
                      <p class="m-0 pr-3 text-dark">{{ videoCall.time | time_audio }}</p>
                      <b-icon icon="camera-video" />
                    </div>
                  </div>
                </b-popover>
                <div v-else class="dialer-user-bio">
                  <h6 class="text-capitalize mb-0 text-light">{{ videoCall.data.display_name || videoCall.name }}</h6>
                  
                  <div class="d-flex align-items-center">
                    <p class="m-0 pr-3 text-light">{{ videoCall.time | time_audio }}</p>
                    <b-icon icon="camera-video" variant="light"/>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <span class="md-mar-left">
                  <vb-icon
                    @click="$store.state.calls.videoCalls[listIndex].isPlay = !videoCall.isPlay"
                    class="cursor_pointer"
                    :icon="videoCall.isPlay?'video-call-pause-icon': 'video-call-play-icon'"
                    width="40"
                    height="40"
                    id="video-icon-hold"
                  />
                  
                  <b-popover target="video-icon-hold" placement="top" triggers="hover">
                    <small>{{videoCall.isPlay?'hold':'unhold'}}</small>
                  </b-popover>
                </span>
                <span class="ml-3">
                  <vb-icon @click="$emit('calls-list')" class="cursor_pointer" icon="video-call-switchCall-icon" width="40" height="40"/>
                </span>
              </div>
            </div>
          </div>
        </template>
        <!-- <div class="alert-error p-0" style="background-color:transparent;" v-if="error">
          <b-alert show variant="danger" class="p-3" v-if="error">
            <b-icon icon="plus" @click="error = ''" />
            <h1>{{ error.type }}</h1>
            <p>{{ error.message }}</p>
            <b-icon icon="x" @click="error.track_failed=='audio' ? audio() : error.track_failed=='video' ? camera() : ''" />
          </b-alert>
        </div> -->
        
        
        
        <Participants
          :performanceSetting="performanceSetting"
          :speaker_id="speaker_id"
          :JitsiConference="JitsiConference"
          :participants="participants"
          :videoCall="videoCall"
          :speaker_device_id="speaker_device_id"
          :class="`${swithViewN ? 'swithView' : ''}`"
        >
          <LocalTracks
            :performanceSetting="performanceSetting"
            :speaker="speaker_id==user_id"
            :JitsiConference="JitsiConference"
            :localTracks="localTracks"
            :isJoined="isJoined"
            :payload="payload"
            :listIndex="listIndex"
            :participants="participants"
            :videoCall="videoCall"
            :speaker_device_id="speaker_device_id"
            :error="error"
            @camera="camera()"
            @audio="audio()"
            @error-close="error = ''"
            @swithView="swithViewN=!swithViewN"
          />
        </Participants>

      
        <div 
          v-if="isJoined" 
          class="d-flex dialer-incommingVideoCall-active v2" 
          :class="videoCall.isChat && $store.state.global_conditions.is_right_bar_open ? 'dialer-incommingVideoCall-active-new' : ''"
        >
        
          <div class="d-flex dialer-incommingVideoCall-close">
            <div class="actions-info-container">
              
              <span class="d-flex with-dd position-relative"> 
                <vb-icon v-if="!$store.getters.getIsMobile"
                  @click="videoCall.toggleIsVideoMute()"
                  :icon="videoCall.isVideoMute?'videoOff-duringJitsiCall-icon': 'videoOn-duringJitsiCall-icon'" 
                  class="cursor_pointer" 
                  width="40" 
                  height="40" 
                  id="video-icon-video"
                />
                <vb-icon v-else
                  @click="videoCall.toggleIsVideoMute()"
                  :icon="videoCall.isVideoMute?'video-call-VideoNot-icon': 'video-call-VideoIcon-icon'" 
                  class="cursor_pointer" 
                  width="57" 
                  height="57" 
                />
                <b-popover target="video-icon-video" placement="top" triggers="hover">
                  <small>{{videoCall.isVideoMute?'video on':'video off'}}</small>
                </b-popover>
                <div class="dialer-dropdown position-absolute audioVideoDevicesDD" style="top:-22px;right:-10px;">
                  <b-dropdown varient="link" dropup dropleft no-caret class="ico-filter w-auto">
                    <template v-slot:button-content>
                      <vb-icon icon="arrowPillow-duringJitsiCall-icon" height="18px" width="18px" /> 
                    </template>
                    <div class="moreOptionsDD-heading">
                      <div>Camera Devices</div>
                    </div>
                    <b-dropdown-item v-for="device in media_devices_setting.videoinputs" :key="device.deviceId" @click="camera_device_id=device.deviceId;camera();">
                      <div class="d-flex align-items-center w-100">
                        <span class="icon">
                          <b-icon v-if="camera_device_id==device.deviceId" icon="camera-video-fill" />
                        </span>
                        <span class="ml-3 textContainer">{{ device.label }}</span>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </span>

              <span class="md-mar-left with-dd position-relative">
                <vb-icon v-if="!$store.getters.getIsMobile"
                  @click="videoCall.toggleIsAudioMute()" 
                  :icon="videoCall.isAudioMute?'audioOff-duringJitsiCall-icon':'audioOn-duringJitsiCall-icon'" 
                  class="cursor_pointer" 
                  width="40" 
                  height="40" 
                  id="video-icon-audio-mute"
                />
                <vb-icon v-else
                  @click="videoCall.toggleIsAudioMute()" 
                  :icon="videoCall.isAudioMute?'video-call-AudioNot-icon':'video-call-AudioIcon-icon'" 
                  class="cursor_pointer" 
                  width="57" 
                  height="57" 
                />
                <b-popover target="video-icon-audio-mute" placement="top" triggers="hover">
                  <small>{{videoCall.isAudioMute?'audio unmute':'audio mute'}}</small>
                </b-popover>
                <div class="dialer-dropdown audioVideoDevicesDD position-absolute" style="top:-22px;right:-10px;">
                  <b-dropdown varient="link" no-caret>
                    <template v-slot:button-content>
                      <vb-icon icon="arrowPillow-duringJitsiCall-icon" height="18px" width="18px" />
                    </template>
                    <div class="moreOptionsDD-heading">
                      <div>Speaker Devices</div>
                    </div>
                    <b-dropdown-item v-for="device in media_devices_setting.audiooutputs" :key="`${device.deviceId}-audiooutputs`" @click="speaker_device_id=device.deviceId">
                      <div class="d-flex align-items-center w-100">
                        <span class="icon">
                          <b-icon v-if="speaker_device_id==device.deviceId" icon="speaker-fill" />
                        </span>
                        <span class="ml-3 textContainer">{{ device.label }}</span>
                      </div>
                    </b-dropdown-item>
                    <div class="dialer-divider-md"></div>
                    <div class="moreOptionsDD-heading">
                      <div>Microphone Devices</div>
                    </div>
                    <b-dropdown-item v-for="device in media_devices_setting.audioinputs" :key="`${device.deviceId}-audioinputs`" @click="mic_device_id=device.deviceId;audio()">
                      <div class="d-flex align-items-center w-100 ">
                        <span class="icon">
                          <b-icon v-if="mic_device_id==device.deviceId" icon="mic-fill" />
                        </span>
                        <span class="ml-3 textContainer">{{ device.label }}</span>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </span>

              <span class="md-mar-left d-flex with-dd position-relative"> 
                <vb-icon v-if="!$store.getters.getIsMobile" icon="handRaise-duringJitsiCall-icon" width="40" height="40" @click="sendEmoji(defaultEmoji.value)" class="cursor_pointer" v-b-popover.top.hover.html="{content: '<small>Hand Raise</small>'}" /> 
                <vb-icon v-else icon="HandRise-MobileIcon" width="40" height="40" @click="sendEmoji(defaultEmoji.value)" class="cursor_pointer" v-b-popover.top.hover.html="{content: '<small>Hand Raise</small>'}" /> 
                <div class="dialer-dropdown position-absolute handRiseDD-container" style="top:-22px;right:-10px;">
                  <b-dropdown varient="link" dropup dropleft no-caret class="ico-filter w-auto">
                    <template v-slot:button-content>
                      <vb-icon icon="arrowPillow-duringJitsiCall-icon" height="18px" width="18px" />
                    </template>
                    <b-dropdown-item v-for="emoji in emojis" :key="emoji.value" @click="sendEmoji(emoji.value);">
                      <div class="d-flex align-items-center w-100">
                        <div style="font-size:35px;">{{emoji.icon}}</div>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </span>

              <span v-if="!$store.getters.getIsMobile" class="md-mar-left ">
                <vb-icon 
                  @click="videoCall.toggleIsVideo()" 
                  :icon="videoCall.isVideo?'shareScreen-duringJitsiCall-icon':'shareScreen-duringJitsiCall-icon'" 
                  class="cursor_pointer" 
                  width="40" 
                  height="40" 
                  id="video-icon-screen-sharing"
                />
                <b-popover target="video-icon-screen-sharing" placement="top" triggers="hover">
                  <!-- <small>{{videoCall.isVideo?'screen sharing':'video'}}</small> -->
                  <small>{{videoCall.isVideo?'screen sharing':'screen sharing'}}</small>
                </b-popover>
              </span>
              <div class="md-mar-left" v-if="videoCall.is_conference" @click="$modal.show(videoCall.room,{ meeting_id: videoCall.meeting_id, link: joinURL })">
                <vb-icon
                  class="cursor_pointer"
                  :icon="videoCall.isMemberOpen?'addPeople-duringJitsiCall-icon':'addPeople-duringJitsiCall-icon'"
                  width="40"
                  height="40"
                />
              </div>
              
              <!-- <template v-if="!$store.getters.getIsMobile">
                <div class="dialer-dropdown ml-3" v-if="$store.state.calls.videoCalls.length>1">
                  <b-dropdown class="m-2 during-video-call w-350px">
                    <template #button-content>
                      <span class="pr-1" v-if="videoCall.isChat && $store.state.global_conditions.is_right_bar_open">{{`${$store.state.calls.videoCalls.length}`}}</span>
                      <span v-else>{{ `${$store.state.calls.videoCalls.length} Calls` }}</span>
                      <b-icon-chevron-down />
                    </template>
                    <h6 class="m-0 pt-2">Switch Call</h6>
                    <b-dropdown-item 
                      v-for="videoCall2 in $store.state.calls.videoCalls" 
                      :key="`${videoCall2.room}-nested`" 
                      @click="videoCall2.isPlay = true;videoCall2.isShown = true;" 
                      v-show="!videoCall2.isPlay"
                    >
                      <DropdownVideoList :data="videoCall2" />
                    </b-dropdown-item>
                    <vb-no-record v-if="isEmpty(holdCalls)" text="No calls on Hold" :design="3" />
                  </b-dropdown>
                </div>
              </template> -->
              <template v-if="!$store.getters.getIsMobile">
                <span class="md-mar-left position-relative" v-if="!videoCall.is_conference">
                  <b-badge v-if="unread>0" variant="primary" style="top:-18px;right:-7px;position:absolute">{{ unread }}</b-badge>
                  <vb-icon 
                    @click="$store.state.calls.videoCalls[listIndex].isChat = !videoCall.isChat"
                    :icon="videoCall.isChat?'openChat-duringJitsiCall-icon':'openChat-duringJitsiCall-icon'" 
                    class="cursor_pointer"
                    width="40" 
                    height="40" 
                    id="video-icon-open-chat"
                  />
                  <b-popover target="video-icon-open-chat" placement="top" triggers="hover">
                    <small>{{videoCall.isChat?'close chat':'open chat'}}</small>
                  </b-popover>
                </span>                
                <span class="md-mar-left" v-if="!videoCall.is_conference">
                  <vb-icon
                    @click="$store.state.calls.videoCalls[listIndex].isMemberOpen = !videoCall.isMemberOpen"
                    class="cursor_pointer"
                    :icon="videoCall.isMemberOpen?'addPeople-duringJitsiCall-icon':'addPeople-duringJitsiCall-icon'"
                    width="40"
                    height="40"
                    id="video-icon-open-members"
                  />
                  <b-popover target="video-icon-open-members" placement="top" triggers="hover">
                    <small>{{videoCall.isMemberOpen?'close members':'open members'}}</small>
                  </b-popover>
                </span>
                <span class="md-mar-left" >
                  <div class="dialer-dropdown moreOptionsDD">
                    <b-dropdown varient="link" no-caret class="ico-filter w-auto during-video-call">
                      <template v-slot:button-content>
                        <vb-icon icon="moreOptions-duringJitsiCall-icon" height="40px" width="40px" />
                      </template>
                      <div class="moreOptionsDD-heading">
                        <div>More</div>
                      </div>
                      <b-dropdown-item @click="feedBack()">
                        <div class="d-flex align-items-center w-100">
                          <span class="icon">
                            <vb-icon icon="leaveFeedback-duringJitsiCall-icon" height="22px" width="20px" />
                          </span>
                          <span class="ml-3">Leave Feedback</span>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item @click="$modal.show(`${el_id}PerformanceSettingModal`,{value:performance_setting})">
                        <div class="d-flex align-items-center w-100">
                          <span class="icon">
                            <vb-icon icon="performanceSettings-duringJitsiCall-icon" height="22px" width="20px" />
                          </span>
                          <span class="ml-3">Performance setting</span>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item @click="toggleFullScreen()">
                        <div class="d-flex align-items-center w-100">
                          <span class="icon">
                            <vb-icon :icon="full_screen?'halfScreen-duringJitsiCall-icon':'fullScreen-duringJitsiCall-icon'" :class="`${full_screen?'halfScreen-duringJitsiCall-icon':''}`" height="20px" width="20px" />
                            
                          </span>
                          <span class="ml-3">{{full_screen ? 'Exit' : 'View'}} full screen</span>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item @click="$modal.show(`${el_id}ShortCuts`)">
                        <div class="d-flex align-items-center w-100">
                          <span class="icon">
                            <vb-icon icon="ShortcutKeysModal-duringJitsiCall-icon" height="16px" width="18px" />
                          </span>
                          <span class="ml-3">Shortcut keys</span>
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </span>
              </template>
              <span v-if="!$store.getters.getIsMobile" class="md-mar-left">
                <vb-icon
                  @click="$store.state.calls.videoCalls[listIndex].isPlay = !videoCall.isPlay"
                  class="cursor_pointer"
                  :icon="videoCall.isPlay?'dialer-pauseInBox-icon': 'dialer-playInBox-icon'"
                  width="40"
                  height="40"
                  id="video-icon-hold"
                />
                
                <b-popover target="video-icon-hold" placement="top" triggers="hover">
                  <small>{{videoCall.isPlay?'hold':'unhold'}}</small>
                </b-popover>
              </span>
              <span class="md-mar-left">
                <vb-icon v-if="!$store.getters.getIsMobile"
                  @click="$store.state.calls.videoCalls[listIndex].isHangout = !videoCall.isHangout"
                  class="cursor_pointer"
                  icon="dialer-callRejectInbox-icon"
                  width="40"
                  height="40"
                  id="video-icon-hangup"
                />
                <vb-icon v-else
                  @click="$store.state.calls.videoCalls[listIndex].isHangout = !videoCall.isHangout"
                  class="cursor_pointer"
                  icon="video-call-hangupIcon-icon"
                  width="57"
                  height="57"
                />
                <b-popover target="video-icon-hangup" placement="top" triggers="hover">
                  <small>{{videoCall.isHangout?'not hangup':'hangup'}}</small>
                </b-popover>
              </span>
              <template v-if="$store.getters.getIsMobile">
                <span class="md-mar-left">
                  <vb-icon
                    @click="show_extra_button = !show_extra_button"
                    class="cursor_pointer"
                    icon="video-call-more-icon"
                    width="28"
                    height="8"
                  />
                </span>
              </template>
            </div>
          </div>
        </div>
        <div :class="`emojiAnimation ${reactEmoji.classC}`" v-if="reactEmoji" >
          <span>{{reactEmoji.icon}}</span>
        </div>
        <!-- <div style="background-color: red;color: white;height: 300px;" v-if="mic_mute_change">Your mic is {{ videoCall.isAudioMute ? 'Muted' : 'Un Muted' }}</div> -->
        
        <div :class="`DuringJitsiCallMICmutedUnMutedNotification ${mic_mute_change ? 'active' : ''}`">
          <div class="DuringJitsiCallMICmutedUnMutedNotification-inner">
            <span>Your mic is {{ videoCall.isAudioMute ? 'Muted' : 'Un Muted' }}</span>
          </div>
        </div>
        
        <div v-if="show_extra_button && $store.getters.getIsMobile" class="extra-buttons-bottom">
          <div class="extra-buttons-bottom-inner">
            <b-icon icon="x" class="extraButtonCloseIcon" @click="show_extra_button = false"/>
            <!-- extra buttons -->
            <span class="md-mar-left" v-if="!videoCall.is_conference">
              <b-badge v-if="unread>0" variant="primary">{{ unread }}</b-badge>
              <vb-icon 
                @click="$store.state.calls.videoCalls[listIndex].isChat = !videoCall.isChat"
                :icon="videoCall.isChat?'video-call-chat-icon':'video-call-chat-icon'" 
                class="cursor_pointer"
                width="57" 
                height="57" 
                id="video-icon-open-chat"
              />
              <b-popover target="video-icon-open-chat" placement="top" triggers="hover">
                <small>{{videoCall.isChat?'close chat':'open chat'}}</small>
              </b-popover>
            </span>                
            <span class="md-mar-left" v-if="!videoCall.is_conference">
              <vb-icon
                @click="$store.state.calls.videoCalls[listIndex].isMemberOpen = !videoCall.isMemberOpen"
                class="cursor_pointer"
                :icon="videoCall.isMemberOpen?'video-call-AddPeople-icon':'video-call-AddPeople-icon'"
                width="57"
                height="57"
                id="video-icon-open-members"
              />
              <b-popover target="video-icon-open-members" placement="top" triggers="hover">
                <small>{{videoCall.isMemberOpen?'close members':'open members'}}</small>
              </b-popover>
            </span>
            <span v-if="$store.getters.getIsMobile" class="md-mar-left mr-3">
              <vb-icon 
                @click="videoCall.toggleIsVideo()" 
                :icon="videoCall.isVideo?'video-call-ShareScreen-icon':'video-call-ShareScreen-icon'" 
                class="cursor_pointer" 
                width="57" 
                height="57" 
                id="video-icon-screen-sharing"
              />
              <b-popover target="video-icon-screen-sharing" placement="top" triggers="hover">
                <small>{{videoCall.isVideo?'screen sharing':'video'}}</small>
              </b-popover>
            </span>
          </div>
        </div>
      </div>
      <transition name="slide-fade" v-if="!videoCall.is_conference">
        <div class="dialer-chatBox-inVideo v2 newChatInterfaceDuringVideoCall" v-if="videoCall.isChat">
          <div class="dialer-edit-header d-flex justify-content-between px-3 p-3 align-items-center">
            <h2 class="dialer-edit-title">Chat</h2>
            <div class="dialer-edit-actions">
              <!-- <a v-if="!$store.getters.getIsMobile" class="btn btn-outline-primary p-2" @click="$store.state.calls.videoCalls[listIndex].isChat = false">
                Close
              </a> -->
              <a v-if="!$store.getters.getIsMobile" class="newCloseButton" @click="$store.state.calls.videoCalls[listIndex].isChat = false">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </a>
              <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$store.state.calls.videoCalls[listIndex].isChat = false" />
            </div>
          </div>
          <ChatBox :channel_id="channel_id" @change-channel-id="channel_id=$event"  ref="chat" type="video" />
        </div>
      </transition>
      <transition name="slide-fade" v-else>
        <div class="dialer-chatBox-inVideo v2" v-if="videoCall.isChat">
          <div class="dialer-edit-header d-flex justify-content-between px-3 p-3 align-items-center">
            <h2 class="dialer-edit-title">Chat</h2>
            <div class="dialer-edit-actions">
              <a v-if="!$store.getters.getIsMobile" class="btn btn-outline-primary p-2" @click="$store.state.calls.videoCalls[listIndex].isChat = false">Close</a>
              <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$store.state.calls.videoCalls[listIndex].isChat = false" />
            </div>
          </div>
          <ConferenceChat :messages="messages" />
        </div>
      </transition>
      <transition name="slide-fade">
        <div v-if="videoCall.isParticipants" class="dialer-chatBox-inVideo addMemberDuringChat">
          <div v-for="participant in participants" :key="participant._id">{{participant._displayName}}</div>
          
        </div>
      </transition>
      <!-- <transition name="slide-fade">
        <div v-show="videoCall.isMemberOpen" class="dialer-chatBox-inVideo addMemberDuringChat">
          <AddMembers ref="members" :room="videoCall.room" :listIndex="listIndex" @closeModal="$store.state.calls.videoCalls[listIndex].isMemberOpen = false" />
        </div>
      </transition> -->
      <div v-show="videoCall.isMemberOpen">
        <AddMembers ref="members" :room="videoCall.room" :listIndex="listIndex" @closeModal="$store.state.calls.videoCalls[listIndex].isMemberOpen = false" />
      </div>
    </div>
    <MembersList v-if="!$store.getters.getIsMobile" ref="membersList" :participants="participants" />
    <AddPersonByEmail :modalName="videoCall.room" />
    <FeedBackModal :modalName="`${el_id}FeedBack`" />
    <PerformanceSettingModal :modalName="`${el_id}PerformanceSettingModal`" :performanceSettings="performanceSettings" @update="performance_setting=$event" />
    <ShortCutsModal :modalName="`${el_id}ShortCuts`" />
  </div>
</template>

<script>
import FeedBackModal from '../Modals/Video/FeedBackModal.vue'
import PerformanceSettingModal from './modals/PerformanceSetting.vue'
import ShortCutsModal from '../Modals/Video/ShortCutsModal.vue'
import _ from "lodash";
import JitsiMeetJS from "@/Jitsi";
import {
  LOGGER,
  events,
  $fn,
  VOIP_API,
  permissions,
} from "../../utils";
import LocalTracks from "./LocalTracks/index.vue";
import Participants from "./Participants/index.vue";
import ChatBox from "../Chat/Box/index.vue";
// import { centrifugo } from "../../Centrifuge";
import { mattermost, MATTERMOST_API } from "@/MatterMost";
import AddMembers from "./AddMembers.vue";
import MembersList from "./MembersList.vue";
import AddPersonByEmail from "../Modals/Video/AddPersonByEmail.vue";
// import DropdownVideoList from "../Lists/DropdownVideoList.vue";
import ConferenceChat from "./ConferenceChat.vue";
import { phone } from '../../sip/app';
import { mapState } from 'vuex';
import { AMI_EVENTS, AMI_SOCKET } from '@/ami';
import { MOBILE_APP } from '@/mobile-app';
import { MOBILE_APP_EVENTS, MOBILE_APP_SEND_EVENTS } from '@/mobile-app/events';

const commands = {
  CHANNEL_ID_CHANGED: "CHANNEL_ID_CHANGED",
  CHANNEL_DATA: "CHANNEL_DATA",
  EMOJI: "EMOJI",
};
let reaction_interval;
let performance_settings = {
  high: {
    value: 'high',
    video_resolution: 720,
    show_video: true,
  },
  standard: {
    value: 'standard',
    video_resolution: 360,
    show_video: true,
  },
  low: {
    value: 'low',
    video_resolution: 180,
    show_video: true,
  },
  none: {
    value: 'none',
    video_resolution: 0,
    show_video: false,
  },
}
// let current_state_of_audio_video = {
//   audio: false,
//   video: false,
// };
let emojis = {
  // hand: {
  //   value: 'hand',
  //   icon: 'hand-thumbs-up-fill',
  //   default: true,
  // },
  // smile: {
  //   value: 'smile',
  //   icon: 'emoji-smile-fill',
  //   default: false,
  // },
  // angry: {
  //   value: 'angry',
  //   icon: 'emoji-angry-fill',
  //   default: false,
  // },
  // heart: {
  //   value: 'heart',
  //   icon: 'heart-fill',
  //   default: false,
  // },

  // added by frank
  
  // hand: {
  //   value: 'hand',
  //   icon: 'handWithThaenga-duringJitsiCall-icon',
  //   default: true,
  // },
  // clapping: {
  //   value: 'clapping',
  //   icon: 'clapping-duringJitsiCall-icon',
  //   default: false,
  // },
  // amazed: {
  //   value: 'amazed',
  //   icon: 'amazedFace-duringJitsiCall-icon',
  //   default: false,
  // },
  // smile: {
  //   value: 'smile',
  //   icon: 'laughingFace-duringJitsiCall-icon',
  //   default: false,
  // },
  // angry: {
  //   value: 'angry',
  //   icon: 'sadFace-duringJitsiCall-icon',
  //   default: false,
  // },
  // stunned: {
  //   value: 'stunned',
  //   icon: 'stunnedFace-duringJitsiCall-icon',
  //   default: false,
  // },
  hand: {
    value: 'hand',
    icon: '🖐',
    default: true,
    classC : 'hand',
  },
  clapping: {
    value: 'clapping',
    icon: '👏',
    default: false,
    classC : 'clapping',
  },
  ok: {
    value: 'ok',
    icon: '👍',
    default: false,
    classC : 'ok',
  },
  amazed: {
    value: 'amazed',
    icon: '😧',
    default: false,
    classC : 'amazed',
  },
  smile: {
    value: 'smile',
    icon: '😀',
    default: false,
    classC : 'smile',
  },
  angry: {
    value: 'angry',
    icon: '😡',
    default: false,
    classC : 'angry',
  },
  stunned: {
    value: 'stunned',
    icon: '😶',
    default: false,
    classC : 'stunned',
  },
  middleFinger: {
    value: 'middleFinger',
    icon: '🖕',
    default: false,
    development: true,
    classC : 'middleFinger',
  },
}
export default {
  name: "Conference",
  components: {
    MembersList,
    AddPersonByEmail,
    LocalTracks,
    AddMembers,
    Participants,
    ChatBox,
    // DropdownVideoList,
    FeedBackModal,
    ShortCutsModal,
    ConferenceChat,
    PerformanceSettingModal,
    // MembersList,
    // VideoList,
  },
  props: {
    JitsiConnection: {
      type: Object,
    },
    payload: {
      type: Object,
    },
    videoCall: {
      type: Object,
    },
    listIndex: {
      type: Number,
    },
    el_id: {
      type: String,
    },
  },
  data() {
    return {
      show_extra_button: false,
      JitsiConference: null,
      localTracks: [],
      participants: {},
      resolution: 720,
      error: "",
      effect: {},
      isJoined: false,
      isLeave: false,
      channel_id: "",
      type: "",
      phones: {},
      mic_device_id: '',
      camera_device_id: '',
      speaker_device_id: '',
      emoji: '',
      full_screen: false,
      userDp: require("@/assets/images/user-male-icon.svg"),
      feed_back: {
        rate: 0,
        deatil: '',
      },
      current_state_of_audio_video: {
        audio: false,
        video: false,
      },
      reaction: '',
      user_id: '',
      speaker_id: '',
      performance_setting: performance_settings.standard.value,
      messages: [],
      mic_mute_change: false,
      swithViewN: false,
    };
  },
  inject:['isEmpty'],
  computed: {
    ...mapState({
      media_devices_setting: state=>state.media_devices_setting,
    }),
    performanceSettings(){
      return performance_settings
    },
    performanceSetting(){
      return performance_settings[this.performance_setting]
    },
    joinURL(){ return `${process.env.NODE_ENV==='production'?`https://${process.env.VUE_APP_URL_MOTHERSHIP}`:`${window.location.protocol}//${window.location.hostname}:8093`}/meet?meeting_id=${this.videoCall.meeting_id}` },
    unread(){
      return _.get(this.$store.state.mattermost.channels,`[${this.channel_id}].unread`) || 0
    },
    holdCalls(){ return this.$store.state.calls.videoCalls.filter(call=>!call.isPlay) },
    accounts() {
      return Object.values(this.participants).map((participant) => participant._displayName);
    },
    mattermostIds() {
      let ids = [];
      for (let index = 0; index < this.accounts.length; index++) {
        const account = this.accounts[index];
        if (account) {
          ids.push(this.$store.state.common.voipusers[account].user.user_chat_id);
        }
      }
      return ids;
    },
    info(){
      const id = this.videoCall?.data?.extra_data?.id
      const team = this.$store.getters.getVoipTeams?.find?.(team=>team.real_id==id)
      const user = this.$store.getters.getVoipUsersAlises[this.videoCall?.data?.caller]
      return {
        image: this.videoCall?.is_group ? team?.avatar?.image?.image ?? this.image ?? '' : user?.profile_img ?? this.image ?? '',
        name: this.videoCall?.is_group ? team?.name ?? this.videoCall?.data?.display_name ?? this.videoCall?.name ?? '' : user?.user?.display_name ?? this.videoCall?.data?.display_name ?? this.videoCall?.name ?? '',
      }
    },
    isDevelopment(){ return process.env.NODE_ENV!='production' },
    emojis(){ return Object.values(emojis).filter(i=>!i.default || (this.isDevelopment && i.development)) },
    defaultEmoji(){ return Object.values(emojis).find(i=>i.default) },
    reactEmoji(){ return emojis[this.reaction]},
  },
  watch: {
    // "$store.state.centrifuge.message": "watchingCentrifugeMessage",
    "videoCall.isMemberOpen": "watchingVideoCallIsMemberOpen",
    "videoCall.isVideo": "watchingVideoCallIsVideo",
    "videoCall.isVideoMute": "watchingVideoCallIsVideoMute",
    "videoCall.isAudioMute": "watchingVideoCallIsAudioMute",
    "videoCall.isChat": "watchingVideoCallIsChat",
    "videoCall.volume": "watchingVideoCallVolume",
    "videoCall.isPlay": "watchingVideoCallIsPlay",
    "videoCall.isShown": "watchingVideoCallIsShown",
    "videoCall.isHangout": "watchingVideoCallIsHangout",
    "videoCall.names": "watchingVideoCallNames",
    channel_id(value){
      this.$store.state.calls.videoCalls[this.listIndex].channel_id = value
    },
    mattermostIds: "watchingMattermostIds",
    participants: "watchingParticipants",
    // channel_id: "watchingChannelId",
    performanceSetting(performance_setting){
      this.resolution=performance_setting.video_resolution
      if(performance_setting.video_resolution==0){
        this.$store.state.calls.videoCalls[this.listIndex].isVideoMute = true
      } else {
        this.$store.state.calls.videoCalls[this.listIndex].isVideoMute = false
        this.camera()
      }
    },
  },
  filters: {
    maxString(str) {
      return str.length < 15 ? str : `${str.slice(0, 15)}...`;
    },
  },
  methods: {
    //--watching--
    watchingVideoCallIsMemberOpen(newVal) {
      if (newVal) {
        this.$modal.show(`${this.videoCall.room}-CreateChannelModal`)
        this.$store.state.calls.videoCalls[this.listIndex].isChat = false;
      } else {
        this.$modal.hide(`${this.videoCall.room}-CreateChannelModal`)
      }
    },
    watchingVideoCallIsVideo(newVal) {
      if (newVal) {
        this.camera();
      } else {
        this.shareScreen();
      }
    },
    async watchingVideoCallIsVideoMute(newVal) {
      try {
        const [video] = this.localTracks.filter((track) => track.type === "video");
        if (!newVal && !video) {
          await this.camera();
        }
        this.JitsiConference.setLocalParticipantProperty("isVideoMuted",newVal);
      } catch (error) {
        LOGGER.danger("error in watchingVideoCallIsVideoMute function", error);
      }
    },
    async watchingVideoCallIsAudioMute(newVal) {
      const [audio] = this.localTracks.filter((track) => track.type === "audio");
      if (audio) {
        if (audio.isMuted()) {
          audio.unmute();
        } else if (!audio.isMuted()) {
          audio.mute();
        }
      } else {
        if (!newVal) {
          this.audio();
        }
      }
      this.mic_mute_change=true
      setTimeout(()=>{this.mic_mute_change=false},3*1000)
    },
    watchingVideoCallIsChat(newVal) {
      if (newVal) {
        this.$store.state.calls.videoCalls[this.listIndex].isMemberOpen = false;
        // this.chat()
      }
    },
    watchingVideoCallVolume(newVal, oldVal) {
      if ((newVal === 0 && oldVal > 0) || (oldVal === 0 && newVal > 0)) {
        this.$store.state.calls.videoCalls[this.listIndex].isAudioMute = true;
      }
      this.JitsiConference.setLocalParticipantProperty("audioLevel",newVal / 10);
    },
    watchingVideoCallIsPlay(newVal) {
      LOGGER.watch('watchingVideoCallIsPlay',newVal)
      if (newVal) {
        this.$store.state.calls.videoCalls[this.listIndex].isVideoMute = this.current_state_of_audio_video.video;
        this.$store.state.calls.videoCalls[this.listIndex].isAudioMute = this.current_state_of_audio_video.audio;
        // for (var x = 0; x < this.$store.state.calls.videoCalls.length; x++) {
        //   if (x === this.listIndex) continue;
        //   this.$store.state.calls.videoCalls[x].isPlay = false;
        //   this.$store.state.calls.videoCalls[x].isShown = true;
        // }
        this.JitsiConference.setLocalParticipantProperty('isHold','false')
        // this.$root.$emit(events.hold_audio_call)
      } else {
        this.current_state_of_audio_video.audio = this.$store.state.calls.videoCalls[this.listIndex].isAudioMute;
        this.current_state_of_audio_video.video = this.$store.state.calls.videoCalls[this.listIndex].isVideoMute;
        this.$store.state.calls.videoCalls[this.listIndex].isVideoMute = true;
        this.$store.state.calls.videoCalls[this.listIndex].isAudioMute = true;
        this.JitsiConference.setLocalParticipantProperty('isHold','true')
      }
    },
    watchingVideoCallIsShown(newVal) {
      if (newVal) {
        for (var x = 0; x < this.$store.state.calls.videoCalls.length; x++) {
          if (x === this.listIndex) continue;
          this.$store.state.calls.videoCalls[x].isShown = false;
        }
      }
    },
    watchingVideoCallIsHangout(newVal) {
      if (newVal) {
        this.leaveConference();
      }
      MOBILE_APP.sendMessage(MOBILE_APP_SEND_EVENTS.HANGUP)
    },
    watchingParticipants(participants) {
      this.$store.state.calls.videoCalls[this.listIndex].members = participants;
    },
    watchingMattermostIds(mattermostIds) {
      let vm = this
      if(!this.videoCall.is_conference){
        setTimeout(()=>{
          vm.makeChannelId(mattermostIds);
        },3*1000)
      }
    },
    // watchingCentrifugeMessage(message) {
    //   const { data } = message;
    //   switch (data.type) {
    //     case centrifugeEvents.REJECTED:
    //       this.REJECTED(message);
    //       break;
    //     default:
    //       break;
    //   }
    // },
    watchingVideoCallNames(names) {
      if (names.length === 1) {
        this.$store.state.calls.videoCalls[this.listIndex].name = names[0];
      } else if (names.length === 2) {
        this.$store.state.calls.videoCalls[
          this.listIndex
        ].name = `${names[0]}, ${names[1]}`;
      } else if (names.length > 2) {
        this.$store.state.calls.videoCalls[this.listIndex].name = `${
          names[0]
        }, ${names[1]}, ${names.length - 2} others`;
      } else {
        this.$store.state.calls.videoCalls[this.listIndex].name = "";
      }
    },
    // watchingChannelId(channel_id) {
    //   this.$refs.chat.channel_id = channel_id;
    // },

    //--own--
    async addMemberInChannelAndMakeAdmin(id) {
      try {
        const { data } = await MATTERMOST_API.endpoints.channels.addChannelMember(this.channel_id, {
          user_id: id,
        });
        mattermost.emitOnUserLevel(mattermost.events.user_added,{
          user_id: data?.user_id,
          channel_id: data?.channel_id,
        })
        await MATTERMOST_API.endpoints.channels.updateMemberRole(this.channel_id, id, {
          scheme_admin: true,
          scheme_user: true,
        });
        const index = this.$store.state.mattermost.channels[this.channel_id]?.members?.findIndex(i=>i.user_id==data.user_id)
        if(index>-1){
          this.$store.state.mattermost.channels[this.channel_id].members[index].roles='channel_user channel_admin'
          this.$store.state.mattermost.channels[this.channel_id].members[index].scheme_admin=true
        }
        if(this.$store.state.mattermost.channels[this.channel_id]?.channel?.display_name && this.$store.state.mattermost.channels[this.channel_id]?.members){
          let display_name = ''
          const members = this.$store.state.mattermost.channels[this.channel_id].members
          if(members.length >= 3){
            const user_one = this.$store.getters.getVoipUsersAlises[members[0]?.user_id]
            const user_two = this.$store.getters.getVoipUsersAlises[members[1]?.user_id]
            if(user_one && user_two) {
              display_name = `${user_one?.user?.display_name}, ${user_two?.user?.display_name}, ${members.length-2} Others`
            }
          } else if(members.length == 2) {
            const user_one = this.$store.getters.getVoipUsersAlises[members[0]?.user_id]
            const user_two = this.$store.getters.getVoipUsersAlises[members[1]?.user_id]
            if(user_one && user_two) {
              display_name = `${user_one?.user?.display_name}, ${user_two?.user?.display_name}`
            }
          }
          if(display_name){
            const { data: channel } = await MATTERMOST_API.endpoints.channels.patchChannel(this.channel_id, {
              display_name: display_name,
            });
            mattermost.emitOnUserLevel(mattermost.events.channel_updated,{
              channel_id: channel.id,
              channel: channel
            })
          }
          // const members_length = this.$store.state.mattermost.channels[this.channel_id].members?.length
          
          // const display_name = this.$store.state.mattermost.channels[this.channel_id]?.channel?.display_name ?? ''
          // const display_name_splits = display_name.split(',')
          // const new_display_name = `${display_name_splits[0]},${display_name_splits[1]}, ${members_length-2} Others`
          // const { data: channel } = await MATTERMOST_API.endpoints.channels.patchChannel(this.channel_id, {
          //   display_name: new_display_name,
          // });
          // mattermost.emitOnUserLevel(mattermost.events.channel_updated,{
          //   channel_id: channel.id,
          //   channel: channel
          // })
        }
      } catch (error) {
        LOGGER.danger("error in addMemberInChannelAndMakeAdmin function", {
          error,
        });
      }
    },
    async makeChannelId(mattermostIds) {
      
      LOGGER.log("makeChannelId",this.videoCall.isCaller,this.type,this.channel_id);
      if (_.isEmpty(mattermostIds)) return;
      try {
        if (this.type === "updated_grouped") {
          this.addMemberInChannelAndMakeAdmin(mattermostIds[mattermostIds.length - 1]);
        }
        if (this.videoCall.isCaller) {
          if (this.videoCall.is_group) {
            this.channel_id = this.videoCall.room;
            this.type = "grouped";
          } else {
            if (mattermostIds.length > 1) {
              if (this.type !== "updated_grouped") {
                const { data: channel } = await MATTERMOST_API.endpoints.channels.create({
                  team_id: this.$store.getters.getCurrentUser?.mm_team_id,//this.current_user.mm_team_id,
                  type: "P",
                  display_name: `${this.videoCall.name}, 1 Others`,
                  name: $fn.makeid(52).toLowerCase(),
                });
                mattermost.emitOnUserLevel(mattermost.events.channel_created,{
                  channel_id: channel.id
                })
                this.channel_id = channel.id;
                for (let index = 0; index < mattermostIds.length; index++) {
                  this.addMemberInChannelAndMakeAdmin(mattermostIds[index]);
                }
              }
              this.type = "updated_grouped";
            } else if(this.type != "updated_grouped") {  
              let channel_id = "";
              const user = this.$store.getters.getVoipUsersAlises[mattermostIds[0]]; //this.getUserInfo(mattermostIds[0]);
              if (_.isEmpty(user)) throw new Error("User is Empty");
              channel_id = user.channel_id;
              if (!channel_id) {
                // const { data: channel } = await MATTERMOST_API.endpoints.channels.createDirectChannel([user.user.user_chat_id, this.mattermost_current_user.id,]);
                const { data: channel } = await MATTERMOST_API.endpoints.channels.createDirectChannel([user.user.user_chat_id, this.$store.getters.getMattermostCurrentUser?.id]);
                mattermost.emitOnUserLevel(mattermost.events.direct_added,{
                  channel_id: channel.id
                })
                this.$set(this.$store.state.common.voipusers[user.voipaccount],"channel_id",channel.id);
                // this.$root.$emit(events.adding_channel, {
                //   channel_id: channel.id,
                //   data: {
                //     channel: channel,
                //     type: "user",
                //     // data: this.$store.state.common.voipusers[user.voipaccount],
                //     id: user?.voipaccount,
                //   },
                // });
                channel_id = channel.id;
              }
              this.channel_id = channel_id;
              this.type = "direct";
            }
          }
        }
        if (this.channel_id && this.type) {
          this.JitsiConference.sendCommand(commands.CHANNEL_DATA, {
            attributes: {
              channel_id: this.channel_id,
              type: this.type,
            },
          });
        }
        LOGGER.log("makeChannelId end",this.videoCall.isCaller,this.type,this.channel_id);
      } catch (error) {
        LOGGER.danger("error in makeChannelId function", error);
      }
    },
    // async chat() {
    //   try {
    //     let vm = this;
    //     const participants_array = Object.values(this.participants)
    //     let channel_id = ''
    //     if(this.videoCall.is_group){
    //       channel_id = this.videoCall.room
    //     } else {
    //       if(participants_array.length>1){
    //         const { data: channel } = await MATTERMOST_API.endpoints.channels.create({
    //           team_id: this.current_user.mm_team_id,
    //           type: "P",
    //           display_name: 'video call',
    //           name: (getUniqueID() + 'video call' + getUniqueID()).replace(/[^a-zA-Z0-9 ]/g, "").replace(/ /g, "-").toLowerCase(),
    //         });
    //         channel_id = channel.id
    //       } else {
    //         const participant = participants_array[0]
    //         const user = this.getUserInfo(participant._displayName)
    //         if(_.isEmpty(user)) return;
    //         channel_id = user.channel_id
    //         if(!channel_id){
    //           const { data: channel } = await MATTERMOST_API.endpoints.channels.createDirectChannel([user.user.user_chat_id, this.mattermost_current_user.id]);
    //           this.$set(this.$store.state.common.voipusers[user.voipaccount],'channel_id',channel.id)
    //           this.$root.$emit(events.adding_channel,{
    //             channel_id: channel.id,
    //             data: {
    //               channel: channel,
    //               type: 'user',
    //               data: this.$store.state.common.voipusers[user.voipaccount],
    //             }
    //           })
    //           channel_id = channel.id
    //         }
    //       }
    //       setTimeout(()=>{
    //         vm.$refs.chat.channel_id = channel_id;
    //       },1 * 1000)
    //     }
    //   } catch (error) {
    //     LOGGER.danger("error in creating direct chat", error);
    //   }
    // },
    leaveConference() {
      try {
        if (!this.isLeave) {
          if (
            !this.videoCall.is_conference && 
            Object.values(this.participants).length === 0 &&
            this.videoCall.is_group
          ) {
            const channels = this.videoCall.data.callee.map((account) => `${account}-in`);
            channels.push(`${this.videoCall.data.caller}-in`);
            VOIP_API.endpoints.users.groupPublish({
              method: "broadcast",
              params: {
                channels: channels,
                data: {
                  ...this.videoCall.data,
                  type: AMI_EVENTS.NOT_ACTIVE_GROUP_CALLS,
                },
              },
            });
          }
          this.$root.$emit(events.call_ended, {
            data: this.videoCall,
            index: this.listIndex,
          });
          // if(this.$store.getters.getCurrentUser?.account===this.videoCall?.data?.caller){
          //   this.$delete(this.$store.state.calls.restricted_calls,this.videoCall?.data?.restricted_key_caller)
          // } else {
          //   this.$delete(this.$store.state.calls.restricted_calls,this.videoCall?.data?.restricted_key_callee)
          // }
          this.JitsiConference.leave();
          this.isLeave = true;
        }
        this.videoCall.stopTimer();
        this.$delete(this.$store.state.calls.active_group_calls,this.videoCall.room);
      } catch (error) {
        LOGGER.danger("error in leaveConference function", error);
      }
    },
    async creatingLocalTracks() {
      try {
        if (this.videoCall.call_type === "audio") {
          this.$store.state.calls.videoCalls[this.listIndex].isAudioMute = false
        } else {
          this.$store.state.calls.videoCalls[this.listIndex].isVideoMute = false;
          this.$store.state.calls.videoCalls[this.listIndex].isAudioMute = false
        }
      } catch (JitsiTrackError) {
        LOGGER.danger("JitsiTrackError", JitsiTrackError);
        this.error = {
          type: "Error to Make tracks",
          message: "Something went wrong!!",
        };
      } finally {
        // this.$parent.$parent.$parent.isFullScreen=true;
      }
    },
    async makeConference() {
      try {
        await this.creatingLocalTracks();
        const options = {
          openBridgeChannel: true,
          enableNoisyMicDetection: true,
          p2p: {
            enabled: false,
          },
          // callStatsID: '',
          // callStatsSecret: '',
          // statisticsDisplayName: '',
          // statisticsId: '',
        };
        this.JitsiConference = this.JitsiConnection.initJitsiConference(
          this.payload.room,
          options
        );
        this.JitsiConference.join();
        this.user_id=this.JitsiConference.myUserId()
        this.JitsiConference.setLocalParticipantProperty(
          "account",
          // this.current_user.account
          this.$store.getters.getCurrentUser?.account
        );
        const {
          events: { conference: events },
        } = JitsiMeetJS;
        //events
        this.JitsiConference.on(events.AUDIO_INPUT_STATE_CHANGE,this.AUDIO_INPUT_STATE_CHANGE);
        this.JitsiConference.on(events.AUTH_STATUS_CHANGED,this.AUTH_STATUS_CHANGED);
        this.JitsiConference.on(events.BEFORE_STATISTICS_DISPOSED,this.BEFORE_STATISTICS_DISPOSED);
        this.JitsiConference.on(events.BOT_TYPE_CHANGED,this.BOT_TYPE_CHANGED);
        this.JitsiConference.on(events.CONFERENCE_CREATED_TIMESTAMP,this.CONFERENCE_CREATED_TIMESTAMP);
        this.JitsiConference.on(events.CONFERENCE_ERROR,this.CONFERENCE_ERROR);
        this.JitsiConference.on(events.CONFERENCE_FAILED,this.CONFERENCE_FAILED);
        this.JitsiConference.on(events.CONFERENCE_JOINED,this.CONFERENCE_JOINED);
        this.JitsiConference.on(events.CONFERENCE_LEFT,this.CONFERENCE_LEFT);
        this.JitsiConference.on(events.CONNECTION_ESTABLISHED,this.CONNECTION_ESTABLISHED);
        this.JitsiConference.on(events.CONNECTION_INTERRUPTED,this.CONNECTION_INTERRUPTED);
        this.JitsiConference.on(events.CONNECTION_RESTORED,this.CONNECTION_RESTORED);
        this.JitsiConference.on(events.DATA_CHANNEL_OPENED,this.DATA_CHANNEL_OPENED);
        this.JitsiConference.on(events.DISPLAY_NAME_CHANGED,this.DISPLAY_NAME_CHANGED);
        this.JitsiConference.on(events.DOMINANT_SPEAKER_CHANGED,this.DOMINANT_SPEAKER_CHANGED);
        this.JitsiConference.on(events.DTMF_SUPPORT_CHANGED,this.DTMF_SUPPORT_CHANGED);
        this.JitsiConference.on(events.ENDPOINT_MESSAGE_RECEIVED,this.ENDPOINT_MESSAGE_RECEIVED);
        this.JitsiConference.on(events.JVB121_STATUS,this.JVB121_STATUS);
        this.JitsiConference.on(events.KICKED,this.KICKED);
        this.JitsiConference.on(events.LAST_N_ENDPOINTS_CHANGED,this.LAST_N_ENDPOINTS_CHANGED);
        this.JitsiConference.on(events.LOBBY_USER_JOINED,this.LOBBY_USER_JOINED);
        this.JitsiConference.on(events.LOBBY_USER_LEFT,this.LOBBY_USER_LEFT);
        this.JitsiConference.on(events.LOBBY_USER_UPDATED,this.LOBBY_USER_UPDATED);
        this.JitsiConference.on(events.LOCK_STATE_CHANGED,this.LOCK_STATE_CHANGED);
        this.JitsiConference.on(events.MEMBERS_ONLY_CHANGED,this.MEMBERS_ONLY_CHANGED);
        this.JitsiConference.on(events.MESSAGE_RECEIVED,this.MESSAGE_RECEIVED);
        this.JitsiConference.on(events.NOISY_MIC,this.NOISY_MIC);
        this.JitsiConference.on(events.NO_AUDIO_INPUT,this.NO_AUDIO_INPUT);
        this.JitsiConference.on(events.P2P_STATUS,this.P2P_STATUS);
        this.JitsiConference.on(events.PARTCIPANT_FEATURES_CHANGED,this.PARTCIPANT_FEATURES_CHANGED);
        this.JitsiConference.on(events.PARTICIPANT_CONN_STATUS_CHANGED,this.PARTICIPANT_CONN_STATUS_CHANGED);
        this.JitsiConference.on(events.PARTICIPANT_KICKED,this.PARTICIPANT_KICKED);
        this.JitsiConference.on(events.PARTICIPANT_PROPERTY_CHANGED,this.PARTICIPANT_PROPERTY_CHANGED);
        this.JitsiConference.on(events.PHONE_NUMBER_CHANGED,this.PHONE_NUMBER_CHANGED);
        this.JitsiConference.on(events.PRIVATE_MESSAGE_RECEIVED,this.PRIVATE_MESSAGE_RECEIVED);
        this.JitsiConference.on(events.PROPERTIES_CHANGED,this.PROPERTIES_CHANGED);
        this.JitsiConference.on(events.RECORDER_STATE_CHANGED,this.RECORDER_STATE_CHANGED);
        this.JitsiConference.on(events.SERVER_REGION_CHANGED,this.SERVER_REGION_CHANGED);
        this.JitsiConference.on(events.STARTED_MUTED,this.STARTED_MUTED);
        this.JitsiConference.on(events.START_MUTED_POLICY_CHANGED,this.START_MUTED_POLICY_CHANGED);
        this.JitsiConference.on(events.SUBJECT_CHANGED,this.SUBJECT_CHANGED);
        this.JitsiConference.on(events.SUSPEND_DETECTED,this.SUSPEND_DETECTED);
        this.JitsiConference.on(events.TALK_WHILE_MUTED,this.TALK_WHILE_MUTED);
        this.JitsiConference.on(events.TRACK_ADDED,this.TRACK_ADDED);
        this.JitsiConference.on(events.TRACK_AUDIO_LEVEL_CHANGED,this.TRACK_AUDIO_LEVEL_CHANGED);
        this.JitsiConference.on(events.TRACK_MUTE_CHANGED,this.TRACK_MUTE_CHANGED);
        this.JitsiConference.on(events.TRACK_REMOVED,this.TRACK_REMOVED);
        this.JitsiConference.on(events.TRANSCRIPTION_STATUS_CHANGED,this.TRANSCRIPTION_STATUS_CHANGED);
        this.JitsiConference.on(events.USER_JOINED,this.USER_JOINED);
        this.JitsiConference.on(events.USER_LEFT,this.USER_LEFT);
        this.JitsiConference.on(events.USER_ROLE_CHANGED,this.USER_ROLE_CHANGED);
        this.JitsiConference.on(events.USER_STATUS_CHANGED,this.USER_STATUS_CHANGED);
        this.JitsiConference.on(events.VIDEO_SIP_GW_AVAILABILITY_CHANGED,this.VIDEO_SIP_GW_AVAILABILITY_CHANGED);
        this.JitsiConference.on(events.VIDEO_SIP_GW_SESSION_STATE_CHANGED,this.VIDEO_SIP_GW_SESSION_STATE_CHANGED);
        this.JitsiConference.on(events._MEDIA_SESSION_ACTIVE_CHANGED,this._MEDIA_SESSION_ACTIVE_CHANGED);
        this.JitsiConference.on(events._MEDIA_SESSION_STARTED,this._MEDIA_SESSION_STARTED);
        this.JitsiConference.addCommandListener(commands.CHANNEL_ID_CHANGED,this.CHANNEL_ID_CHANGED);
        this.JitsiConference.addCommandListener(commands.CHANNEL_DATA,this.CHANNEL_DATA);
        this.JitsiConference.addCommandListener(commands.EMOJI,this.EMOJI);
        MOBILE_APP.sendMessage(MOBILE_APP_SEND_EVENTS.SHOW_WEB_VIEW)
      } catch (error) {
        LOGGER.danger("error in makeConference function ", error);
      }
    },
    async camera() {
      try {
        let video_index = this.localTracks.findIndex((track) => track.type === "video");
        const [video] = await JitsiMeetJS.createLocalTracks({
          devices: ["video"],
          resolution: this.resolution,
          cameraDeviceId: this.camera_device_id,
          minFps: 30,
          maxFps: 60,
        });
        if (video_index>-1) {
          await this.JitsiConference.replaceTrack(this.localTracks[video_index], video);
          this.localTracks.splice(video_index, 1);
          this.localTracks.splice(video_index, 0, video);
        } else {
          await this.JitsiConference.addTrack(video)
          this.localTracks.push(video);
        }
        this.$store.state.calls.videoCalls[this.listIndex].isVideo=true
        if(this.error?.track_failed=='video'){
          this.error = ''
        }
      } catch (ex) {
        LOGGER.danger("error in camera function", {ex});
        let message = 'There is something went wrong in starting the video camera'
        if(ex.name=='gum.general') message = 'Your camera device is in use by another application'
        else if(permissions.camera=='denied') message = 'Your camera Permissions is Blocked'
        else if(ex?.message) message=ex?.message
        this.error = {
          type: "Error Create Video Track",
          message: message,
          track_failed: 'video',
        };
      }
    },
    async shareScreen() {
      try {
        if (!JitsiMeetJS.isDesktopSharingEnabled()) return;
        let video_index = this.localTracks.findIndex(i=>i.type=="video");
        const desktop = await JitsiMeetJS.createLocalTracks({
          devices: ["desktop"],
          resolution: this.resolution,
          desktopSharingFrameRate: {
            min: 180,
            max: 180,
          },
        });
        const desktopVideo = desktop.length === 1 ? desktop[0] : desktop[1];
        const desktopaudio = desktop.length === 2 ? desktop[0] : null;
        if (desktopaudio) {
          // TODO
        }
        if (video_index > -1 && desktopVideo) {
          await this.JitsiConference.replaceTrack(this.localTracks[video_index], desktopVideo);
          this.localTracks.splice(video_index, 1);
          this.localTracks.splice(video_index, 0, desktopVideo);
        } else {
          await this.JitsiConference.addTrack(desktopVideo)
          this.localTracks.push(desktopVideo);
        }
        this.$store.state.calls.videoCalls[this.listIndex].isVideoMute=false
      } catch (error) {
        LOGGER.danger("error in shareScreen function", error);
      }
    },
    async audio() {
      try {
        const [audio] = this.localTracks.filter(
          (track) => track.type === "audio"
        );
        const [new_audio] = await JitsiMeetJS.createLocalTracks({
          devices: ["audio"],
          micDeviceId: this.mic_device_id,
        });
        if (audio) {
          await this.JitsiConference.replaceTrack(audio, new_audio);
          const index = this.localTracks[1]?.type === "audio" ? 1 : 0;
          this.localTracks.splice(index, 1);
          this.localTracks.splice(index, 0, new_audio);
        } else {
          await this.JitsiConference.addTrack(new_audio)
          this.localTracks.push(new_audio);
        }
        if(this.error?.track_failed=='audio'){
          this.error = ''
        }
      } catch (error) {
        LOGGER.danger("error in audio function", error);
        this.error = {
          type: "Error Create Audio Track",
          message: "Something went wrong!!",
          track_failed: 'audio',
        };
      }
    },
    mirrorVideo() {
      const ell = document.getElementById(`local_video_${this.listIndex}`);
      if (ell.style.transform && ell.style.webkitTransform) {
        ell.style.transform = "";
        ell.style.webkitTransform = "";
        this.JitsiConference.setLocalParticipantProperty(
          "isMirrorVideo",
          false
        );
      } else {
        ell.style.transform = "rotateY(180deg)";
        ell.style.webkitTransform = "rotateY(180deg)";
        this.JitsiConference.setLocalParticipantProperty("isMirrorVideo", true);
      }
    },
    sendEmoji(value){
      if(emojis[value].default){
        const send_emoji = this.emoji==value ? '' : value
        this.JitsiConference.setLocalParticipantProperty(
          "emoji",send_emoji
        );
        this.emoji=send_emoji
      } else {
        this.JitsiConference.sendCommandOnce(commands.EMOJI, {
          attributes: {
            emoji: value,
          },
        });
      }
    },
    emojiAnimation(emoji){
      let vm = this
      LOGGER.log(emoji)
      // TODO
      // console.log(emoji + " tun")
      if(reaction_interval) clearTimeout(reaction_interval)
      vm.reaction=emoji
      reaction_interval = setTimeout(()=>{
        vm.reaction=''
      }, 3 * 1000)
      // }, 300 * 1000)
    },
    toggleFullScreen(){
      const el = document.getElementById(this.el_id)
      if(!this.full_screen && el){
        $fn.openFullscreen(el)
        this.full_screen=true
      } else if(this.full_screen) {
        $fn.closeFullscreen()
        this.full_screen=false
      }
    },
    feedBack(){
      let vm = this
      vm.$modal.show(`${this.el_id}FeedBack`,{
        onSubmit({ rate, detail }){
          vm.JitsiConference.sendFeedback(rate, detail)
        },
      })
    },
    //--events--

    //----centrifuge----
    REJECTED(message) {
      LOGGER.event(
        "REJECTED",
        message,
        Object.values(this.participants).length,
        this.videoCall.room
      );
      if (message.data.room === this.videoCall.room && Object.values(this.participants).length === 0) {
        this.leaveConference();
      }
    },
    //------------------

    //----command----
    CHANNEL_ID_CHANGED(values) {
      LOGGER.event("CHANNEL_ID_CHANGED", values);
      const { value } = values;
      this.channel_id = value;
    },
    CHANNEL_DATA(values) {
      LOGGER.event(commands.CHANNEL_DATA, values);
      const {
        attributes: { channel_id, type },
      } = values;
      this.channel_id = channel_id || this.channel_id;
      this.type = type || this.type;
    },
    EMOJI(values){
      LOGGER.event(commands.EMOJI, values);
      const {
        attributes: { emoji },
      } = values;
      this.emojiAnimation(emoji)
    },
    //------------------

    //----conference----
    CONFERENCE_CREATED_TIMESTAMP(timestamp) {
      LOGGER.event("CONFERENCE_CREATED_TIMESTAMP", {
        timestamp,
      });
      this.videoCall.startTimer(timestamp)
    },
    CONFERENCE_ERROR(errorCode) {
      LOGGER.event("CONFERENCE_ERROR", {
        errorCode,
      });
      const {
        errors: { conference: errors },
      } = JitsiMeetJS;
      switch (errorCode) {
        case errors.AUTHENTICATION_REQUIRED:
          this.AUTHENTICATION_REQUIRED();
          break;
        case errors.CHAT_ERROR:
          this.CHAT_ERROR();
          break;
        case errors.CONFERENCE_ACCESS_DENIED:
          this.CONFERENCE_ACCESS_DENIED();
          break;
        case errors.CONFERENCE_DESTROYED:
          this.CONFERENCE_DESTROYED();
          break;
        case errors.CONFERENCE_MAX_USERS:
          this.CONFERENCE_MAX_USERS();
          break;
        case errors.CONNECTION_ERROR:
          this.CONNECTION_ERROR();
          break;
        case errors.FOCUS_DISCONNECTED:
          this.FOCUS_DISCONNECTED();
          break;
        case errors.FOCUS_LEFT:
          this.FOCUS_LEFT();
          break;
        case errors.GRACEFUL_SHUTDOWN:
          this.GRACEFUL_SHUTDOWN();
          break;
        case errors.ICE_FAILED:
          this.ICE_FAILED();
          break;
        case errors.INCOMPATIBLE_SERVER_VERSIONS:
          this.INCOMPATIBLE_SERVER_VERSIONS();
          break;
        case errors.MEMBERS_ONLY_ERROR:
          this.MEMBERS_ONLY_ERROR();
          break;
        case errors.NOT_ALLOWED_ERROR:
          this.NOT_ALLOWED_ERROR();
          break;
        case errors.OFFER_ANSWER_FAILED:
          this.OFFER_ANSWER_FAILED();
          break;
        case errors.PASSWORD_NOT_SUPPORTED:
          this.PASSWORD_NOT_SUPPORTED();
          break;
        case errors.PASSWORD_REQUIRED:
          this.PASSWORD_REQUIRED();
          break;
        case errors.RESERVATION_ERROR:
          this.RESERVATION_ERROR();
          break;
        case errors.VIDEOBRIDGE_NOT_AVAILABLE:
          this.VIDEOBRIDGE_NOT_AVAILABLE();
          break;
        default:
          LOGGER.info("error are not in JitsiMeetJS");
          break;
      }
    },
    CONFERENCE_FAILED(errorCode) {
      LOGGER.event("CONFERENCE_FAILED", {
        errorCode,
      });
      const {
        errors: { conference: errors },
      } = JitsiMeetJS;
      switch (errorCode) {
        case errors.AUTHENTICATION_REQUIRED:
          this.AUTHENTICATION_REQUIRED();
          break;
        case errors.CHAT_ERROR:
          this.CHAT_ERROR();
          break;
        case errors.CONFERENCE_ACCESS_DENIED:
          this.CONFERENCE_ACCESS_DENIED();
          break;
        case errors.CONFERENCE_DESTROYED:
          this.CONFERENCE_DESTROYED();
          break;
        case errors.CONFERENCE_MAX_USERS:
          this.CONFERENCE_MAX_USERS();
          break;
        case errors.CONNECTION_ERROR:
          this.CONNECTION_ERROR();
          break;
        case errors.FOCUS_DISCONNECTED:
          this.FOCUS_DISCONNECTED();
          break;
        case errors.FOCUS_LEFT:
          this.FOCUS_LEFT();
          break;
        case errors.GRACEFUL_SHUTDOWN:
          this.GRACEFUL_SHUTDOWN();
          break;
        case errors.ICE_FAILED:
          this.ICE_FAILED();
          break;
        case errors.INCOMPATIBLE_SERVER_VERSIONS:
          this.INCOMPATIBLE_SERVER_VERSIONS();
          break;
        case errors.MEMBERS_ONLY_ERROR:
          this.MEMBERS_ONLY_ERROR();
          break;
        case errors.NOT_ALLOWED_ERROR:
          this.NOT_ALLOWED_ERROR();
          break;
        case errors.OFFER_ANSWER_FAILED:
          this.OFFER_ANSWER_FAILED();
          break;
        case errors.PASSWORD_NOT_SUPPORTED:
          this.PASSWORD_NOT_SUPPORTED();
          break;
        case errors.PASSWORD_REQUIRED:
          this.PASSWORD_REQUIRED();
          break;
        case errors.RESERVATION_ERROR:
          this.RESERVATION_ERROR();
          break;
        case errors.VIDEOBRIDGE_NOT_AVAILABLE:
          this.VIDEOBRIDGE_NOT_AVAILABLE();
          break;
        default:
          LOGGER.info("error are not in JitsiMeetJS");
          break;
      }
      // this.$root.$emit(events.call_ended,{
      //   data: this.videoCall,
      //   index: this.listIndex
      // })
    },
    CONFERENCE_JOINED() {
      this.JitsiConference.setLocalParticipantProperty("avatar",this.payload.context.user.avatar);
      // this.JitsiConference.setDisplayName(this.current_user.account);
      this.JitsiConference.setDisplayName(this.$store.getters.getCurrentUser?.account);
      this.isJoined = true;
      this.$store.state.calls.videoCalls[this.listIndex].isJoined = true;
    },
    CONFERENCE_LEFT() {
      LOGGER.event("CONFERENCE_LEFT");
      this.$destroy();
    },
    //------------------

    //----connection----
    CONNECTION_ESTABLISHED() {
      //no params
      LOGGER.event("CONNECTION_ESTABLISHED");
    },
    CONNECTION_INTERRUPTED() {
      //no params
      LOGGER.event("CONNECTION_INTERRUPTED");
    },
    CONNECTION_RESTORED() {
      //no params
      LOGGER.event("CONNECTION_RESTORED");
    },
    //------------------

    //----lobby----
    LOBBY_USER_JOINED(...args) {
      LOGGER.event("LOBBY_USER_JOINED", {
        ...args,
      });
    },
    LOBBY_USER_LEFT(...args) {
      LOGGER.event("LOBBY_USER_LEFT", {
        ...args,
      });
    },
    LOBBY_USER_UPDATED(...args) {
      LOGGER.event("LOBBY_USER_UPDATED", {
        ...args,
      });
    },
    //-------------

    //----participant----
    PARTCIPANT_FEATURES_CHANGED(...args) {
      LOGGER.event("PARTCIPANT_FEATURES_CHANGED", { ...args });
    },
    PARTICIPANT_CONN_STATUS_CHANGED(id, status) {
      LOGGER.event("PARTICIPANT_CONN_STATUS_CHANGED", { id, status });
    },
    PARTICIPANT_KICKED(JitsiParticipant, removedJitsiParticipant) {
      LOGGER.event("PARTICIPANT_KICKED", {
        JitsiParticipant,
        removedJitsiParticipant,
      });
    },
    PARTICIPANT_PROPERTY_CHANGED(JitsiParticipant, key) {
      LOGGER.event("PARTICIPANT_PROPERTY_CHANGED", { JitsiParticipant, key });
      const participant = this.participants[JitsiParticipant._id];
      const value = JitsiParticipant._properties[key];
      if (key === "bye") {
        this.leaveConference();
      } else if (participant) {
        this.$set(participant._properties, key, value);
      }
    },
    //-------------------

    //----track----
    TRACK_ADDED(JitsiTrack) {
      LOGGER.event("TRACK_ADDED", { JitsiTrack },JitsiTrack.isLocal(),JitsiTrack.getParticipantId());
      // const uid = $fn.makeid(50)
      if (JitsiTrack.isLocal()) return;
      const participantId = JitsiTrack.getParticipantId();
      const participant = this.participants[participantId];
      if (participant) {
        participant._tracks.push(JitsiTrack);
      }
    },
    TRACK_AUDIO_LEVEL_CHANGED(participantId, audioLevel) {
      LOGGER.event("TRACK_AUDIO_LEVEL_CHANGED", {
        participantId,
        audioLevel,
      });
      const participant = this.participants[participantId];
      if (participant) {
        // participant._tracks.push(JitsiTrack)
      }
    },
    TRACK_MUTE_CHANGED(JitsiTrack) {
      LOGGER.event("TRACK_MUTE_CHANGED", {
        JitsiTrack,
      });
      if (JitsiTrack.isLocal()) {
        return;
      } else {
        const participantId = JitsiTrack.getParticipantId();
        const participant = this.participants[participantId];
        if (participant) {
          // participant._tracks.push(JitsiTrack)
        }
      }
    },
    TRACK_REMOVED(JitsiTrack) {
      LOGGER.event("TRACK_REMOVED", {
        JitsiTrack,
      });
      if (JitsiTrack.isLocal()) {
        JitsiTrack.dispose()
      } else {
        const participantId = JitsiTrack.getParticipantId();
        const participant = this.participants[participantId];
        if (participant) {
          const newTracks = participant._tracks.filter(
            (track) => track.getId() === JitsiTrack.getId()
          );
          participant._tracks = newTracks;
        }
      }
    },
    //-------------

    //----user----
    USER_JOINED(id, JitsiParticipant) {
      LOGGER.event("USER_JOINED", { id, JitsiParticipant });
      // const participants_array = Object.values(this.participants);
      // if (participants_array.length === 0) {
      //   this.$store.state.calls.videoCalls[this.listIndex].isAudioMute = false;
      // }
      const participant = this.participants[id];
      if (!participant) {
        this.$set(this.participants, id, {
          ...JitsiParticipant,
          _properties: {
            ...JitsiParticipant._properties,
            isVideoMuted: false,
            isRiseHand: false,
            isMirrorVideo: false,
            avatar: "",
            audioLevel: 10,
            emoji: '',
            account: "",
            isHold: 'false',
          },
        });
        if (JitsiParticipant._displayName) {
          if (this.videoCall.data.is_group) {
            //TODO
          }
          this.$store.state.calls.videoCalls[this.listIndex].names.push(this.$store.getters.getVoipUsersAlisesDisplayName[JitsiParticipant._displayName]);
          this.$store.state.calls.videoCalls[this.listIndex].images.push({
            key: JitsiParticipant._displayName,
            alt: this.$store.getters.getVoipUsersAlisesDisplayName[JitsiParticipant._displayName],
            src: this.$store.getters.getVoipUsersAlisesImages[JitsiParticipant._displayName],
          });
          this.$refs.members?.addMember?.(JitsiParticipant._displayName);
        }
      }
    },
    USER_LEFT(id, JitsiParticipant) {
      LOGGER.event("USER_LEFT", {
        id,
        JitsiParticipant,
      });
      const participant = this.participants[id];
      if (participant) {
        this.$delete(this.participants, id);
        this.$refs.members?.removeMember?.(JitsiParticipant._displayName);
      }
      if(!this.videoCall.is_conference){
        if (Object.values(this.participants).length === 0) {
          this.$store.state.calls.videoCalls[this.listIndex].isHangout = true;
        }
      }
    },
    USER_ROLE_CHANGED(id, role) {
      LOGGER.event("USER_ROLE_CHANGED", {
        id,
        role,
      });
      const participant = this.participants[id];
      if (participant) {
        participant._role = role;
      }
    },
    USER_STATUS_CHANGED(id, status) {
      LOGGER.event("USER_STATUS_CHANGED", {
        id,
        status,
      });
      const participant = this.participants[id];
      if (participant) {
        participant._status = status;
      }
    },
    //------------

    //----video sip----
    VIDEO_SIP_GW_AVAILABILITY_CHANGED(...args) {
      LOGGER.event("VIDEO_SIP_GW_AVAILABILITY_CHANGED", {
        ...args,
      });
    },
    VIDEO_SIP_GW_SESSION_STATE_CHANGED(...args) {
      LOGGER.event("VIDEO_SIP_GW_SESSION_STATE_CHANGED", {
        ...args,
      });
    },
    //-----------------

    //----media session----
    _MEDIA_SESSION_ACTIVE_CHANGED(JingleSessionPC) {
      LOGGER.event("_MEDIA_SESSION_ACTIVE_CHANGED", {
        JingleSessionPC,
      });
    },
    _MEDIA_SESSION_STARTED(JingleSessionPC) {
      LOGGER.event("_MEDIA_SESSION_STARTED", {
        JingleSessionPC,
      });
    },
    //---------------------

    //----others----
    AUDIO_INPUT_STATE_CHANGE(...args) {
      LOGGER.event("AUDIO_INPUT_STATE_CHANGE", {
        ...args,
      });
    },
    AUTH_STATUS_CHANGED(isAuthEnabled, authIdentity) {
      LOGGER.event("AUTH_STATUS_CHANGED", {
        isAuthEnabled,
        authIdentity,
      });
    },
    BEFORE_STATISTICS_DISPOSED() {
      LOGGER.event("BEFORE_STATISTICS_DISPOSED");
    },
    BOT_TYPE_CHANGED(...args) {
      LOGGER.event("BOT_TYPE_CHANGED", {
        ...args,
      });
    },
    DATA_CHANNEL_OPENED() {
      //no params
      LOGGER.event("DATA_CHANNEL_OPENED");
    },
    DISPLAY_NAME_CHANGED(id, displayName) {
      LOGGER.event("DISPLAY_NAME_CHANGED", { id, displayName });
      if (this.participants[id]) {
        this.participants[id]._displayName = displayName;
        this.$store.state.calls.videoCalls[this.listIndex].names.push(
          this.$store.getters.getVoipUsersAlisesDisplayName[displayName]
        );
        this.$store.state.calls.videoCalls[this.listIndex].images.push({
          key: displayName,
          alt: this.$store.getters.getVoipUsersAlisesDisplayName[displayName],
          src: this.$store.getters.getVoipUsersAlisesImages[displayName],
        });
        this.$refs.members.addMember(displayName);
      }
    },
    DOMINANT_SPEAKER_CHANGED(id) {
      LOGGER.event("DOMINANT_SPEAKER_CHANGED", {
        id,
      });
      this.speaker_id=id
    },
    DTMF_SUPPORT_CHANGED(supports) {
      LOGGER.event("DTMF_SUPPORT_CHANGED", {
        supports,
      });
    },
    ENDPOINT_MESSAGE_RECEIVED(JitsiParticipant, properties) {
      LOGGER.event("ENDPOINT_MESSAGE_RECEIVED", {
        JitsiParticipant,
        properties,
      });
    },
    JVB121_STATUS(status) {
      LOGGER.event("JVB121_STATUS", {
        status,
      });
    },
    KICKED(...args) {
      LOGGER.event("KICKED", {
        ...args,
      });
    },
    LAST_N_ENDPOINTS_CHANGED(leavingEndpointIds) {
      LOGGER.event("LAST_N_ENDPOINTS_CHANGED", {
        leavingEndpointIds,
      });
    },
    LOCK_STATE_CHANGED(state) {
      LOGGER.event("LOCK_STATE_CHANGED", {
        state,
      });
    },
    MEMBERS_ONLY_CHANGED(isMembersOnlyChanged) {
      LOGGER.event("MEMBERS_ONLY_CHANGED", {
        isMembersOnlyChanged,
      });
    },
    MESSAGE_RECEIVED(id, text, ts) {
      LOGGER.event("MESSAGE_RECEIVED", {
        id,
        text,
        ts,
      });
    },
    NOISY_MIC(...args) {
      LOGGER.event("NOISY_MIC", {
        ...args,
      });
    },
    NO_AUDIO_INPUT(...args) {
      LOGGER.event("NO_AUDIO_INPUT", {
        ...args,
      });
    },
    P2P_STATUS(JitsiConference) {
      LOGGER.event("P2P_STATUS", {
        JitsiConference,
      });
    },
    PHONE_NUMBER_CHANGED(...args) {
      LOGGER.event("PHONE_NUMBER_CHANGED", {
        ...args,
      });
    },
    PRIVATE_MESSAGE_RECEIVED(...args) {
      LOGGER.event("PRIVATE_MESSAGE_RECEIVED", {
        ...args,
      });
    },
    PROPERTIES_CHANGED(properties) {
      LOGGER.event("PROPERTIES_CHANGED", {
        properties,
      });
    },
    RECORDER_STATE_CHANGED(...args) {
      LOGGER.event("RECORDER_STATE_CHANGED", {
        ...args,
      });
    },
    SERVER_REGION_CHANGED() {
      //no params
      LOGGER.event("SERVER_REGION_CHANGED");
    },
    STARTED_MUTED(...args) {
      LOGGER.event("STARTED_MUTED", {
        ...args,
      });
    },
    START_MUTED_POLICY_CHANGED({ audio, video }) {
      LOGGER.event("START_MUTED_POLICY_CHANGED", {
        audio,
        video,
      });
    },
    SUBJECT_CHANGED(subject) {
      LOGGER.event("SUBJECT_CHANGED", {
        subject,
      });
    },
    SUSPEND_DETECTED(...args) {
      LOGGER.event("SUSPEND_DETECTED", {
        ...args,
      });
    },
    TALK_WHILE_MUTED(...args) {
      LOGGER.event("TALK_WHILE_MUTED", {
        ...args,
      });
    },
    TRANSCRIPTION_STATUS_CHANGED(status) {
      LOGGER.event("TRANSCRIPTION_STATUS_CHANGED", {
        status,
      });
    },
    //--------------

    //--errors--
    AUTHENTICATION_REQUIRED() {
      LOGGER.danger("AUTHENTICATION_REQUIRED");
    },
    CHAT_ERROR() {
      LOGGER.danger("CHAT_ERROR");
    },
    CONFERENCE_ACCESS_DENIED() {
      LOGGER.danger("CONFERENCE_ACCESS_DENIED");
    },
    CONFERENCE_DESTROYED() {
      LOGGER.danger("CONFERENCE_DESTROYED");
    },
    CONFERENCE_MAX_USERS() {
      LOGGER.danger("CONFERENCE_MAX_USERS");
    },
    CONNECTION_ERROR() {
      LOGGER.danger("CONNECTION_ERROR");
    },
    FOCUS_DISCONNECTED() {
      LOGGER.danger("FOCUS_DISCONNECTED");
    },
    FOCUS_LEFT() {
      LOGGER.danger("FOCUS_LEFT");
    },
    GRACEFUL_SHUTDOWN() {
      LOGGER.danger("GRACEFUL_SHUTDOWN");
    },
    ICE_FAILED() {
      LOGGER.danger("ICE_FAILED");
    },
    INCOMPATIBLE_SERVER_VERSIONS() {
      LOGGER.danger("INCOMPATIBLE_SERVER_VERSIONS");
    },
    MEMBERS_ONLY_ERROR() {
      LOGGER.danger("MEMBERS_ONLY_ERROR");
    },
    NOT_ALLOWED_ERROR() {
      LOGGER.danger("NOT_ALLOWED_ERROR");
    },
    OFFER_ANSWER_FAILED() {
      LOGGER.danger("OFFER_ANSWER_FAILED");
    },
    PASSWORD_NOT_SUPPORTED() {
      LOGGER.danger("PASSWORD_NOT_SUPPORTED");
    },
    PASSWORD_REQUIRED() {
      LOGGER.danger("PASSWORD_REQUIRED");
    },
    RESERVATION_ERROR() {
      LOGGER.danger("RESERVATION_ERROR");
    },
    VIDEOBRIDGE_NOT_AVAILABLE() {
      LOGGER.danger("VIDEOBRIDGE_NOT_AVAILABLE");
    },
    //------------------

    //--shortcut--
    ctrlShiftF(){
      this.toggleFullScreen()
    },
    ctrlShiftH(){
      this.$store.state.calls.videoCalls[this.listIndex].isHangout = !this.videoCall.isHangout
    },
    ctrlAltH(){
      this.$store.state.calls.videoCalls[this.listIndex].isPlay = !this.videoCall.isPlay
    },
    ctrlShiftC(){
      this.$store.state.calls.videoCalls[this.listIndex].isChat = !this.videoCall.isChat
    },
    ctrlShiftM(){
      this.$store.state.calls.videoCalls[this.listIndex].isMemberOpen = !this.videoCall.isMemberOpen
    },
    //-----
    exitHandler(){
      if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement){
        this.full_screen=false
      }
    },
    HANGUP({ room }){
      if (room!=this.videoCall.room) return;
      this.$store.state.calls.videoCalls[this.listIndex].isHangout = !this.videoCall.isHangout
    },
  },
  created() {
    MOBILE_APP?.on?.(MOBILE_APP_EVENTS.HANGUP,this.HANGUP.bind(this))
    this.$root.$on(events.ctrl_shift_f,this.ctrlShiftF)
    this.$root.$on(events.ctrl_shift_h,this.ctrlShiftH)
    this.$root.$on(events.ctrl_alt_h,this.ctrlAltH)
    this.$root.$on(events.ctrl_shift_c,this.ctrlShiftC)
    this.$root.$on(events.ctrl_shift_m,this.ctrlShiftM)
    document.addEventListener('webkitfullscreenchange', this.exitHandler, false);
    document.addEventListener('mozfullscreenchange', this.exitHandler, false);
    document.addEventListener('fullscreenchange', this.exitHandler, false);
    document.addEventListener('MSFullscreenChange', this.exitHandler, false);
    AMI_SOCKET.on(AMI_EVENTS.REJECTED,this.REJECTED)
    MOBILE_APP?.on?.(AMI_EVENTS.REJECTED,this.REJECTED)
    // centrifugo.calls.on(centrifugo.calls.events.REJECTED,this.REJECTED)
    this.phones = phone;
    this.mic_device_id = this.media_devices_setting.mic_id ?? ''
    this.camera_device_id = this.media_devices_setting.camera_id ?? ''
    this.speaker_device_id = this.media_devices_setting.speaker_id ?? ''
  },
  mounted() {
    this.makeConference();
  },
  beforeDestroy() {
    AMI_SOCKET.off(AMI_EVENTS.REJECTED,this.REJECTED)
    MOBILE_APP?.off?.(AMI_EVENTS.REJECTED,this.REJECTED)
    // centrifugo.calls.off(centrifugo.calls.events.REJECTED,this.REJECTED)
    this.$root.$on(events.ctrl_shift_f,this.ctrlShiftF)
    this.$root.$on(events.ctrl_shift_h,this.ctrlShiftH)
    this.$root.$on(events.ctrl_alt_h,this.ctrlAltH)
    this.$root.$on(events.ctrl_shift_c,this.ctrlShiftC)
    this.$root.$on(events.ctrl_shift_m,this.ctrlShiftM)
    document.removeEventListener('webkitfullscreenchange', this.exitHandler, false);
    document.removeEventListener('mozfullscreenchange', this.exitHandler, false);
    document.removeEventListener('fullscreenchange', this.exitHandler, false);
    document.removeEventListener('MSFullscreenChange', this.exitHandler, false);
    try {
      LOGGER.hook("beforeDestroy Conference");
      const {
        events: { conference: events },
      } = JitsiMeetJS;
      if (this.JitsiConference) {
        this.JitsiConference.off(
          events.AUDIO_INPUT_STATE_CHANGE,
          this.AUDIO_INPUT_STATE_CHANGE
        );
        this.JitsiConference.off(
          events.AUTH_STATUS_CHANGED,
          this.AUTH_STATUS_CHANGED
        );
        this.JitsiConference.off(
          events.BEFORE_STATISTICS_DISPOSED,
          this.BEFORE_STATISTICS_DISPOSED
        );
        this.JitsiConference.off(
          events.BOT_TYPE_CHANGED,
          this.BOT_TYPE_CHANGED
        );
        this.JitsiConference.off(
          events.CONFERENCE_CREATED_TIMESTAMP,
          this.CONFERENCE_CREATED_TIMESTAMP
        );
        this.JitsiConference.off(
          events.CONFERENCE_ERROR,
          this.CONFERENCE_ERROR
        );
        this.JitsiConference.off(
          events.CONFERENCE_FAILED,
          this.CONFERENCE_FAILED
        );
        this.JitsiConference.off(
          events.CONFERENCE_JOINED,
          this.CONFERENCE_JOINED
        );
        this.JitsiConference.off(events.CONFERENCE_LEFT, this.CONFERENCE_LEFT);
        this.JitsiConference.off(
          events.CONNECTION_ESTABLISHED,
          this.CONNECTION_ESTABLISHED
        );
        this.JitsiConference.off(
          events.CONNECTION_INTERRUPTED,
          this.CONNECTION_INTERRUPTED
        );
        this.JitsiConference.off(
          events.CONNECTION_RESTORED,
          this.CONNECTION_RESTORED
        );
        this.JitsiConference.off(
          events.DATA_CHANNEL_OPENED,
          this.DATA_CHANNEL_OPENED
        );
        this.JitsiConference.off(
          events.DISPLAY_NAME_CHANGED,
          this.DISPLAY_NAME_CHANGED
        );
        this.JitsiConference.off(
          events.DOMINANT_SPEAKER_CHANGED,
          this.DOMINANT_SPEAKER_CHANGED
        );
        this.JitsiConference.off(
          events.DTMF_SUPPORT_CHANGED,
          this.DTMF_SUPPORT_CHANGED
        );
        this.JitsiConference.off(
          events.ENDPOINT_MESSAGE_RECEIVED,
          this.ENDPOINT_MESSAGE_RECEIVED
        );
        this.JitsiConference.off(events.JVB121_STATUS, this.JVB121_STATUS);
        this.JitsiConference.off(events.KICKED, this.KICKED);
        this.JitsiConference.off(
          events.LAST_N_ENDPOINTS_CHANGED,
          this.LAST_N_ENDPOINTS_CHANGED
        );
        this.JitsiConference.off(
          events.LOBBY_USER_JOINED,
          this.LOBBY_USER_JOINED
        );
        this.JitsiConference.off(events.LOBBY_USER_LEFT, this.LOBBY_USER_LEFT);
        this.JitsiConference.off(
          events.LOBBY_USER_UPDATED,
          this.LOBBY_USER_UPDATED
        );
        this.JitsiConference.off(
          events.LOCK_STATE_CHANGED,
          this.LOCK_STATE_CHANGED
        );
        this.JitsiConference.off(
          events.MEMBERS_ONLY_CHANGED,
          this.MEMBERS_ONLY_CHANGED
        );
        this.JitsiConference.off(
          events.MESSAGE_RECEIVED,
          this.MESSAGE_RECEIVED
        );
        this.JitsiConference.off(events.NOISY_MIC, this.NOISY_MIC);
        this.JitsiConference.off(events.NO_AUDIO_INPUT, this.NO_AUDIO_INPUT);
        this.JitsiConference.off(events.P2P_STATUS, this.P2P_STATUS);
        this.JitsiConference.off(
          events.PARTCIPANT_FEATURES_CHANGED,
          this.PARTCIPANT_FEATURES_CHANGED
        );
        this.JitsiConference.off(
          events.PARTICIPANT_CONN_STATUS_CHANGED,
          this.PARTICIPANT_CONN_STATUS_CHANGED
        );
        this.JitsiConference.off(
          events.PARTICIPANT_KICKED,
          this.PARTICIPANT_KICKED
        );
        this.JitsiConference.off(
          events.PARTICIPANT_PROPERTY_CHANGED,
          this.PARTICIPANT_PROPERTY_CHANGED
        );
        this.JitsiConference.off(
          events.PHONE_NUMBER_CHANGED,
          this.PHONE_NUMBER_CHANGED
        );
        this.JitsiConference.off(
          events.PRIVATE_MESSAGE_RECEIVED,
          this.PRIVATE_MESSAGE_RECEIVED
        );
        this.JitsiConference.off(
          events.PROPERTIES_CHANGED,
          this.PROPERTIES_CHANGED
        );
        this.JitsiConference.off(
          events.RECORDER_STATE_CHANGED,
          this.RECORDER_STATE_CHANGED
        );
        this.JitsiConference.off(
          events.SERVER_REGION_CHANGED,
          this.SERVER_REGION_CHANGED
        );
        this.JitsiConference.off(events.STARTED_MUTED, this.STARTED_MUTED);
        this.JitsiConference.off(
          events.START_MUTED_POLICY_CHANGED,
          this.START_MUTED_POLICY_CHANGED
        );
        this.JitsiConference.off(events.SUBJECT_CHANGED, this.SUBJECT_CHANGED);
        this.JitsiConference.off(
          events.SUSPEND_DETECTED,
          this.SUSPEND_DETECTED
        );
        this.JitsiConference.off(
          events.TALK_WHILE_MUTED,
          this.TALK_WHILE_MUTED
        );
        this.JitsiConference.off(events.TRACK_ADDED, this.TRACK_ADDED);
        this.JitsiConference.off(
          events.TRACK_AUDIO_LEVEL_CHANGED,
          this.TRACK_AUDIO_LEVEL_CHANGED
        );
        this.JitsiConference.off(
          events.TRACK_MUTE_CHANGED,
          this.TRACK_MUTE_CHANGED
        );
        this.JitsiConference.off(events.TRACK_REMOVED, this.TRACK_REMOVED);
        this.JitsiConference.off(
          events.TRANSCRIPTION_STATUS_CHANGED,
          this.TRANSCRIPTION_STATUS_CHANGED
        );
        this.JitsiConference.off(events.USER_JOINED, this.USER_JOINED);
        this.JitsiConference.off(events.USER_LEFT, this.USER_LEFT);
        this.JitsiConference.off(
          events.USER_ROLE_CHANGED,
          this.USER_ROLE_CHANGED
        );
        this.JitsiConference.off(
          events.USER_STATUS_CHANGED,
          this.USER_STATUS_CHANGED
        );
        this.JitsiConference.off(
          events.VIDEO_SIP_GW_AVAILABILITY_CHANGED,
          this.VIDEO_SIP_GW_AVAILABILITY_CHANGED
        );
        this.JitsiConference.off(
          events.VIDEO_SIP_GW_SESSION_STATE_CHANGED,
          this.VIDEO_SIP_GW_SESSION_STATE_CHANGED
        );
        this.JitsiConference.off(
          events._MEDIA_SESSION_ACTIVE_CHANGED,
          this._MEDIA_SESSION_ACTIVE_CHANGED
        );
        this.JitsiConference.off(
          events._MEDIA_SESSION_STARTED,
          this._MEDIA_SESSION_STARTED
        );
        this.JitsiConference.removeCommandListener(commands.CHANNEL_ID_CHANGED,this.CHANNEL_ID_CHANGED);
        this.JitsiConference.removeCommandListener(commands.CHANNEL_DATA,this.CHANNEL_DATA);
        this.JitsiConference.removeCommandListener(commands.EMOJI,this.EMOJI);
        this.$el.parentNode.removeChild(this.$el);
      }
    } catch (error) {
      LOGGER.danger("error", error);
    }
    this.localTracks.map((track) => {
      track.dispose();
    });
  },
  destroyed() {
    LOGGER.hook("destroyed Conference");
    this.$emit("onDestroyed");
  },
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}

.conference-container {
  height: inherit;
  width: 100%;
  position: relative;
  overflow: hidden;
  .actions-info-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    color: var(--white);

    div {
      z-index: 2;
    }

    .actions {
      display: flex;

      div {
        border: currentColor solid 1px;
        padding: 3px;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px;
        cursor: pointer;
        &.volume {
          border: 0;
          border-radius: 5%;
          padding: 20px;
          background-color: var(--white);
          width: 250px;
          position: absolute;
          top: 45px;
          left: 0px;
          z-index: 1111;
          input {
            width: 100%;
          }
        }
      }

      .hangout {
        background-color: var(--danger);
        color: var(--white);
        border: 0;
      }
    }

    .info {
      display: flex;
      justify-content: space-between;

      .call {
        h6 {
          font-size: 32px;
          font-weight: 100;
          color: #a3a3a3;
        }

        .time {
          display: flex;
          align-items: center;
          color: #a3a3a3;

          .call {
            h6 {
              font-size: 26px;
              font-weight: 100;
              color: #a3a3a3;
            }

            .time {
              display: flex;
              align-items: center;
              color: #a3a3a3;

              p {
                margin-right: 15px;
              }
            }
          }
        }
      }
    }

    div.toggle-display-video-content {
      height: 100%;
      width: 100%;
      z-index: inherit;
      position: absolute;
    }
  }
  .activeCall-videoArea {
    .video-container {
      position: relative;
      background-color: transparent;
      display: flex;
      div.alert-error {
        position: absolute;
        background-color: var(--danger);
        right: 10%;
        left: 10%;
        padding: 15px;
        z-index: 2;

        svg {
          position: absolute;
          right: 5px;
          height: 30px;
          transform: rotate(45deg);
          width: 30px;
          cursor: pointer;
        }
      }

      div.screen-actions {
        position: absolute;
        top: 15px;
        left: 15px;
        display: flex;
        z-index: 1;

        div {
          color: var(--white);
          margin: 5px;
          cursor: pointer;
        }
      }
      div.image-container {
        position: absolute;
        height: 50px;
        width: 50px;
        overflow: hidden;
        bottom: 5px;
        right: 5px;
        z-index: 1;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}

.dialer-user-dp-box {
  padding: 0% 10%;

  .dialer-user-dp {
    &:first-child {
      margin-right: -12px;
      z-index: 22;
    }
  }
}
.resizable-content {
  height: 100%;
  width: 100%;
}
</style>
