// import { $fn, files } from "./utils";
import { mapGetters } from "vuex";
// import { centrifugo } from "./Centrifuge/index";
import { files, LOGGER, VOIP_API } from "./utils/index";
import { number_formater } from "./filter";
import { GET_LOCAL_SETTING_IS_DARK_MODE } from "./store/helper/getters";
import { AMI_EVENTS } from "./ami/events";
// import _ from "lodash";
// import { TokenService } from "./services/storage.service";
// import store from "./store";

export const permission_handling = {
  methods: {
    checkPermission(permission,cb,payload){
      // console.log(permission)
      if(!permission){
        this.$appConfirmation({
          title: 'Permission',
          message: `Your package does not support this feature`,
          button: {
            yes: 'OK',
          },
          ok: true,
        })
      } else {
        cb?.(payload)
      }
    },
  },
}

export const ctkDateTimePicker = {
  computed: {
    ...mapGetters([
      GET_LOCAL_SETTING_IS_DARK_MODE
    ]),
    ctkDateTimePickerOptions(){
      return {
        dark: this.GET_LOCAL_SETTING_IS_DARK_MODE,
        colored: this.$store.getters.getIscolored,
        colored2: this.$store.getters.getIscolored2,
        colored3: this.$store.getters.getIscolored3,
        color: '#5576d1',
      }
    },
  },
}
export const findService = {
  computed: {
    findedService(){
      const key = this.serviceKey
      if(!key) return null;
      const user = this.$store.getters.getVoipUsersAlises[key];
      const team = this.$store.getters.getVoipTeams.find(team=>key==team?.ringroup?.accountcode || key==team?.real_id || key==team?.ringroup?.extn)
      const call_queue = this.$store.getters.getVoipCallQueues.find(call_queue=>key==call_queue?.voipaccount || key==call_queue?.extn)
      if(user){
        return {
          data: user,
          type: 'user'
        }
      } else if(team){
        return {
          data: team,
          type: 'team'
        }
      } else if(call_queue){
        return {
          data: call_queue,
          type: 'usercall_queue'
        }
      }
      return null;
    },
  },
}


export const common = {
  methods: {
    // getProperty(object, attribute, defaultVal = "") { return _.get(object, attribute) ?? defaultVal; }, //done
    // isNull(value) { return _.isNull(value); }, //done
    // isEmpty(value) { return _.isEmpty(value); }, //done
  },
};
export const dragging = {
  data() {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
      elementId: "",
    };
  },
  methods: {
    dragMouseDown: function(event, id) {
      event.preventDefault();
      this.elementId = id;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag: function(event) {
      event.preventDefault();
      const elmnt = document.getElementById(this.elementId);
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      elmnt.style.top = elmnt.offsetTop - this.positions.movementY + "px";
      elmnt.style.left = elmnt.offsetLeft - this.positions.movementX + "px";
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
  },
};
export const check_service = {
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'addServiceQueue',
    ])
  },
  methods: {
    async checkServicePromise(props={}){
      return new Promise((resolve, reject)=>{
        this.checkService({
          ...props,
          then(payload){
            resolve(payload)
          },
          catch(ex){
            reject(ex)
          },
          finally(){
            resolve({
              finally: true,
            })
          },
        })
      })
    },
    async checkService(props={}){
      let vm = this;
      const service = props?.service ?? '';
      const number_category = props?.number_category ?? '';
      const number_type = props?.number_type ?? '';
      const product_id = props?.product_id ?? '';
      const account = props?.account ?? '';
      const thenFun = props?.then
      const catchFun = props?.catch
      const finallyFun = props?.finally
      const no_confirmation = props?.no_confirmation
      const only_check = props?.only_check
      const service_text_obj = {
        queue: 'Call Queue',
        aql: 'Number',
        team: 'Team',
        user: 'User',
        call_prefix: 'Call Prefix',
        call_prefix_2: 'Call Prefix',
        unlimited_call_plan: 'Unlimited Call Plan',
        ivrs: 'IVRS'
      }
      const queue_type = {
        ivr: 'IVRS',
        team: 'Team',
        user: 'User',
        call_queue: 'Call Queue',
        number: 'Number'
      }
      const service_text = service_text_obj[service] ?? service
      async function callBack(payload){
        try {
          if(payload?.fetch_plan) {
            const { data } = await VOIP_API.endpoints.billing.getsubscriptiondetail({ 
              uid: this.getCurrentUser?.uid,
            })
            const is_free = data?.userCurrentSubscription?.plan_detail?.plan_free==1
            await thenFun?.({
              ...payload,
              free_plan: is_free
            })
          } else {
            await thenFun?.(payload)
          }
        } catch(ex){
          catchFun?.(ex)
        } finally {
          finallyFun?.()
        }
      }
      try {
        let response;
        if(service=='unlimited_call_plan'){
          const { data: { data: cards } } = await VOIP_API.endpoints.billing_api.getcreditcards({ 
            uid: vm.getCurrentUser?.uid 
          })
          const card = await cards?.find?.(item=>item.primary=='yes' || item.secondary=='yes') 
          if(card){
            const { data } = await VOIP_API.endpoints.billing_api.checkPriceOfUnlimitedCallPlan({ 
              uid: vm.getCurrentUser?.uid,
              accountcode: account,
            })
            const prorota_amount = data?.unlimited_call_plan?.prorota_amount
            const price = `${parseFloat((data?.unlimited_call_plan?.price || 0)).toFixed(2)}`
            const prorota_price = `${parseFloat((prorota_amount || 0)).toFixed(2)}`
            const plan_billing_period = data?.unlimited_call_plan?.billing_period=='monthly' ? 'month' : data?.unlimited_call_plan?.billing_period || ''
            const is_not_show_price = data?.is_show_price==0
            if(is_not_show_price) {
              callBack()
            } else {
              if(no_confirmation) {
                callBack({
                  type: 'ADDON',
                  message: `Are you sure you want to switch to unlimited call`,
                  service_text: service_text,
                })
              } else {
                const { confirm } = await this.$appConfirmation({
                  title: "Attention",
                  message: `Are you sure you want to upgrade to ${service_text}?`,
                  alert: {
                    variant: 'info',
                    title: 'Note',
                    message: `Prorota amount for current ${plan_billing_period} is ${prorota_price}. Price for next ${plan_billing_period} is ${price}.`,
                  },
                  button: {
                    no: "No",
                    yes: "Yes",
                  },
                });
                if(confirm) {
                  callBack()
                } else {
                  finallyFun?.()
                }
              }
            }
          } else {
            if(no_confirmation) {
              callBack({
                type: 'NOCARD',
                message: `Add credit card to proceed`,
                service_text: service_text,
              })
            } else {
              vm.$appConfirmation({
                title: 'Warning',
                message: `Add credit card to proceed`,
                button: {
                  yes: 'OK',
                },
                ok: true,
                callback: () => {
                  finallyFun?.()
                },
              })
            }
          }
        } else if(service!='call_prefix' && this.addServiceQueue){
          const queue_text = queue_type[this.addServiceQueue.type] ?? this.addServiceQueue.type
          if(no_confirmation) {
            callBack({
              type: 'IN_QUEUE',
              message: `You already in queue for added ${queue_text}`,
              queue_text: queue_text,
            })
          } else {
            vm.$appConfirmation({
              title: 'Attention',
              message: `You already in queue for added ${queue_text}`,
              button: {
                yes: 'OK',
              },
              ok: true,
              callback: () => {
                finallyFun?.()
              },
            })
          }
        } else {
          if(service=='call_prefix'){
            const { data } = await VOIP_API.endpoints.techprifex.update(product_id,{
              id: product_id,
              prefix: product_id,
              useraccount: vm.getCurrentUser?.account,
              accountcode: vm.getCurrentUser?.account,
              account: vm.getCurrentUser?.account,
              uid: vm.getCurrentUser?.uid,
              check_service: true,
            })
            response=data
          } else if(service=='call_prefix_2'){
            const { data } = await VOIP_API.endpoints.techprifex.update(account,{
              accountcode: account,
              account: account,
              check_service: true,
            })
            response=data
          } else if(service=='aql') {
            const { data } = await VOIP_API.endpoints.billing.checkservice({
              uid: vm.getCurrentUser.uid,
              accountcode: vm.getCurrentUser.account,
              service: service,
              number_category: number_category,
              email: vm.getCurrentUser.email,
            })
            response=data
          } else {
            const { data } = await VOIP_API.endpoints.billing.checkservice({
              uid: vm.getCurrentUser.uid,
              accountcode: vm.getCurrentUser.account,
              service: service,
              email: vm.getCurrentUser.email,
            })
            response=data
          }
          const slot = response?.slots ?? {}
          const product_detail = response?.product_detail
          const prorota_amount = response?.prorota_amount
          const price = `${parseFloat((response?.price || 0)).toFixed(2)}` /*${product_detail?.plan?.plan_cost_currency || ''}*/
          const prorota_price = `${parseFloat((prorota_amount || 0)).toFixed(2)}`  /*${product_detail?.plan?.plan_cost_currency || ''}*/
          const plan_billing_period = product_detail?.plan?.plan_billing_period ?? ''
          const slot_available = slot.PlanRemainingProductCount>0 || slot.PlanAddonRemainingProductCount>0
          const is_addon = slot.PlanRemainingProductCount<=0 && slot.PlanAddonRemainingProductCount>0
          const is_user_free_number = response?.is_user_free_number==1
          // const is_trail_plan = response?.plan_free==0 && price=='0.00' && prorota_price=='0.00' 
          const is_trail_plan = response?.is_trial==1 
          if(service=='call_prefix'){
            let text = `You are going to purchase ${service_text} on addon. Prorota amount for current ${plan_billing_period} is ${prorota_price}. Price for next ${plan_billing_period} is ${price}`
            if(no_confirmation) {
              callBack({
                type: 'ADDON',
                message: text,
                service_text: service_text,
                plan_billing_period: plan_billing_period,
                prorota_price: prorota_price,
                price: price,
              })
            } else {
              vm.$appConfirmation({
                title: "Attention",
                message: text,
                button: {
                  no: "No",
                  yes: "Yes",
                },
                callback: ({confirm}) => {
                  if (confirm) {
                    callBack()
                  } else {
                    finallyFun?.()
                  }
                },
              });
            }
          } else {
            if(!slot_available){
              if(no_confirmation) {
                callBack({
                  type: 'NO_SLOT',
                  // message: `You don't have the slot to add ${service_text}`,
                  message: `You have utilized all the included ${service_text} from your current plan.Consider upgrading your plan to add more.`,
                  service_text: service_text,
                })
              } else {
                vm.$appConfirmation({
                  title: 'Attention',
                  message: `You have utilized all the included ${service_text} from your current plan.Consider upgrading your plan to add more.`,
                  button: {
                    yes: 'OK',
                  },
                  ok: true,
                  callback: () => {
                    finallyFun?.()
                  },
                })
              }
            } else if(is_addon) {
              const { data: { data: cards } } = await VOIP_API.endpoints.billing_api.getcreditcards({ 
                uid: vm.getCurrentUser?.uid 
              })
              const card = await cards?.find?.(item=>item.primary=='yes' || item.secondary=='yes') 
              if(card){
                if(only_check) {
                  callBack()
                } else {
                  // let text = `You are going to purchase ${service_text} on addon. Prorota amount for current ${plan_billing_period} is ${prorota_price}. Price for next ${plan_billing_period} is ${price}`
                  let text = `You are going to purchase ${service_text} \n on addon. Prorota amount for current ${plan_billing_period} is ${prorota_price}. Price for next ${plan_billing_period} is ${price}`
                  if(service_text=='User' && is_trail_plan) {
                    // text = `You are going to purchase user on trial plan`
                    text = `You are going to add a user on trial plan. This user will be charged £${prorota_price} once the trial is over. Do you wish to continue?`
                  }
                  // console.log(is_user_free_number)
                  if(service_text=='Number' && is_user_free_number && number_type!='ukm') {
                    text = `This is a Free Number`
                  }
                  if(no_confirmation) {
                    callBack({
                      type: 'ADDON',
                      message: text,
                      service_text: service_text,
                      plan_billing_period: plan_billing_period,
                      prorota_price: prorota_price,
                      price: price,
                    })
                  } else {
                    vm.$appConfirmation({
                      title: "Attention",
                      message: text,
                      button: {
                        no: "No",
                        yes: "Yes",
                      },
                      callback: ({confirm}) => {
                        if (confirm) {
                          callBack()
                        } else {
                          finallyFun?.()
                        }
                      },
                    });
                  }
                }
              } else {
                if(no_confirmation) {
                  callBack({
                    type: 'NOCARD',
                    message: `You don't have a credit card to purchase ${service_text} in addon. Please add credit card first.`,
                    service_text: service_text,
                    fetch_plan: true,
                  })
                } else {
                  vm.$appConfirmation({
                    title: 'Warning',
                    message: `You don't have a credit card to purchase ${service_text} in addon. Please add credit card first.`,
                    button: {
                      yes: 'OK',
                    },
                    ok: true,
                    callback: () => {
                      finallyFun?.()
                    },
                  })
                }
              }
            } else if(slot_available) {
              callBack?.()
            } else {
              throw new Error('another')
            }
          }
        } 
      } catch(ex) {
        LOGGER.danger(ex)
        catchFun?.(ex)
        finallyFun?.()
      }
    },
  },
}
export const vue_resizeable_controller = {
  data() {
    return {
      // tW: 240,
      // tH: 140,
      // handlers: ["r", "rb", "b", "lb", "l", "lt", "t", "rt"],
      // maxW: 350,
      // maxH: 350,
      // minW: 100,
      // minH: 100,
      // values updated by frank start
      tW: 284,
      tH: 200,
      handlers: ["r", "rb", "b", "lb", "l", "lt", "t", "rt"],
      maxW: 484,
      maxH: 400,
      minW: 184,
      minH: 104,
      // values updated by frank end
      fit: true,
      event: "",
      dragSelector: "",
      currentWidth: "",
      currentHeight: "",
      currentTop: "",
      currentLeft: "",
    };
  },
  computed: {
    left: {
      get() {
        return this.currentLeft
          ? this.currentLeft
          : `calc( 50% - ${this.tW / 2}px)`;
      },
      set(newVal) {
        this.currentLeft = newVal;
      },
    },
    top: {
      get() {
        return this.currentTop
          ? this.currentTop
          : `calc(50% - ${this.tH / 2}px)`;
      },
      set(newVal) {
        this.currentTop = newVal;
      },
    },
    width: {
      get() {
        return this.currentWidth ? this.currentWidth : this.tW;
      },
      set(newVal) {
        this.currentWidth = newVal;
      },
    },
    height: {
      get() {
        return this.currentHeight ? this.currentHeight : this.tH;
      },
      set(newVal) {
        this.currentHeight = newVal;
      },
    },
  },
  methods: {
    eHandler(data) {
      this.width = data.width;
      this.height = data.height;
      this.left = data.left;
      this.top = data.top;
      this.event = data.eventName;
    },
  },
  filters: {
    checkEmpty(value) {
      return typeof value !== "number" ? 0 : value;
    },
  },
};
export const local_files = {
  data() {
    return {
      local_files: files,
    }
  },
  computed: {
    userDPMale(){
      return this.local_files.user_male
    },
  },
};
export const call_info = {
  data(){
    return {
      call_obj_123: null,
    }
  },
  computed: {
    ...mapGetters([
      'getVoipTeamsAlises',
      'getVoipUsersAlises',
      'getVoipCallQueuesAlises',
      'getVoipIVRSAlises',
      'getCurrentUser',
      'getAddressbooksAlaisesNumbers',
    ]),
    callObjInfo(){
      const call = this.callObj || this.call_obj_123
      const is_sip = call?.is_sip
      const info = {
        from_name: '',
        from_number: '',
        from_other: '',
        from_team: '',
        to_name: '',
        to_number: '',
        to_other: '',
        image: '',
        name: '',
        sub_info: '',
        accountcode: '',
        call_type: '',
        active: false,
        incoming: false,
        outgoing: false,
        hold: false,
        test_call: false,
        incall: false,
        is_transfer: false,
        uuid: '',
        caller_id: '',
        cids: {
          from: {
            caller_id: '',
            label: '',
          },
          to: {
            caller_id: '',
            label: '',
          },
        },
        is_current_call: false,
        is_mute: false,
        call_transfered: false,
        call_id: '',
        note: '',
        tags: '',
        is_internal_call: false,
      }
      if(call) {
        if(is_sip){ // sip
          const number = call?.number;
          const from_user = call?.incomingInviteRequest?.message?.from?.uri?.normal?.user
          const callState = call?.callState
          const callStatus = call?.callStatus
          const extra_data = call?.extra_data ?? {}
          const direction = call.direction
          const callee_id = extra_data?.callee_id
          const caller_id = extra_data?.caller_id || ''
          const from_caller_id = extra_data?.from_caller_id || ''
          const from_caller_id_label = extra_data?.from_caller_id_label || ''
          const to_caller_id = extra_data?.to_caller_id || ''
          const to_caller_id_label = extra_data?.to_caller_id_label || ''
          const callee_type = extra_data?.type
          const call_type = extra_data?.call_type ?? ''
          const is_transfer = !!extra_data?.is_transfer
          const local_speed_dials = this.$store.state.common.speed_dials.local.data
          const global_speed_dials = this.$store.state.common.speed_dials.global.data
          let speedDial, globalSpeedDial, user, addressbook, team, call_queue, ivr;
          info.call_type=['listen', 'whisper', 'barge'].includes(call_type) ? call_type : ''
          info.active = callState === 'incall' && callStatus != 'OnHold'
          info.incoming = direction != 'out'
          info.outgoing = direction == 'out'
          info.hold = !!call.held
          info.test_call = number=='*154*'
          info.incall = callState === 'incall'
          info.is_transfer = is_transfer
          info.uuid = call?.uuid ?? ''
          info.caller_id=caller_id
          info.cids.from.caller_id=from_caller_id
          info.cids.from.label=from_caller_id_label
          info.cids.to.caller_id=to_caller_id
          info.cids.to.label=to_caller_id_label
          info.is_current_call=info.uuid==this.$store.state.sip.phone?.activeSession?.uuid
          info.is_mute=call?.mute ?? false
          info.call_transfered=call?.transfercalls?.length > 0
          info.call_id=extra_data?.id??''
          info.note=call?.note??''
          info.tags=call?.tags??''
          const additionalDataArray = call.additionalData?.split?.("~") ?? []
          const addtional_info = {
            CALEE_EXTENSION_TYPE: (additionalDataArray[0]!='NA' && additionalDataArray[0]) || '',
            INFO_DID: (additionalDataArray[1]!='NA' && additionalDataArray[1]) || '',
            INFO_EXT_TYPE: (additionalDataArray[2]!='NA' && additionalDataArray[2]) || '',
            INFO_SPEED_DIAL: (additionalDataArray[3]!='NA' && additionalDataArray[3]) || '',
            INFO_ADDRESSBOOK: (additionalDataArray[4]!='NA' && additionalDataArray[4]) || '',
            INFO_DID_NAME: (additionalDataArray[5]!='NA' && additionalDataArray[5]) || '',
            INFO_FORWARD: (additionalDataArray[6]!='NA' && additionalDataArray[6]) || '',
            INFO_FORWARD_ACCOUNT: (additionalDataArray[7]!='NA' && additionalDataArray[7]) || '',
            TEAM_NAME: (additionalDataArray[8]!='NA' && additionalDataArray[8]) || '',
          }
          // get info
          if(callee_id && callee_type){
            if(callee_type=='speed_dial'){
              speedDial = local_speed_dials.find((i)=>callee_id==i.real_id);
            } else if(callee_type=='global_speed_dial'){
              globalSpeedDial = global_speed_dials.find((i)=>callee_id==i.real_id);
            } else if(callee_type=='user'){
              user = this.getVoipUsersAlises[callee_id];
            } else if(callee_type=='address_book'){
              addressbook = this.getAddressbooksAlaisesNumbers[callee_id]
            } else if(callee_type=='team'){
              team = this.getVoipTeamsAlises[callee_id]
            } else if(callee_type=='call_queue'){
              call_queue = this.getVoipCallQueuesAlises[callee_id]
            } else if(callee_type=='ivr'){
              ivr = this.getVoipIVRSAlises[callee_id]
            } 
          } else if(number) { 
            speedDial = local_speed_dials.find((i)=>i.fullNumber==number);
            globalSpeedDial = global_speed_dials.find((i)=>i.fullNumber==number);
            user = this.getVoipUsersAlises[number];
            addressbook = this.getAddressbooksAlaisesNumbers[number_formater(number,'',true)]
            team = this.getVoipTeamsAlises[number];
            call_queue = this.getVoipCallQueuesAlises[number]
            ivr = this.getVoipIVRSAlises[number]
          } 
          if(!globalSpeedDial && !speedDial && !addressbook && !team && !call_queue && !ivr && !user && from_user) {
            speedDial = local_speed_dials.find((i)=>i.fullNumber==from_user);
            globalSpeedDial = global_speed_dials.find((i)=>i.fullNumber==from_user);
            user = this.getVoipUsersAlises[from_user];
            addressbook = this.getAddressbooksAlaisesNumbers[from_user]
            team = this.getVoipTeamsAlises[from_user];
            call_queue = this.getVoipCallQueuesAlises[from_user]
            ivr = this.getVoipIVRSAlises[from_user]
          }
          
          let cids = {
            caller: {
              caller_id: '',
              label: '',
            },
            callee: {
              caller_id: '',
              label: '',
            },
          }
          if(addtional_info.INFO_DID && addtional_info.INFO_DID_NAME){
            cids.callee.caller_id=addtional_info.INFO_DID
            cids.callee.label=addtional_info.INFO_DID_NAME
          }
          if(caller_id) {
            cids.caller.caller_id=caller_id
          }
          let other_info = {
            image: '',
            name: '',
            number: '',
            accountcode: '',
          }
          if(globalSpeedDial){
            other_info.image=globalSpeedDial?.avatar?.image?.image
            other_info.name=globalSpeedDial?.name
          } else if(speedDial){
            other_info.image=speedDial?.avatar?.image?.image
            other_info.name=speedDial?.name
          } else if(addressbook){
            other_info.image=addressbook?.profile_image
            other_info.name=addressbook?.name
            other_info.number=addressbook?.phone?.formated_number
          } else if(team){
            other_info.image=team?.avatar?.image?.image
            other_info.name=team?.name
            other_info.number=team?.ringroup?.extn
          } else if(call_queue){
            other_info.image=call_queue?.avatar?.image?.image
            other_info.name=call_queue?.label
            other_info.number=call_queue?.extn
          } else if(ivr){
            other_info.image=ivr?.avatar?.image?.image
            other_info.name=ivr?.label
            other_info.number=ivr?.extn
          } else if(user){
            info.is_internal_call=true
            other_info.image=user?.profile_img
            other_info.name=user?.user?.display_name
            other_info.number=user?.extn
            other_info.accountcode=user?.voipaccount
          }
          if(!other_info.number){
            other_info.number = (number=='mobile extension' ? other_info.number : number || other_info.number) || ''
          }
          if(addtional_info.CALEE_EXTENSION_TYPE=='RINGGROUP' && addtional_info.TEAM_NAME){
            info.to_other=addtional_info.TEAM_NAME
          }
          // if(addtional_info.INFO_ADDRESSBOOK){
          //   info.to_other=addtional_info.INFO_ADDRESSBOOK
          // }
          if(direction == 'out'){ // outgoing
            info.from_name = this.getCurrentUser?.display_name
            info.from_number = this.getCurrentUser?.extn
            info.to_name = other_info.name || ''
            info.to_number = other_info.number
            info.image = other_info.image || ''
            info.name=other_info.name || ''
            info.sub_info=other_info.number
            info.accountcode=other_info.accountcode || ''
            info.cids.from.caller_id=cids.caller.caller_id
            info.cids.from.label=cids.caller.label
            info.cids.to.caller_id=cids.callee.caller_id
            info.cids.to.label=cids.callee.label
          } else { // incoming
            info.from_name = other_info.name || ''
            info.from_number = other_info.number
            info.to_name = this.getCurrentUser?.display_name || ''
            info.to_number = this.getCurrentUser?.extn || ''
            info.image = other_info.image || ''
            info.name=other_info.name || ''
            info.sub_info=other_info.number
            info.accountcode=other_info.accountcode || ''
            info.cids.from.caller_id=cids.caller.caller_id
            info.cids.from.label=cids.caller.label
            info.cids.to.caller_id=cids.callee.caller_id
            info.cids.to.label=cids.callee.label
          }
        } else { // jitsi
          let team, user, group, default_display_name
          if(call.is_incoming){ // incoming
            const id = call?.data?.extra_data?.id
            const caller = call?.data?.caller
            default_display_name = call?.data?.extra_data?.display_name
            team = this.getVoipTeamsAlises[id]
            user = this.$store.getters.getVoipUsersAlises[caller]
            group = this.$store.state.mattermost.channels[id]
          } else if(call?.type==AMI_EVENTS.INVITE){ // incoming
            const id = call?.extra_data?.id
            const caller = call?.caller
            default_display_name =this.data?.extra_data?.display_name
            team = this.getVoipTeamsAlises[id]
            user = this.$store.getters.getVoipUsersAlises[caller]
            group = this.$store.state.mattermost.channels[id]
          } else { // running
            const data = call?.data
            const channel_id = call?.channel_id
            default_display_name = call?.name
            const id = data?.extra_data?.id
            const caller = data?.caller
            team = this.getVoipTeamsAlises[channel_id] || this.getVoipTeamsAlises[id]
            user = this.getVoipUsersAlises[channel_id] || this.getVoipUsersAlises[id]
            group = this.$store.state.mattermost.channels[channel_id] || this.$store.state.mattermost.channels[id]
            if(caller!==this.getCurrentUser.account){
              user = this.getVoipUsersAlises[caller]
            }
          }
          if(team){
            info.image=team?.avatar?.image?.image
            info.name=team?.name
            info.sub_info=team?.ringroup?.extn
          } else if(group){
            const group_data_id = group?.id
            if(group.type=='channel'){
              info.image=require("@/assets/images/channel-image.svg")
              info.name=group?.channel?.display_name
            } else if(group.type=='user'){
              const group_user = this.getVoipUsersAlises[group_data_id]
              info.image=group_user?.profile_img
              info.name=group_user?.user?.display_name
              info.sub_info=group_user?.extn
            } else if(group.type=='team'){
              const group_team = this.getVoipTeamsAlises[group_data_id]
              info.image=group_team?.avatar?.image?.image
              info.name=group_team?.name
              info.sub_info=group_team?.ringroup?.extn
            }
          } else if(user){
            info.image=user?.profile_img
            info.name=user?.user?.display_name
            info.sub_info=user?.extn
          }
          if(!info.name){
            info.name=default_display_name
          }
        }
      }
      return info
    },
  },
  methods: {
    getCallInfo(call){
      let vm = this
      return new Promise((resolve)=>{
        vm.call_obj_123=call
        vm.$nextTick()
        .then(()=>{
          resolve(vm.callObjInfo)
          vm.call_obj_123=null
        })
      })
    },
  },
}