import { TokenService } from "../../services/storage.service";
import Axios from 'axios';
// import { $fn } from './functions'
import { API_LOGGER } from '../console'
import _ from 'lodash';
import { URLS } from "../urls";
import { $fn } from "../functions";
import store from "@/store/index";
import { GET_CRM_API_URL, GET_CRM_TOKEN, GET_PAMARLY_TOKEN } from "@/store/helper/getters";
import { API_ENDPOINTS } from "./endpoints/index";
import { CRM_REFRESH_TOKEN } from "@/store/helper/actions";
import { ADD_API_ERROR } from "@/store/helper/mutations";
import { MOBILE_APP } from "@/mobile-app/index";
import { MOBILE_APP_SEND_EVENTS } from "@/mobile-app/events";

const timeout = 1 * 60 * 1000
export const VOIP_API = {
  axios: {
    voip: Axios.create({
      name: 'voip',
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: timeout
    }),
    voip_relay_proxy: Axios.create({
      name: 'relay-proxy',
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: timeout
    }),
    voip_relay: Axios.create({
      name: 'relay',
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: timeout
    }),
    voip_billing: Axios.create({
      name: 'relay',
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: timeout
    }),
    voip_centeral_point: {
      v1: Axios.create({
        name: 'centeralpoint-detect',
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: timeout,
        version: 1,
      }),
      v2: Axios.create({
        name: 'centeralpoint-detect',
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: timeout,
        version: 2,
      }),
    },
    voip_error_capture: {
      v1: Axios.create({
        name: 'error-capture',
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: timeout,
        version: 1,
      }),
    },
    pamarly: Axios.create({
      name: 'pamarly',
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: timeout
    }),
    crm: Axios.create({
      name: 'crm',
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: timeout
    }),
    git: Axios.create({
      name: 'git-repo',
      headers: {
        'accept': 'application/vnd.github.v3+json',
      },
      timeout: timeout
    }),
    address: Axios.create({
      name: 'get-address',
      timeout: timeout
    }),
    rest_countries: Axios.create({
      name: 'rest-countries',
      timeout: timeout
    }),
    ami_server: Axios.create({
      name: 'ami-server',
      timeout: timeout
    }),
  },
  region: '',
  platform: '',
  cp_url: '',
  oncpurlupdate: null,
  set CPUrl(data){
    this.cp_url=data?.centeralpoint
    if(data?.domain) TokenService.CP_DOMAIN.set(data?.domain)
    if(data?.region && !this.region) this.region = data?.region
    if(data?.platform && !this.platform) this.platform = data?.platform
    this.oncpurlupdate?.()
  },
  get errorMessages(){
    return {
      default: `There seems to be a issue try again later`,
      timeout: `It's taking longer than usual to connect to our servers. Please check your internet connection and try again.`,
      network: `Unable to establish a connection to our servers. Please ensure you're connected to the internet and try again.`,
      server_down: `Our servers are currently unavailable. We apologise for the inconvenience. Please try again later.`,
    }
  },
  setRegionPlatform({ region, platform }){
    TokenService.REGION_PLATFORM.set({
      region,
      platform
    })
    // this.region=region
    // this.platform=platform
  },
  getRegionPlatform(){
    const REGION_PLATFORM = TokenService.REGION_PLATFORM.get()
    return {
      region: REGION_PLATFORM?.region ?? this.region ?? '',
      platform: REGION_PLATFORM?.platform ?? this.platform ?? '',
    }
  },
  init(){
    try {
      this.axios.voip_error_capture.v1.interceptors.request.use(this.onRequest.bind(this))
      this.axios.voip_error_capture.v1.interceptors.response.use(this.onSuccess.bind(this),this.onError.bind(this))
      
      this.axios.voip_centeral_point.v1.interceptors.request.use(this.onRequest.bind(this))
      this.axios.voip_centeral_point.v1.interceptors.response.use(this.onSuccess.bind(this),this.onError.bind(this))
      
      this.axios.voip_centeral_point.v2.interceptors.request.use(this.onRequest.bind(this))
      this.axios.voip_centeral_point.v2.interceptors.response.use(this.onSuccess.bind(this),this.onError.bind(this))
      
      this.axios.voip.interceptors.request.use(this.onRequest.bind(this))
      this.axios.voip.interceptors.response.use(this.onSuccess.bind(this),this.onError.bind(this))
      
      this.axios.voip_relay_proxy.interceptors.request.use(this.onRequest.bind(this))
      this.axios.voip_relay_proxy.interceptors.response.use(this.onSuccess.bind(this),this.onError.bind(this))
      
      this.axios.voip_relay.interceptors.request.use(this.onRequest.bind(this))
      this.axios.voip_relay.interceptors.response.use(this.onSuccess.bind(this),this.onError.bind(this))
      
      this.axios.voip_billing.interceptors.request.use(this.onRequest.bind(this))
      this.axios.voip_billing.interceptors.response.use(this.onSuccess.bind(this),this.onError.bind(this))
      
      this.axios.git.interceptors.request.use(this.onRequest.bind(this))
      // this.axios.git.interceptors.response.use(this.onSuccess.bind(this),this.onError.bind(this))
      
      this.axios.address.interceptors.request.use(this.onRequest.bind(this))
      // this.axios.address.interceptors.response.use(this.onSuccess.bind(this),this.onError.bind(this))
      
      this.axios.rest_countries.interceptors.request.use(this.onRequest.bind(this))
      // this.axios.rest_countries.interceptors.response.use(this.onSuccess.bind(this),this.onError.bind(this))
      
      this.axios.pamarly.interceptors.request.use(this.onRequest.bind(this))
      this.axios.pamarly.interceptors.response.use(this.onSuccess.bind(this),this.onError.bind(this))
      
      this.axios.crm.interceptors.request.use(this.onRequest.bind(this))
      this.axios.crm.interceptors.response.use(this.onSuccess.bind(this),this.onError.bind(this))
      
      this.axios.ami_server.interceptors.request.use(this.onRequest.bind(this))
      this.axios.ami_server.interceptors.response.use(this.onSuccess.bind(this),this.onError.bind(this))

      const REGION_PLATFORM = TokenService.REGION_PLATFORM.get()
      if(REGION_PLATFORM?.region) this.region = REGION_PLATFORM.region
      if(REGION_PLATFORM?.platform) this.platform = REGION_PLATFORM.platform
    } catch(ex) {
      API_LOGGER.danger(ex)
    } finally {
      window.VOIP_API=this
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  is_refresh_token: false,
  async getRefreshToken(){
    if(this.is_refresh_token){
      // TODO - in case of refresh token api send
      await $fn.sleep('5s')
      if(this.is_refresh_token){
        await $fn.sleep('5s')
        if(this.is_refresh_token){
          await $fn.sleep('5s')
          if(this.is_refresh_token){
            await $fn.sleep('5s')
            if(this.is_refresh_token){
              await $fn.sleep('5s')
              if(this.is_refresh_token){
                await $fn.sleep('5s')
                if(this.is_refresh_token){
                  await $fn.sleep('5s')
                  return;
                } else {
                  return;
                }
              } else {
                return;
              }
            } else {
              return;
            }
          } else {
            return;
          }
        } else {
          return;
        }
      } else {
        return;
      }
    } else {
      this.is_refresh_token=true
      const refresh_token = TokenService.REFRESH_TOKEN.get()
      const { data } = await VOIP_API.endpoints.auth.refreshToken({
        refresh_token,
      })
      TokenService.TOKEN.set(data?.access_token)
      TokenService.REFRESH_TOKEN.set(data?.refresh_token)
      MOBILE_APP.sendMessage(MOBILE_APP_SEND_EVENTS.NEW_TOKEN,{
        access_token: data?.access_token,
        refresh_token: data?.refresh_token,
      })
      this.is_refresh_token=false
    }
  },
  async onRequest(config){
    API_LOGGER.info(config.url,config)
    const api_name = config?.name
    const url = config?.url
    const method = config?.method?.toLowerCase?.() ?? ''
    const user = TokenService.USER.get()
    const token = TokenService.TOKEN.get()
    try {
      if (api_name=='relay-proxy' && !VOIP_API.cp_url){
        const { data } = await VOIP_API.endpoints.dns.getCP({
          domain: TokenService.CP_DOMAIN.get()
        })
        VOIP_API.CPUrl=data
      }
    } catch {
      if (api_name=='relay-proxy'){
        VOIP_API.CPUrl=null
      }
    } finally {
      if(api_name=='voip'){
        config.baseURL = URLS?.koneApi ?? '';
        if(!config.headers.Authorization){
          config.headers.Authorization =  `Bearer ${token}`;
        }
        if(method=='get'){
          _.set(config,'params.vuid',user?.uid ?? '')
          _.set(config,'params.vaccountcode',user?.account ?? '')
        } else {
          if(config.data instanceof FormData){
            config.data.append('vuid',user?.uid ?? '')
            config.data.append('vaccountcode',user?.account ?? '')
          } else {
            _.set(config,'data.vuid',user?.uid ?? '')
            _.set(config,'data.vaccountcode',user?.account ?? '')
          }
        }
        if(url=='billingcons' && method=='post'){
          const uid = config?.data?.uid ?? user?.uid ?? ''
          const accountcode = config?.data?.accountcode ?? user?.account ?? ''
          _.set(config,'data.uid',uid ?? '')
          _.set(config,'data.accountcode',accountcode ?? '')
        }
      } else if(api_name=='centeralpoint-detect'){
        // console.log('not-login-issue',config.version,config.url)
        let url = ``
        if(config.version==1) {
          url = `${process.env.VUE_APP_CENTRAL_POINT_DETECT}v1/`
        } else {
          url = `${process.env.VUE_APP_CENTRAL_POINT_DETECT}v2/`
        }
        // console.log('not-login-issue',url)
        config.baseURL = url;
        // console.log('not-login-issue',config.baseURL)
      } else if(api_name=='error-capture'){
        let url = ``
        if(config.version==1) {
          url = `${process.env.VUE_APP_GET_ERROR_CAPTURE_API}v1/`
        }
        config.baseURL = url;
      } else if (api_name=='relay-proxy'){
        config.baseURL = `${VOIP_API.cp_url}api/v1/`;
        if(url!='logins'){
          if(method=='get'){
            _.set(config,'params.region',this.getRegionPlatform().region ?? '')
            _.set(config,'params.platform',this.getRegionPlatform().platform ?? '')
          } else {
            if(config.data instanceof FormData){
              config.data.append('region',this.getRegionPlatform().region ?? '')
              config.data.append('platform',this.getRegionPlatform().platform ?? '')
            } else {
              _.set(config,'data.region',this.getRegionPlatform().region ?? '')
              _.set(config,'data.platform',this.getRegionPlatform().platform ?? '')
            }
          }
        }
      } else if(api_name=='relay'){
        config.baseURL = URLS?.relayApi ?? '';
      } else if(api_name=='billing'){
        config.baseURL = URLS?.billingApi ?? '';
        config.headers.Authorization =  `Bearer ${token}`;
      } else if(api_name=='git-repo'){
        config.baseURL = 'https://api.github.com/';
        config.url = config.url.replace(':owner',process.env.VUE_APP_PUBLIC_REPO_OWNER).replace(':repo',process.env.VUE_APP_PUBLIC_REPO)
      } else if(api_name=='get-address'){
        config.baseURL = process.env.VUE_APP_GET_ADDRESS_URL;
        _.set(config,'params.api-key',process.env.VUE_APP_GET_ADDRESS_API_KEY)
      } else if(api_name=='rest-countries'){
        config.baseURL = process.env.VUE_APP_REST_COUNTRIES;
      } else if(api_name=='pamarly'){
        const token = store.getters[GET_PAMARLY_TOKEN]
        config.baseURL = 'https://www.pamarly.com/api/v1/';
        config.headers.Authorization = `Bearer ${token}`;
      } else if(api_name=='crm'){
        // TODO - change values of the crm url
        const token = store.getters[GET_CRM_TOKEN]
        const api_url = store.getters[GET_CRM_API_URL]
        config.baseURL = api_url;
        config.headers.Authorization = `Bearer ${token}`;
      } else if(api_name=='ami-server'){
        // TODO - change values of the crm url
        config.headers.Authorization = `Bearer secret`;
        const api_url = 'http://ami.server:5022/api/v1/'
        config.baseURL = api_url;
      }
      // eslint-disable-next-line no-unsafe-finally
      return config;
    }
  },
  async onError(ex){
    API_LOGGER.danger(ex?.config?.url,{ex})
    // console.error(ex?.config?.url,{ex})
    let response_error = ex?.response?.data ?? {}
    let errors = response_error?.errors ?? {}
    const api_name = ex?.config?.name
    const url = ex?.config?.url
    const status_code = response_error?.status_code ?? ex?.response?.status
    const response_description = response_error?.description
    const response_message = response_error?.message
    const user = TokenService.USER.get()
    if(errors instanceof Object){
      Object.keys(errors).forEach((key)=>{
        errors[key] = typeof errors[key] == 'string' ? [errors[key]] : errors[key]
      })
      if(response_error?.errors) response_error.errors=errors
    }
    let message = ''
    let no_record = false
    if(ex.message == "Network Error"){
      if(!window?.navigator?.onLine){
        message = this.errorMessages.network
      } else {
        message = this.errorMessages.server_down
      }
    } else if(ex.message.includes('timeout')){
      message = this.errorMessages.timeout
    } else if(status_code==500){
      message = this.errorMessages.default
    } else if(status_code==400 || response_message=='No more record found.'){
      message = ''
      no_record=true
    } else {
      message = ex?.response?.data ? response_description || response_message : ex.message
    }
    if(api_name=='voip' && url=='refresh-token'){
      $fn.logout({is_user: true})
    } else {
      if(!['error-capture'].includes(api_name)) {
        if(ex?.response?.status>=500 && ex?.response?.status!=502) {
          const url = new URL(ex?.config?.baseURL)
          store.commit(ADD_API_ERROR,{
            domain: url.host,
            method: ex?.config?.method,
            status: `${ex?.response?.status}`,
            url: ex?.config?.url,
            accountcode: user?.account ?? null,
            cp_domain: TokenService.CP_DOMAIN.get(),
          })
        }
      }
      if(['voip'].includes(api_name)){
        const notHandleUnauthorize = ex?.config?.notHandleUnauthorize
        if(!notHandleUnauthorize && ex?.response?.status===401){
          await this.getRefreshToken()
          const token = TokenService.TOKEN.get()
          ex.config.headers.Authorization =  `Bearer ${token}`;
          if(ex?.config?.method=='get'){
            // _.set(ex.config,'params.region',this.getRegionPlatform().region ?? '')
            // _.set(ex.config,'params.platform',this.getRegionPlatform().platform ?? '')
          } else {
            if(ex.config.data instanceof FormData){
              // config.data.append('region',this.getRegionPlatform().region ?? '')
              // config.data.append('platform',this.getRegionPlatform().platform ?? '')
            } else {
              ex.config.data=JSON.parse(ex.config.data)
              // _.set(config,'data.region',this.getRegionPlatform().region ?? '')
              // _.set(config,'data.platform',this.getRegionPlatform().platform ?? '')
            }
          }
          API_LOGGER.log('ex?.config',ex?.config)
          return VOIP_API.axios.voip(ex?.config)
          // const refresh_token = TokenService.REFRESH_TOKEN.get()
          // const { data } = await VOIP_API.endpoints.auth.refreshToken({
          //   refresh_token,
          // })
          // TokenService.TOKEN.set(data?.access_token)
          // TokenService.REFRESH_TOKEN.set(data?.refresh_token)
          // this.axios.voip(ex?.config)
        }
      }
      if(['crm'].includes(api_name)){
        const notHandleUnauthorize = ex?.config?.notHandleUnauthorize
        if(!notHandleUnauthorize && ex?.response?.status===401){
          await store.dispatch(CRM_REFRESH_TOKEN)
          const token = store.getters[GET_CRM_TOKEN]
          API_LOGGER.log('CRM_TOKEN',token)
          if(token) {
            ex.config.headers.Authorization =  `Bearer ${token}`;
            API_LOGGER.log('ex?.config',ex?.config)
            return VOIP_API.axios.crm(ex?.config)
          }
        }
      }
    }
    const response = {
      own_errors: errors,
      own_message: message ?? this.errorMessages.default,
      response_error: response_error,
      no_record,
      ...ex,
      message: ex.message ?? '',
      stack: ex.stack ?? '',
      cancel: Axios.isCancel?.(ex),
    }
    API_LOGGER.danger(response)
    throw response
  },
  async onSuccess(response){
    API_LOGGER.success(response?.config?.url,response)
    const api_name = response?.config?.name
    const url = response?.config?.url
    /*if(url=='logins' && api_name=='relay-proxy') {
      let server = ''
      if(!response.data){
        const { data } = await VOIP_API.endpoints.auth.signupServer()
        server = data?.server
      } else {
        server = response?.data?.relay?.server
      }
      return {
        ...response,
        data: {
          ...response.data,
          server
        }
      }
    } else */if(response?.data?.length==0 || response?.data?.data?.length==0){
      return {
        ...response,
        no_record: true
      }
    } else if (url=='device-info' && api_name=='centeralpoint-detect') {
      store.commit('setDeviceInfo',response.data)
    } else {
      return response
    }
  },
  endpoints: API_ENDPOINTS,
}
