var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup tagStatus-popup",
    attrs: {
      "width": "50%",
      "height": "auto",
      "scrollable": true,
      "name": "StatusesModal",
      "clickToClose": false
    },
    on: {
      "closed": _vm.onClosed
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to md-pad-bottom dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between aling-items-center w-100"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_c('vb-svg', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "name": "leftLongArrow-icon",
      "width": "20",
      "height": "12",
      "viewBox": "0 0 30 16",
      "stroke-width": "1",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    },
    on: {
      "click": function ($event) {
        _vm.updated_id ? _vm.updated_id = '' : _vm.$modal.hide('StatusesModal');
      }
    }
  }), _vm._v(" Status ")], 1), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "w-fit-content",
    class: {
      'for-disable': !_vm.getUserPermissions.unlimited_custom_status
    },
    attrs: {
      "title": _vm.getUserPermissions.unlimited_custom_status ? '' : 'Your package does not support this feature'
    }
  }, [!_vm.updated_id ? _c('button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "newButton",
    attrs: {
      "title": _vm.getUserPermissions.unlimited_custom_status ? '' : 'Your package does not support this feature',
      "disabled": !_vm.getUserPermissions.unlimited_custom_status,
      "variant": "success"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddStatus');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add status")])], 1) : _vm._e()])])]), _vm.updated_id ? _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v(" Edit status name and details. ")]) : _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v(" Customise your status to convey your availability or current situation beyond standard options like available, away, or busy. ")])]), _c('div', {
    staticClass: "dialer-tabs"
  }, [_vm.updated_id ? [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-12px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Update status")]), _c('b-form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.update.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mt-32px"
  }, [_c('label', [_vm._v("Status name")]), _c('b-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api_sent.update,
      "placeholder": "Enter name"
    },
    model: {
      value: _vm.forms.update.text,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update, "text", $$v);
      },
      expression: "forms.update.text"
    }
  }), _vm.is_submitted.update && _vm.$v.forms.update.text.$invalid || _vm.errors.update.text ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update.text.required ? _c('span', [_vm._v("* Name is required")]) : _vm._e(), _vm._l(_vm.errors.update.text, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e(), _vm.is_submitted.update ? [!_vm.$v.forms.update.text.required ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("* Name is required")])]) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-20px"
  }, [_c('label', [_vm._v("Status color")]), _c('div', {
    staticClass: "createSpace-selectColor-container"
  }, [_vm.api_sent.update || _vm.api.fetch_color_combination.send ? _vm._l(32, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "createSpace-selectColor-item-container"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "30px",
        "width": "30px"
      }
    })]);
  }) : _vm._l(_vm.colorComboOptions, function (color) {
    return _c('div', {
      key: color.id,
      staticClass: "createSpace-selectColor-item-container"
    }, [_c('div', {
      staticClass: "createSpace-selectColor-item",
      class: {
        'selected': _vm.forms.update.color_combo_id == color.value,
        'TransparentSelected': color.color == 'rgba(0,0,0,0)'
      },
      style: `background-color:${color.color};`,
      on: {
        "click": function ($event) {
          _vm.api_sent.update ? '' : _vm.forms.update.color_combo_id = color.value;
        }
      }
    }, [color.color == 'rgba(0,0,0,0)' ? _c('vb-icon', {
      attrs: {
        "icon": "todo-transparentColored-icon",
        "height": "28px",
        "width": "30px"
      }
    }) : _vm._e()], 1)]);
  })], 2), _vm.is_submitted.update && _vm.$v.forms.update.color_combo_id.$invalid ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update.color_combo_id.required ? _c('span', [_vm._v("* Color is required")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Allow incoming calls")]), _c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "disabled": _vm.api_sent.update,
      "checked": _vm.forms.update.allow_call,
      "switch": ""
    },
    on: {
      "change": function ($event) {
        _vm.forms.update.allow_call = !_vm.forms.update.allow_call;
      }
    }
  })], 1)]), _c('div', {
    staticClass: "w-100 d-flex justify-content-end mt-4"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "variant": "success",
      "disabled": _vm.api_sent.update
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart position-relative d-flex justify-content-center"
  }, [_vm.api_sent.update ? _c('vb-spinner') : [_vm._v("Update")]], 2)], 1)])])], 1)] : [_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again showLastTDonHover",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.$store.state.common.statuses),
      "listLength": _vm.$store.state.common.statuses.length,
      "perPage": 5,
      "conditions": {
        loading: {
          show: false
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return [_vm.$store.getters.getIsMobile ? _vm._l(_vm.$store.state.common.statuses, function (status, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: status.id
          }, [_c('td', {
            staticClass: "left"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', {
            staticClass: "basicIOSIcon withoutAfter mr-6px"
          }, [_c('div', {
            staticClass: "dialer-userStatusArea"
          }, [_c('vb-status', {
            staticStyle: {
              "height": "25px",
              "width": "25px",
              "border-radius": "50%",
              "right": "0px",
              "bottom": "0px"
            },
            attrs: {
              "statusId": status.id
            }
          })], 1)]), _c('div', [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', {
            staticClass: "headingInsideTable mr-4px"
          }, [_vm._v(_vm._s(status.status))]), status.id == _vm.$store.getters.getCurrentUser.status_id ? _c('b-icon', {
            staticClass: "ml-3",
            attrs: {
              "icon": "check-circle-fill",
              "font-scale": "1.3",
              "variant": "success"
            }
          }) : _vm._e()], 1), status.accountcode ? _c('span', {
            staticClass: "OwnertaggedVersion mb-0"
          }, [_vm._v("Custom")]) : _vm._e()])])]), _c('td', {
            staticClass: "right"
          }, [_c('div', [_c('div', {
            staticClass: "d-flex justify-content-end w-100"
          }, [_vm.api_sent.allow_call === status.id ? _c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "34px",
              "width": "60px",
              "border-radius": "25px"
            }
          }) : _c('b-checkbox', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip"
            }],
            staticClass: "m-0 newerSwitch mr-8px",
            attrs: {
              "title": _vm.getUserPermissions.status ? '' : 'Your package does not support this feature',
              "disabled": !_vm.getUserPermissions.status || _vm.api_sent.allow_call === status.id || _vm.api_sent.remove === status.id || _vm.api_sent.set === status.id || !status.accountcode,
              "checked": status.allow_call,
              "switch": ""
            },
            on: {
              "change": function ($event) {
                status.accountcode ? _vm.allowCall(status.id, $event, index) : '';
              }
            }
          }), status.accountcode ? _c('div', {
            staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD"
          }, [_c('b-dropdown', {
            attrs: {
              "right": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('b-icon', {
                  attrs: {
                    "icon": "three-dots-vertical",
                    "scale": "1.5"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip"
            }],
            attrs: {
              "title": _vm.getUserPermissions.status ? '' : 'Your package does not support this feature',
              "disabled": _vm.api_sent.remove === status.id
            },
            on: {
              "click": function ($event) {
                !_vm.getUserPermissions.status ? '' : _vm.setUpdateId(status);
              }
            }
          }, [_vm._v(" Edit ")]), _vm.api_sent.remove === status.id ? _c('b-dropdown-item') : _c('b-dropdown-item', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip"
            }],
            attrs: {
              "title": _vm.getUserPermissions.status ? '' : 'Your package does not support this feature',
              "disabled": !!_vm.api_sent.set || !!_vm.api_sent.remove
            },
            on: {
              "click": function ($event) {
                !_vm.getUserPermissions.status ? '' : _vm.remove(status);
              }
            }
          }, [_vm._v("Delete")])], 1)], 1) : _vm._e()], 1)])])]);
        }) : _vm._l(_vm.$store.state.common.statuses, function (status, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: status.id
          }, [_c('td', {
            staticClass: "dialer-row-title wd-768-50"
          }, [_c('span', {
            staticClass: "d-r-768px-show mr-2"
          }, [_vm._v("Name :")]), _vm._v(" " + _vm._s(status.status) + " "), status.id == _vm.$store.getters.getCurrentUser.status_id ? _c('b-icon', {
            staticClass: "ml-3",
            attrs: {
              "icon": "check-circle-fill",
              "font-scale": "1.3",
              "variant": "success"
            }
          }) : _vm._e(), status.accountcode ? _c('span', {
            staticClass: "OwnertaggedVersion mb-0 ml-16px"
          }, [_vm._v("Custom")]) : _vm._e()], 1), _c('td', {
            staticClass: "dialer-has-sort dialer-col-center wd-768-50"
          }, [_c('span', {
            staticClass: "d-r-768px-show mr-2"
          }, [_vm._v("Color :")]), _c('div', {
            staticClass: "dialer-userStatusArea"
          }, [_c('vb-status', {
            staticStyle: {
              "height": "20px",
              "width": "20px",
              "border-radius": "50%"
            },
            attrs: {
              "statusId": status.id
            }
          })], 1)]), _c('td', {
            staticClass: "dialer-has-sort dialer-col-center wd-768-50"
          }, [_c('span', {
            staticClass: "d-r-768px-show mr-2"
          }, [_vm._v("Allow call :")]), _c('div', {
            staticClass: "d-flex justify-content-center w-100"
          }, [_vm.api_sent.allow_call === status.id ? _c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "34px",
              "width": "60px",
              "border-radius": "25px"
            }
          }) : _c('b-checkbox', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip"
            }],
            staticClass: "m-0 newerSwitch",
            attrs: {
              "title": _vm.getUserPermissions.status ? '' : 'Your package does not support this feature',
              "disabled": !_vm.getUserPermissions.status || _vm.api_sent.allow_call === status.id || _vm.api_sent.remove === status.id || _vm.api_sent.set === status.id || !status.accountcode,
              "checked": status.allow_call,
              "switch": ""
            },
            on: {
              "change": function ($event) {
                status.accountcode ? _vm.allowCall(status.id, $event, index) : '';
              }
            }
          })], 1)]), _c('td', {
            staticClass: "dialer-has-sort dialer-col-right wd-768-50"
          }, [_c('span', {
            staticClass: "d-r-768px-show mr-2"
          }, [_vm._v("Action :")]), _c('div', {
            staticClass: "d-flex justify-content-end"
          }, [status.accountcode ? _c('div', {
            staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD"
          }, [_c('b-dropdown', {
            attrs: {
              "right": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('b-icon', {
                  attrs: {
                    "icon": "three-dots",
                    "scale": "1.5"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip"
            }],
            attrs: {
              "title": _vm.getUserPermissions.status ? '' : 'Your package does not support this feature',
              "disabled": _vm.api_sent.remove === status.id
            },
            on: {
              "click": function ($event) {
                !_vm.getUserPermissions.status ? '' : _vm.setUpdateId(status);
              }
            }
          }, [_vm._v(" Edit ")]), _vm.api_sent.remove === status.id ? _c('b-dropdown-item') : _c('b-dropdown-item', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip"
            }],
            attrs: {
              "title": _vm.getUserPermissions.status ? '' : 'Your package does not support this feature',
              "disabled": !!_vm.api_sent.set || !!_vm.api_sent.remove
            },
            on: {
              "click": function ($event) {
                !_vm.getUserPermissions.status ? '' : _vm.remove(status);
              }
            }
          }, [_vm._v("Delete")])], 1)], 1) : _vm._e()])])]);
        })];
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "21px",
              "width": "80px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content mx-auto"
          }, [_c('div', {
            staticClass: "md-mar-left latestShimmerDesign",
            staticStyle: {
              "height": "21px",
              "width": "21px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content mx-auto"
          }, [_c('div', {
            staticClass: "latestShimmerDesign md-mar-left",
            staticStyle: {
              "height": "21px",
              "width": "21px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content mx-auto"
          }, [_c('div', {
            staticClass: "latestShimmerDesign md-mar-left",
            staticStyle: {
              "height": "21px",
              "width": "50px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center justify-sontent-end w-fit-content ml-auto"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "16px",
              "width": "70px"
            }
          })])])]);
        });
      },
      proxy: true
    }])
  }, [!_vm.$store.getters.getIsMobile ? _c('tr', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-center"
  }, [_c('span', [_vm._v("Color")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-center"
  }, [_c('span', [_vm._v("Allow call")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right"
  })]) : _vm._e()])]], 2)])]), _c('AddStatusModal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }