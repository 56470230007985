<template>
  <div class="dialer-main-wrapper dialer-activeCall-wrapper">
    <div class="MonitorDashboard-12-10-2023">
      <div class="d-flex justify-content-between align-items-start w-100 MonitorDashboard-TopSection">
        <div>
          <div class="MonitorDashboard-heading">Monitor dashboard</div>
          <!-- <div class="MonitorDashboard-heading-text">Here’s where you can monitor calls and users.</div> -->
          <div class="MonitorDashboard-heading-text">
            <!-- View recent call activity and also oversee live calls by our real-time monitoring of ongoing calls. -->
            View recent call activities and monitor live calls with our real time monitoring feature.
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div class="userDashboard-heading mr-16px">Call dashboard</div>
          <b-form-checkbox v-model="conditions.user_mode" class="newerSwitch" switch />
          <div class="userDashboard-heading ml-16px">User dashboard</div>
        </div>
      </div>
      <div class="mt-30px MonitorDashboard-section1">
        <div class="d-flex wd-83">
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20prcntOnly mr-16px">
            <label>Filter by number</label>
            <vb-select 
              class="w-100"
              v-model="filters.calls.voip_number"
              :valueField="'did'"
              :textField="'did'"
              :options="voipnumbers"
            >
              <template #selected-option="{ option }">
                <div class="d-flex align-content-center w-100 h-100">
                  <span class="p-0">{{ option | get_property('did','Select Number') | number_formater }}</span>
                  <b-button v-if="option" variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;">
                    <b-icon icon="x" scale="1.5" @click="filters.calls.voip_number='';" />
                  </b-button>
                </div>
              </template>
              <template #option="{ option }">
                {{ option | get_property('did') | number_formater }}
              </template>
              <template #no-record>There is no number</template>
            </vb-select>
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20prcntOnly mr-16px">
            <label>Filter by user</label>
            <vb-select 
              class="w-100"
              v-model="filters.calls.user"
              :options="voipusers"
              textField="user.display_name"
              valueField="voipaccount"
            >
              <template #selected-option="{ option }">
                <div class="d-flex align-content-center justify-content-between w-100 h-100">
                  <Info v-if="option" :id="option.voipaccount" :is_blf="false" :is_suspended_show="false" :type="'user'" />
                  <span class="p-0" v-else>Select user</span>
                  <b-button v-if="option" variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;">
                    <b-icon icon="x" scale="1.5" @click="filters.calls.user='';" />
                  </b-button>
                </div>
              </template>
              <template #option="{ option }">
                <Info :id="option.voipaccount" :is_blf="false" :is_suspended_show="false" :type="'user'" />
              </template>
              <template #no-record>There is no user</template>
            </vb-select>
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20prcntOnly mr-16px">
            <label>Filter by team</label>
            <vb-select 
              class="w-100"
              v-model="filters.calls.team"
              :options="teams"
              textField="name"
              valueField="real_id"
            >
              <template #selected-option="{ option }">
                <div class="d-flex align-content-center justify-content-between w-100 h-100">
                  <Info v-if="option" :id="option.real_id" :is_blf="false" :is_suspended_show="false" :type="'team'" />
                  <span class="p-0" v-else>Select team</span>
                  <b-button v-if="option" variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;">
                    <b-icon icon="x" scale="1.5" @click="filters.calls.team='';" />
                  </b-button>
                </div>
              </template>
              <template #option="{ option }">
                <Info :id="option.real_id" :is_blf="false" :is_suspended_show="false" :type="'team'" />
              </template>
              <template #no-record>There is no team</template>
            </vb-select>
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20prcntOnly mr-16px">
            <label>Filter by call type</label>
            <vb-select 
              class="w-100"
              v-model="filters.calls.call_type"
              :options="Object.values(callTypes)"
            >
              <template #selected-option="{ option }">
                <div class="d-flex align-content-center w-100 h-100">
                  <span class="p-0">{{ option | get_property('text','Select call type') }}</span>
                  <b-button v-if="option" variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;">
                    <b-icon icon="x" scale="1.5" @click="filters.calls.call_type='';" />
                  </b-button>
                </div>
              </template>
              <template #option="{ option }">
                {{ option | get_property('text') }}
              </template>
              <template #no-record>There is no number</template>
            </vb-select>
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20prcntOnly mr-16px">
            <label>Filter by time</label>
            <vb-select 
              class="w-100"
              v-model="filters.calls.date"
              :options="Object.values(dates)"
            >
              <template #selected-option="{ option }">
                <div class="d-flex align-content-center w-100 h-100">
                  <span class="p-0">{{ option | get_property('text','Select time') }}</span>
                </div>
              </template>
              <template #option="{ option }">
                {{ option | get_property('text') }}
              </template>
              <template #no-record>There is no times</template>
            </vb-select>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-38px w-100 MonitorDashboard-section2">
        <div class="monitorDashboard-analytics-box align-self-stretch">
          <div class="monitorDashboard-newProgressBar">
            <div 
              role="progressbar" 
              :aria-valuenow="response.calls | get_property('data.service_level',0)" 
              aria-valuemin="0" 
              aria-valuemax="100" 
              :style="`--value:${getProperty(response.calls,'data.service_level',0)};--fg:#249CE0`"
            >
              <div class="newProgressBar-inner">
                <div class="d-flex flex-column align-items-center">
                  <div class="newProgressBar-smallText">Service level</div>
                  <div class="newProgressBar-bigText">{{ response.calls | get_property('data.service_level',0) }}%</div>
                  <div class="newProgressBar-smallText">Target: {{ response.calls | get_property('data.service_level',0) }}%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="monitorDashboard-analytics-box ">
          <vb-icon icon="monitorDashboard-callWaiting" width="52.005px" height="52.005px" />
          <div class="monitorDashboard-analytics-heading">Calls waiting</div>
          <div class="monitorDashboard-analytics-number callWaiting">{{ response.calls | get_property('data.calls_wating',0) | numberPad }}</div>
          <div class="monitorDashboard-analytics-text">Now</div>
        </div>
        <div class="monitorDashboard-analytics-box">
          <vb-icon icon="monitorDashboard-missedCalls" width="52.005px" height="52.005px" />
          <div class="monitorDashboard-analytics-heading">Missed calls</div>
          <div class="monitorDashboard-analytics-number missedCalls">{{ response.calls | get_property('data.missed_call',0) | numberPad }}</div>
          <div class="monitorDashboard-analytics-text">{{selectedDate | get_property('text')}}</div>
        </div>
        <div class="monitorDashboard-analytics-box">
          <vb-icon icon="monitorDashboard-longestWaitingTime" width="52.005px" height="52.005px" />
          <div class="monitorDashboard-analytics-heading">Longest waiting time</div>
          <div class="monitorDashboard-analytics-number longestWaitingTime">{{ response.calls | get_property('data.longest_waiting_time',0) | filter_date_current('',{ seconds: true, formate: 'mm:ss' }) }}</div>
          <div class="monitorDashboard-analytics-text">{{selectedDate | get_property('text')}}</div>
        </div>
        <div class="monitorDashboard-analytics-box">
          <vb-icon icon="monitorDashboard-averageWaitingTime" width="52.005px" height="52.005px" />
          <div class="monitorDashboard-analytics-heading">Average waiting time</div>
          <div class="monitorDashboard-analytics-number averageWaitingTime">{{ response.calls | get_property('data.average_waiting_time',0) | filter_date_current('',{ seconds: true, formate: 'mm:ss' }) }}</div>
          <div class="monitorDashboard-analytics-text">{{selectedDate | get_property('text')}}</div>
        </div>
        <div class="monitorDashboard-analytics-box align-self-stretch">
          <vb-icon icon="monitorDashboard-availableUsers" width="52.005px" height="52.005px" />
          <div class="monitorDashboard-analytics-heading">Available users</div>
          <div class="monitorDashboard-analytics-number availableUsers">{{ response.calls | get_property('data.available_users',0) | numberPad }}</div>
          <div class="monitorDashboard-analytics-text">Now</div>
        </div>
        <div class="monitorDashboard-analytics-box align-self-stretch">
          <vb-icon icon="monitorDashboard-incommingCalls" width="52.005px" height="52.005px" />
          <div class="monitorDashboard-analytics-heading">Incoming calls</div>
          <div class="monitorDashboard-analytics-number IncomingCalls">{{ response.calls | get_property('calls_type.incoming',0) | numberPad }}</div>
          <div class="monitorDashboard-analytics-text">Now</div>
        </div>
        <div class="monitorDashboard-analytics-box align-self-stretch">
          <vb-icon icon="monitorDashboard-outgoingCalls" width="52.005px" height="52.005px" />
          <div class="monitorDashboard-analytics-heading">Outgoing calls</div>
          <div class="monitorDashboard-analytics-number OutgoingCalls">{{ response.calls | get_property('calls_type.outgoing',0) | numberPad }}</div>
          <div class="monitorDashboard-analytics-text">Now</div>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center my-25px">
        <div class="MonitorDashboard-heading">Live filtered calls</div>
        <div class="wd-17" style="min-width: 270px;">
          <div class="liveFeed-switch-container">
            <div class="liveFeed-ststus-circle" style="background-color: #00C267;"></div>
            <div class="liveFeed-text">Live feed</div>
            <b-form-checkbox class="newerSwitch" v-model="conditions.live_feed" switch />
          </div>
        </div>
      </div>
      <div class="monitoringTable-container mb-5">
        <div class="latestMonitorDashboardBox mb-20px" v-if="api.fetch_calls.send && prevCalls.length==0 && liveCalls.length==0">
          <!-- Loading... -->
          <div class="latestMonitorDashboardBox-row" v-for="n in 6" :key="n.id">
            <div class="latestMonitorDashboardBox-column one">
              <div class="latestShimmerDesign" style="height: 19px;width: 50%;"></div>
            </div>
            <div class="latestMonitorDashboardBox-column two">
              <div class="latestShimmerDesign" style="height: 19px;width: 50%;"></div>
            </div>
            <div class="latestMonitorDashboardBox-column three">
              <div class="latestShimmerDesign" style="height: 19px;width: 50%;"></div>
            </div>
            <div class="latestMonitorDashboardBox-column four">
              <div class="latestShimmerDesign" style="height: 19px;width: 50%;"></div>
            </div>
            <div class="latestMonitorDashboardBox-column five">
              <div class="latestShimmerDesign" style="height: 19px;width: 50%;"></div>
            </div>
          </div>
        </div>
        <template v-else-if="conditions.user_mode">
          <div class="latestMonitorDashboardBox">
            <Item 
              v-for="data in usersCalls" 
              :key="data.accountcode"
              :call="{
                user_accountcode: data.accountcode,
                ...data.call
              }"
              :extension_data="response.extension_data"
              :userDashboard="true"
              @click-play="playAudio($event)"
              @clicked="$modal.show(`${_uid}-UserExtensionCallsModal`,{ 
                accountcode: data.accountcode 
              })"
              @ai-modal="$modal.show(`${_uid}-AITranscriptModal`,{ 
                call: {
                  ...data.call,
                  call_type: 'audio',
                  status: '',
                } 
              })" 
            />
          </div>
          <!-- <div v-for="data in usersCalls" :key="data.accountcode" @click="$modal.show(`${_uid}-UserExtensionCallsModal`,{ accountcode: data.accountcode })">
            {{ data.accountcode }}
          </div> -->
        </template>
        <template v-else>
          <div v-if="liveCalls.length>0" class="latestMonitorDashboardBox mb-20px">
            <Item v-for="call in liveCalls" :key="call.id" :call="call" :extension_data="response.extension_data" @click-play="playAudio($event)" />
            <!--<div class="latestMonitorDashboardBox-row" v-for="call in liveCalls" :key="call.id">
              <div class="latestMonitorDashboardBox-column one">
                <vb-icon class="latestMonitorDashboardBox-firstColumn-icon" v-if="call.type!='outgoing'" icon="monitorDashboard-table-inboundCall" width="25.827px" height="25.833px" />
                <vb-icon class="latestMonitorDashboardBox-firstColumn-icon" v-else icon="monitorDashboard-table-outboundCall" width="25.829px" height="25.829px" />
                <div class="latestMonitorDashboardBox-text">
                  <Info :id="call.accountcode" :only_name="true" />
                  is in call with
                  <span class="darkerColor">{{call.number | number_formater}}</span>
                </div>
              </div>
              <div class="latestMonitorDashboardBox-column two">
                <div class="latestMonitorDashboardBox-text">{{0 | time_audio}}</div>
              </div>
              <div class="latestMonitorDashboardBox-column three">
                <div class="latestMonitorDashboardBox-text">
                  Via <img :src="call.number | number_formater | flag_icon" /> {{call.number | number_formater}} 
                </div>
              </div>
              <div class="latestMonitorDashboardBox-column four">
                <!~~ <b-dropdown v-if="call.accountcode!=getCurrentUser.sip.user" varient="link" no-caret class="latestMonitorDashboardBox-dropdown"> ~~>
                <b-dropdown v-if="call.accountcode!=getCurrentUser.sip.user"
                  :disabled="!getUserPermissions.monitor_dashboard" 
                  v-b-tooltip :title="getUserPermissions.monitor_dashboard?'':'Your package does not support this feature'"
                  varient="link" no-caret class="latestMonitorDashboardBox-dropdown"
                >
                  <template #button-content>
                    <span class="mr-8px">Listen in</span>
                    <vb-icon width="20.524px" height="17.127px" icon="monitordashboard-listenIn-icon" />
                  </template>
                  <b-dropdown-item
                    :disabled="!getUserPermissions.monitor_dashboard || call.status!='Up' || api.calling.send.includes(call.id)" 
                    @click.stop="calling(call,'listen')"
                  >
                    Listen
                  </b-dropdown-item>
                  <b-dropdown-item
                    :disabled="!getUserPermissions.monitor_dashboard || call.status!='Up' || api.calling.send.includes(call.id)" 
                    @click.stop="calling(call,'listen')"
                  >
                    Whisper
                  </b-dropdown-item>
                  <b-dropdown-item
                    :disabled="!getUserPermissions.monitor_dashboard || call.status!='Up' || api.calling.send.includes(call.id)" 
                    @click.stop="calling(call,'barge')"
                  >
                    Barge
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="latestMonitorDashboardBox-column five">
                <div class="latestMonitorDashboardBox-text">
                  {{ call.created_at | filter_date_current('',{ unix: true, formate: 'DD MMM YYYY' }) }}
                </div>
              </div>
            </div>-->
          </div>
          <div v-if="prevCalls.length>0" class="latestMonitorDashboardBox">
            <Item 
              v-for="call in prevCalls" 
              :key="call.uniqueid" 
              :call="call" 
              :extension_data="response.extension_data"
              @click-play="playAudio($event)"
              @ai-modal="$modal.show(`${_uid}-AITranscriptModal`,{ 
                call: {
                  ...call,
                  call_type: 'audio',
                  status: '',
                } 
              })" 
            />
            <!--<div class="latestMonitorDashboardBox-row" v-for="call in prevCalls" :key="call.uniqueid">
              <div class="latestMonitorDashboardBox-column one">
                <vb-icon class="latestMonitorDashboardBox-firstColumn-icon" v-if="call.call_type=='INCOMMING'" icon="monitorDashboard-table-inboundCall" width="25.827px" height="25.833px" />
                <vb-icon class="latestMonitorDashboardBox-firstColumn-icon" v-else icon="monitorDashboard-table-outboundCall" width="25.829px" height="25.829px" />
                <div class="latestMonitorDashboardBox-text">
                  <Info :id="call.accountcode" :only_name="true" />
                  was in call with
                  <span class="darkerColor">{{call.dst | number_formater}}</span>
                </div>
              </div>
              <div class="latestMonitorDashboardBox-column two">
                <div class="latestMonitorDashboardBox-text">{{call | get_property('duration') | time_audio}}</div>
              </div>
              <div class="latestMonitorDashboardBox-column three">
                <div class="latestMonitorDashboardBox-text">
                  Via <img :src="call | get_property('calee_accountcode') | number_formater | flag_icon" /> {{call | get_property('calee_accountcode') | number_formater}} 
                </div>
              </div>
              <div class="latestMonitorDashboardBox-column four">
                <template v-if="call.soundFile">
                  <div class="d-flex align-items-center">
                    <vb-audio-player :src="call.soundFile" :design="'forMonitorDashboard'" class="mr-8px" />
                    <vb-icon icon="ai-brain-icon" width="26px" height="24px" class="cursor_pointer_no_hover"
                      @click="$modal.show(`${_uid}-AITranscriptModal`,{ 
                        call: {
                          ...call,
                          call_type: 'audio',
                          status: '',
                        } 
                      })" 
                    />
                  </div>
                </template>
              </div>
              <div class="latestMonitorDashboardBox-column five">
                <div class="latestMonitorDashboardBox-text">
                  {{call.calldate}}
                </div>
              </div>
            </div>-->
          </div>
          <template v-else-if="prevCalls.length==0">
            <div class="emptyScreenContainer allTransparent pt-0">
              <img width="65%" style="max-width: 380px;" height="auto" class="mt-20px" :src="require('@/assets/images/emptyScreenImages/forCallLogs.png')"/>
              <div class="emptyScreenContainer-heading inCallLogs mt-24px">
                Welcome aboard to VoIP Business!
              </div>
              <div class="emptyScreenContainer-text inCallLogs w-100">
                Are you ready to unleash unstoppable productivity?<br/> Take the plunge and make your first call now!
              </div>
            </div>
          </template>
        </template>
      </div>
      <template v-if="selected.sound_file">
        <div class="AudioPlayerShownInsideCallLogsList">
          <div class="AudioPlayerShownInsideCallLogsList-inner">
            <vb-audio-player :src="selected.sound_file" :autoPlay="true" :design="'NoTranscript'" />
            <b-button @click="selected.sound_file=null" class="p-0 bg-transparent border-0">
              <vb-icon icon="squared-close-icon" class="squared-close-icon" height="38px" width="38px" />
            </b-button>
          </div>
        </div>
      </template>
    </div>
    <modal :name="`${_uid}-UserExtensionCallsModal`" class="monitorCallItemsModal" @before-open="selected.user_accountcode=$event.params.accountcode;" @before-close="selected.user_accountcode='';">
      <div class="dialer-edit-header pt-0">
        <h2 class="dialer-settings-title newer mb-0">List</h2>
        <div class="dialer-edit-actions">
          <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(`${_uid}-UserExtensionCallsModal`)" />
          <a v-else class="newCloseButton" @click="$modal.hide(`${_uid}-UserExtensionCallsModal`)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
      </div>
      <div class="monitoringTable-container">
        <div class="latestMonitorDashboardBox">
          <Item 
            v-for="data in specificUserCalls" 
            :key="data.accountcode"
            :call="{
              user_accountcode: selected.user_accountcode,
              extension_accountcode: data.accountcode,
              ...data.call ?? {}
            }"
            :extension_data="response.extension_data"
            :userDashboard="true"
            @click-play="playAudio($event)"
            @ai-modal="$modal.show(`${_uid}-AITranscriptModal`,{ 
              call: {
                ...data.call,
                call_type: 'audio',
                status: '',
              } 
            })" 
          />
          <template v-if="specificUserCalls.length == 0">
            <div class="emptyScreenContainer allTransparent pt-0">
              <img width="65%" style="max-width: 380px;" height="auto" class="mt-20px" :src="require('@/assets/images/emptyScreenImages/forCallLogs.png')"/>
              <div class="emptyScreenContainer-heading inCallLogs mt-24px">
                Welcome aboard to VoIP Business!
              </div>
              <div class="emptyScreenContainer-text inCallLogs w-100">
                <!-- Are you ready to unleash unstoppable productivity?<br/> Take the plunge and make your calls now! -->
                Ready to boost your productivity?<br/> Experience now by making your first call
              </div>
            </div>
          </template>
        </div>
      </div>
      <!-- <div v-for="data in specificUserCalls" :key="data.accountcode">
        {{ data.accountcode }}
      </div> -->
    </modal>
    <AITranscriptModal :CallItemShow="false" :modalName="`${_uid}-AITranscriptModal`" />
  </div>
</template>

<script>
import { /*events,*/ VOIP_API } from "../../utils";
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash'
import moment from 'moment'
import Info from '../Lists/Info.vue';
import { AMI_EVENTS, AMI_SOCKET } from '@/ami';
import AITranscriptModal from '../Modals/AITranscriptModal.vue';
import Item from './ItemNew.vue';
const dates = {
  last_min: {
    text: 'Last minute',
    value: 'last_min',
  },
  last_05_min: {
    text: 'Last 5 minutes',
    value: 'last_05_min',
  },
  last_30_min: {
    text: 'Last 30 minutes',
    value: 'last_30_min',
  },
  last_hour: {
    text: 'Last hour',
    value: 'last_hour',
  },
  last_02_hour: {
    text: 'Last 2 hours',
    value: 'last_02_hour',
  },
}
const call_types = {
  voicemail: {
    text: 'Voicemail',
    value: 'voicemail',
  },
  missed: {
    text: 'Missed',
    value: 'missed',
  },
  recieved: {
    text: 'Recieved',
    value: 'recieved',
  },
  dialed: {
    text: 'Dialed',
    value: 'dialed',
  },
}
let timeout_instance;
export default {
  name: "ActiveCallComponent",
  components: {
    Info,
    AITranscriptModal,
    Item,
  },
  inject:[
    'isEmpty',
    'getProperty',
    'appNotify'
  ],
  data() {
    return {
      api: {
        fetch_calls: this.$helperFunction.apiInstance({ 
          source: true, 
        }),
        calling: this.$helperFunction.apiInstance({ 
          send: [], 
        }),
        fetch_extension_data: this.$helperFunction.apiInstance(),
      },
      filters: {
        calls: {
          voip_number: '',
          date: dates.last_min.value,
          user: '',
          team: '',
          call_type: '',
        },
      },
      response: {
        calls: {},
        extension_data: {},
      },
      conditions: {
        live_feed: false,
        user_mode: false,
      },
      selected: {
        user_accountcode: '',
        sound_file: null,
      },
    };
  },
  computed: {
    ...mapState({
      voipusers: (state)=>Object.values(state.common.voipusers ?? {}),
      teams: (state)=>state.common.teams?.data ?? [],
      voipnumbers: (state)=>state.common.numbers?.filter?.(i=>i.object=='TelephoneNumbers') ?? [],
      voipusersaccountcode: (state)=>state.common.user_accountcode,
    }),
    ...mapGetters([
      'getCurrentUser',
      'getUserPermissions',
      'getIsMobile',
    ]),
    usersCalls(){ 
      return Object.keys(this.voipusersaccountcode ?? {})?.map?.(i=>({
        accountcode: i,
        call: this.liveCalls.find(ii=>this.voipusersaccountcode[i]?.includes(ii.accountcode)) || this.prevCalls.find(ii=>this.voipusersaccountcode[i]?.includes(ii.answerExt)),
      })) ?? []
    },
    specificUserCalls(){ 
      return this.voipusersaccountcode[this.selected.user_accountcode]?.map?.(i=>({
        accountcode: i,
        call: this.liveCalls.find(ii=>ii.accountcode==i) || this.prevCalls.find(ii=>ii.answerExt==i),
      })) ?? []
    },
    dates(){ return dates },
    callTypes(){ return call_types },
    selectedDate(){ return dates[this.filters.calls.date] },
    calls(){ return [..._.orderBy(this.response.calls?.live_calls ?? {},"created_at","desc"),..._.orderBy(this.response.calls?.prev_calls ?? {},"created_at","desc")] },
    prevCalls(){ return [..._.orderBy(this.response.calls?.prev_calls?.data ?? {},"created_at","desc")] },
    liveCalls(){ return [..._.orderBy(this.response.calls?.live_calls ?? {},"created_at","desc")] },
  },
  watch: {
    "filters.calls": {
      deep: true,
      handler(){
        this.fetchCallsDebounce()
      },
    },
  },
  filters: {
    numberPad(num, size=2) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    }
  },
  methods: {
    fetchCallsDebounce: _.debounce(function(){
      this.fetchCalls()
    }, 2*1000),
    async fetchCalls() {
      let vm = this
      this.api.fetch_calls.source?.cancel?.("Requesting again");
      this.api.fetch_calls.source = require("axios").default.CancelToken.source();
      try {
        clearTimeout(timeout_instance)
        this.api.fetch_calls.send=true
        let start_date = '' 
        let end_date = ''
        if(this.filters.calls.date==dates.last_min.value) {
          start_date = moment.utc().subtract(1, 'minute')
          end_date = moment.utc()
        } else if(this.filters.calls.date==dates.last_05_min.value) {
          start_date = moment.utc().subtract(5, 'minute')
          end_date = moment.utc()
        } else if(this.filters.calls.date==dates.last_30_min.value) {
          start_date = moment.utc().subtract(30, 'minute')
          end_date = moment.utc()
        } else if(this.filters.calls.date==dates.last_hour.value) {
          start_date = moment.utc().subtract(1, 'hour')
          end_date = moment.utc()
        } else if(this.filters.calls.date==dates.last_02_hour.value) {
          start_date = moment.utc().subtract(2, 'hour')
          end_date = moment.utc()
        } 
        const { data } = await VOIP_API.endpoints.ami.getCallsByUid({
          voip_number: this.filters.calls.voip_number,
          start_date: start_date?.format?.('YYYY MM DD hh:mm:ss a') ?? '',
          end_date: end_date?.format?.('YYYY MM DD hh:mm:ss a') ?? '',
          user: this.filters.calls.user,
          team: this.filters.calls.team,
          call_type: this.filters.calls.call_type,
          uid: this.getCurrentUser.uid,
        },this.api.fetch_calls.source.token);
        this.response.calls = data ?? {}
      } catch (ex) {
        if(!ex.cancel) {
          this.appNotify({
            message: ex.own_message || ex.message,
            type: 'danger',
          })
        }
      } finally {
        this.api.fetch_calls.send=false
        this.api.fetch_calls.source=null
        timeout_instance = setTimeout(()=>{
          if(vm.conditions.live_feed) vm.fetchCalls()
        },10*1000)
      }
    },
    async fetchExtensionData() {
      this.api.fetch_extension_data.source?.cancel?.("Requesting again");
      this.api.fetch_extension_data.source = require("axios").default.CancelToken.source();
      try {
        this.api.fetch_extension_data.send=true
        const { data } = await VOIP_API.endpoints.users.getExtensionData({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.accountcode,
        },this.api.fetch_extension_data.source.token);
        this.response.extension_data = data ?? {}
      } catch (ex) {
        if(!ex.cancel) {
          this.appNotify({
            message: ex.own_message || ex.message,
            type: 'danger',
          })
        }
      } finally {
        this.api.fetch_extension_data.send=false
        this.api.fetch_extension_data.source=null
      }
    },
    // async calling(call,type='listen'){
    //   if(this.api.calling.send.includes(call.id)) return;
    //   try {
    //     this.api.calling.send.push(call.id)
    //     const { data } = await VOIP_API.endpoints.user.extensionAccountInfo({
    //       accountcode: call.accountcode
    //     })
    //     this.$root.$emit(events.audio_call,{
    //       call_type: type,
    //       number: data.callerid,
    //       callee_id: data.user_ext,
    //       callee_type: 'user',
    //     })
    //   } catch (ex) {
    //     this.appNotify({
    //       message: ex.own_message,
    //       type: 'error'
    //     })
    //   } finally {
    //     if(this.api.calling.send.includes(call.id)) {
    //       this.api.calling.send.splice(this.api.calling.send.indexOf(call.id),1)
    //     }
    //   }
    // },
    onUpdateCalls(call){
      const { id } = call
      if(this.response.calls.live_calls) {
        if(call.status=='hangup') {
          this.$delete(this.response.calls.live_calls,id)
        } else {
          this.$set(this.response.calls.live_calls,id,call)
        }
      }
    },
    async playAudio(event){
      const sound_file = event?.soundFile
      try {
        await fetch(sound_file)
        this.selected.sound_file=sound_file
      } catch (ex) {
        this.appNotify({
          message: 'Please wait for 30 seconds',
          type: 'info'
        })
      }
    },
  },
  created(){
    AMI_SOCKET?.on?.(AMI_EVENTS.update_calls,this.onUpdateCalls)
  },
  mounted() {
    this.fetchCalls();
    this.fetchExtensionData();
  },
  activated(){
    this.fetchCalls();
    this.fetchExtensionData();
  },
  deactivated(){
    clearTimeout(timeout_instance)
  },
  beforeDestroy(){
    AMI_SOCKET?.off?.(AMI_EVENTS.update_calls,this.onUpdateCalls)
    clearTimeout(timeout_instance)
  },
};
</script>

<style lang="scss" scoped>
.dialer-table-row {
  position: relative;
  .position-absolute {
    right: 24px;
    top: 18px;
  }
}
.dialer-activeCall-wrapper {
  .dialer-downArrow {
    display: flex;
    color: $black;
    width: 20px;
    font-size: 20px;
  }
}
.ct-chart {
  position: relative;
  max-height: 50px !important;
  .apexcharts-canvas {
    position: unset;
  }
}
</style>
