var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('section', {
    staticClass: "dialer-settings-section dialer-devices-settings"
  }, [_c('div', {
    staticClass: "settingsFirstSection"
  }, [_vm.getIsMobile ? [_c('div', {
    staticClass: "d-flex align-items-center calc-title-width-button"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    staticStyle: {
      "opacity": "0",
      "pinter-events": "none"
    },
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center"
  }, [_vm._v("My devices")]), _c('button', {
    staticClass: "newButton mr-0",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddDevicesOptionsModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add devices")])], 1)], 1)] : [_c('h2', {
    staticClass: "dialer-settings-title mb-0 settingsFirstSection-left newer"
  }, [_vm._v(" My devices "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon md-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('KbInfoModal', {
          kb: 'phones'
        });
      }
    }
  })], 1), _c('div', {
    staticClass: "settingsFirstSection-right"
  }, [_c('button', {
    staticClass: "newButton mwd-175px mr-0",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddDevicesOptionsModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add devices")])], 1)])]], 2), _c('div', {
    staticClass: "settingsSecondSection"
  }, [_c('span', {
    staticClass: "newerTextVersion dialer-settings-intro mb-0 mt-0",
    class: _vm.getIsMobile ? 'text-center' : ''
  }, [_vm._v(" View all your active devices and statuses. ")])]), _c('vb-table', {
    staticClass: "for-modal-box-bg-gray latestTableDesign-withBlackBorders-again",
    attrs: {
      "isListEmpty": _vm.extensions.length == 0,
      "listLength": _vm.extensions.length,
      "loading": _vm.api.extension_detail.send,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return [_vm.getIsMobile ? _vm._l(_vm.extensions, function (extension, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: extension.accountcode,
            on: {
              "click": function ($event) {
                extension.type == 'MOBILEEXT' && !_vm.getUserPermissions.mobile_extension || extension.type == 'EXTERNALEXT' && !_vm.getUserPermissions.external_extension ? _vm.appNotify({
                  message: 'Your package does not support this feature',
                  type: 'danger'
                }) : _vm.$modal.show('EditExtensionDetail', {
                  extension,
                  voipaccount: _vm.voipaccount,
                  limit: _vm.response.extension_detail.extensionlimit[extension.limit_type]
                });
              }
            }
          }, [_c('td', {
            staticClass: "dialer-row-title left"
          }, [_c('div', {
            staticClass: "w-auto"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', {
            class: `ExtentionIcon mr-8px ${extension.device_status.status == 'online' ? 'statusOnline' : 'statusOffline'}`
          }, [_c('vb-icon', {
            attrs: {
              "icon": _vm._f("extensionIcon")(extension.type),
              "width": "25px",
              "height": "25px"
            }
          })], 1), _c('div', [_c('div', {
            staticClass: "headingInsideTable"
          }, [extension.type == 'EXTERNALEXT' ? [_c('div', [_vm._v("External app")]), _c('div', {
            staticClass: "extensionDeviceName"
          }, [_vm._v(" ( " + _vm._s(extension.accountname) + " ) ")])] : _c('div', {
            staticClass: "onlyfirstLetterCapital"
          }, [_c('div', [_vm._v(_vm._s(extension.accountname))]), extension.device_status.device_detail ? _c('div', {
            staticClass: "extensionDeviceName"
          }, [_vm._v(" ( " + _vm._s(_vm._f("DeviceInfo")(_vm._f("get_property")(extension, 'device_status.device_detail'))) + " ) ")]) : _vm._e()])], 2), _c('div', {
            staticClass: "deviceContainer"
          }, [extension.accountcode == _vm.registeredExtension ? _c('span', {
            staticClass: "newThisDeviceIndicaterTextBox-25-11-2023 ml-0",
            attrs: {
              "id": `${extension.accountcode}-current-device-popover`
            }
          }, [_c('span', [_vm._v("This device")])]) : _vm._e(), extension.accountcode == _vm.registeredExtension ? _c('b-popover', {
            attrs: {
              "custom-class": "thisDeviceCustomPopOver",
              "target": `${extension.accountcode}-current-device-popover`,
              "triggers": "hover",
              "placement": "topright"
            }
          }, [_c('div', {
            staticClass: "d-flex flex-column"
          }, [_c('span', {
            staticClass: "mb-2"
          }, [_vm._v(_vm._s(_vm._f("extensionDeviceInfo")(_vm._f("get_property")(extension, 'device_status.device_detail'), 'ip')) + " ")]), _c('span', {
            staticClass: "mb-2"
          }, [_vm._v(_vm._s(_vm._f("extensionDeviceInfo")(_vm._f("get_property")(extension, 'device_status.device_detail'), 'app-name')) + " " + _vm._s(_vm._f("extensionDeviceInfo")(_vm._f("get_property")(extension, 'device_status.device_detail'), 'location')))]), _c('span', {
            staticClass: "mb-2"
          }, [_vm._v("Next registration: " + _vm._s(_vm._f("dateFormate")(_vm._f("get_property")(extension, 'device_status.next_registration'))) + " ")]), _c('span', {
            staticClass: "mb-0"
          }, [_vm._v("Last activity: " + _vm._s(_vm._f("dateFormate")(_vm._f("get_property")(extension, 'device_status.last_registration'))) + " ")])])]) : _vm._e()], 1), _vm.getProperty(extension, 'device_status.device_detail.extra_data') ? _c('span', {
            staticClass: "mobileTag blue mb-0"
          }, [_vm._v(" Online ")]) : _vm._e(), _vm.user.active_status != '1' ? _c('div', {
            staticClass: "suspendedTag"
          }, [_vm._v("Suspended")]) : _vm._e()])])])]), _c('td', {
            staticClass: "dialer-row-title dialer-col-right right",
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                '';
              }
            }
          }, [_c('div', {
            staticClass: "d-flex flex-row justify-content-end align-items-center w-fit-content ml-auto"
          }, [_c('b-icon', {
            staticClass: "mr-8px cursor_pointer_no_hover editPencilMobileIcon",
            staticStyle: {
              "font-size": "16px"
            },
            attrs: {
              "icon": "pencil-fill"
            },
            on: {
              "click": function ($event) {
                extension.type == 'MOBILEEXT' && !_vm.getUserPermissions.mobile_extension || extension.type == 'EXTERNALEXT' && !_vm.getUserPermissions.external_extension ? _vm.appNotify({
                  message: 'Your package does not support this feature',
                  type: 'danger'
                }) : _vm.$modal.show('EditExtensionDetail', {
                  extension,
                  voipaccount: _vm.voipaccount,
                  limit: _vm.response.extension_detail.extensionlimit[extension.limit_type]
                });
              }
            }
          }), _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip"
            }],
            class: {
              'for-disable': extension.type == 'MOBILEEXT' && !_vm.getUserPermissions.mobile_extension || extension.type == 'EXTERNALEXT' && !_vm.getUserPermissions.external_extension
            },
            attrs: {
              "title": extension.type == 'MOBILEEXT' && !_vm.getUserPermissions.mobile_extension || extension.type == 'EXTERNALEXT' && !_vm.getUserPermissions.external_extension ? 'Your package does not support this feature' : ''
            }
          }, [_c('b-form-checkbox', {
            staticClass: "newerSwitch",
            attrs: {
              "disabled": _vm.api.update_setting.send.includes(extension.accountcode) || extension.type == 'MOBILEEXT' && !_vm.getUserPermissions.mobile_extension || extension.type == 'EXTERNALEXT' && !_vm.getUserPermissions.external_extension,
              "checked": extension.extension_call_group === 'yes',
              "name": "check-button",
              "switch": ""
            },
            on: {
              "change": function ($event) {
                return _vm.updateSetting(extension.extension_call_group, extension.accountcode);
              }
            }
          })], 1)], 1)])]);
        }) : _vm._l(_vm.extensions, function (extension, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: extension.accountcode,
            on: {
              "click": function ($event) {
                extension.type == 'MOBILEEXT' && !_vm.getUserPermissions.mobile_extension || extension.type == 'EXTERNALEXT' && !_vm.getUserPermissions.external_extension ? _vm.appNotify({
                  message: 'Your package does not support this feature',
                  type: 'danger'
                }) : _vm.$modal.show('EditExtensionDetail', {
                  extension,
                  voipaccount: _vm.voipaccount,
                  limit: _vm.response.extension_detail.extensionlimit[extension.limit_type]
                });
              }
            }
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', {
            class: `ExtentionIcon ${extension.device_status.status == 'online' ? 'statusOnline' : 'statusOffline'}`
          }, [_c('vb-icon', {
            attrs: {
              "icon": _vm._f("extensionIcon")(extension.type),
              "width": "25px",
              "height": "25px"
            }
          })], 1), _c('div', {
            staticClass: "d-flex flex-column"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [extension.type == 'EXTERNALEXT' ? [_c('span', {
            staticClass: "mx-3 onlyfirstLetterCapital"
          }, [_vm._v(" External app "), _c('span', {
            staticClass: "extensionDeviceName"
          }, [_vm._v(" ( " + _vm._s(extension.accountname) + " ) ")])])] : _c('span', {
            staticClass: "mx-3 onlyfirstLetterCapital"
          }, [_vm._v(" " + _vm._s(extension.accountname) + " "), extension.device_status.device_detail ? _c('span', {
            staticClass: "extensionDeviceName"
          }, [_vm._v(" ( " + _vm._s(_vm._f("DeviceInfo")(_vm._f("get_property")(extension, 'device_status.device_detail'))) + " ) ")]) : _vm._e()]), _c('div', {
            staticClass: "deviceContainer"
          }, [extension.accountcode == _vm.registeredExtension ? _c('span', {
            staticClass: "newThisDeviceIndicaterTextBox-25-11-2023 ml-0",
            attrs: {
              "id": `${extension.accountcode}-current-device-popover`
            }
          }, [_c('span', [_vm._v("This device")])]) : _vm._e(), extension.accountcode == _vm.registeredExtension ? _c('b-popover', {
            attrs: {
              "custom-class": "thisDeviceCustomPopOver",
              "target": `${extension.accountcode}-current-device-popover`,
              "triggers": "hover",
              "placement": "topright"
            }
          }, [_c('div', {
            staticClass: "d-flex flex-column"
          }, [_c('span', {
            staticClass: "mb-2"
          }, [_vm._v(_vm._s(_vm._f("extensionDeviceInfo")(_vm._f("get_property")(extension, 'device_status.device_detail'), 'ip')) + " ")]), _c('span', {
            staticClass: "mb-2"
          }, [_vm._v(_vm._s(_vm._f("extensionDeviceInfo")(_vm._f("get_property")(extension, 'device_status.device_detail'), 'app-name')) + " " + _vm._s(_vm._f("extensionDeviceInfo")(_vm._f("get_property")(extension, 'device_status.device_detail'), 'location')))]), _c('span', {
            staticClass: "mb-2"
          }, [_vm._v("Next registration: " + _vm._s(_vm._f("dateFormate")(_vm._f("get_property")(extension, 'device_status.next_registration'))) + " ")]), _c('span', {
            staticClass: "mb-0"
          }, [_vm._v("Last activity: " + _vm._s(_vm._f("dateFormate")(_vm._f("get_property")(extension, 'device_status.last_registration'))) + " ")])])]) : _vm._e()], 1), _vm.getProperty(extension, 'device_status.device_detail.extra_data') ? _c('span', {
            staticClass: "OwnertaggedVersion mb-0 ml-16px"
          }, [_vm._v(" Online ")]) : _vm._e()], 2), _vm.user.active_status != '1' ? _c('div', {
            staticClass: "suspendedTag ml-3"
          }, [_vm._v("Suspended")]) : _vm._e()])])]), _c('td', {
            staticClass: "dialer-row-title dialer-col-right",
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                '';
              }
            }
          }, [_c('div', {
            staticClass: "d-flex flex-row justify-content-end align-items-center w-fit-content ml-auto"
          }, [_c('span', {
            staticClass: "newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content ml-0 mr-16px showOnly-OnHover-insideTable",
            on: {
              "click": function ($event) {
                extension.type == 'MOBILEEXT' && !_vm.getUserPermissions.mobile_extension || extension.type == 'EXTERNALEXT' && !_vm.getUserPermissions.external_extension ? _vm.appNotify({
                  message: 'Your package does not support this feature',
                  type: 'danger'
                }) : _vm.$modal.show('EditExtensionDetail', {
                  extension,
                  voipaccount: _vm.voipaccount,
                  limit: _vm.response.extension_detail.extensionlimit[extension.limit_type]
                });
              }
            }
          }, [_vm._v(" View info ")]), _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip"
            }],
            class: {
              'for-disable': extension.type == 'MOBILEEXT' && !_vm.getUserPermissions.mobile_extension || extension.type == 'EXTERNALEXT' && !_vm.getUserPermissions.external_extension
            },
            attrs: {
              "title": extension.type == 'MOBILEEXT' && !_vm.getUserPermissions.mobile_extension || extension.type == 'EXTERNALEXT' && !_vm.getUserPermissions.external_extension ? 'Your package does not support this feature' : ''
            }
          }, [_c('b-form-checkbox', {
            staticClass: "newerSwitch",
            attrs: {
              "disabled": _vm.api.update_setting.send.includes(extension.accountcode) || extension.type == 'MOBILEEXT' && !_vm.getUserPermissions.mobile_extension || extension.type == 'EXTERNALEXT' && !_vm.getUserPermissions.external_extension,
              "checked": extension.extension_call_group === 'yes',
              "name": "check-button",
              "switch": ""
            },
            on: {
              "change": function ($event) {
                return _vm.updateSetting(extension.extension_call_group, extension.accountcode);
              }
            }
          })], 1)])])]);
        })];
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-3",
            staticStyle: {
              "height": "48px",
              "width": "48px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "21px",
              "width": "180px"
            }
          })])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "32px",
              "width": "60px"
            }
          })])]);
        });
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "header"
  }, [!_vm.getIsMobile ? _c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_vm._v("Extension type")]), _c('th', {
    staticClass: "dialer-col-right cursor_pointer"
  }, [_vm._v("Receive calls")])]) : _vm._e()])], 2), _c('div', {
    staticClass: "position-relative"
  }, [_c('modal', {
    staticClass: "AddDevicesOptionsModal",
    attrs: {
      "name": "AddDevicesOptionsModal",
      "clickToClose": false,
      "width": "50%",
      "height": "auto",
      "scrollable": true
    }
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0 w-100 d-block"
  }, [_c('div', {
    staticClass: "d-flex alingn-items-center justify-content-between w-100"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer my-0"
  }, [_vm._v("Choose device type ")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('AddDevicesOptionsModal');
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('AddDevicesOptionsModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v(" Select the device type you want to add: either a handset for traditional usage or an external device for expanded functionality. ")])]), _c('div', {
    staticClass: "AddDevicesOptionsModal-boxes"
  }, [_c('div', {
    staticClass: "AddDevicesOptionsModal-box",
    on: {
      "click": function ($event) {
        _vm.remainings.desktop <= 0 ? _vm.appNotify({
          message: 'Your limit for adding this service has been reached.',
          type: 'danger'
        }) : _vm.$modal.show('PurchaseExtensionModal', {
          type: 'desktop'
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-desktop-icon",
      "height": "47px",
      "width": "47px"
    }
  }), _c('div', {
    staticClass: "AddDevicesOptionsModal-box-heading"
  }, [_vm._v("Desktop app")]), _c('div', {
    staticClass: "AddDevicesOptionsModal-box-limitText"
  }, [_vm._v(" Limit: " + _vm._s(_vm._f("get_property")(_vm.response.extension_detail, 'extensionlimit.desktop.allowed', 0)) + " ")])], 1), _c('div', {
    staticClass: "AddDevicesOptionsModal-box",
    on: {
      "click": function ($event) {
        _vm.remainings.web <= 0 ? _vm.appNotify({
          message: 'Your limit for adding this service has been reached.',
          type: 'danger'
        }) : _vm.$modal.show('PurchaseExtensionModal', {
          type: 'web'
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-web-icon",
      "height": "47px",
      "width": "47px"
    }
  }), _c('div', {
    staticClass: "AddDevicesOptionsModal-box-heading"
  }, [_vm._v("Web extension")]), _c('div', {
    staticClass: "AddDevicesOptionsModal-box-limitText"
  }, [_vm._v(" Limit: " + _vm._s(_vm._f("get_property")(_vm.response.extension_detail, 'extensionlimit.web.allowed', 0)) + " ")])], 1), _c('div', {
    staticClass: "AddDevicesOptionsModal-box",
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.mobile_extension || _vm.remainings.mobile <= 0 ? _vm.appNotify({
          message: 'Your limit for adding this service has been reached.',
          type: 'danger'
        }) : _vm.$modal.show('PurchaseExtensionModal', {
          type: 'mobile'
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-mobile-icon",
      "height": "47px",
      "width": "47px"
    }
  }), _c('div', {
    staticClass: "AddDevicesOptionsModal-box-heading"
  }, [_vm._v("Mobile app")]), _c('div', {
    staticClass: "AddDevicesOptionsModal-box-limitText"
  }, [_vm._v(" Limit: " + _vm._s(_vm._f("get_property")(_vm.response.extension_detail, 'extensionlimit.mobile.allowed', 0)) + " ")])], 1), _c('div', {
    staticClass: "AddDevicesOptionsModal-box",
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.external_extension || _vm.remainings.external <= 0 ? _vm.appNotify({
          message: 'Your limit for adding this service has been reached.',
          type: 'danger'
        }) : _vm.$modal.show('PurchaseExtensionModal', {
          type: 'external'
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-external-icon",
      "height": "47px",
      "width": "47px"
    }
  }), _c('div', {
    staticClass: "AddDevicesOptionsModal-box-heading"
  }, [_vm._v("External app")]), _c('div', {
    staticClass: "AddDevicesOptionsModal-box-limitText"
  }, [_vm._v(" Limit: " + _vm._s(_vm._f("get_property")(_vm.response.extension_detail, 'extensionlimit.external.allowed', 0)) + " ")])], 1), _c('div', {
    staticClass: "AddDevicesOptionsModal-box",
    on: {
      "click": function ($event) {
        _vm.remainings.handset <= 0 ? _vm.appNotify({
          message: 'Your limit for adding this service has been reached.',
          type: 'danger'
        }) : _vm.$modal.show('PurchaseExtensionModal', {
          type: 'handset'
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-handset-icon",
      "height": "47px",
      "width": "47px"
    }
  }), _c('div', {
    staticClass: "AddDevicesOptionsModal-box-heading"
  }, [_vm._v("Handset")]), _c('div', {
    staticClass: "AddDevicesOptionsModal-box-limitText"
  }, [_vm._v(" Limit: " + _vm._s(_vm._f("get_property")(_vm.response.extension_detail, 'extensionlimit.handset.allowed', 0)) + " ")])], 1), _c('div', {
    staticClass: "AddDevicesOptionsModal-box",
    on: {
      "click": function ($event) {
        _vm.remainings.integration <= 0 ? _vm.appNotify({
          message: 'Your limit for adding this service has been reached.',
          type: 'danger'
        }) : _vm.$modal.show('PurchaseExtensionModal', {
          type: 'integration'
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-integration-icon",
      "height": "47px",
      "width": "47px"
    }
  }), _c('div', {
    staticClass: "AddDevicesOptionsModal-box-heading"
  }, [_vm._v("Integration")]), _c('div', {
    staticClass: "AddDevicesOptionsModal-box-limitText"
  }, [_vm._v(" Limit: " + _vm._s(_vm._f("get_property")(_vm.response.extension_detail, 'extensionlimit.integration.allowed', 0)) + " ")])], 1)])])]), _c('div', {
    staticClass: "dialer-flex dialer-dropdown justify-content-end"
  }), _c('PurchaseExtensionModal', {
    on: {
      "interface": function ($event) {
        _vm.$emit('updated');
        _vm.fetchExtensionDetailDebounce();
      }
    }
  }), _c('EditExtensionDetailModal', {
    ref: "edit-extension-detail-modal",
    on: {
      "update-list": function ($event) {
        _vm.$emit('updated');
        _vm.fetchExtensionDetailDebounce();
      }
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }