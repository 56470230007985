/*var session;
var sessions = [];
var incommingSession = null;*/


// import { MEDIA_DEVICES_SETTING } from "@/utils/mediaDevices";
import { INTEGRATIONS } from "@/integrations/index";
import { 
  $fn, 
  SIP_LOGGER,
  VOIP_API, 
  // VOIP_API 
} from "@/utils/index";
import Emitter from "component-emitter";
import moment from "moment";
import _ from 'lodash';
import { createModel } from 'vosk-browser'
import { 
  URI,
  //Invitation,
  Inviter,
  //InviterOptions,
  Registerer,
  Subscriber,
  //RegistererOptions,
  //Session,
  SessionState,
  UserAgent,
  //UserAgentOptions,
  //InvitationAcceptOptions 
} from "sip.js";

import Timer from "timer.js";
// import { TokenService } from "../services";
import store from "../store";
import { URLS } from "../utils";
// import { VOIP_API } from "../utils";
// import { MEDIA_DEVICES_SETTING, $fn } from "../utils";
export const user = {
  //  User Name
  "User" : "",
  //  Password
  "Pass" : "",
  //  Auth Realm
  "Realm"   : "",
  // Display Name
  "Display" : "WebRTC Client",
  // WebSocket URL
  "WSServer"  : ""
  // "WSServer"  : "wss://sip1.voipbusiness.com:8089/ws"
};

import getStats from "getstats";
// import { TokenService } from "@/services/storage.service";
// import { TokenService } from "@/services/storage.service";

function makeid(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
var newSession = (session,extra_data) => {
  session.stats = {
    localIPs: '',
    remoteIPs: '',
    bandwidth: '',
    audioLatency: '',
    packetsLost: '',
    sendCodes: '',
    recCodes: '',
    byteSend: '',
    byteRec: '',
  };
  session.transfercalls = []
  session.is_sip = true;
  session.events = {
    call_missed: 'call_missed',
    call_rejected: 'call_rejected',
    call_hangup: 'call_hangup',
    call_cancel: 'call_cancel',
    call_accepted: 'call_accepted',
  }
  session.other_person_hold = false
  session.uuid = extra_data?.uuid ?? phone.getUniqueID();
  session.callStatus = null;
  session.terminated_status = 'missed';
  session.timer = new Timer({
    tick : 1,
    ontick : function () {
      // SIP_LOGGER.log(duration());
      session.time = new Date((86400*1000) - session.timer.getDuration()).toISOString().substr(11, 8);
    },
    onstart : function() {
      SIP_LOGGER.log('timer started');
    }
  });
  session.extra_data=extra_data;
  session.is_terminated=false;
  session.time = null;
  // for video upgrade
  session.isUpgradeable = false;
  session.upgradeAccount = '';
  session.mute = false;
  session.switchable = false
  session.held = false;
  session.speech_to_text = {
    streams: [],
    async handler() {
      try {
        const local_streams = session?.sessionDescriptionHandler?.peerConnection?.getLocalStreams?.()
        const remote_streams = session?.sessionDescriptionHandler?.peerConnection?.getRemoteStreams?.()
        const streams = [...local_streams,...remote_streams]
        streams.forEach(stream=>{
          session.speech_to_text.streams.push({
            stream_id: stream.id,
            text: '',
            partial: '',
            model: null,
            recognizer: null,
            processor: null,
            source: null,
            audio_context: null,
            stream: stream,
            async handler(){
              this.model = await createModel('http://localhost:8090/assets/models/vosk-model-small-en-us-0.15.tar.gz',-1);
              this.recognizer = new this.model.KaldiRecognizer(48000);
              this.recognizer.on("result", (message) => this.text+=message.result.text?` ${message.result.text}`:'' );
              this.recognizer.on("partialresult", (message) => this.partial=message.result.partial );
              this.audio_context = new AudioContext();
              this.processor = this.audio_context.createScriptProcessor(4096, 1, 1)
              this.processor.connect(this.audio_context.destination);
              this.processor.onaudioprocess = (event) => this.recognizer.acceptWaveform(event.inputBuffer)
              this.source = this.audio_context.createMediaStreamSource(stream);
              this.source.connect(this.processor);
            },
            disconnect(){
              this.processor.disconnect(this.audio_context.destination)
              this.source.disconnect(this.processor)
              this.text = ''
              this.partial = ''
              this.model = null
              this.recognizer = null
              this.processor = null
              this.source = null
              this.audio_context = null
              this.stream = null
              session.speech_to_text.streams.splice(session.speech_to_text.streams.findIndex(i=>i.stream_id==this.stream_id),1)
            }
          })
        })
        session.speech_to_text.streams.forEach(i=>i.handler().bind(i))
        // session.speech_to_text.model = await createModel('http://localhost:8090/assets/models/vosk-model-small-en-us-0.15.tar.gz',-1);
        // session.speech_to_text.recognizer = new session.speech_to_text.model.KaldiRecognizer(48000);
        // session.speech_to_text.recognizer.on("result", (message) => session.speech_to_text.text+=message.result.text?` ${message.result.text}`:'' );
        // session.speech_to_text.recognizer.on("partialresult", (message) => session.speech_to_text.partial=message.result.partial );
        // const stream = session?.sessionDescriptionHandler?.peerConnection?.getLocalStreams?.()?.[0]
        // session.speech_to_text.audio_context = new AudioContext();
        // session.speech_to_text.processor = session.speech_to_text.audio_context.createScriptProcessor(4096, 1, 1)
        // session.speech_to_text.processor.connect(session.speech_to_text.audio_context.destination);
        // session.speech_to_text.processor.onaudioprocess = (event) => session.speech_to_text.recognizer.acceptWaveform(event.inputBuffer)
        // session.speech_to_text.source = session.speech_to_text.audio_context.createMediaStreamSource(stream);
        // session.speech_to_text.source.connect(session.speech_to_text.processor);
      } catch (error) {
        SIP_LOGGER.log('fail speech_to_text.handler',error)
      }
    },
    disconnect(){
      session.speech_to_text.streams.forEach(i=>i.disconnect().bind(i))
      // session.speech_to_text.processor.disconnect(session.speech_to_text.audio_context.destination)
      // session.speech_to_text.source.disconnect(session.speech_to_text.processor)
      // session.speech_to_text.text = ''
      // session.speech_to_text.partial = ''
      // session.speech_to_text.model = null
      // session.speech_to_text.recognizer = null
      // session.speech_to_text.processor = null
      // session.speech_to_text.source = null
      // session.speech_to_text.audio_context = null
    },
  }
  session.initSession = () => {
    session.held = false;
    session.mute = true;
    session.callState = 'incall';
    phone.setupMedia(session);
    SIP_LOGGER.log(phone.onInitSession);
    if(phone.onInitSession) {
      phone.onInitSession();
    }
    phone.stopRingbackTone();
    getStats(session?.sessionDescriptionHandler?.peerConnection,(result) => {
        session.stats.localIPs = result.connectionType.local.ipAddress.join(", ");
        session.stats.remoteIPs = result.connectionType.remote.ipAddress.join(", ");
        session.stats.bandwidth = result.bandwidth.speed;
        session.stats.audioLatency = result.audio.latency;
        session.stats.packetsLost = result.audio.packetsLost;
        session.stats.sendCodes = result.audio.send.codecs.join(", ");
        session.stats.recCodes = result.audio.recv.codecs.join(", ");
        session.stats.byteSend = result.audio.bytesSent;
        session.stats.byteRec = result.audio.bytesReceived;
      },
      2000
    );
  };
  session.terminateSession = () => {
    SIP_LOGGER.log(`terminateSession function run`,session.terminated_status)
    if(session.terminated_status=='bye'){
      session.emit(session.events.call_hangup,{session})
    } else if(session.terminated_status=='reject'){
      session.emit(session.events.call_rejected,{session})
      const { uniqe_id } = session.extra_data ?? {}
      if(uniqe_id) {
        VOIP_API.endpoints.call.onCallReject({
          channel_id: uniqe_id
        })
      }
      phone.onreject?.({session})
    } else if(session.terminated_status=='cancel'){
      session.emit(session.events.call_cancel,{session})
    } else if(session.terminated_status=='missed'){
      SIP_LOGGER.log('session.callState,session.direction',session.callState,session.direction)
      if(session.callState != 'incall' && session.direction != 'out') {
        session.emit(session.events.call_missed,{session})
        phone.onmissed?.({session})
      }
    }
    phone.lastCallDuration = session.time;
    session.callStatus = 'Terminated';
    session.timer.stop();
    SIP_LOGGER.log("End time"+session.time)
    session.is_terminated=true
    SIP_LOGGER.log(`before my code`)
    if(store.getters.isIntegration){
      INTEGRATIONS.callended()
      // if(phone.sessions.length===0){
      //   INTEGRATIONS.callcompleted()
      // }
    }
    SIP_LOGGER.log(`after my code`)
    if(phone.onTerminateSession) {
      phone.onTerminateSession();
    }
    phone.stopRingTone();
    phone.stopRingbackTone();
    if(phone.activeSession && phone.activeSession.uuid === session.uuid) {
      phone.activeSession = null;
    }
    if(phone.incommingSession && session.uuid === phone.incommingSession.uuid) {
      phone.incommingSession = null;
    }
    // code by bill for remove the tracks on call ended
    const local_streams = session?.sessionDescriptionHandler?.peerConnection?.getLocalStreams?.() ?? []
    for (const stream of local_streams) {
      const tracks = stream.getTracks?.() ?? []
      for (const track of tracks) {
        track?.stop?.()
      }
    }
    if(session.extra_data?.is_transfer){
      const transfered_call = phone.sessions.find(i=>i.uuid==session.cuuid)
      if(transfered_call && transfered_call.transfercalls.includes(session.uuid)){
        transfered_call.transfercalls.splice(transfered_call.transfercalls.indexOf(session.uuid), 1);
      }
    }
    // ---------------------------
    // for(let i=0; i < phone.sessions.length; i++) {
    //   if(phone.sessions[i].uuid === session.uuid) {
    //     phone.sessions.splice(i,1);
    //     //return;
    //     if(store.getters.isIntegration && phone.sessions.length===0){
    //       INTEGRATIONS.callcompleted()
    //     }
    //   }
    // }
    // const setting = TokenService.SETTING.get()
    // const after_call_feedback = _.get(setting,'calls.feedback') == undefined || !!_.get(setting,'calls.feedback')
    const after_call_feedback = false
    
    if(session.callState == 'incall' && after_call_feedback){
      session.feedback_screen_active = true
    } else {
      // TODO - session revoke
      phone.removeSessionFromList(session.uuid)
    }
    if(session.callState == 'incall') {
      phone.onsessionterminate?.(session)
    }

    // SIP_LOGGER.log('*****Check If Custom Forward*****')
    // SIP_LOGGER.log(`uuid: ${phone.cTransferSession?.uuid} cuuid: ${session.cuuid} transfer Number: ${phone.cTransfer}`);
    // if(phone.cTransferSession?.uuid === session.cuuid && phone.cTransfer) {
    //   phone.cTransferSession.transfer(phone.cTransfer);
    //   phone.cTransferSession = null;
    //   phone.cTransfer = null;
    // }
  };
  session.establishingSession = () => {
    if(phone.onEstablishingSession) {
      phone.onEstablishingSession();
    }
  };
  session.sessionEvents = (newState) => {
    SIP_LOGGER.log(`session.sessionEvents`,newState)
    switch (newState) {
      case SessionState.Initial:
        SIP_LOGGER.log('SessionState Initial');
        break;
      case SessionState.Establishing:
          // Session is establishing.
          session.establishingSession();
          session.switchable=true
          break;
      case SessionState.Established:
        // Session has been established.
        session.timer.start(86400);
        // session.speech_to_text.handler()
        session.initSession();
        session.onestablishedhold?.()
        break;
      case SessionState.Terminating:
        SIP_LOGGER.log('SessionState Terminating');
          session.callStatus = 'Terminating';
          // session.speech_to_text.disconnect()
          break;
      case SessionState.Terminated:
        SIP_LOGGER.log('Session has terminated.',session.signalingState)
        session.terminateSession();
        break;
      default:
        break;
    }
  };
  session.delegate = {
    // Handle incoming REFER request.
    onRefer(referral) {
      // ...
      SIP_LOGGER.event('session.delegate.onRefer',referral)
    },
    onInfo(info) {
      SIP_LOGGER.event('session.delegate.onInfo',info)
    },
    onInvite(request, response, statusCode) {
      SIP_LOGGER.event('session.delegate.onInvite',request, response, statusCode)
    },
    onMessage(message) {
      SIP_LOGGER.event('session.delegate.onMessage',message)
    },
    onNotify(notification) {
      SIP_LOGGER.event('session.delegate.onNotify',notification)
    },
    onAccept(notification) {
      SIP_LOGGER.event('session.delegate.onAccept',notification)
    },
    onReject(notification) {
      SIP_LOGGER.event('session.delegate.onReject',notification)
    },
    onSessionDescriptionHandler(sessionDescriptionHandler, provisional) {
      SIP_LOGGER.event('session.delegate.onSessionDescriptionHandler',sessionDescriptionHandler, provisional)
    }
  };

  session.stateChange.addListener(session.sessionEvents);
  session.onBye = () => {
    SIP_LOGGER.log('Sending on Bye');
  }
  session.onByeRequest = session.terminateSession;

  session.toggelMute = (callback = null) => {
    if(!session) {
      return;
    }
    SIP_LOGGER.log('Toggel Mute');
    if (!session) {
        throw new Error("Session does not exist.");
    }
    var enable = !session.mute;
    const sessionDescriptionHandler = session.sessionDescriptionHandler;
    const peerConnection = sessionDescriptionHandler.peerConnection;
    peerConnection.getSenders().forEach((sender) => {
        if (sender.track) {
            sender.track.enabled = enable;
            session.mute = enable;
        }
    });

    if(callback){
      callback(enable);
    }
  }
  session.onaccepthold=null
  session.onestablishedhold=null
  session.feedback_screen_active = false
  session.hold = (callback = null) => {
    if(session.feedback_screen_active) return;
    if(!session) {
      return;
    }
    function holding(){

      const sessionDescriptionHandler = session.sessionDescriptionHandler;
  
      const options = {
          requestDelegate: {
              onAccept: () => {              
                SIP_LOGGER.log('call on hold');
              },
              onReject: () => {
                SIP_LOGGER.log('call on hold failed');
              }
          }
      };
      if(!session.held) {
  
        options.sessionDescriptionHandlerModifiers = [
            (description) => sessionDescriptionHandler.holdModifier(description)
        ];
      }
      
      session.invite(options).then(successMessage => {
        SIP_LOGGER.log(successMessage);
        session.held = !session.held;
        if(callback) {
          callback(session.held);
        }
        SIP_LOGGER.log(`Session: ${session.uuid} is ${session.held}`);
        if(session.held) {
          session.callStatus = 'OnHold';
        } else {
          session.callStatus = 'InCall';
        }
      }).catch(error => {
        SIP_LOGGER.log(error);
      });
    }
    if(session._state!=SessionState.Established) {
      session.onestablishedhold = () => {
        if(store.state.calls.active_call!=session.uuid){
          holding()
        }
      }
    }
    else holding()
  }

  // window.csession = session;

  session.transfer = (number, afterTransfer = {}) => {
    if(!session) {
      return;
    }
    const target = phone.makeURI(number);

    return session.refer(target, {
      requestDelegate: {
        onAccept() {
          SIP_LOGGER.log('Call Transfer Accepted');
          SIP_LOGGER.log(afterTransfer);
          if (typeof afterTransfer === 'function') {
              afterTransfer();
          }
          
        }
      }
    });
    //  .then(session => {
    //   window.transfer = session;
    //   SIP_LOGGER.log(session);
    // });
  }

  session.isHeld = () => {

    return session.held;
  }

  session.accepts = (cb) => {
    if(['listen', 'whisper', 'barge', 'test'].includes(phone.activeSession?.extra_data?.call_type ?? '')){
      phone.activeSession.hangup()
    }
    session.emit(session.events.call_accepted,{session})
    phone.onaccept?.({session})
    const audioSource = phone.audioInputSelect.value; // MEDIA_DEVICES_SETTING.mic_id;
    // const videoSource = false; //phone.videoSelect.value;
    const constrainsDefault = {
    audio: {deviceId: audioSource ? {exact: audioSource} : undefined},
    video: false,
    // video: {deviceId: videoSource ? {exact: videoSource} : undefined},
  
    };

    // const constrainsDefault = {
    //   audio: true,
    //   video: false,
    // };

    const options = {
      sessionDescriptionHandlerOptions: {
        constraints: constrainsDefault,
      },
    };
    if(session) { 
      session.accept(options).then(() => {
        session.callStatus = 'InCall';
        phone.markSessionActive(session.uuid)
        cb?.()
      });
      // if(phone.activeSession && this.activeSession.held === false) {
      //   phone.activeSession.hold();
      // }
      // phone.hold(); 
      // phone.activeSession = phone.incommingSession;
      // phone.incommingSession = null;
    }
    phone.stopRingTone();
    // $('.incommingOptions').hide();
    if(phone.onAccepts) {
      phone.onAccepts();
    }
  }
  // session.hangup = () => {
  //   session.bye();
  // }
  session.feedback_screen = true
  session.hangup = async (options={}) => {
    if(session.feedback_screen_active){
      phone.removeSessionFromList(session.uuid)
      return;
    }
    session.feedback_screen = !options.forcefully
    // previous code before changing by bill waleed
    // SIP_LOGGER.log('session.callState,session.direction,session.terminated_status',session.callState,session.direction,session.terminated_status);
    // if(session.callState == 'incall') {
    //   SIP_LOGGER.log("bye");
    //   session.terminated_status='bye'
    //   return session.bye();
    // } else if(session.direction == 'in') {
    //   SIP_LOGGER.log("reject");
    //   session.terminated_status='reject'
    //   return session.reject();
    // } else if(session.direction == 'out') {
    //   SIP_LOGGER.log("cancel");
    //   session.terminated_status='cancel'
    //   return session.cancel();
    // } else {
    //   session.terminated_status='missed'
    // }
    // if(store.getters.isIntegration){
    // INTEGRATIONS.callended()
    //   if(phone.sessions.length==0){
    //      INTEGRATIONS.callcompleted()
    //   }
    // }
    
    // code change by bill waleed
    try { 
      SIP_LOGGER.log('session.callState,session.direction,session.terminated_status',session.callState,session.callStatus,session.direction,session.terminated_status);
      if(session.callState == 'incall') {
        SIP_LOGGER.log("bye");
        session.terminated_status='bye'
        await session.bye();
        phone.onhangup?.({session})
      } else if(session.direction == 'in') {
        SIP_LOGGER.log("reject");
        session.terminated_status='reject'
        await session.reject();
      } else if(session.direction == 'out') {
        SIP_LOGGER.log("cancel");
        session.terminated_status='cancel'
        await session.cancel();
      } else {
        session.terminateSession()
        session.terminated_status='missed'
      }
      if(store.getters.isIntegration){
        INTEGRATIONS.callended()
        // if(phone.sessions.length==0){
        //   INTEGRATIONS.callcompleted()
        // }
      }
    } catch(ex) {
      session.terminateSession()
    } finally {
      session.transfercalls.forEach(uuid => {
        phone.sessions.find(i=>i.uuid==uuid)?.hangup?.()
      });
    }
  };
  session.recording = false
  session.record = async () => {
    await session.sendDTMF('*')
    await session.sendDTMF('9')
    session.recording = !session.recording
  }
  session.sendDTMF = (tone) => {
    if (!/^[0-9A-D#*,]$/.exec(tone)) {
      return Promise.reject(new Error("Invalid DTMF tone."));
    }
    const dtmf = tone;
    const duration = 160;
    const body = {
        contentDisposition: "render",
        contentType: "application/dtmf-relay",
        content: "Signal=" + dtmf + "\r\nDuration=" + duration
    };
    const requestOptions = { body };
    return session.info({ requestOptions }).then(() => {
        return;
    });
  }
  session.note = ''
  session.tags = []
  Emitter(session)
  return session;
}
let events = {
  incoming_call: 'incoming_call',
  user_state_change: 'user_state_change',
  user_blf_change: 'user_blf_change',
}
export const phone = {
  sessions: [],
  activeSession: null,
  callVolume: null,
  dnd: false,
  uuid: null,

  onaccept: null,
  onhangup: null,
  onsessionterminate: null,
  ondial: null,
  onreject: null,
  onmissed: null,
  
  onOutAccept: null,
  onOutReject: null,
  onInAccept: null,
  onInReject: null,
  ontimeout: null,
  onProgress: null,
  onTrying: null,
  onHold: null,
  onUnHold: null,
  onCancel: null,
  onMute: null,
  onUnMute: null,
  onRedirect: null,
  cTransfer: null,
  cTransferSession: null,
  registererOptions : {
    uri: null,
    authorizationUsername: '',
    authorizationPassword: '',
    displayName: '',
    forceRport: true,
    logLevel: 'warn',
    noAnswerTimeout: 600,
    transportOptions: {
      server: ''
    },
    sessionDescriptionHandlerFactoryOptions: {
      peerConnectionOptions: {
        iceCheckingTimeout: 0.5 * 1000,
      },
    },
  },
  // ringtones: {
  //   incoming: new Audio(require('@/assets/sounds/incoming.mp3')),
  //   outgoing: new Audio(require('@/assets/sounds/outgoing.mp3')),
  // },
  get events(){ return events },
  UADelegation: {
    onInvite(invitation) {
      SIP_LOGGER.event("UADelegation.onInvite",invitation)
      if((store.getters.isIntegration) && phone.sessions.length >= 1) {
        SIP_LOGGER.log(store.getters.isIntegration,phone.sessions.length)
        return;
      }
      invitation.callStatus = 'Incomming';
      invitation.additionalData = invitation.incomingInviteRequest.message?.headers?.["X-Ext-Info"]?.[0]?.raw;
      const [ channel, uniqe_id, link_id ] = invitation.incomingInviteRequest.message?.headers?.["X-Chan-Info"]?.[0]?.raw?.split('~') ?? [];

      //  $('.incommingOptions').show();
      
      if(phone.dnd) {
        SIP_LOGGER.log("phone.dnd",phone.dnd)
        invitation.reject();
        return;
      }
      if(phone.onIncomming){
        phone.onIncomming();
      }
      if(!phone.activeSession) {
        SIP_LOGGER.log("phone.activeSession",phone.activeSession)
        phone.startRingTone()
      }
      // An Invitation is a Session
      phone.incommingSession = invitation;
      phone.incommingSession.direction = 'in';
      phone.incommingSession.callState  = 'pending';
      phone.incommingSession.number = invitation.remoteIdentity.displayName;

      // Setup incoming session delegate
      /*this.incommingSession.delegate = {
        onRefer(referral) {
          // ...
        },
      };*/
      const session = newSession(invitation,{
        uid: makeid(50), // $fn.makeid(50),
        id: invitation.request.callId,
        uniqe_id: uniqe_id ?? '',
        link_id: link_id ?? '',
        channel: channel ?? '',
        bridgeuniqueid: '',
      });
      phone.sessions.push(session);
      phone.emit(phone.events.incoming_call,{
        session
      })
      if(store.getters.isIntegration){
        INTEGRATIONS.incomingcall()
      }
    },
    onDisconnect(error) {
      SIP_LOGGER.event("UADelegation.onDisconnect",error,phone.registerer)
      store.state.sip.state.is_connected=false
      phone.userAgent.transport.dispose()
      phone.is_ua_connected=false
      phone.userAgentRegister(false)
      if (error) {
        phone.attemptReconnection();
      }
    },
    onConnect() {
      SIP_LOGGER.event("UADelegation.onConnect",phone.registerer)
      store.state.sip.state.is_connected=true
      phone.is_ua_connected=true
      phone.userAgentRegister()
    },
    onMessage(message) {
      SIP_LOGGER.event("UADelegation.onMessage",message)
    },
    onNotify(notification) {
      SIP_LOGGER.event("UADelegation.onNotify",notification)
    },
  },
  is_ua_connected: false,
  is_try_register: false,
  register_logs: [],
  last_register_time: '',
  register_try_interval: null,
  async userAgentRegister(cb){
    clearTimeout(this.register_try_interval)
    if(this.is_try_register) {
      cb?.()
      return;
    }
    try {
      this.is_try_register=true
      await $fn.sleep(3*1000)
      SIP_LOGGER.log('User Agent Connected',phone.userAgent.isConnected(),'Register State',phone.registerer.state)
      if(!phone.userAgent.isConnected() && phone.is_ua_connected && !phone.attemptingReconnection){
        phone.attemptReconnection()
      } else  {
        if(!phone.userAgent.isConnected() && phone.registerer.state=='Registered'){
          await phone.registerer.unregister()
          SIP_LOGGER.success('registerer.unregister')
        } else if(phone.userAgent.isConnected() && (phone.registerer.state=='Unregistered' || phone.registerer.state=='Terminated')) {
          await phone.registerer.register()
          SIP_LOGGER.success('registerer.register')
        } else if(!phone.is_register && phone.registerer.state=='Registered'){
          const time = moment().format('YYYY/MM/DD hh:mm:ss a')
          phone.register_logs.push({
            time: time,
            register: true
          })
          phone.last_register_time=time
          phone.is_register=true
          store.state.sip.state.is_register=phone.is_register
        }
        // await phone.registerer.register()
        // const accountcode = TokenService.USER.get()?.account
        // const extension_accountcode = TokenService.USER.get()?.sip?.user
        // const res = await VOIP_API.endpoints.extensions.mobileextensions(accountcode)
        // const is_offline = await res?.data?.data?.extensions?.find?.(i=>i.accountcode==extension_accountcode)?.device_status?.status=='Offline'
        // if(is_offline){
        //   await phone.registerer.register()
        // }
      }
    } catch(ex) {
      SIP_LOGGER.danger('userAgentRegister',ex)
    } finally {
      this.is_try_register=false
      if(this.initialize) {
        this.register_try_interval = setTimeout(()=>{phone.userAgentRegister()},1 * 60 * 1000 /*2 min*/)
      }
      cb?.()
    }
  },
  userAgent: null,
  registerer: null,
  is_register: false,
  onTerminated: null,
  lastCallDuration: 0,
  users_accountcodes: [],
  subscribes: [],
  onuserstatechange: null,
  onuserblfchange: null,
  is_dialable: true,
  last_register: '',
  next_register: '',
  next_registration_time: 0,
  next_registration_time_interval: null,
  initialize: true,
  init: function(username,password,uuid) {
    this.initialize=true
    this.next_registration_time=0
    this.last_register = ''
    this.next_register = ''
    Emitter(this);
    user.User = username
    user.Pass = password
    user.Realm = (URLS?.sipSocketUrl ?? '').replace("wss://","").replace(":4444","")
    user.Display = "WebRTC Client"
    user.WSServer = URLS?.sipSocketUrl ?? ''
    try {
      this.registererOptions.uri = new URI('sip',username,(URLS?.sipSocketUrl ?? '').replace("wss://","").replace(":4444",""));
      this.registererOptions.authorizationUsername = username;
      this.registererOptions.authorizationPassword = password;
      this.registererOptions.displayName = user.Display;
      this.registererOptions.transportOptions.server = URLS?.sipSocketUrl;
      this.userAgent = new UserAgent(this.registererOptions);
      // this.userAgent.transport.stateChange(event => {
      //   SIP_LOGGER.log('this.userAgent.transport.stateChange',event)
      // });
      this.userAgent.delegate = this.UADelegation;
      // let timeaftercheck = 1 * 60 * 60 * 1000 // 2 min
      let registration_expire_time = 30 * 60 // 30 min
      this.registerer = new Registerer(this.userAgent,{
        expires: registration_expire_time,  // 30 min
      });
      
      this.registerer.stateChange.addListener((newState) => {
        SIP_LOGGER.log('registerer state',newState)
        const is_register = newState=='Registered'
        if(is_register){
          this.last_register = new Date().getTime()
          this.next_register = new Date(this.last_register+registration_expire_time*1000).getTime()
        }
        const time = moment().format('YYYY/MM/DD hh:mm:ss a')
        phone.register_logs.push({
          time: time,
          register: is_register
        })
        phone.last_register_time=time
        phone.is_register=is_register
        store.state.sip.state.is_register=phone.is_register
        // phone.userAgentRegister()
        phone.subscribeAllUsers()
      })
      this.uuid = uuid || this.getUniqueID();
      // if(this.last_register) this.next_register = new Date(this.last_register+timeaftercheck)
      // this.register_try_interval = setInterval(phone.userAgentRegister,timeaftercheck)
      this.next_registration_time_interval = setInterval(()=>{
        if(this.next_register){
          const time = Math.floor((new Date().getTime()-this.next_register)/1000)
          if(time>registration_expire_time){
            // TODO - if time is increased for expire time
            this.next_registration_time = 0
            phone.userAgentRegister()
          } else {
            // TODO - if time is not increased for expire time
            this.next_registration_time = time
          }
        }
      },1000)
      window.addEventListener("online", this.onOnline.bind(this));
      window.addEventListener("offline", this.onOffline.bind(this));
    } catch(ex){
      SIP_LOGGER.danger('init',ex)
    }
    return this;
  },
  removeSessionFromList(session_uuid=''){
    for(let i=0; i < phone.sessions.length; i++) {
      if(phone.sessions[i].uuid==session_uuid) {
        phone.sessions.splice(i,1);
        //return;
        if(store.getters.isIntegration && phone.sessions.length===0){
          INTEGRATIONS.callcompleted()
        }
      }
    }
  },
  onOnline(){
    SIP_LOGGER.event('online')
    phone.attemptReconnection();
  },
  onOffline(){
    //TODO - after offline
    SIP_LOGGER.event('offline')
    this.userAgent?.transport?.dispose?.()
  },
  disconnect(){
    return new Promise((resolve)=>{
      const instance = setTimeout(()=>{resolve()},10*1000)
      if(this.register_try_interval) clearInterval(this.register_try_interval)
      if(this.reconnectInterval) clearTimeout(this.reconnectInterval)
      if(this.next_registration_time_interval) clearInterval(this.next_registration_time_interval)
      phone.is_ua_connected=false
      phone.register_logs=[]
      phone.last_register_time=''
      phone.is_register=false
      store.state.sip.state.is_register=phone.is_register
      phone.hangupAllCalls()
      .finally(()=>phone?.unSubscribeAllUsers?.() ?? true)
      .finally(()=>phone?.registerer?.dispose?.() ?? true)
      .finally(()=>phone?.userAgent?.stop?.() ?? true)
      .finally(()=>{
        SIP_LOGGER.log('phone.userAgent.stop')
        window.removeEventListener("online", phone.onOnline.bind(phone));
        window.removeEventListener("offline", phone.onOffline.bind(phone));
        phone.is_dialable=true
      })
      .finally(()=>{
        SIP_LOGGER.log('disconnect')
        phone.initialize=false
        if(instance) clearTimeout(instance)
        resolve()
      })
    })
  },
  subscribeAllUsers(){
    if (this.is_register) {
      const diff = _.difference(this.users_accountcodes, this.subscribes.map(i=>i.account))
      for (let index = 0; index < diff.length; index++) {
        const account = diff[index];
        if(this.subscribes.find(i=>i.account==account)){
          this.unSubscribeUser()
        }
      }
      for (let index = 0; index < this.users_accountcodes.length; index++) {
        const account = this.users_accountcodes[index];
        this.subscribeUser(account)
      }
    }
  },
  unSubscribeAllUsers(){
    let list = this.users_accountcodes.map(i=>new Promise((resolve,reject)=>this.unSubscribeUser(i,()=>{resolve()},()=>{reject()})))
    return Promise.all(list)
    // for (let index = 0; index < this.users_accountcodes.length; index++) {
    //   const account = this.users_accountcodes[index];
    //   this.unSubscribeUser(account)
    // }
  },
  unSubscribeUser(account,cb,eb){
    try {
      const subscribe = phone.subscribes.find(i=>i.account==account)
      SIP_LOGGER.log(phone.subscribes,subscribe,account)
      if(!subscribe) {
        eb?.()
        return;
      }
      subscribe.subscriber.unsubscribe()
      .then(()=>{
        const index = phone.subscribes.findIndex(i=>i.account==account)
        if(index>-1) phone.subscribes.splice(index,1)
        cb?.()
      })
      .catch(()=>{
        eb?.()
      })
    } catch (error) {
      SIP_LOGGER.danger("error in unSubscribeUser function", error);
    }
  },
  subscribeUser(account){
    try {
      const subscribe = this.subscribes.find(i=>i.account==account)
      if(subscribe) return;
      const targetURI = new URI("sip", account, user.Realm);
      const subscriber = new Subscriber(this.userAgent, targetURI, "presence", {
        extraHeaders: [
          "Accept: application/pidf+xml, application/xpidf+xml"
        ],
      });
      subscriber.delegate = {
        onNotify(notification) {
          try {
            SIP_LOGGER.event(`subscriber.delegate.onNotify`);
            const accountcode = notification?.incomingNotifyRequest?.message?.from?.uri?.normal?.user;
            const fromXML = require("from-xml")?.fromXML;
            const data = fromXML(notification?.request?.body ?? '');
            SIP_LOGGER.event(`subscriber.delegate.onNotify`,data.presence.note);
            phone.emit(phone.events.user_state_change,{
              accountcode,
              state: data.presence.note,
            })
            phone.onuserstatechange?.({
              accountcode,
              state: data.presence.note,
            })
          } catch (error) {
            SIP_LOGGER.danger(`subscriber.delegate.onNotify`, error);
          }
        },
      };
      subscriber.stateChange.addListener(function (newState) {
        SIP_LOGGER.log(`subscriber.stateChange.addListener`,newState)
        try {
          phone.emit(phone.events.user_blf_change,{
            account,
            blf_state: newState,
          })
          phone.onuserblfchange?.({
            account,
            blf_state: newState,
          })
        } catch(error) {
          SIP_LOGGER.danger(`subscriber.stateChange.addListener`, error);
        }
      });
      subscriber.subscribe()
      .then(()=>{
        this.subscribes.push({
          account,
          subscriber,
        })
      })
    } catch (error) {
      SIP_LOGGER.danger("error in subscribe function", error);
    }
  },
  setUsersAccountcodes(accountcodes){
    this.users_accountcodes=accountcodes
    this.subscribeAllUsers()
  },
  startRingTone: () => {
    // try { document.getElementById('ringtone').play(); } catch (e) { SIP_LOGGER.log(e); }
    SIP_LOGGER.log('startRingTone')
    document.getElementById('ringtone').play()
    // phone.ringtones.incoming.play()
    .then(()=>{
      SIP_LOGGER.success('startRingTone')
    }).catch((error) => {
      SIP_LOGGER.danger('startRingTone',error)
    })
  },
  stopRingTone: async () => { 
    SIP_LOGGER.log('stopRingTone')
    try { 
      document.getElementById('ringtone').pause();
      // await phone.ringtones.incoming.pause()
      SIP_LOGGER.success('stopRingTone') 
    } catch (e) { 
      SIP_LOGGER.danger('stopRingTone',e)
    }
  },
  startRingbackTone: () => {
    SIP_LOGGER.log('startRingbackTone')
    // phone.ringtones.outgoing.play()
    document.getElementById('ringbacktone').play().then().catch((error) => {
      SIP_LOGGER.danger('startRingbackTone',error)
    })

    //try { document.getElementById('ringbacktone').play(); } catch (e) { SIP_LOGGER.log(e); }
  },
  stopRingbackTone: () => {
    // phone.ringtones.outgoing.pause()
    SIP_LOGGER.log('stopRingbackTone')
    try { 
      document.getElementById('ringbacktone').pause(); 
    } catch (e) { 
      SIP_LOGGER.danger('stopRingbackTone',e)
    }
  },
  makeURI: (target) => {
   // let target = $("#numDisplay").val();

   // $("#numDisplay").val("");

    if (target.indexOf('@') < 0) {
        target = target+'@'+(URLS?.sipSocketUrl ?? '').replace("wss://","").replace(":4444","");
    }
    if (target.indexOf(':') < 0) {
        target = 'sip:'+target;
    }
    // Send an outgoing INVITE request
    target = UserAgent.makeURI(target);
    if (!target) {
      throw new Error("Failed to create target URI.");
    }
    return target;
  },
  dial: async function(num,extra_data={},extraHeaders=[]) {
    SIP_LOGGER.log('phone.is_dialable',phone.is_dialable)
    if(!phone.is_dialable) return;
    SIP_LOGGER.log('after phone.is_dialable',phone.is_dialable)
    if(store.getters.isIntegration && !extra_data.is_transfer && phone.sessions.length >= 1) return;
    if(phone.userAgent.transport.state != "Connected" || !num) {
      return;
    }
    try {
      phone.is_dialable=false
      let target = this.makeURI(num);
      const audioSource = this.audioInputSelect?.value; // MEDIA_DEVICES_SETTING.mic_id;
      // const videoSource = false; //this.videoSelect.value;
      // Create a new Inviter
      SIP_LOGGER.log('mic id',this.audioInputSelect?.value)
      const inviterOptions = {
        anonymous: false,
        earlyMedia: true,
        inviteWithoutSdp: false,
        sessionDescriptionHandlerOptions: {
          //constraints: { audio: true, video: false }
          constraints: {
            audio: {deviceId: audioSource ? {exact: audioSource} : undefined},
            video: false,
           // video: {deviceId: videoSource ? {exact: videoSource} : undefined}
          },
        },

        extraHeaders: [
          `Dial-User-Call-Id: ${$fn.makeid(15)}`,
          ...extraHeaders,
        ],
      };
      SIP_LOGGER.log(inviterOptions)
      const inviter = new Inviter(this.userAgent, target, inviterOptions);
      SIP_LOGGER.log('inviter',inviter)
      let session = newSession(inviter,{
        id: inviter.request.callId,
        uniqe_id: '',
        link_id: '',
        channel: '',
        bridgeuniqueid: '',
        ...extra_data
      });
      session.number = num;
      session.direction = 'out';
      session.callState = 'pending';
      this.sessions.push(session);
      if(this.activeSession && this.activeSession.held === false) {
        SIP_LOGGER.log('hold active session')
        this.activeSession.hold(); //session
      }
      this.ondial?.()
      this.activeSession = session;
      const before_invite_time = moment().format('DD MMM YYYY hh:mm:ss a')
      SIP_LOGGER.success('before session.invite',this.registererOptions)
      const successMessage = await session.invite({
        // withoutSdp: true,
        requestDelegate: {
          onAccept: (inviteResponse) => {
            SIP_LOGGER.event('requestDelegate.onAccept',inviteResponse)
            session.callStatus = 'Incall';
            session.onaccepthold?.()
            if(phone.onOutAccept) {
              phone.onOutAccept();
            }
          },
          onProgress: (inviteResponse) => {
            SIP_LOGGER.event('requestDelegate.onProgress',inviteResponse)
            session.callStatus = 'Ringing';
            if(phone.onProgress) {
              phone.onProgress();
            }
            phone.setupMedia(session)
            phone.stopRingbackTone();
          },
          onRedirect: (inviteResponse) => {
            SIP_LOGGER.event('requestDelegate.onRedirect',inviteResponse)
            session.callStatus = 'Redirected';
            if(phone.onRedirect) {
              phone.onRedirect();
            }
          },
          onReject: (inviteResponse) => {
            SIP_LOGGER.event('requestDelegate.onReject',inviteResponse,inviteResponse?.message?.reasonPhrase)
            session.callStatus = inviteResponse.message.reasonPhrase
            if(phone.onOutReject) {
              phone.onOutReject(inviteResponse.message.reasonPhrase);
            }
          },
          onTrying: (inviteResponse) => {
            SIP_LOGGER.event('requestDelegate.onTrying',inviteResponse)
            session.callStatus = 'Trying';
            if(phone.onTrying) {
              phone.onTrying();
            }
          }
        },
      })
      const after_invite_time = moment().format('DD MMM YYYY hh:mm:ss a')
      const seconds = moment(after_invite_time,'DD MMM YYYY hh:mm:ss a').diff(moment(before_invite_time,'DD MMM YYYY hh:mm:ss a'),'seconds')
      SIP_LOGGER.success('session.invite',successMessage,seconds)
      this.startRingbackTone();
      store.commit('pushCallActivityTimer',extra_data.uid)
      if(store.getters.isIntegration){
        INTEGRATIONS.outgoingcall({
          number: num
        })
      }
    } catch(ex) {
      SIP_LOGGER.danger('session.invite',ex)
    } finally {
      phone.is_dialable=true
    }
  },
  sendDTMF: (tone) => {
    if (!/^[0-9A-D#*,]$/.exec(tone)) {
        return Promise.reject(new Error("Invalid DTMF tone."));
    }
    if (!phone.activeSession) {
        return Promise.reject(new Error("Session does not exist."));
    }
    const dtmf = tone;
    const duration = 160;
    const body = {
        contentDisposition: "render",
        contentType: "application/dtmf-relay",
        content: "Signal=" + dtmf + "\r\nDuration=" + duration
    };
    const requestOptions = { body };
    return phone.activeSession.info({ requestOptions }).then(() => {
        return;
    });
  },
  toggelMute: function() {
    this.activeSession.toggelMute();
  },
  getUniqueID: () => {
      return Math.random().toString(36).substr(2, 9);
  },
  setupMedia: function(session) {
    const remoteAudioTrack = this.getReceiverTrack('audio', session);
    SIP_LOGGER.log('remoteAudioTrack',remoteAudioTrack)
    const remoteStream = new MediaStream();
    if (remoteAudioTrack) {
        remoteStream.addTrack(remoteAudioTrack);
        document.getElementById('audioRemote').srcObject = remoteStream;
        document.getElementById('audioRemote').play().catch((error) => {
          SIP_LOGGER.danger('Error playing Remote MediaStream',error)
        });
    }
  },
  getReceiverTrack: function(kind, session) {
      if(!session) {
        return;
      }
      const sessionDescriptionHandler = session.sessionDescriptionHandler;
      if (!sessionDescriptionHandler) {
          return undefined;
      }
      const peerConnection = sessionDescriptionHandler.peerConnection;
      const rtpReceiver = peerConnection.getReceivers().find((receiver) => {
          return receiver.track.kind === kind ? true : false;
      });
      return rtpReceiver ? rtpReceiver.track : undefined;
  },
  bye: function() {
    this.activeSession.bye();
    if(store.getters.isIntegration){
      INTEGRATIONS.callended()
    }
  },
  cancel: function() {
    this.activeSession.cancel();
    if(store.getters.isIntegration){
      INTEGRATIONS.callended()
    }
  },
  hold: function() {

    if(this.activeSession){
      this.activeSession.hold();
    }
    
  },
  transfer: function(number) {
    this.activeSession.transfer(number);
  },
  accept: function() {
    this.incommingSession.accepts();
    if(store.getters.isIntegration){
      INTEGRATIONS.callanswered()
    }
  },
  getSessions: function () {
    return this.sessions;
  },
  markSessionActive: function(uuid) {
    SIP_LOGGER.log('this.activeSession.uuid,uuid',this.activeSession?.uuid,uuid)
    for(let i=0; i < this.sessions.length; i++) {
      if(this.sessions[i].uuid === uuid && this.activeSession?.uuid!==uuid) {
        if(this.activeSession && this.activeSession.held === false) {
          this.activeSession.hold();
        }
        this.activeSession = this.sessions[i];
        if(this.activeSession.held == true) {
          this.activeSession.hold();
        }
        // this.activeSession.setupMedia();
        break;
      }
    }
    this.setupMedia(this.activeSession);
    SIP_LOGGER.log('Media Setup')
  },
  onTerminateSession: () => {
    if(phone.onTerminated){
      phone.onTerminated();
    }
  },
  onInitSession: () => {
    
  },
  onEstablishingSession: () => {
    
  },
  onAccepts: () => {
    
  },
  onIncomming: () => {
    
  },
  volume: function(volume) {
    
      var v = volume / 100
      var audio = document.getElementById('audioRemote');
      audio.volume = v;
      return false;
  },
  toggleDnd: function(state = -1) {
    if(state == -1) {
    this.dnd = !this.dnd;
    return this.dnd;
    }
    this.dnd = state;
    return this.dnd;
  },
  reconnectLogs: [],
  reconnectionAttempts: 3,
  reconnectionDelay: 4,
  attemptingReconnection: false,
  shouldBeConnected: true,
  reconnectInterval: null,
  attemptReconnection : function (reconnectionAttempt = 1) {
    SIP_LOGGER.log('attemptReconnection::reconnectionAttempt',reconnectionAttempt)
    if (!this.shouldBeConnected) return;
    if (this.attemptingReconnection) return;
    // if (reconnectionAttempt > this.reconnectionAttempts) return;
    this.attemptingReconnection = true;
    
    let delay = 0
    if(this.reconnectionAttempt==1) delay = 0
    else if(this.reconnectionAttempt>50) delay = 1 * 60 * 1000
    else delay = this.reconnectionDelay * 1000
    
    this.reconnectInterval = setTimeout(() => {
      // If not intentionally connected, don't reconnect.
      if (!this.shouldBeConnected) {
        this.attemptingReconnection = false;
        return;
      }
      this.reconnectLogs.push({
        time: moment().utc().format('DD-MM-YYYY hh:mm:ss a'),
        attempt: reconnectionAttempt,
        delay,
      })
      // Attempt reconnect
      this.userAgent.reconnect()
      .then(() => {
        // Reconnect attempt succeeded
        this.attemptingReconnection = false;
      })
      .catch((error) => {
        // Reconnect attempt failed
        SIP_LOGGER.danger('attemptReconnection::reconnectionAttempt',error)
        this.attemptingReconnection = false;
        this.attemptReconnection(++reconnectionAttempt);
      });
    }, delay);

  },
  videoElement: null,
  audioInputSelect: null,
  audioOutputSelect: null,
  videoSelect: null,
  selectors: [],
  gotDevices: function(deviceInfos) {
    // Handles being called several times to update labels. Preserve values.
    
    SIP_LOGGER.log('gotDevices',phone.selectors)
    const values = phone.selectors.map(select => select.value);
    phone.selectors.forEach(select => {
    while (select.firstChild) {
      select.removeChild(select.firstChild);
    }
    });
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      const option = document.createElement('option');
      // if(deviceInfo.deviceId == 'default' || deviceInfo.deviceId == 'communications') {
      //   continue;
      // }
      option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === 'audioinput') {
        option.text = deviceInfo.label || `microphone ${phone.audioInputSelect.length + 1}`;
        phone.audioInputSelect.appendChild(option);
      } else if (deviceInfo.kind === 'audiooutput') {
        option.text = deviceInfo.label || `speaker ${phone.audioOutputSelect.length + 1}`;
        phone.audioOutputSelect.appendChild(option);
      } else if (deviceInfo.kind === 'videoinput') {
        option.text = deviceInfo.label || `camera ${phone.videoSelect.length + 1}`;
        phone.videoSelect.appendChild(option);
      } else {
        SIP_LOGGER.log('Some other kind of source/device: ', deviceInfo)
      }
    }
    phone.selectors.forEach((select, selectorIndex) => {
    if (Array.prototype.slice.call(select.childNodes).some(n => n.value === values[selectorIndex])) {
      select.value = values[selectorIndex];
    }
    });
  },
  attachSinkId: function(element, sinkId) {
    if (typeof element.sinkId !== 'undefined') {
      element.setSinkId(sinkId).then(() => {
        SIP_LOGGER.log(`Success, audio output device attached: ${sinkId}`);
      }).catch(error => {
        let errorMessage = error;
        if (error.name === 'SecurityError') {
          errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
        }
        SIP_LOGGER.danger('element.setSinkId',errorMessage);
        // Jump back to first output device in the list as it's the default.
        this.audioOutputSelect.selectedIndex = 0;
      });
    } else {
      SIP_LOGGER.warn('Browser does not support output device selection.');
    }
  },
  changeAudioDestination: function() {
    const audioDestination = this.audioOutputSelect.value; // MEDIA_DEVICES_SETTING.speaker_id;
    this.attachSinkId(this.videoElement, audioDestination);
  },
  gotStream: function(stream) {
    window.stream = stream; // make stream available to console
    this.videoElement.srcObject = stream;
    // Refresh button list in case labels have become available
    return navigator.mediaDevices.enumerateDevices();
  },
  handleError: function(error) {
    SIP_LOGGER.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
  },
  start: function() {
    if (window.stream) {
      window.stream.getTracks().forEach(track => {
        track.stop();
      });
    }
    const audioSource = this.audioInputSelect.value; // MEDIA_DEVICES_SETTING.mic_id;
    // const videoSource = false; //this.videoSelect.value;
    const constraints = {
      audio: {deviceId: audioSource ? {exact: audioSource} : undefined},
      // video: {deviceId: videoSource ? {exact: videoSource} : undefined}
    };
    navigator.mediaDevices.getUserMedia(constraints).then(this.gotStream).then(this.gotDevices).catch(this.handleError);
  },
  customBeforeTransfer(number, extra_data = {}) {
    if(!this.activeSession) {
      SIP_LOGGER.log("No Active call currently");
    }
    this.activeSession.transfercalls.push(extra_data.uuid)
    this.cTransfer = number;
    this.cTransferSession = this.activeSession;
    // if(!this.activeSession?.isHeld === false) {
    //   this.activeSession.hold();
    // }
    this.dial(number, extra_data);
    this.activeSession.cuuid = this.cTransferSession.uuid;
  },
  customAfterTransfer(){
      if(this.activeSession) {
        this.activeSession.bye();
      }
      this.cTransferSession.transfer(this.cTransfer, () => {
        phone.cTransferSession.bye();
        phone.cTransferSession = null;
        phone.cTransfer = null;
      });
  },
  cutomCancelTransfer(){
    const uuid = this.activeSession.uuid
    if(this.activeSession) {
      this.activeSession.hangup();
    }
    if(phone.cTransferSession) {
      this.markSessionActive(phone.cTransferSession.uuid);
      // this.activeSession =  phone.cTransferSession;
      this.activeSession.hold();
      if(this.activeSession.transfercalls.includes(uuid)){
        this.activeSession.transfercalls.splice(this.activeSession.transfercalls.indexOf(uuid), 1);
      }
      this.cTransferSession = null;
      this.cTransfer = null;
    }
  },
  hangupAllCalls(){
    return Promise.all(phone?.sessions?.map?.(session=>session.hangup({
      forcefully: true
    })) ?? []).then(() => {
      SIP_LOGGER.log('calls Terminated');
    });
  },
};


window.addEventListener("beforeunload", function (e) {
  SIP_LOGGER.log('Before loading'+e);

  let sessions = [];
  for(let i=0; i<phone.sessions.length; i++){
    sessions.push(phone.sessions[i]);
  }
  Promise.all(sessions.map(session =>{
    session.hangup();
  })).then(() => {
    SIP_LOGGER.log('calls Terminated');
  });
});



