var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crmView"
  }, [!_vm.GET_CRM_IS_CONNECTED ? _c('div', [_vm._v(" You are not connected with crm system right now contact for adminstrator for help "), _vm.getCurrentUser.user_type == 'main' ? _c('div', [_c('button', {
    on: {
      "click": function ($event) {
        return _vm.connect();
      }
    }
  }, [_vm._v(" Connect ")])]) : _vm._e()]) : _vm._e(), _vm.activeTab != 'crm-setting' ? _c('CrmHome', {
    ref: "crm-setting"
  }) : _vm._e(), _vm.activeTab === 'crm-setting' ? _c('CRMSetting', {
    ref: "crm-setting"
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }