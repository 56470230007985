<template>
  <div>
    <component 
      :is="propTaskID?'div':'modal'" 
      :name="modalName"
      class="EditTasksModal"
      width="50%"
      height="auto"
      :scrollable="true"
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose()"
    >
      <template v-if="$store.getters.getIsMobile">
        <div class="d-flex align-items-center mb-3">
          <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" :style="`pointer-events:${api.remove_task.send || api.update_task.send?'none':'auto'}`" :disabled="api.remove_task.send || api.update_task.send" @click="$modal.hide(modalName)" />
          <h2 class="dialer-settings-title w-100 mb-0 text-center flex-1">Edit task</h2>
          <button class="dialer-button dialer-button-primary" :disabled="api.remove_task.send || api.update_task.send" @click="updateTask()" type="button" >
            <template>Update</template>
          </button>
        </div>
        
        <div class="latestGreyBox-9-9-2023">
          <div class="basicWhiteIOScard insideWebApp">
            <div class="basicWhiteIOScard-item">
              <div class="d-flex align-items-center">
                <div @click="api.update_task.send || api.remove_task.send ? '' : forms.update_task.my_day=!forms.update_task.my_day" class="mr-20px mt-0 todo-smallGreyBox">
                  <b-icon icon="sun-fill" :variant="forms.update_task.my_day?'warning':'dark'" width="21.056px" height="21.052px"  />
                </div>
                <div @click="api.update_task.send || api.remove_task.send ? '' : forms.update_task.is_important=!forms.update_task.is_important" class="todo-smallGreyBox mt-0 ml-2">
                  <b-icon icon="exclamation-lg" :variant="forms.update_task.is_important?'danger':'dark'" width="21.056px" height="21.052px" />
                  <p v-if="api.update_task.validation_errors.is_important" class="text text-danger animated bounceIntop">
                    <span v-for="(em,i) in api.update_task.validation_errors.is_important" :key="i">{{em}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- task title in mobile section -->
        <div class="latestGreyBox-9-9-2023 mt-12px">
          <div class="basicWhiteIOScard insideWebApp">
            <template v-if="!isEmpty(response.task)">
              <div class="basicWhiteIOScard-item" v-if="!conditions.edit_title">
                <h2 class="dialer-edit-title my-0 flex-fill" @click="conditions.edit_title=true">{{ forms.update_task.title }}</h2>
                <div class="d-flex align-items-center">
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
              <div class="basicWhiteIOScard-item align-items-start flex-column" v-else>
                <div class="whiteBGinputWithGreyRoundedBorder w-100 d-flex align-items-center">
                  <b-input class="flex-fill"
                    type="text" 
                    :disabled="api.remove_task.send || api.update_task.send" 
                    v-model="forms.update_task.title"
                    :maxLength="$v.forms.update_task.title.$params.maxLength.max"
                  />
                  <div class="d-flex align-items-center">
                    <b-icon class="mx-3" @click="conditions.edit_title=false" icon="x-lg" variant="danger" />
                    <!-- <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" /> -->
                  </div>
                </div>
                <p class="mb-0 text-danger mt-1 text text-danger animated bounceIntop" v-if="(forms.update_task.submitted && $v.forms.update_task.title.$invalid) || api.update_task.validation_errors.title">
                  <span v-if="!$v.forms.update_task.title.required"> Title is required</span>
                  <span v-else-if="!$v.forms.update_task.title.minLength"> Title should be minimum {{$v.forms.update_task.title.$params.minLength.min}} character</span>
                  <span v-else-if="!$v.forms.update_task.title.maxLength"> Title can be maximum {{$v.forms.update_task.title.$params.maxLength.max}} character</span>
                  <span v-for="(em,i) in api.update_task.validation_errors.title" :key="i">{{em}}</span>
                </p>
              </div>
            </template>
            <div class="basicWhiteIOScard-item" v-else>
              <h2 class="dialer-edit-title my-0 flex-fill" ></h2>
              <div class="d-flex align-items-center">
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
            </div>
          </div>
        </div>

        <!-- task description and attachment in mobile section -->
        <div class="latestGreyBox-9-9-2023 mt-12px">
          <div class="basicWhiteIOScard insideWebApp">
            <!-- task description in mobile section -->
            <div v-if="conditions.edit_desc" class="basicWhiteIOScard-item align-items-start flex-column">
              <div class="d-flex align-items-center w-100">
                <textarea 
                  v-if="conditions.edit_desc"
                  class="w-100 border px-2 py1" 
                  rows="auto" 
                  v-model="forms.update_task.note" 
                  :disabled="api.update_task.send" 
                  placeholder="Write a description"
                  :maxlength="$v.forms.update_task.note.$params.maxLength.max"
                />
                <b-icon v-if="conditions.edit_desc" @click="conditions.edit_desc=false" icon="x-lg" variant="danger" font-weight="1.4" class="darker-chevron-down withoutPath mr-2"/>
              </div>
              <p v-if="(forms.update_task.submitted && $v.forms.update_task.note.$invalid) || api.update_task.validation_errors.note" class="text text-danger animated bounceIntop">
                <span v-if="!$v.forms.update_task.note.minLength"> Note should be minimum {{$v.forms.update_task.note.$params.minLength.min}} character</span>
                <span v-else-if="!$v.forms.update_task.note.maxLength"> Note can be maximum {{$v.forms.update_task.note.$params.maxLength.max}} character</span>
                <span v-for="(em,i) in api.update_task.validation_errors.note" :key="i">{{em}}</span>
              </p>
            </div>
            <div v-else class="basicWhiteIOScard-item">
              <div class="d-flex align-items-center">
                <div class="basicIOSIcon mr-16px">
                  <vb-icon class="" icon="latest-mobileDescription-icon"/>
                </div>
                <p class="mb-0">
                  {{ forms.update_task.note }}
                </p>
              </div>
              <vb-icon icon="rightFacingArrow-IOSIcon" @click="conditions.edit_desc=true" class="rightFacingArrow" width="7.066" height="11.57" />
            </div>
            <!-- task attacments in mobile section -->
            <div class="basicWhiteIOScard-item removeMt-45px">
              <Attachments :task_id="taskID" :disabled="api.remove_task.send" @media-gallery-modal-show="$modal.show(`${_uid}-MediaGalleryModal`,$event)" />
            </div>
          </div>
        </div>

        <!-- linked call in mobile web section -->
        <CallLog ref="call_log" :task_id="taskID" :cdr_id="response.task.cdr_id" :task="response.task" />
        <!--<div class="latestGreyBox-9-9-2023 mt-12px">
          <div class="basicWhiteIOScard insideWebApp">
            <div class="basicWhiteIOScard-item">
              <div class="d-flex align-items-center">
                <div class="basicIOSIcon mr-16px">
                  <vb-icon class="" icon="latest-mobileLinkedCall-icon"/>
                </div>
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">
                    Linked call
                  </div>
                </div>
              </div>
              <!~~ <div class="d-flex align-items-center">
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div> ~~>
            </div>
          </div>
        </div>-->

        <!-- all (other things left) mobile web section -->
        <div class="latestGreyBox-9-9-2023 mt-12px">
          <div class="basicWhiteIOScard insideWebApp">
            <!-- assigned to section -->
            <div class="basicWhiteIOScard-item">
              <div class="d-flex align-items-center">
                <div class="basicIOSIcon mr-16px">
                  <vb-icon class="" icon="latest-mobileAssignedTo-icon"/>
                </div>
                <div class="headingInsideTable">
                  Assigned to
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="allAssignedAvatarContainer">
                  <div 
                    class="vb-avatar-container" 
                    v-for="(assigned,index) in assigneds.array" 
                    :aria-disabled="index+1" 
                    :key="assigned.id" 
                    :style="`margin-left:-${assignedsMarginLeft}px;z-index:${(index+1)*1}`" 
                  >
                    <vb-avatar :is_blf="false" :id="assigned.assigned_to" />
                  </div>
                  <div v-if="assigneds.count">+{{assigneds.count}}</div>
                </div>
                <vb-icon @click="api.remove_task.send ? '' : $modal.show(`${_uid}-AddRemoveUsersTask`,{ task_id: taskID, type: 'task_assigned' })" icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
            </div>

            <!-- Status section -->
            <div class="basicWhiteIOScard-item flex-column align-items-start">
              <div class="w-100 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="basicIOSIcon mr-16px">
                    <vb-icon class="" icon="latest-mobileStatus-icon"/>
                  </div>
                  <div class="headingInsideTable">
                    Status
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <vb-select
                    class="unique-dropDown inside-basicWhiteIOScard-item mt-0"
                    :textElStyle="`background-color: ${selecetdStatus.color ?? ''};`"
                    :disabled="api.update_task.send || api.status.send || api.remove_task.send"
                    :selected="forms.update_task.status_id"
                    @change="forms.update_task.status_id = $event;"
                    :options="statuses"
                    :isCheckShow="true"
                  />
                  <!-- <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" /> -->
                </div>
              </div>
              <p class="text-danger mb-0" v-if="(forms.update_task.submitted && $v.forms.update_task.status_id.$invalid) || api.update_task.validation_errors.status_id">
                <span v-if="!$v.forms.update_task.status_id.required"> Status is required</span>
                <span v-for="(em,i) in api.update_task.validation_errors.status_id" :key="i">{{em}}</span>
              </p>
            </div>

            <!-- Priority section -->
            <div class="basicWhiteIOScard-item flex-column align-items-start">
              <div class="w-100 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="basicIOSIcon mr-16px">
                    <vb-icon class="" icon="latest-mobilePriority-icon"/>
                  </div>
                  <div class="headingInsideTable">
                    Priority
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <vb-select
                    class="unique-dropDown enterColor inside-basicWhiteIOScard-item mt-0"
                    :textElStyle="`background-color: ${selecetdPriority.color ?? ''};`"
                    :disabled="api.update_task.send || api.remove_task.send"
                    :selected="forms.update_task.priority"
                    @change="forms.update_task.priority = $event;"
                    :options="priorities"
                    :isCheckShow="true"
                  />
                  <!-- <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" /> -->
                </div>
              </div>
              <p class="text-danger mb-0" v-if="(forms.update_task.submitted && $v.forms.update_task.priority.$invalid) || api.update_task.validation_errors.priority">
                <span v-if="!$v.forms.update_task.priority.required"> Priority is required</span>
                <span v-else-if="!$v.forms.update_task.priority.valid"> Priority is invalid</span>
                <span v-for="(em,i) in api.update_task.validation_errors.priority" :key="i">{{em}}</span>
              </p>
            </div>

            <!-- Due Date section -->
            <div class="basicWhiteIOScard-item flex-column align-items-start">
              <div class="w-100 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="basicIOSIcon mr-16px">
                    <vb-icon class="" icon="latest-mobileDueDate-icon"/>
                  </div>
                  <div class="headingInsideTable">
                    Due date
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <vue-ctk-date-time-picker 
                    v-bind="ctkDateTimePickerOptions" 
                    :minDate="conditions.min_date" 
                    :disabled="api.update_task.send || api.remove_task.send" 
                    :format="'YYYY-MM-DD HH:mm'" 
                    v-model="forms.update_task.due_date"
                  ></vue-ctk-date-time-picker>
                  <!-- <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" /> -->
                </div>
              </div>
              <p class="text-danger mb-0" v-if="api.update_task.validation_errors.due_date">
                <span v-for="(em,i) in api.update_task.validation_errors.due_date" :key="i">{{em}}</span>
              </p>
            </div>

            <!-- groups section -->
            <div class="basicWhiteIOScard-item">
              <div class="d-flex align-items-center">
                <div class="basicIOSIcon mr-16px">
                  <vb-icon class="" icon="latest-mobileInGroups-icon"/>
                </div>
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">
                    Group
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <template v-if="response.task.group">
                  <span class="ml-2">{{ response.task.group.name }}</span>
                  <div class="smallColoredTag mr-3" :style="`background-color: ${response.task.group.color};`"></div>
                </template>
                <vb-icon @click="$modal.show(`${_uid}-MoveTask`,{ moved: true, payload: { task_id: taskID, flag: 'task' } })" icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
            </div>
            
            <!-- Spaces section -->
            <div class="basicWhiteIOScard-item">
              <div class="d-flex align-items-center">
                <div class="basicIOSIcon mr-16px">
                  <vb-icon class="" icon="latest-mobileInSpaces-icon"/>
                </div>
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">
                    Space
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <template v-if="response.task.spaces">
                  <span class="ml-2">{{ response.task.spaces.name }}</span>
                  <div class="smallColoredTag mr-3" :style="`background-color: ${response.task.spaces.color};`"></div>
                </template>
                <vb-icon @click="$modal.show(`${_uid}-MoveTask`,{ moved: true, payload: { task_id: taskID, flag: 'task' } })" icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
            </div>

            <!-- task viewers section -->
            <div class="basicWhiteIOScard-item">
              <div class="d-flex align-items-center">
                <div class="basicIOSIcon mr-16px">
                  <vb-icon class="" icon="latest-mobileTaskViewer-icon"/>
                </div>
                <div class="headingInsideTable">
                  Task viewer
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="allAssignedAvatarContainer">
                  <div 
                    class="vb-avatar-container" 
                    v-for="(viewer,index) in viewers.array" 
                    :aria-disabled="index+1" 
                    :key="viewer.id" 
                    :style="`margin-left:-${viewersMarginLeft}px;z-index:${(index+1)*1}`" 
                  >
                    <vb-avatar :is_blf="false" :id="viewer.accountcode" />
                  </div>
                </div>
                <div v-if="viewers.count">+{{viewers.count}}</div>
                <vb-icon @click="api.remove_task.send ? '' : $modal.show(`${_uid}-AddRemoveUsersTask`,{ task_id: taskID, type: 'task_viewer' })" icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
            </div>

          </div>
        </div>

        <!-- activity in mobile web section -->
        <div class="latestGreyBox-9-9-2023 mt-12px">
          <div class="basicWhiteIOScard insideWebApp">
            <Comments :task_id="taskID" :disabled="api.remove_task.send" />
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-12px">
          <div class="basicWhiteIOScard insideWebApp">
            <div class="basicWhiteIOScard-item" @click="api.remove_task.send ? '' : removeTask()">
              <!-- <b-button  @click="api.remove_task.send ? '' : removeTask()" variant="danger" >
                <b-icon icon="trash" width="21.056px" height="21.052px"/>
                {{v-if="response.task.deleted_status=='yes'?'Un Archived':'Archived'"}}
              </b-button> -->
              <div class="d-flex align-items-center">
                <div class="basicIOSIcon mr-16px">
                  <!-- <vb-icon class="" icon="latest-mobileAssignedTo-icon"/> -->
                </div>
                <div class="headingInsideTable">
                  {{response.task.deleted_status=='yes' ? 'Un Archived' : 'Archived'}}
                </div>
              </div>
              <b-icon icon="trash" width="21.056px" height="21.052px" variant="danger"/>
            </div>
          </div>
        </div>  

      </template>
      <template v-else>
        <div class="dialer-edit-header position-relative py-0 justify-content-between align-items-center">
          <template v-if="!isEmpty(response.task)">
            <!-- <h2>{{ response.task | get_property('spaces.prefix') }}#{{ response.task | get_property('id') }}</h2> -->
            <div class="d-flex align-items-center flex-fill whiteBGinputWithGreyRoundedBorder mr-4" v-if="conditions.edit_title">
              <b-input 
                type="text" 
                :disabled="api.remove_task.send || api.update_task.send || response.task.deleted_status!='no'" 
                v-model="forms.update_task.title"
                :maxLength="$v.forms.update_task.title.$params.maxLength.max"
                class="mr-2"
              />
              <b-icon @click="conditions.edit_title=false" icon="x-lg" variant="danger" />
            </div>
            <h2 v-else class="dialer-edit-title my-0" @click="conditions.edit_title=true">
              <vb-svg :disabled="api.remove_task.send || api.update_task.send || response.task.deleted_status!='no'" @click="$modal.hide(modalName)" class="cursor_pointer_no_hover" name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
              {{ response.task | get_property('spaces.prefix')}}#{{ response.task | get_property('id') }} {{ forms.update_task.title }}
            </h2>
            <p class="text text-danger animated bounceIntop" v-if="(forms.update_task.submitted && $v.forms.update_task.title.$invalid) || api.update_task.validation_errors.title">
              <span v-if="!$v.forms.update_task.title.required"> Title is required</span>
              <span v-else-if="!$v.forms.update_task.title.minLength"> Title should be minimum {{$v.forms.update_task.title.$params.minLength.min}} character</span>
              <span v-else-if="!$v.forms.update_task.title.maxLength"> Title can be maximum {{$v.forms.update_task.title.$params.maxLength.max}} character</span>
              <span v-for="(em,i) in api.update_task.validation_errors.title" :key="i">{{em}}</span>
            </p>
          </template>
          <h2 v-else></h2>
          <div class="dialer-edit-actions">
            <!-- <button class="newCloseButton" :disabled="api.remove_task.send || api.update_task.send" @click="$modal.hide(modalName)" type="button" >
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </button> -->
            <button v-if="response.task && response.task.deleted_status=='no'" class="newButton" :disabled="api.remove_task.send || api.update_task.send" @click="updateTask()" type="button" >
              <vb-icon icon="squared-done-icon" class="submitIcon" height="38px" width="38px" />
              <span class="newButton-textPart">Update</span>
            </button>
            <!-- <button class="dialer-button dialer-button-primary" :disabled="api.remove_task.send || api.update_task.send" @click="updateTask()" type="button" >
              <template>Update</template>
            </button> -->
          </div>
        </div>
        <!-- <vb-loading v-if="isEmpty(response.task) && api.get_task.send" /> -->
        <template v-if="isEmpty(response.task) && api.get_task.send">
          <div class="w-100 mt-20px">
            <div class="w-100 px-3 mt-20px">
              <div class="latestShimmerDesign w-100" style="height:40px;"></div>
            </div>
            <div class="d-flex mt-20px">
              <div class="w-25 px-3" v-for="n in 4" :key="n.id">
                <div class="latestShimmerDesign w-100" style="height:40px;"></div>
              </div>
            </div>
            <div class="w-100 px-3 mt-20px">
              <div class="latestShimmerDesign w-100" style="height:40px;"></div>
            </div>
            <div class="d-flex mt-20px">
              <div class="w-25 px-3" v-for="n in 4" :key="n.id">
                <div class="latestShimmerDesign w-100" style="height:40px;"></div>
              </div>
            </div>
            <div class="w-100 px-3 mt-20px">
              <div class="latestShimmerDesign w-100" style="height:40px;"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="d-flex align-items-center w-auto mt-12px">
            <!-- <div class="lightGreyText">VB-125</div> -->
            <!-- spaces section -->
            <div class="d-flex align-items-center w-25">
              <div class="lightGreyText">In space</div>
              <template v-if="response.task.spaces">
                <span class="ml-2">{{ response.task.spaces.name }}</span>
                <div class="smallColoredTag" :style="`background-color: ${response.task.spaces.color};`"></div>
              </template>
            </div>
            <!-- groups section -->
            <div class="d-flex align-items-center ml-4 w-25">
              <div class="lightGreyText">In group </div>
              <vb-select class="onlyTextInButton-dropDown"
                :disabled="api.update_task.send || api.groups.send || api.remove_task.send || response.task.deleted_status!='no'"
                v-model="forms.update_task.group_id"
                :options="response.groups"
                :valueField="'id'"
                :textField="'name'"
              >
                <template #selected-option="{ option }">
                  <span>{{ option | get_property('name','Select Group') }}</span>
                  <div v-if="option" class="smallColoredTag withMinHeightWidth" :style="`background-color: ${option.color};`"></div>
                </template>
                <template #option="{ option }">
                  <span class="ml-2">{{ option | get_property('name') }}</span>
                  <div class="smallColoredTag" :style="`background-color: ${option.color};`"></div>
                </template>
              </vb-select>
            </div>
            <!-- link tasks section -->
            <!-- <div class="d-flex align-items-center ml-4"> -->
            <div class="d-inline-block ml-4 w-50">
              <span class="lightGreyText">Linked tasks:</span>
              <span v-for="linkedTask in linkedTasks" :key="linkedTask.id" @click="getTask({ task_id: linkedTask.task_link_id })">
                {{ linkedTask | get_property('tasklistlink.spaces.prefix') }}{{ linkedTask.task_link_id }} ,
              </span>
              <!-- <b-button 
                :disabled="api.remove_task.send" 
                variant="link" 
                @click="$modal.show(`${_uid}-LinkTask`,{ task_id: taskID, task_ids: linkedTasksIds })"
              >
                <b-icon icon="x" />
              </b-button> -->
            </div>
            <!-- move task section -->
            <!-- <div class="d-flex align-items-center ml-4"> -->
              <!-- <b-button variant="link" @click="$modal.show(`${_uid}-MoveTask`,{ moved: true, payload: { task_id: taskID, flag: 'task' } })">
                <b-icon icon="pencil" />
              </b-button> -->
              <!-- <b-button 
                v-if="response.task.group"
                variant="link" 
                @click="$modal.show(`${_uid}-MoveTask`,{ 
                  moved: true, 
                  select: 'group', 
                  space_id: response.task.space_id,
                  payload: { 
                    group_id: response.task.group.id, 
                    flag: 'group' 
                  } 
                })"
              >
                <b-icon icon="pencil-fill" />
              </b-button> -->
            <!-- </div> -->
          </div>
          <div class="w-100 d-flex align-items-end mt-45px mb-32px">
            <!-- status section -->
            <div class="d-flex flex-column align-items-start mr-20px">
              <div class="lightGreyText-labelType">Status</div>
              <vb-select
                class="unique-dropDown"
                :textElStyle="`background-color: ${selecetdStatus.color ?? ''};`"
                :disabled="api.update_task.send || api.status.send || api.remove_task.send || response.task.deleted_status!='no'"
                :selected="forms.update_task.status_id"
                @change="forms.update_task.status_id = $event;"
                :options="statuses"
                :isCheckShow="true"
              />
              <p v-if="(forms.update_task.submitted && $v.forms.update_task.status_id.$invalid) || api.update_task.validation_errors.status_id" class="text text-danger animated bounceIntop">
                <span v-if="!$v.forms.update_task.status_id.required"> Status is required</span>
                <span v-for="(em,i) in api.update_task.validation_errors.status_id" :key="i">{{em}}</span>
              </p>
            </div>
            <!-- due date section -->
            <div class="d-flex flex-column align-items-start mr-20px">
              <div class="lightGreyText-labelType">Due date</div>
              <div class="d-flex align-items-center mt-12px">
                <!-- <b-form-checkbox 
                  :checked="isClosed" 
                  @change="forms.update_task.status_id=isClosed ? activeDefaultStatus.id : closedStatus.id;" 
                  class="makeCheckBoxesSquare ml-4 inTodo"
                  :disabled="api.update_task.send || api.status.send || api.remove_task.send"
                /> -->
                <vue-ctk-date-time-picker class="EditTaskModal-ckt-timeEditor"
                  v-bind="ctkDateTimePickerOptions" 
                  :minDate="conditions.min_date" 
                  :disabled="api.update_task.send || api.remove_task.send || response.task.deleted_status!='no'" 
                  :format="'YYYY-MM-DD HH:mm'" 
                  v-model="forms.update_task.due_date"
                >
                  <!--<template v-slot="{ dateFormatted, toggleDatePicker, isOpen }">
                    <!~~ , close ~~>
                    <div class="todo-smallGreyBox">
                      <div class="todo-smallGreyBox-text mr-20px">{{ dateFormatted }}</div>
                      <div class="ajeebTag">Due soon</div>
                      <b-icon 
                        @click="api.update_task.send || api.remove_task.send ? '' : toggleDatePicker(true)" 
                        :icon="isOpen ? 'chevron-up' : 'chevron-down'" 
                        class="darker-chevron-down" 
                      />
                    </div>
                  </template>-->
                </vue-ctk-date-time-picker>
                <p v-if="api.update_task.validation_errors.due_date" class="text text-danger animated bounceIntop">
                  <span v-for="(em,i) in api.update_task.validation_errors.due_date" :key="i">{{em}}</span>
                </p>
              </div>
            </div>
            <!-- priority section -->
            <div class="d-flex flex-column align-items-start mr-20px">
              <div class="lightGreyText-labelType">Priority</div>
              <vb-select
                class="unique-dropDown enterColor"
                :textElStyle="`background-color: ${selecetdPriority.color ?? ''};`"
                :disabled="api.update_task.send || api.remove_task.send || response.task.deleted_status!='no'"
                :selected="forms.update_task.priority"
                @change="forms.update_task.priority = $event;"
                :options="priorities"
                :isCheckShow="true"
              />
              <p v-if="(forms.update_task.submitted && $v.forms.update_task.priority.$invalid) || api.update_task.validation_errors.priority" class="text text-danger animated bounceIntop">
                <span v-if="!$v.forms.update_task.priority.required"> Priority is required</span>
                <span v-else-if="!$v.forms.update_task.priority.valid"> Priority is invalid</span>
                <span v-for="(em,i) in api.update_task.validation_errors.priority" :key="i">{{em}}</span>
              </p>
            </div>
            <div @click="api.update_task.send || api.remove_task.send || response.task.deleted_status!='no' ? '' : forms.update_task.my_day=!forms.update_task.my_day" class="mr-20px todo-smallGreyBox">
              <!-- <vb-icon :class="`${forms.update_task.my_day?'my-day':''}`" icon="todo-sun-icon" width="21.056px" height="21.052px"/> -->
              <b-icon icon="sun-fill" :variant="forms.update_task.my_day?'warning':'dark'" width="21.056px" height="21.052px"  />
            </div>
            <div @click="api.update_task.send || api.remove_task.send || response.task.deleted_status!='no' ? '' : forms.update_task.is_important=!forms.update_task.is_important" class="mr-20px todo-smallGreyBox">
              <b-icon icon="exclamation-lg" :variant="forms.update_task.is_important?'danger':'dark'" width="21.056px" height="21.052px" />
              <p v-if="api.update_task.validation_errors.is_important" class="text text-danger animated bounceIntop">
                <span v-for="(em,i) in api.update_task.validation_errors.is_important" :key="i">{{em}}</span>
              </p>
            </div>
            <!-- <div @click="api.remove_task.send ? '' : removeTask()" class="mr-20px todo-smallGreyBox">
              <b-icon v-if="response.task.deleted_status=='yes'" icon="trash" variant="danger" width="21.056px" height="21.052px"/>
              <b-icon v-else icon="trash" variant="danger" width="21.056px" height="21.052px"/>
            </div> -->
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD buttonHeightAuto ul-width-fit-content ml-auto mb-1">
              <b-dropdown varient="link" no-caret class="ico-filter" right> 
                <template #button-content>
                  <b-icon icon="three-dots-vertical" variant="dark" class="fillBlack" scale="1.5" />
                </template>
                <b-dropdown-item @click="forms.update_task.status_id=isClosed ? activeDefaultStatus.id : closedStatus.id;" :disabled="api.update_task.send || api.status.send || api.remove_task.send">
                  <vb-icon icon="markAsComplete-icon" width="14.49px" height="10.431px"/>
                  <span class="ml-2">
                    Mark as 
                    <template>{{isClosed ? 'incomplete' : 'complete'}}</template>
                  </span>
                </b-dropdown-item>
                <b-dropdown-item :disabled="response.task.deleted_status!='no'" @click="$modal.show(`${_uid}-MoveTask`,{ moved: true, payload: { task_id: taskID, flag: 'task' } })">
                  <vb-icon icon="MoveTask-icon" width="12.951px" height="10.638px" class="fillBlack"/>
                  <span class="ml-2">Move</span>
                </b-dropdown-item>
                <b-dropdown-item :disabled="api.remove_task.send || response.task.deleted_status!='no'" @click="$modal.show(`${_uid}-LinkTask`,{ task_id: taskID, task_ids: linkedTasksIds })">
                  <vb-icon icon="linkCallToTask-icon" height="14px" width="14px" class="fillBlack"/>
                  <span class="ml-2">Link task</span>
                </b-dropdown-item>
                <b-dropdown-item @click="api.remove_task.send || response.task.deleted_status!='no' ? '' : removeTask()">
                  <vb-icon icon="trash-InTodoCheckList-icon" height="14px" width="14px" class="fillBlack"/>
                  <span class="ml-2">{{response.task.deleted_status=='yes' ? 'Un Archived' : 'Archived'}}</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="w-100 d-flex align-items-end mt-45px mb-32px">
            <div class="d-flex flex-column align-items-start mr-20px">
              <div class="lightGreyText-labelType">Assigned to</div>
              <div class="d-flex align-items-center mt-12px">
                <div class="allAssignedAvatarContainer">
                  <div 
                    class="vb-avatar-container" 
                    v-for="(assigned,index) in assigneds.array" 
                    :aria-disabled="index+1" 
                    :key="assigned.id" 
                    :style="`margin-left:-${assignedsMarginLeft}px;z-index:${(index+1)*1}`" 
                  >
                    <vb-avatar :is_blf="false" :id="assigned.assigned_to" />
                  </div>
                  <div v-if="assigneds.count">+{{assigneds.count}}</div>
                </div>
                <div @click="api.remove_task.send || response.task.deleted_status!='no' ? '' : $modal.show(`${_uid}-AddRemoveUsersTask`,{ task_id: taskID, type: 'task_assigned' })" class="todo-smallGreyBox pillowType mt-0 ml-3">
                  <b-icon icon="plus-lg" class="darker-chevron-down ml-0"/>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column align-items-start mr-20px">
              <div class="lightGreyText-labelType">Task viewers</div>
              <div class="TaskViewers-box mt-12px">
                <b-icon icon="eye-fill" font-weight="1.3" class="darker-chevron-down withoutPath grey mr-2"/>
                <div class="todo-smallGreyBox-text color-black mr-20px">Watching</div>
                <div @click="api.remove_task.send || response.task.deleted_status!='no' ? '' : forms.update_task.is_watcher=!forms.update_task.is_watcher" class="TaskViewers-innerBox">
                  <b-icon :icon="forms.update_task.is_watcher?'check-lg':'x-lg'"/>
                  <p v-if="api.update_task.validation_errors.is_watcher" class="text text-danger animated bounceIntop">
                    <span v-for="(em,i) in api.update_task.validation_errors.is_watcher" :key="i">{{em}}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column align-items-start justify-content-end mr-20px">
              <div class="d-flex align-items-center mt-12px">
                <div class="allAssignedAvatarContainer">
                  <div 
                    class="vb-avatar-container" 
                    v-for="(viewer,index) in viewers.array" 
                    :aria-disabled="index+1" 
                    :key="viewer.id" 
                    :style="`margin-left:-${viewersMarginLeft}px;z-index:${(index+1)*1}`" 
                  >
                    <vb-avatar :is_blf="false" :id="viewer.accountcode" />
                  </div>
                  <div v-if="viewers.count">+{{viewers.count}}</div>
                </div>
                <div @click="api.remove_task.send || response.task.deleted_status!='no' ? '' : $modal.show(`${_uid}-AddRemoveUsersTask`,{ task_id: taskID, type: 'task_viewer' })" class="todo-smallGreyBox pillowType mt-0 ml-3">
                  <b-icon icon="plus-lg" class="darker-chevron-down ml-0"/>
                </div>
              </div>
            </div>
            <Timer :taskId="taskID" :seconds="tasktimer.time" :started="tasktimer.status=='1'" :disabled="response.task.deleted_status!='no'" @update-timer="updateActivitiesList()" />
          </div>
          <div class="d-flex flex-column align-items-start mt-45px">
            <div class="w-100 d-flex align-items-center justify-content-between">
              <div class="TODO-innerHeading">Description</div>
              <b-icon v-if="conditions.edit_desc" @click="response.task.deleted_status!='no'?'':conditions.edit_desc=false" icon="x" font-scale="1.4" scale="1.3" class="darker-chevron-down withoutPath mr-2"/>
              <b-icon v-else @click="response.task.deleted_status!='no'?'':conditions.edit_desc=true" icon="pencil-fill" font-weight="1.4" class="darker-chevron-down withoutPath mr-2"/>
            </div>
            <div class="whiteBGinputWithGreyRoundedBorder w-100 mt-12px" v-if="conditions.edit_desc">
              <textarea 
                v-if="conditions.edit_desc"
                class="TODO-textArea w-100" 
                rows="auto" 
                v-model="forms.update_task.note" 
                :disabled="api.update_task.send || response.task.deleted_status!='no'" 
                placeholder="Write a description"
                :maxlength="$v.forms.update_task.note.$params.maxLength.max"
              />
            </div>
            <div v-else class="TODO-greyBox mt-12px">
              <p class="w-75 todo-smallGreyBox-text mb-0">
                {{ forms.update_task.note }}
              </p>
            </div>
            <p v-if="(forms.update_task.submitted && $v.forms.update_task.note.$invalid) || api.update_task.validation_errors.note" class="text text-danger animated bounceIntop">
              <span v-if="!$v.forms.update_task.note.minLength"> Note should be minimum {{$v.forms.update_task.note.$params.minLength.min}} character</span>
              <span v-else-if="!$v.forms.update_task.note.maxLength"> Note can be maximum {{$v.forms.update_task.note.$params.maxLength.max}} character</span>
              <span v-for="(em,i) in api.update_task.validation_errors.note" :key="i">{{em}}</span>
            </p>
          </div>
          <Attachments 
            :task_id="taskID" 
            :disabled="api.remove_task.send || response.task.deleted_status!='no'" 
            @attachment-uploaded="updateActivitiesList()" 
            @attachment-removed="updateActivitiesList()" 
            @media-gallery-modal-show="$modal.show(`${_uid}-MediaGalleryModal`,$event)"
          />
          <CallLog ref="call_log" :task_id="taskID" :disabled="response.task.deleted_status!='no'" @call-linking-modal-show="$modal.show(`${_uid}-CallLinking`,$event)" :cdr_id="response.task.cdr_id" @call-log-updated="updateActivitiesList()" />
          <CheckList 
            :task_id="taskID" 
            ref="checklist"
            :disabled="api.remove_task.send || response.task.deleted_status!='no'" 
            @select-voip-users-modal-show="$modal.show(`${_uid}-EditTaskSelectVoipUsers`,$event)"
            @checklist-added="updateActivitiesList()" 
            @checklist-updated="updateActivitiesList()" 
            @checklist-removed="updateActivitiesList()" 
            @checklist-mark-change="updateActivitiesList()" 
          />
          <div>
            <Comments 
              :task_id="taskID" 
              ref="comments"
              :disabled="api.remove_task.send || response.task.deleted_status!='no'" 
              @comment-updated="updateActivitiesList()" 
              @comment-created="updateActivitiesList()" 
              @comment-removed="updateActivitiesList()" 
            />
            <!-- <Activities ref="activities" :task_id="taskID" /> -->
          </div>
        </template>
      </template>
    </component>
    <!-- <modal
      :name="modalName"
      class="EditTasksModal"
      width="50%"
      height="auto"
      :scrollable="true"
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose()"
    >
    </modal> -->
    <!-- <SelectUserModal @select="forms.update_task.assigned_to=$event.accountcode;updateActivitiesList();" /> -->
    <AddRemoveFromList :modalName="`${_uid}-AddRemoveUsersTask`" @update-list="getTask({ fill_form: false, });$emit('update-list');updateActivitiesList();" />
    <SelectSpaceGroupAndStatusModal :modalName="`${_uid}-MoveTask`" @moved="response.task={};getTask();$emit('update-list');updateActivitiesList();" />
    <LinkCallModal :modalName="`${_uid}-CallLinking`" @updated="$refs.call_log.onUpdatedCallLink($event)" />
    <LinkTaskModal :modalName="`${_uid}-LinkTask`" @updated="getTask();$emit('update-list');updateActivitiesList();" />
    <SelectVoipUsersModal :modalName="`${_uid}-EditTaskSelectVoipUsers`" @select="$refs['checklist'].assignUser($event)" />
    <MediaGalleryModal :modalName="`${_uid}-MediaGalleryModal`" />
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
// import Info from "../../Lists/Info.vue";
import { mapGetters } from 'vuex';
import { ctkDateTimePicker } from '@/mixin';
import moment from 'moment-timezone';
// import SelectUserModal from '../SelectUserModal.vue';
import required from 'vuelidate/lib/validators/required';
import minLength from 'vuelidate/lib/validators/minLength';
import maxLength from 'vuelidate/lib/validators/maxLength';
import _ from 'lodash'
import CheckList from '@/components/widgets/todo/CheckList.vue';
import Attachments from '@/components/widgets/todo/Attachments.vue';
import Timer from '@/components/widgets/todo/Timer.vue';
import Comments from '@/components/widgets/todo/Comments.vue';
// import Activities from '@/components/widgets/todo/Activity.vue';
import AddRemoveFromList from '../AddRemoveFromList.vue';
import SelectSpaceGroupAndStatusModal from './SelectSpaceGroupAndStatusModal.vue';
import CallLog from '@/components/widgets/todo/CallLog.vue';
import LinkCallModal from '@/components/Modals/Todo/LinkCallModal.vue';
import LinkTaskModal from '@/components/Modals/Todo/LinkTaskModal.vue';
import MediaGalleryModal from '@/components/Modals/Todo/MediaGalleryModal.vue';
import SelectVoipUsersModal from '@/components/Modals/SelectVoipUsersModal.vue';
const priorities = {
  high: {
    text: 'High',
    value: 'high',
    color: '#ff403b',
  },
  mid: {
    text: 'Medium',
    value: 'mid',
    color: '#ffba39',
  },
  low: {
    text: 'Low',
    value: 'low',
    color: '#8ad129',
  }
}
export default {
  name: "EditTasksModal",
  mixins: [
    ctkDateTimePicker,
  ],
  components: {
    // Info,
    // SelectUserModal,
    CheckList,
    Attachments,
    Comments,
    Timer,
    // Activities,
    AddRemoveFromList,
    SelectSpaceGroupAndStatusModal,
    CallLog,
    LinkCallModal,
    LinkTaskModal,
    MediaGalleryModal,
    SelectVoipUsersModal,
  },
  inject: [
    'isEmpty',
    'getProperty',
    'appNotify'
  ],
  props: {
    modalName: {
      type: String,
      default: 'EditTasks'
    },
    propTaskID: {
      type: [String,Number],
      default: ''
    },
  },
  data() {
    return {
      forms: {
        update_task: this.$helperFunction.formInstance({
          data: {
            title: '',
            status_id: '',
            group_id: '',
            due_date: '',
            priority: '',
            my_day: false,
            is_important: false,
            is_watcher: false,
            note: '',
          },
        }),
      },
      api: {
        get_task: this.$helperFunction.apiInstance(),
        remove_task: this.$helperFunction.apiInstance(),
        update_task: this.$helperFunction.apiInstance({ 
          validation_errors: true, 
          error_message: true, 
        }),
        spaces: this.$helperFunction.apiInstance(),
        groups: this.$helperFunction.apiInstance(),
        status: this.$helperFunction.apiInstance(),
        add_my_day: this.$helperFunction.apiInstance(),
        remove_my_day: this.$helperFunction.apiInstance(),
      },
      data: {
        id: '',
      },
      conditions: {
        min_date: '',
        edit_desc: false,
        edit_title: false,
        edit_group: false,
      },

      response: {
        status: {},
        task: {},
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    priorities(){ return Object.values(priorities) },
    statuses(){ return Object.values(this.response.status).map(i=>({ text: i.title, value: i.id })) },
    selecetdStatus(){ return this.response.status?.[this.forms.update_task.status_id] ?? {} },
    selecetdPriority(){ return priorities[this.forms.update_task.priority] ?? {} },
    myDay(){ return this.response.task?.my_day?.includes?.(this.getCurrentUser.account) ?? false },
    assignedsMarginLeft(){
      let difference = (this.assigneds.array.length * 35) - 150
      let divisible = difference / this.assigneds.array.length
      let afterDivisible = (divisible / this.assigneds.array.length) + divisible
      if(afterDivisible < 0) { afterDivisible = 0 }
      return afterDivisible
    },
    assigneds(){ 
      let array = [], count = 0, assigned = this.response.task?.assigned ?? []
      if(assigned?.length>3) {
        array = assigned.slice(0,3)
      } else {
        array = assigned
      }
      count = assigned.length-3
      return {
        array: array ?? [],
        count: count > 0 ? count : 0
      }
    },
    viewersMarginLeft(){
      let difference = (this.viewers.array.length * 35) - 150
      let divisible = difference / this.viewers.array.length
      let afterDivisible = (divisible / this.viewers.array.length) + divisible
      if(afterDivisible < 0) { afterDivisible = 0 }
      return afterDivisible
    },
    viewers(){ 
      let array = [], count = 0, viewer = this.response.task?.viewer ?? []
      if(viewer?.length>3) {
        array = viewer.slice(0,3)
      } else {
        array = viewer
      }
      count = viewer.length-3
      return {
        array: array ?? [],
        count: count > 0 ? count : 0
      }
    },
    linkedTasks(){
      const tasklink = this.response.task?.tasklink ?? []
      return tasklink
    },
    linkedTasksIds(){
      return this.linkedTasks.map(i=>~~i.task_link_id)
    },
    taskID(){ return this.response.task.id || this.data.id },
    closedStatus(){ return Object.values(this.response.status)?.find(i=>i.type=='closed') },
    activeDefaultStatus(){ return Object.values(this.response.status)?.find(i=>i.type=='active'&&i.is_global=='yes') },
    isClosed(){ return this.selecetdStatus?.type=='closed' },
    tasktimer(){ return this.response.task?.timer ?? {} },
  },
  validations: {
    forms: {
      update_task: {
        title: {
          required: required,
          minLength: minLength(1),
          maxLength: maxLength(150),
        },
        status_id: {
          required: required,
        },
        group_id: {
          
        },
        due_date: {
          
        },
        priority: {
          required: required,
          valid: (value) => { return priorities[value] }
        },
        my_day: {
          
        },
        is_important: {
          
        },
        is_watcher: {
          
        },
        note: {
          minLength: minLength(1),
          maxLength: maxLength(500),
        },
      },
    }
  },
  methods: {
    onBeforeOpen(event) {
      this.conditions.min_date = moment.utc().format('YYYY-MM-DD HH:mm')
      this.data.id=event?.params?.id ?? ''
      this.getTask()
    },
    onBeforeClose() {
      this.forms.update_task.reset()
      this.api.get_task.reset()
      this.api.remove_task.reset()
      this.api.update_task.reset()
      this.api.spaces.reset()
      this.api.groups.reset()
      this.api.status.reset()
      this.api.add_my_day.reset()
      this.api.remove_my_day.reset()
      this.data.id=''
      this.conditions.min_date = ''
      this.conditions.edit_desc = false
      this.conditions.edit_title = false
      this.response.status=[]
      this.response.groups=[]
      this.response.spaces=[]
      this.response.task={}
    },
    updateActivitiesList: _.debounce(function(){
      this.$refs.comments.getActivities()
    }, 2 * 1000),
    async getStatus(){
      if(this.api.status.send) return;
      try {
        this.api.status.send=true
        const { data } = await VOIP_API.endpoints.task_status.list({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          space_id: this.response.task.space_id,
        })
        this.response.status=_.keyBy(data ?? [],'id')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.status.send=false
      }
    },
    async getGroups(){
      if(this.api.groups.send) return;
      try {
        this.api.groups.send=true
        const { data: { data } } = await VOIP_API.endpoints.taskgroups.list({
          uid: this.getCurrentUser.uid,
          space_id: this.response.task.space_id,
        })
        this.response.groups = data ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.groups.send=false
      }
    },
    async getTask(options={}){
      const fill_form = options?.fill_form ?? true
      const task_id = ~~(options?.task_id ?? this.taskID)
      let task = {};
      if(options?.task_id) {
        task = _.cloneDeep(this.response.task)
        this.response.task = {}
      }
      if(this.api.get_task.send) return;
      try {
        this.api.get_task.send=true
        const { data } = await VOIP_API.endpoints.tasklist.find(task_id)
        this.response.task=data
        this.data.id=task_id
        if(fill_form){
          this.forms.update_task.title=data.title
          this.forms.update_task.priority=data.priority
          this.forms.update_task.note=data.note
          this.forms.update_task.due_date=data.due_date
          this.forms.update_task.assigned_to=data.assigned_to
          this.forms.update_task.status_id=data.status_id ?? '';
          this.forms.update_task.group_id=data.group_id ?? '';
          this.forms.update_task.space_id=data.space_id ?? '';
          this.forms.update_task.is_important=data.is_important=='1';
          this.forms.update_task.is_watcher=data.is_watcher=='1';
          this.forms.update_task.my_day=data.my_day?.includes?.(this.getCurrentUser.account) ?? false
        }
        this.getStatus()
        this.getGroups()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
        this.response.task=task
      } finally {
        this.api.get_task.send=false
      }
    },
    async removeTask(){
      if(this.api.remove_task.send) return;
      try {
        this.api.remove_task.send=true;
        const { confirm } = await this.$appConfirmation({
          title: "WARNING",
          message: `Are you sure you want to archived?`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        await VOIP_API.endpoints.tasklist.delete(this.taskID,{
          ...this.response.task.deleted_status=='yes' ? {
            flag: 'unarchived'
          } : {}
        })
        this.appNotify({
          message: 'Successfully Deleted',
          type: 'success',
        })
        this.$modal.hide(this.modalName)
        this.$emit('deleted')
        this.$emit('update-list')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.remove_task.send=false;
      }
    },
    async updateTask(){
      this.forms.update_task.submitted=true
      this.$v.forms.update_task.$touch()
      if(this.$v.forms.update_task.$invalid || this.api.update_task.send) return;
      try {
        this.api.update_task.send=true
        this.api.update_task.error_message=''
        this.api.update_task.validation_errors={}
        await VOIP_API.endpoints.tasklist.update(this.taskID,{
          assigned_by: this.getCurrentUser.account,
          uid: this.getCurrentUser.uid,
          space_id: this.response.task.space_id,
          group_id: this.forms.update_task.group_id,
          title: this.forms.update_task.title,
          status_id: this.forms.update_task.status_id,
          due_date: this.forms.update_task.due_date,
          priority: this.forms.update_task.priority,
          my_day: this.forms.update_task.my_day ? '1' : '0',
          is_important: this.forms.update_task.is_important ? '1' : '0',
          is_watcher: this.forms.update_task.is_watcher ? '1' : '0',
          note: this.forms.update_task.note,
          accountcode: this.getCurrentUser.account,
        })
        this.updateActivitiesList()
        this.$emit('updated')
        this.$emit('update-list')
        this.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
        this.api.update_task.validation_errors=ex.own_errors ?? {}
      } finally {
        this.forms.update_task.submitted=false
        this.api.update_task.send=false
      }
    },
  },
  mounted(){
    if(this.propTaskID) {
      this.onBeforeOpen({
        params: {
          id: this.propTaskID
        }
      })
    }
  },
  deactivated(){
    if(this.propTaskID) {
      this.onBeforeClose()
    }
  },
}
</script>

<style lang="scss">

  .EditTasksModal{
    &.vm--container {
      & > .vm--overlay{
        width: 100vw !important;
      }
      & > .vm--modal{
        max-height: calc(100vh - 120px); 
        min-height: auto;
        width: 55vw !important;
        min-width: 800px;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50% , -50%);
        border: 12px solid white;
        @include border-radius(0px); 
        padding: 32px 24px;
        overflow-y: auto;
        & > .dialer-edit-header{
          position: relative;
          display: flex;
        }
        .callActivity-Item-v2-container {
          width: 100%;
          &.dialledCall{
            margin-left: 0px;
          }
          .last-section {
            .time {
              & > span{
                opacity: 1;
              }
              & > .ico-filter > button{
                padding: 0px;
                background-color: transparent;

                display: none;
              }
            }
          }
        }
      }
    }

    
    .unique-dropDown{
      margin-top: 12px;
      &.dialer-dropdown > .dropdown.b-dropdown {
        & > button.btn-secondary.dropdown-toggle{
          @include border-radius(4px);
          border: none;
          padding: 4px 6px;
          background-color: #F3F3F3;
          & > span > span{
            @include border-radius(4px);
            height: 37px;
            min-width: 120px;
            font-size: 12px;
            line-height: 16px;
            color: white;
            font-weight: 600;
            background-color: #2B2C33;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 10px 16px;
            margin-right: 20px;
          }
          svg{
            color: #2B2C33;
            margin-right: 15px;
            path{
              stroke-width: 1px;
              stroke: #2B2C33;
              fill: #2B2C33;
            }
          }
        }
        & > ul.dropdown-menu.show{
          width: 100%;
          border: 1px solid #AFAFAF !important;
          @include border-radius(6px);
          padding: 8px;
          li {
            a{
              @include border-radius(6px);
              margin-bottom: 4px;
              padding: 8px 16px;
            }
            &:last-child{
              a{
                margin-bottom: 0px;
              }
            }
          }
        }

      }
      &.enterColor{
        &.dialer-dropdown > .dropdown.b-dropdown {
        & > button{
          & > span > span{
            background-color: #14BEB9;
          }
        }
      }
      }
    }
  }
  .textUnderline{
    text-decoration: underline;
    padding-left: 10px;
    margin-left: 8px;
    position: relative;
    cursor: pointer;
    &::before{
      content: '';
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 3px;
      background-color: #2B2C33;
      @include border-radius(50%);
    }
    &.withoutBefore{
      padding-left: 0px;
      margin-left: 0px;
      &::before{
        display: none;
      }
    }
  }
  .ml-60px{
    margin-left: 60px;
  }
  .mb-60px{
    margin-bottom: 60px;
  }
  .mt-50px{
    margin-top: 50px;
  }
  .mt-30px{
    margin-top: 30px;
  }
  .mt-45px{
    margin-top: 35px;
  }
  .mt-24px{
    margin-top: 24px;
  }
  .mr-20px{
    margin-right: 20px;
  }
  .mr-32px{
    margin-right: 32px;
  }
  .mb-32px{
    margin-bottom: 32px;
  }
  .mt-12px{
    margin-top: 12px;
  }
  .mt-38px{
    margin-top: 38px;
  }
  .lightGreyText{
    font-size: 20px;
    line-height: 27px;
    color: #707070;
  }
  .lightGreyText-labelType{
    font-size: 18px;
    line-height: 24px;
    color: #707070;
    font-weight: 600;
  }
  .smallColoredTag{
    background-color: #707070;
    @include border-radius(5px); 
    width: 16px;
    height: 16px;
    margin-left: 16px;
    &.withMinHeightWidth{
      min-width: 16px;
      min-height: 16px;
      margin-left: 8px;
    }
    &.red{
      background-color: #FF7258;
    }
    &.green{
      background-color: #00C267;
    }
    &.twentyPX{
      width: 20px;
      height: 20px;
    }
  }
  .onlyTextInButton-dropDown{
    margin-left: 6px;
    &.dialer-dropdown > .dropdown.b-dropdown {
      & > button{
        padding: 0px;
        border: none;
        svg{
          display: none;
        }
        span{
          font-size: 16px;
          line-height: 1.3;
          color: #707070;
          text-decoration: underline;
          padding: 0px;
          font-weight: 400;
        }
      }
      & > ul.dropdown-menu.show{
        width: 100%;
        border: 1px solid #AFAFAF !important;
        @include border-radius(6px);
        padding: 8px;
        li {
          a{
            @include border-radius(6px);
            margin-bottom: 4px;
            padding: 8px 16px;
          }
          &:last-child{
            a{
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
  .todo-smallGreyBox-text{
    font-size: 16px;
    line-height: 28px;
    color: #707070;
  }
  .todo-smallGreyBox{
    margin-top: 12px;
    margin-left: 4px;
    @include border-radius(4px);
    background-color: #F3F3F3;
    padding: 8px 16px;
    height: 45px;
    display: flex;
    align-items: center;
    
    &.pillowType{
      @include border-radius(16px);
      padding: 8px 16px;
      justify-content: center;
      width: 45px;
    }
  }
  .ajeebTag{
    height: 28px;
    @include border-radius(4px);
    background-color: #E6B33C;
    font-size: 12px;
    line-height: 16px;
    color: white;
    font-weight: 600;
    padding: 5px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #000000;
  }
  .makeCheckBoxesSquare.inTodo.custom-control.custom-checkbox{
    padding: 0px;
    label{
      &::before,&::after{
        top:0px
      }
    }
    &.withText{
      label{
        padding-left: 36px;
        &::before,&::after{
          top: 50%;
          transform: translateY(-50%);
          left: 0px;
        }
      }
    }
  }
  .darker-chevron-down{
    color: #2B2C33;
    margin-left: 15px;
    path{
      stroke-width: 1px;
      stroke: #2B2C33;
      fill: #2B2C33 !important;
    }
    &.withoutPath{
      path{
        stroke-width: 0px;
        stroke: transparent;
      }
      &.makeItRed{
        path{
          fill: rgb(220, 38, 38) !important;
        }
      }
    }
    &.grey{
      color: #707070;
      path{
        fill: #707070;
      }
    }
  }
  .allAssignedAvatarContainer{
    display: flex;
    align-items: center;
    position: relative;
    max-width: 150px;
    min-width: 100px;
    height: 45px;
    .vb-avatar-container{
      // position: absolute;
      // top: 5px;
      &:first-child{
        margin-left: 0px !important;
      }
    } 
  }
  .TaskViewers-box{
    border-radius: 4px;
    border: none;
    padding: 4px 6px;
    background-color: #F3F3F3;
    display: flex;
    align-items: center;
    .TaskViewers-innerBox{
      border-radius: 4px;
      height: 37px;
      width: 37px;
      font-size: 12px;
      line-height: 16px;
      color: white;
      font-weight: 600;
      background-color: #2B2C33;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px 10px;
    }
  }
  .TODO-innerHeading{
    font-size: 20px;
    line-height: 27px;
    font-weight: bold;
    color: #2B2C33;
  }
  .TODO-smallInnerHeading{
    font-size: 16px;
    line-height: 21px;
    font-weight: bold;
    color: #2B2C33;
  }
  .TODO-greyBox{
    padding: 24px 20px;
    background-color: #F3F3F3;
    @include border-radius(13px);
    width: 100%;
  }
  .TODO-attachmentImg{
    width: 232px;
    height: 137px;
    @include border-radius(6px);
    margin-right: 34px;
    object-fit: contain;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .Checklist-progressBar-container{
    position: relative;
    width: calc(100% - 41px);
    height: 12px;
    @include border-radius(6px);
    // background-color: #F3F3F3;
    background-color: white;
    .Checklist-progressBar-container-filler{
      position: absolute;
      height: 12px;
      @include border-radius(6px);
      background-color: #3699FF;
      top: 0px;
      left: 0px;
    }
  }
  .Checklist-progressBar-container-text{
    font-size: 12px;
    line-height: 1.4;
    font-weight: 600;
    color: #575757;
  }
  .TODO-textArea{
    padding: 12px 20px;
    // background-color: #F3F3F3;
    background-color: white;
    @include border-radius(13px);
    width: calc(100% - 35px - 16px);
    min-height: 150px;
    height: auto;
    max-height: 150px;
    border: none;
    resize: none;
    font-size: 16px;
    line-height: 21px;
    color: #707070;
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 0px;
    }
  }
  .TODO-comment-name{
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
    color: #202020;
    display: flex;
    align-items: center;
  }
  .TODO-comment-commentedTime{
    font-size: 14px;
    line-height: 19px;
    color: #707070;
    font-weight: 400;
  }
  .todo-comment-smallGreyBox{
    margin-top: 8px;
    // @include border-radius(13px);
    // background-color: #F3F3F3;
    // padding: 16px 20px;
    // min-height: 55px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1.4;
    color: #7A7A7A;
    font-weight: 600;
  }
  .todo-comment-actions{
    margin-top: 12px;
    display: flex;
    align-items: center;
  }




  .latestTabsDesign{
    width: 100%;
    display: flex;
    align-items: center;
    .latestTabsDesign-item{
      min-width: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: #F3F3F3;
      @include border-radius(4px);
      cursor: pointer;
      margin-right: 15px;
      padding: 15px 24px;
      font-size: 14px;
      font-weight: bold;
      line-height: 19px;
      color: #000000;
      &:last-child{
        margin-right: 0px;
      }
      &.active,&.selected,&:hover{
        color: white;
        background-color: black;
        transition: all 0.3s ease;
      }
    }
  }


  .statusDevider{
    font-size: 14px;
    font-weight: 900;
    line-height: 19px;
    color:#707070;
    margin: 10px 0px;
    padding-left: 44px;
    display: flex;
    align-items: center;
    .statusColorBox{
      background-color:#707070;
      height: 14px;
      width: 14px;
      @include border-radius(4px);
      margin-left: 10px;
    }
  }



  .createNewSpaceModal.vm--container {
    & > .vm--modal{
      max-height: calc(100vh - 120px); 
      min-height: auto;
      width: 650px !important;
      min-width: 650px;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50% , -50%);
      border: 12px solid white;
      @include border-radius(0px); 
      padding: 18px;
      overflow-y: auto;
      & > .dialer-edit-header{
        position: relative;
        display: flex;
      }
    }
  }
  .createSpace-greyBox{
    @include border-radius(13px);
    background-color: #F8F8F8;
    padding: 30px;
  }
  .createSpace-selectColor-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    .createSpace-selectColor-item-container{
      height: 34px;
      width: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px 4px;
    }
    .createSpace-selectColor-item{
      height: 30px;
      width: 30px;
      @include border-radius(5px);
      position: relative;
      transition: width 0.3s, height 0.3s;
      transform-style: ease;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &::after{
        opacity: 0;
        height: calc(100% - 4px);
        width: calc(100% - 4px);
        transition: width 0.3s, height 0.3s, opacity 0.3s;
        transform-style: ease;
      }
      &.selected{
        height: 34px;
        width: 34px;
        z-index: 0;
        transition: width 0.3s, height 0.3s;
        @include border-radius(6px);
        &::after{
          content:'';
          height: calc(100% - 4px);
          width: calc(100% - 4px);
          transition: width 0.3s, height 0.3s, opacity 0.3s;
          transform-style: ease;
          opacity: 1;
          position: absolute;
          top: 2px;
          left: 2px;
          border: 2px solid #F8F8F8;
          @include border-radius(5px);
          z-index: 1;
        }
        &.TransparentSelected{
          &::after{
            content:'';
            height: calc(100% - 0px);
            width: calc(100% - 0px);
            top: 0px;
            left: 0px;
            border: 2px solid black;
          }
        }
      }
      &.smaller{
        height: 20px;
        width: 20px;
        &::after{
          height: calc(100% - 2px);
          width: calc(100% - 2px);
        }
        &.selected{
          height: 22px;
          width: 22px;
          &::after{
            height: calc(100% - 2px);
            width: calc(100% - 2px);
            border: 1px solid #F8F8F8;
            top: 1px;
            left: 1px;
          }
        }
      }
    }
  }
  .todoList-blackBordered-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include border-radius(12px);
    border: 1px solid black;
    background-color: #F8F8F8;
    padding: 38px 32px;
    width: calc(50% - 14px);
    opacity: 0.7;
    svg{
      path{
        transition: fill 0.3s ease;
        fill: rgb(112, 112, 112);
      }
    }
    &.selected{
      opacity: 1;
      svg{
        path{
          transition: fill 0.3s ease;
          fill: black;
        }
      }
    }
  }
  .todoList-blackBordered-box-heading{
    font-size: 16px;
    line-height: 21px;
    font-weight: bold;
    color: black;
  }




  .EditSpaceModal.vm--container {
    & > .vm--modal{
      max-height: calc(100vh - 120px); 
      min-height: auto;
      width: 744px !important;
      min-width: 744px;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50% , -50%);
      border: 12px solid white;
      @include border-radius(0px); 
      padding: 18px;
      overflow-y: auto;
      & > .dialer-edit-header{
        position: relative;
        display: flex;
      }
    }
    &.smallerModal{
      & > .vm--modal{
        width: 610px !important;
        min-width: 610px;
      }
    }
  }
  .createSpace-whiteBorderedBox{
    background-color: white;
    border: 1px solid #707070;
    @include border-radius(6px);
    padding: 0px 8px;
    .makeCheckBoxesSquare.custom-control.custom-control-inline.custom-radio{
      padding: 0px;
      width: 100%;
      .custom-control-label{
        width: 100%;
        &::before,&::after{
          right: 20px;
          left: unset;
          top: 50%;
          transform: translateY(-50%);
          @include border-radius(4px);
        }
      }
      .custom-control-input:checked ~ .custom-control-label:before{
        background-color: black;
        border-color: black;
      }
    }
    .createSpace-whiteBorderedBox-row{
      border-bottom: 1px solid #D9D9D6;
      padding: 16px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 60px;
      cursor: pointer;
      &:last-child{
        border-bottom: 0px;
      }
    }
  }
  .createSpace-editableText{
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
















  .EditTaskModal-ckt-timeEditor.date-time-picker{
    & > .datetimepicker {
      .datepicker{
        *{
          transition: none !important;
        }
        // .datepicker-buttons-container{
        //   button{
        //     .datepicker-button-effect{
        //       // transform: none;
        //       // opacity: 0;
        //       // height: 0px;
        //       // width: 0px;
        //       // transition: all .45s cubic-bezier(.23,1,.32,1) 0ms;
        //       // transition-property: opacity , height , width;
        //       // transition-timing-function: ease;
        //       // transition-duration: .45s;
        //       transition: none;
        //     }
        //     &:hover{
        //       .datepicker-button-effect{
        //         // transform: none;
        //         // opacity: 1;
        //         // height: 100%;
        //         // width: 100%;
        //         // transition-property: opacity , height , width;
        //         // transition-timing-function: ease;
        //         // transition-duration: .05s;
        //         transition: none;
        //       }
        //     }
        //   }
        // }
      }
    }
  }

</style>