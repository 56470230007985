var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "AddListModal",
    attrs: {
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0 d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Create List ")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        _vm.$modal.hide(_vm.modalName);
        _vm.conditions.manual = false;
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    attrs: {
      "id": "CloseModalButton"
    },
    on: {
      "click": function ($event) {
        _vm.$modal.hide(_vm.modalName);
        _vm.conditions.manual = false;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)])]), _vm.conditions.table_show ? _c('div', [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": _vm.conditions.invalid_numbers > 0
    }
  }, [_vm._v(_vm._s(_vm.conditions.invalid_numbers) + " filters")]), _c('b-table', {
    attrs: {
      "responsive": "",
      "sticky-header": "700px",
      "items": _vm.data.data
    }
  }), !_vm.$v.forms.add_sheet.$invalid ? _c('button', {
    staticClass: "fullWidthDoneButton mt-20px",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_vm._v("No")]) : _vm._e(), !_vm.$v.forms.add_sheet.$invalid ? _c('button', {
    staticClass: "fullWidthDoneButton mt-20px",
    on: {
      "click": function ($event) {
        return _vm.upload();
      }
    }
  }, [_vm._v("Yes")]) : _vm._e()], 1) : _c('div', [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-20px"
  }, [_c('label', [_vm._v("List name")]), _c('b-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Enter list name"
    },
    model: {
      value: _vm.forms.add_sheet.name,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_sheet, "name", $$v);
      },
      expression: "forms.add_sheet.name"
    }
  })], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-20px"
  }, [_c('label', [_vm._v("Assign to")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "options": _vm.assignToOptions,
      "textField": "name",
      "valueField": "accountcode"
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function (_ref) {
        var option = _ref.option;
        return [_c('div', {
          staticClass: "d-flex align-content-center justify-content-between w-100 h-100"
        }, [option ? _c('Info', {
          attrs: {
            "id": option.accountcode,
            "is_blf": false,
            "is_suspended_show": false,
            "type": option.type
          }
        }) : _c('span', {
          staticClass: "p-0"
        }, [_vm._v("Select user or team")]), option ? _c('b-button', {
          staticClass: "align-content-center justify-content-center p-0 m-0 mr-2",
          staticStyle: {
            "line-height": "0"
          },
          attrs: {
            "variant": "link"
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "x",
            "scale": "1.5"
          },
          on: {
            "click": function ($event) {
              _vm.forms.add_sheet.assign_to = '';
            }
          }
        })], 1) : _vm._e()], 1)];
      }
    }, {
      key: "option",
      fn: function (_ref2) {
        var option = _ref2.option;
        return [_c('Info', {
          attrs: {
            "id": option.accountcode,
            "is_blf": false,
            "is_suspended_show": false,
            "type": option.type
          }
        }), _c('span', {
          staticClass: "ml-2"
        }, [_vm._v(_vm._s(option.type))])];
      }
    }, {
      key: "no-record",
      fn: function () {
        return [_vm._v("There is no user and team")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.forms.add_sheet.assign_to,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_sheet, "assign_to", $$v);
      },
      expression: "forms.add_sheet.assign_to"
    }
  })], 1), _vm.forms.add_sheet.csv_file ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-20px"
  }, [_c('label', [_vm._v("number column")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "options": _vm.data.columns
    },
    model: {
      value: _vm.forms.add_sheet.number_column,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_sheet, "number_column", $$v);
      },
      expression: "forms.add_sheet.number_column"
    }
  })], 1) : _vm._e(), _vm.forms.add_sheet.csv_file ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-20px"
  }, [_c('label', [_vm._v("name column")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "options": _vm.data.columns
    },
    model: {
      value: _vm.forms.add_sheet.name_column,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_sheet, "name_column", $$v);
      },
      expression: "forms.add_sheet.name_column"
    }
  })], 1) : _vm._e(), !_vm.conditions.manual ? [_c('div', {
    staticClass: "latestGreyBox-heading-main mt-20px"
  }, [_vm._v("Upload a CSV")]), _c('div', {
    staticClass: "uploadSoundFileDesign-latest py-3 position-relative mt-20px",
    on: {
      "dragenter": function ($event) {
        $event.preventDefault();
        _vm.conditions.drag_and_drop = true;
      }
    }
  }, [_vm.conditions.drag_and_drop ? _c('div', {
    on: {
      "dragenter": function ($event) {
        $event.preventDefault();
        _vm.conditions.drag_and_drop = true;
      },
      "dragover": function ($event) {
        $event.preventDefault();
        _vm.conditions.drag_and_drop = true;
      },
      "dragleave": function ($event) {
        $event.preventDefault();
        _vm.conditions.drag_and_drop = false;
      },
      "drop": function ($event) {
        $event.preventDefault();
        _vm.conditions.drag_and_drop = false;
        _vm.validateFile(_vm.getProperty($event, 'dataTransfer.files[0]', null));
      }
    }
  }, [_vm._v(" Drag and drop ")]) : _c('div', [_vm.forms.add_sheet.csv_file ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.forms.add_sheet.csv_file.name))]), _c('b-button', {
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        _vm.forms.add_sheet.csv_file = null;
        _vm.data.data = {};
        _vm.data.columns = [];
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "variant": "danger",
      "icon": "x"
    }
  })], 1)], 1) : _c('div', [_c('div', {
    staticClass: "w-100 d-flex justify-content-center"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "PowerDialer-upload-icon",
      "width": "48.68px",
      "height": "39.645px"
    }
  })], 1), _c('div', {
    staticClass: "uploadSoundFileDesign-heading"
  }, [_vm._v(" Select a "), _c('span', {
    staticClass: "bluer",
    staticStyle: {
      "text-decoration": "none"
    }
  }, [_vm._v("CSV")]), _vm._v(" file to upload ")]), _c('div', {
    staticClass: "uploadSoundFileDesign-text mt-10px"
  }, [_c('b', [_vm._v("or drag and drop it here")])])])])]), _c('div', {
    staticClass: "d-flex align-items-center flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main mt-20px mb-20px text-center"
  }, [_vm._v("OR")]), _c('button', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        _vm.conditions.manual = true;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart position-relative d-flex justify-content-center"
  }, [[_vm._v("Add manually")]], 2)], 1)])] : [_vm._l(_vm.forms.add_sheet.manually, function (data, index) {
    return _c('div', {
      key: index,
      staticClass: "w-100 mb-20px"
    }, [_c('div', {
      staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mt-20px"
    }, [_c('label', [_vm._v("Contact name")]), _c('b-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Enter contact name"
      },
      model: {
        value: data.name,
        callback: function ($$v) {
          _vm.$set(data, "name", $$v);
        },
        expression: "data.name"
      }
    })], 1), _c('div', {
      staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mt-20px mb-20px"
    }, [_c('label', [_vm._v("Phone number")]), _c('b-input', {
      directives: [{
        name: "vb-input-number-formate",
        rawName: "v-vb-input-number-formate"
      }],
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Enter phone number"
      },
      model: {
        value: data.number,
        callback: function ($$v) {
          _vm.$set(data, "number", $$v);
        },
        expression: "data.number"
      }
    })], 1), _c('b-icon', {
      staticClass: "cursor_pointer_no_hover",
      attrs: {
        "icon": "trash-fill",
        "font-scale": "1.5",
        "variant": "danger"
      },
      on: {
        "click": function ($event) {
          return _vm.forms.add_sheet.manually.splice(index, 1);
        }
      }
    })], 1);
  }), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_c('button', {
    staticClass: "newButton ml-auto",
    on: {
      "click": function ($event) {
        _vm.conditions.manual = false;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart position-relative d-flex justify-content-center"
  }, [[_vm._v("Back")]], 2)], 1), _c('button', {
    staticClass: "newButton ml-auto",
    on: {
      "click": function ($event) {
        return _vm.forms.add_sheet.manually.push({
          name: '',
          number: ''
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart position-relative d-flex justify-content-center"
  }, [[_vm._v("Add more")]], 2)], 1)])]], 2), !_vm.$v.forms.add_sheet.$invalid ? _c('button', {
    staticClass: "fullWidthDoneButton mt-20px",
    on: {
      "click": function ($event) {
        return _vm.validatePhoneNumber();
      }
    }
  }, [[_vm._v("Next")]], 2) : _vm._e()])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }