<template>
  <div class="crmView">
    <div v-if="!GET_CRM_IS_CONNECTED">
      You are not connected with crm system right now contact for adminstrator for help 
      <div v-if="getCurrentUser.user_type=='main'">
        <button @click="connect()">
          Connect
        </button>
      </div>
    </div>
    <CrmHome v-if="activeTab != 'crm-setting'" ref="crm-setting" />
    <CRMSetting v-if="activeTab === 'crm-setting'" ref="crm-setting" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CrmHome from './Home.vue'
import CRMSetting from './Settings.vue'
import { GET_CRM_IS_CONNECTED } from '@/store/helper/getters'
import { CRM_FETCH_STORE_DATA, CRM_LOGIN } from '@/store/helper/actions'
export default {
  name: 'CrmView',
  components: {
    CrmHome,
    CRMSetting
  },
  data() {
    return {
      api: {
        connect: this.$helperFunction.apiInstance(),
      }
    }
  },
  computed: {
    ...mapGetters([
      GET_CRM_IS_CONNECTED,
      'getCurrentUser',
      CRM_LOGIN,
      CRM_FETCH_STORE_DATA
    ]),
    activeTab(){ return this.$store.state.global_conditions.tab },
  },
  methods: {
    async connect(){
      if(this.api.connect.send) return;
      try {
        this.api.connect.send=true
        await this.CRM_LOGIN()
        await this.CRM_FETCH_STORE_DATA()
      } finally {
        this.api.connect.send=false
      }
    },
  },
}
</script>

<style>

</style>