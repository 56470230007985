<template>
  <div>
    <!--<modal class="AddListModal" name="AddListModal_old">
      <div class="dialer-edit-header pt-0 d-block">
        <div class="d-flex justify-content-between align-items-center w-100">
          <h2 class="dialer-settings-title newer mb-0">Add list </h2>
          <div class="dialer-edit-actions">
            <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide('AddListModal_old')" />
            <a id="CloseModalButton" v-else class="newCloseButton" @click="$modal.hide('AddListModal_old')">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a>
          </div>
        </div>
        <div class="vm--modal-text">Enter the name of the music to be uploaded below, and select the MP3 audio file.</div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-20px">
            <label>List name</label>
            <b-input type="text" class="w-100" v-model="forms.add_sheet.name" placeholder="Enter list name" />
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-20px">
            <label>Assign to</label>
            <vb-select 
              class="w-100"
              v-model="forms.add_sheet.assign_to"
              :options="users"
              textField="user.display_name"
              valueField="voipaccount"
            >
              <template #selected-option="{ option }">
                <div class="d-flex align-content-center justify-content-between w-100 h-100">
                  <Info v-if="option" :id="option.voipaccount" :is_blf="false" :is_suspended_show="false" :type="'user'" />
                  <span class="p-0" v-else>Select user</span>
                  <b-button v-if="option" variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;">
                    <b-icon icon="x" scale="1.5" @click="filters.reports.user='';" />
                  </b-button>
                </div>
              </template>
              <template #option="{ option }">
                <Info :id="option.voipaccount" :is_blf="false" :is_suspended_show="false" :type="'user'" />
              </template>
              <template #no-record>There is no user</template>
            </vb-select>
          </div>
          <template v-if="!conditions.manual">
            <div class="whiteBoxWithBorderInsideGreyBox">
              <div class="whiteBoxWithBorderInsideGreyBox-row">
                <div class="latestGreyBox-heading-main">Add Manually</div>
                <button class="newButton"  @click="conditions.manual = !conditions.manual">
                  <vb-icon icon="squared-add-icon" height="38px" width="38px"  />
                  <span class="newButton-textPart position-relative d-flex justify-content-center">
                    <template>Add</template>
                  </span>
                </button>
              </div>
            </div>
            <div class="uploadSoundFileDesign-latest py-3 position-relative mt-20px" >
              <div v-if="false" >
                Drag and drop
              </div>
              <template v-else>
                <div class="uploadSoundFileDesign-heading">
                  Drop your file here, or 
                  <span class="bluer cursor_pointer_no_hover">Browse</span>
                </div>
                <div class="uploadSoundFileDesign-text mt-10px">
                  5 KB-5 MB
                </div>
                <vb-icon 
                  icon="circularCross-icon" 
                  class="circularCross-icon position-absolute right-15 vertical-centring" 
                  width="29.25px" 
                  height="29.25px"
                />
              </template>
            </div>
          </template>
          <template v-else>
            <div class="w-100 whiteBoxWithBorderInsideGreyBox mb-20px">
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mt-20px">
                <label>Name</label>
                <b-input type="text" class="w-100" placeholder="Enter name" />
              </div>
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mt-20px mb-20px">
                <label>Number</label>
                <b-input type="text" class="w-100" placeholder="Enter number" />
              </div>
            </div>
            <div class="w-100 whiteBoxWithBorderInsideGreyBox mb-20px">
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mt-20px">
                <label>Name</label>
                <b-input type="text" class="w-100" placeholder="Enter name" />
              </div>
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mt-20px mb-20px">
                <label>Number</label>
                <b-input type="text" class="w-100" placeholder="Enter number" />
              </div>
            </div>
            <div class="w-100 d-flex justify-content-between align-items-center">
              <a id="CloseModalButton" class="newCloseButton" @click="conditions.manual = !conditions.manual">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Cancel</span>
              </a>
              <button class="newButton" >
                <vb-icon icon="squared-add-icon" height="38px" width="38px"  />
                <span class="newButton-textPart position-relative d-flex justify-content-center">
                  <template>Add</template>
                </span>
              </button>
            </div>
          </template>
        </div>
      </div>
    </modal>-->
    <modal class="AddListModal" :name="modalName" @before-open="onBeforeOpen($event)" @before-close="onBeforeClose()">
      <div class="dialer-edit-header pt-0 d-block">
        <div class="d-flex justify-content-between align-items-center w-100">
          <h2 class="dialer-settings-title newer mb-0">Create List </h2>
          <div class="dialer-edit-actions">
            <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName);conditions.manual=false" />
            <a id="CloseModalButton" v-else class="newCloseButton" @click="$modal.hide(modalName);conditions.manual=false">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a>
          </div>
        </div>
      </div>
      <div v-if="conditions.table_show">
        <b-alert variant="danger" :show="conditions.invalid_numbers>0">{{ conditions.invalid_numbers }} filters</b-alert>
        <b-table
          responsive
          sticky-header="700px"
          :items="data.data"
        >
        </b-table>
        <button v-if="!$v.forms.add_sheet.$invalid" @click="$modal.hide(modalName)" class="fullWidthDoneButton mt-20px">No</button>
        <button v-if="!$v.forms.add_sheet.$invalid" @click="upload()" class="fullWidthDoneButton mt-20px">Yes</button>
      </div>
      <div v-else>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-20px">
            <label>List name</label>
            <b-input type="text" class="w-100" v-model="forms.add_sheet.name" placeholder="Enter list name" />
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-20px">
            <label>Assign to</label>
            <vb-select 
              class="w-100"
              v-model="forms.add_sheet.assign_to"
              :options="assignToOptions"
              textField="name"
              valueField="accountcode"
            >
              <template #selected-option="{ option }">
                <div class="d-flex align-content-center justify-content-between w-100 h-100">
                  <Info v-if="option" :id="option.accountcode" :is_blf="false" :is_suspended_show="false" :type="option.type" />
                  <span class="p-0" v-else>Select user or team</span>
                  <b-button v-if="option" variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;">
                    <b-icon icon="x" scale="1.5" @click="forms.add_sheet.assign_to='';" />
                  </b-button>
                </div>
              </template>
              <template #option="{ option }">
                <Info :id="option.accountcode" :is_blf="false" :is_suspended_show="false" :type="option.type" />
                <span class="ml-2">{{option.type}}</span>
              </template>
              <template #no-record>There is no user and team</template>
            </vb-select>
          </div>
          <div v-if="forms.add_sheet.csv_file" class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-20px">
            <label>number column</label>
            <vb-select 
              class="w-100"
              v-model="forms.add_sheet.number_column"
              :options="data.columns"
            />
          </div>
          <div v-if="forms.add_sheet.csv_file" class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-20px">
            <label>name column</label>
            <vb-select 
              class="w-100"
              v-model="forms.add_sheet.name_column"
              :options="data.columns"
            />
          </div>
          <template v-if="!conditions.manual">
            <div class="latestGreyBox-heading-main mt-20px">Upload a CSV</div>
            <div class="uploadSoundFileDesign-latest py-3 position-relative mt-20px" @dragenter.prevent="conditions.drag_and_drop=true">
              <div 
                v-if="conditions.drag_and_drop" 
                @dragenter.prevent="conditions.drag_and_drop=true" 
                @dragover.prevent="conditions.drag_and_drop=true" 
                @dragleave.prevent="conditions.drag_and_drop=false" 
                @drop.prevent="conditions.drag_and_drop=false;validateFile(getProperty($event,'dataTransfer.files[0]',null))" 
              >
                Drag and drop
              </div>
              <div v-else>
                <div v-if="forms.add_sheet.csv_file">
                  <span>{{forms.add_sheet.csv_file.name}}</span>
                  <b-button @click="forms.add_sheet.csv_file=null;data.data={};data.columns=[];" variant="link">
                    <b-icon variant="danger" icon="x" />
                  </b-button>
                </div>
                <div v-else>
                  <div class="w-100 d-flex justify-content-center">
                    <vb-icon icon="PowerDialer-upload-icon" width="48.68px" height="39.645px" />
                  </div>
                  <div class="uploadSoundFileDesign-heading">
                    Select a <span class="bluer" style="text-decoration: none;">CSV</span> file to upload
                  </div>
                  <div class="uploadSoundFileDesign-text mt-10px">
                    <b>or drag and drop it here</b>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center flex-column">
              <div class="latestGreyBox-heading-main mt-20px mb-20px text-center">OR</div>
              <button class="newButton"  @click="conditions.manual=true">
                <vb-icon icon="squared-add-icon" height="38px" width="38px"  />
                <span class="newButton-textPart position-relative d-flex justify-content-center">
                  <template>Add manually</template>
                </span>
              </button>
            </div>
          </template>
          <template v-else>
            <div v-for="(data, index) in forms.add_sheet.manually" :key="index" class="w-100 mb-20px">
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mt-20px">
                <label>Contact name</label>
                <b-input type="text" class="w-100" v-model="data.name" placeholder="Enter contact name" />
              </div>
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mt-20px mb-20px">
                <label>Phone number</label>
                <b-input type="text" class="w-100" v-model="data.number" v-vb-input-number-formate placeholder="Enter phone number" />
              </div>
              <b-icon icon="trash-fill" font-scale="1.5" variant="danger" class="cursor_pointer_no_hover" @click="forms.add_sheet.manually.splice(index,1)" />
            </div>
            <div class="w-100 d-flex justify-content-between align-items-center">
              <button class="newButton ml-auto" @click="conditions.manual=false">
                <vb-icon icon="squared-add-icon" height="38px" width="38px"  />
                <span class="newButton-textPart position-relative d-flex justify-content-center">
                  <template>Back</template>
                </span>
              </button>
              <button class="newButton ml-auto" @click="forms.add_sheet.manually.push({ name: '', number: '', })">
                <vb-icon icon="squared-add-icon" height="38px" width="38px"  />
                <span class="newButton-textPart position-relative d-flex justify-content-center">
                  <template>Add more</template>
                </span>
              </button>
            </div>
          </template>
        </div>
        <button v-if="!$v.forms.add_sheet.$invalid" @click="validatePhoneNumber()" class="fullWidthDoneButton mt-20px">
          <template>Next</template>
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import _ from 'lodash'
import Info from '../Lists/Info.vue'
import required from 'vuelidate/lib/validators/required'
import { validNumberChecker } from '@/filter'
import * as XLSX from "xlsx";
import { VOIP_API } from '@/utils'
export default {
  name: 'AddListModal',
  components: {
    Info,
  },
  inject: [
    'getProperty',
    'appNotify',
  ],
  props: {
    modalName: {
      type: String,
      default: 'AddListModal'
    },
  },
  data() {
    return {
      forms: {
        add_sheet: this.$helperFunction.formInstance({
          data: {
            name: '',
            assign_to: '',
            csv_file: null,
            manually: [],
            number_column: '',
            name_column: '',
          },
        }),
      },
      api: {
        create_sheet: this.$helperFunction.apiInstance({}),
      },
      conditions: {
        manual: false,
        drag_and_drop: false,
        table_show: false,
        invalid_numbers: 0,
      },
      data: { 
        columns: [],
        data: {},
      },
    }
  },
  validations: {
    forms: {
      add_sheet: {
        name: {
          required: required,
        },
        assign_to: {
          required: required,
        },
        csv_file: {
          required: required,
        },
        number_column: {
          required: required,
        },
        name_column: {
          required: required,
        },
      },
    },
  },
  computed: {
    ...mapState({
      users: (state)=>state.common.voipusers ?? {},
      teams: (state)=>state.common.teams?.data ?? [],
    }),
    ...mapGetters([
      'getIsMobile',
    ]),
    assignToOptions(){ 
      return _.orderBy([...Object.values(this.users),...this.teams].map(i=>({
        name: i.user?.display_name || i.name,
        accountcode: i.ringroup?.accountcode || i.voipaccount,
        extn: i.ringroup?.extn || i.extn,
        type: i.ringroup ? 'team' : 'user',
      })),['name'], ['asc'])
    },
  },
  methods: {
    onBeforeOpen(){
      // TODO - 
    },
    onBeforeClose(){
      this.conditions.drag_and_drop=false
      this.conditions.manual=false
      this.conditions.table_show=false
      this.conditions.invalid_numbers=0
      this.forms.add_sheet.reset()
    },
    validateFile(file) {
      let vm = this
      if(file.type!='text/csv') {
        this.appNotify({
          message: 'File type must be text/csv',
          type: 'danger',
        })
        return;
      }
      const reader = new FileReader();
      reader.onload = async function(e) {
        const csvContent = e.target.result;
        const rows = csvContent.split('\r\n');
        const new_rows = await rows.map(row=>row.split('",').map(i=>i.slice(1)))
        const columns = new_rows.slice(0,1)?.[0] ?? []
        const data = new_rows.slice(1,new_rows.length)
        const objects = _.map(data, row => _.zipObject(columns, row));
        vm.data.data=objects
        vm.data.columns=columns
        vm.forms.add_sheet.csv_file=file
      };
      reader.onerror = function(error) {
        vm.appNotify({
          message: error.message,
          type: 'danger',
        })
      }
      if (file) reader.readAsText(file);
    },
    async validatePhoneNumber(){
      const new_data = await this.data.data.filter(i=>!!validNumberChecker(i[this.forms.add_sheet.number_column]))
      const invalid_numbers = await this.data.data.reduce((c,v)=>c+=!validNumberChecker(v[this.forms.add_sheet.number_column]) ? 1 : 0,0)
      this.data.data=new_data
      this.conditions.table_show=true
      this.conditions.invalid_numbers=invalid_numbers
    },
    async upload(){
      if(this.api.create_sheet.send) return;
      try {
        this.api.create_sheet.send=true
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(this.data.data);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const excelBinary = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        const buf = new ArrayBuffer(excelBinary.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < excelBinary.length; ++i) view[i] = excelBinary.charCodeAt(i) & 0xFF;
        const excelBlob = new Blob([buf], { type: "application/octet-stream" });
        const formData = new FormData();
        formData.append('file', excelBlob, `${this.forms.add_sheet.name}.xlsx`);
        formData.append('assign_to', this.forms.add_sheet.assign_to);
        formData.append('number_column', this.forms.add_sheet.number_column);
        formData.append('name_column', this.forms.add_sheet.name_column);
        formData.append('name', this.forms.add_sheet.name);
        await VOIP_API.endpoints.power_dialer.createSheet(formData)
        this.$modal.hide(this.modalName)
        this.appNotify({
          message: 'Succssfully Created!',
          type: 'success'
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger'
        })
      } finally {
        this.api.create_sheet.send=false
      }
    },
  },
}
</script>

<style>

</style>