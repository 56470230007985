var render = function render(){
  var _vm$selecetdStatus$co, _vm$selecetdPriority$, _vm$selecetdStatus$co2, _vm$selecetdPriority$2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c(_vm.propTaskID ? 'div' : 'modal', {
    tag: "component",
    staticClass: "EditTasksModal",
    attrs: {
      "name": _vm.modalName,
      "width": "50%",
      "height": "auto",
      "scrollable": true
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.$store.getters.getIsMobile ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    style: `pointer-events:${_vm.api.remove_task.send || _vm.api.update_task.send ? 'none' : 'auto'}`,
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5",
      "disabled": _vm.api.remove_task.send || _vm.api.update_task.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center flex-1"
  }, [_vm._v("Edit task")]), _c('button', {
    staticClass: "dialer-button dialer-button-primary",
    attrs: {
      "disabled": _vm.api.remove_task.send || _vm.api.update_task.send,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.updateTask();
      }
    }
  }, [[_vm._v("Update")]], 2)], 1), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard insideWebApp"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "mr-20px mt-0 todo-smallGreyBox",
    on: {
      "click": function ($event) {
        _vm.api.update_task.send || _vm.api.remove_task.send ? '' : _vm.forms.update_task.my_day = !_vm.forms.update_task.my_day;
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "sun-fill",
      "variant": _vm.forms.update_task.my_day ? 'warning' : 'dark',
      "width": "21.056px",
      "height": "21.052px"
    }
  })], 1), _c('div', {
    staticClass: "todo-smallGreyBox mt-0 ml-2",
    on: {
      "click": function ($event) {
        _vm.api.update_task.send || _vm.api.remove_task.send ? '' : _vm.forms.update_task.is_important = !_vm.forms.update_task.is_important;
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "exclamation-lg",
      "variant": _vm.forms.update_task.is_important ? 'danger' : 'dark',
      "width": "21.056px",
      "height": "21.052px"
    }
  }), _vm.api.update_task.validation_errors.is_important ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.update_task.validation_errors.is_important, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }), 0) : _vm._e()], 1)])])])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-12px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard insideWebApp"
  }, [!_vm.isEmpty(_vm.response.task) ? [!_vm.conditions.edit_title ? _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('h2', {
    staticClass: "dialer-edit-title my-0 flex-fill",
    on: {
      "click": function ($event) {
        _vm.conditions.edit_title = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.forms.update_task.title))]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]) : _c('div', {
    staticClass: "basicWhiteIOScard-item align-items-start flex-column"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100 d-flex align-items-center"
  }, [_c('b-input', {
    staticClass: "flex-fill",
    attrs: {
      "type": "text",
      "disabled": _vm.api.remove_task.send || _vm.api.update_task.send,
      "maxLength": _vm.$v.forms.update_task.title.$params.maxLength.max
    },
    model: {
      value: _vm.forms.update_task.title,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_task, "title", $$v);
      },
      expression: "forms.update_task.title"
    }
  }), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-icon', {
    staticClass: "mx-3",
    attrs: {
      "icon": "x-lg",
      "variant": "danger"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.edit_title = false;
      }
    }
  })], 1)], 1), _vm.forms.update_task.submitted && _vm.$v.forms.update_task.title.$invalid || _vm.api.update_task.validation_errors.title ? _c('p', {
    staticClass: "mb-0 text-danger mt-1 text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_task.title.required ? _c('span', [_vm._v(" Title is required")]) : !_vm.$v.forms.update_task.title.minLength ? _c('span', [_vm._v(" Title should be minimum " + _vm._s(_vm.$v.forms.update_task.title.$params.minLength.min) + " character")]) : !_vm.$v.forms.update_task.title.maxLength ? _c('span', [_vm._v(" Title can be maximum " + _vm._s(_vm.$v.forms.update_task.title.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.update_task.validation_errors.title, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()])] : _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('h2', {
    staticClass: "dialer-edit-title my-0 flex-fill"
  }), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)])], 2)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-12px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard insideWebApp"
  }, [_vm.conditions.edit_desc ? _c('div', {
    staticClass: "basicWhiteIOScard-item align-items-start flex-column"
  }, [_c('div', {
    staticClass: "d-flex align-items-center w-100"
  }, [_vm.conditions.edit_desc ? _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_task.note,
      expression: "forms.update_task.note"
    }],
    staticClass: "w-100 border px-2 py1",
    attrs: {
      "rows": "auto",
      "disabled": _vm.api.update_task.send,
      "placeholder": "Write a description",
      "maxlength": _vm.$v.forms.update_task.note.$params.maxLength.max
    },
    domProps: {
      "value": _vm.forms.update_task.note
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_task, "note", $event.target.value);
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.conditions.edit_desc ? _c('b-icon', {
    staticClass: "darker-chevron-down withoutPath mr-2",
    attrs: {
      "icon": "x-lg",
      "variant": "danger",
      "font-weight": "1.4"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.edit_desc = false;
      }
    }
  }) : _vm._e()], 1), _vm.forms.update_task.submitted && _vm.$v.forms.update_task.note.$invalid || _vm.api.update_task.validation_errors.note ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_task.note.minLength ? _c('span', [_vm._v(" Note should be minimum " + _vm._s(_vm.$v.forms.update_task.note.$params.minLength.min) + " character")]) : !_vm.$v.forms.update_task.note.maxLength ? _c('span', [_vm._v(" Note can be maximum " + _vm._s(_vm.$v.forms.update_task.note.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.update_task.validation_errors.note, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()]) : _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon mr-16px"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latest-mobileDescription-icon"
    }
  })], 1), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.forms.update_task.note) + " ")])]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.edit_desc = true;
      }
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item removeMt-45px"
  }, [_c('Attachments', {
    attrs: {
      "task_id": _vm.taskID,
      "disabled": _vm.api.remove_task.send
    },
    on: {
      "media-gallery-modal-show": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-MediaGalleryModal`, $event);
      }
    }
  })], 1)])]), _c('CallLog', {
    ref: "call_log",
    attrs: {
      "task_id": _vm.taskID,
      "cdr_id": _vm.response.task.cdr_id,
      "task": _vm.response.task
    }
  }), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-12px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard insideWebApp"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon mr-16px"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latest-mobileAssignedTo-icon"
    }
  })], 1), _c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v(" Assigned to ")])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "allAssignedAvatarContainer"
  }, [_vm._l(_vm.assigneds.array, function (assigned, index) {
    return _c('div', {
      key: assigned.id,
      staticClass: "vb-avatar-container",
      style: `margin-left:-${_vm.assignedsMarginLeft}px;z-index:${(index + 1) * 1}`,
      attrs: {
        "aria-disabled": index + 1
      }
    }, [_c('vb-avatar', {
      attrs: {
        "is_blf": false,
        "id": assigned.assigned_to
      }
    })], 1);
  }), _vm.assigneds.count ? _c('div', [_vm._v("+" + _vm._s(_vm.assigneds.count))]) : _vm._e()], 2), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    },
    on: {
      "click": function ($event) {
        _vm.api.remove_task.send ? '' : _vm.$modal.show(`${_vm._uid}-AddRemoveUsersTask`, {
          task_id: _vm.taskID,
          type: 'task_assigned'
        });
      }
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item flex-column align-items-start"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon mr-16px"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latest-mobileStatus-icon"
    }
  })], 1), _c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v(" Status ")])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-select', {
    staticClass: "unique-dropDown inside-basicWhiteIOScard-item mt-0",
    attrs: {
      "textElStyle": `background-color: ${(_vm$selecetdStatus$co = _vm.selecetdStatus.color) !== null && _vm$selecetdStatus$co !== void 0 ? _vm$selecetdStatus$co : ''};`,
      "disabled": _vm.api.update_task.send || _vm.api.status.send || _vm.api.remove_task.send,
      "selected": _vm.forms.update_task.status_id,
      "options": _vm.statuses,
      "isCheckShow": true
    },
    on: {
      "change": function ($event) {
        _vm.forms.update_task.status_id = $event;
      }
    }
  })], 1)]), _vm.forms.update_task.submitted && _vm.$v.forms.update_task.status_id.$invalid || _vm.api.update_task.validation_errors.status_id ? _c('p', {
    staticClass: "text-danger mb-0"
  }, [!_vm.$v.forms.update_task.status_id.required ? _c('span', [_vm._v(" Status is required")]) : _vm._e(), _vm._l(_vm.api.update_task.validation_errors.status_id, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "basicWhiteIOScard-item flex-column align-items-start"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon mr-16px"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latest-mobilePriority-icon"
    }
  })], 1), _c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v(" Priority ")])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-select', {
    staticClass: "unique-dropDown enterColor inside-basicWhiteIOScard-item mt-0",
    attrs: {
      "textElStyle": `background-color: ${(_vm$selecetdPriority$ = _vm.selecetdPriority.color) !== null && _vm$selecetdPriority$ !== void 0 ? _vm$selecetdPriority$ : ''};`,
      "disabled": _vm.api.update_task.send || _vm.api.remove_task.send,
      "selected": _vm.forms.update_task.priority,
      "options": _vm.priorities,
      "isCheckShow": true
    },
    on: {
      "change": function ($event) {
        _vm.forms.update_task.priority = $event;
      }
    }
  })], 1)]), _vm.forms.update_task.submitted && _vm.$v.forms.update_task.priority.$invalid || _vm.api.update_task.validation_errors.priority ? _c('p', {
    staticClass: "text-danger mb-0"
  }, [!_vm.$v.forms.update_task.priority.required ? _c('span', [_vm._v(" Priority is required")]) : !_vm.$v.forms.update_task.priority.valid ? _c('span', [_vm._v(" Priority is invalid")]) : _vm._e(), _vm._l(_vm.api.update_task.validation_errors.priority, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "basicWhiteIOScard-item flex-column align-items-start"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon mr-16px"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latest-mobileDueDate-icon"
    }
  })], 1), _c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v(" Due date ")])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vue-ctk-date-time-picker', _vm._b({
    attrs: {
      "minDate": _vm.conditions.min_date,
      "disabled": _vm.api.update_task.send || _vm.api.remove_task.send,
      "format": 'YYYY-MM-DD HH:mm'
    },
    model: {
      value: _vm.forms.update_task.due_date,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_task, "due_date", $$v);
      },
      expression: "forms.update_task.due_date"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1)]), _vm.api.update_task.validation_errors.due_date ? _c('p', {
    staticClass: "text-danger mb-0"
  }, _vm._l(_vm.api.update_task.validation_errors.due_date, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }), 0) : _vm._e()]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon mr-16px"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latest-mobileInGroups-icon"
    }
  })], 1), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v(" Group ")])])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.response.task.group ? [_c('span', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.response.task.group.name))]), _c('div', {
    staticClass: "smallColoredTag mr-3",
    style: `background-color: ${_vm.response.task.group.color};`
  })] : _vm._e(), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-MoveTask`, {
          moved: true,
          payload: {
            task_id: _vm.taskID,
            flag: 'task'
          }
        });
      }
    }
  })], 2)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon mr-16px"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latest-mobileInSpaces-icon"
    }
  })], 1), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v(" Space ")])])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.response.task.spaces ? [_c('span', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.response.task.spaces.name))]), _c('div', {
    staticClass: "smallColoredTag mr-3",
    style: `background-color: ${_vm.response.task.spaces.color};`
  })] : _vm._e(), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-MoveTask`, {
          moved: true,
          payload: {
            task_id: _vm.taskID,
            flag: 'task'
          }
        });
      }
    }
  })], 2)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon mr-16px"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latest-mobileTaskViewer-icon"
    }
  })], 1), _c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v(" Task viewer ")])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "allAssignedAvatarContainer"
  }, _vm._l(_vm.viewers.array, function (viewer, index) {
    return _c('div', {
      key: viewer.id,
      staticClass: "vb-avatar-container",
      style: `margin-left:-${_vm.viewersMarginLeft}px;z-index:${(index + 1) * 1}`,
      attrs: {
        "aria-disabled": index + 1
      }
    }, [_c('vb-avatar', {
      attrs: {
        "is_blf": false,
        "id": viewer.accountcode
      }
    })], 1);
  }), 0), _vm.viewers.count ? _c('div', [_vm._v("+" + _vm._s(_vm.viewers.count))]) : _vm._e(), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    },
    on: {
      "click": function ($event) {
        _vm.api.remove_task.send ? '' : _vm.$modal.show(`${_vm._uid}-AddRemoveUsersTask`, {
          task_id: _vm.taskID,
          type: 'task_viewer'
        });
      }
    }
  })], 1)])])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-12px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard insideWebApp"
  }, [_c('Comments', {
    attrs: {
      "task_id": _vm.taskID,
      "disabled": _vm.api.remove_task.send
    }
  })], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-12px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard insideWebApp"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.api.remove_task.send ? '' : _vm.removeTask();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon mr-16px"
  }), _c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v(" " + _vm._s(_vm.response.task.deleted_status == 'yes' ? 'Un Archived' : 'Archived') + " ")])]), _c('b-icon', {
    attrs: {
      "icon": "trash",
      "width": "21.056px",
      "height": "21.052px",
      "variant": "danger"
    }
  })], 1)])])] : [_c('div', {
    staticClass: "dialer-edit-header position-relative py-0 justify-content-between align-items-center"
  }, [!_vm.isEmpty(_vm.response.task) ? [_vm.conditions.edit_title ? _c('div', {
    staticClass: "d-flex align-items-center flex-fill whiteBGinputWithGreyRoundedBorder mr-4"
  }, [_c('b-input', {
    staticClass: "mr-2",
    attrs: {
      "type": "text",
      "disabled": _vm.api.remove_task.send || _vm.api.update_task.send || _vm.response.task.deleted_status != 'no',
      "maxLength": _vm.$v.forms.update_task.title.$params.maxLength.max
    },
    model: {
      value: _vm.forms.update_task.title,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_task, "title", $$v);
      },
      expression: "forms.update_task.title"
    }
  }), _c('b-icon', {
    attrs: {
      "icon": "x-lg",
      "variant": "danger"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.edit_title = false;
      }
    }
  })], 1) : _c('h2', {
    staticClass: "dialer-edit-title my-0",
    on: {
      "click": function ($event) {
        _vm.conditions.edit_title = true;
      }
    }
  }, [_c('vb-svg', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "disabled": _vm.api.remove_task.send || _vm.api.update_task.send || _vm.response.task.deleted_status != 'no',
      "name": "leftLongArrow-icon",
      "width": "20",
      "height": "12",
      "viewBox": "0 0 30 16",
      "stroke-width": "1",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }), _vm._v(" " + _vm._s(_vm._f("get_property")(_vm.response.task, 'spaces.prefix')) + "#" + _vm._s(_vm._f("get_property")(_vm.response.task, 'id')) + " " + _vm._s(_vm.forms.update_task.title) + " ")], 1), _vm.forms.update_task.submitted && _vm.$v.forms.update_task.title.$invalid || _vm.api.update_task.validation_errors.title ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_task.title.required ? _c('span', [_vm._v(" Title is required")]) : !_vm.$v.forms.update_task.title.minLength ? _c('span', [_vm._v(" Title should be minimum " + _vm._s(_vm.$v.forms.update_task.title.$params.minLength.min) + " character")]) : !_vm.$v.forms.update_task.title.maxLength ? _c('span', [_vm._v(" Title can be maximum " + _vm._s(_vm.$v.forms.update_task.title.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.update_task.validation_errors.title, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()] : _c('h2'), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.response.task && _vm.response.task.deleted_status == 'no' ? _c('button', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.remove_task.send || _vm.api.update_task.send,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.updateTask();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "submitIcon",
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Update")])], 1) : _vm._e()])], 2), _vm.isEmpty(_vm.response.task) && _vm.api.get_task.send ? [_c('div', {
    staticClass: "w-100 mt-20px"
  }, [_c('div', {
    staticClass: "w-100 px-3 mt-20px"
  }, [_c('div', {
    staticClass: "latestShimmerDesign w-100",
    staticStyle: {
      "height": "40px"
    }
  })]), _c('div', {
    staticClass: "d-flex mt-20px"
  }, _vm._l(4, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "w-25 px-3"
    }, [_c('div', {
      staticClass: "latestShimmerDesign w-100",
      staticStyle: {
        "height": "40px"
      }
    })]);
  }), 0), _c('div', {
    staticClass: "w-100 px-3 mt-20px"
  }, [_c('div', {
    staticClass: "latestShimmerDesign w-100",
    staticStyle: {
      "height": "40px"
    }
  })]), _c('div', {
    staticClass: "d-flex mt-20px"
  }, _vm._l(4, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "w-25 px-3"
    }, [_c('div', {
      staticClass: "latestShimmerDesign w-100",
      staticStyle: {
        "height": "40px"
      }
    })]);
  }), 0), _c('div', {
    staticClass: "w-100 px-3 mt-20px"
  }, [_c('div', {
    staticClass: "latestShimmerDesign w-100",
    staticStyle: {
      "height": "40px"
    }
  })])])] : [_c('div', {
    staticClass: "d-flex align-items-center w-auto mt-12px"
  }, [_c('div', {
    staticClass: "d-flex align-items-center w-25"
  }, [_c('div', {
    staticClass: "lightGreyText"
  }, [_vm._v("In space")]), _vm.response.task.spaces ? [_c('span', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.response.task.spaces.name))]), _c('div', {
    staticClass: "smallColoredTag",
    style: `background-color: ${_vm.response.task.spaces.color};`
  })] : _vm._e()], 2), _c('div', {
    staticClass: "d-flex align-items-center ml-4 w-25"
  }, [_c('div', {
    staticClass: "lightGreyText"
  }, [_vm._v("In group ")]), _c('vb-select', {
    staticClass: "onlyTextInButton-dropDown",
    attrs: {
      "disabled": _vm.api.update_task.send || _vm.api.groups.send || _vm.api.remove_task.send || _vm.response.task.deleted_status != 'no',
      "options": _vm.response.groups,
      "valueField": 'id',
      "textField": 'name'
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function (_ref) {
        var option = _ref.option;
        return [_c('span', [_vm._v(_vm._s(_vm._f("get_property")(option, 'name', 'Select Group')))]), option ? _c('div', {
          staticClass: "smallColoredTag withMinHeightWidth",
          style: `background-color: ${option.color};`
        }) : _vm._e()];
      }
    }, {
      key: "option",
      fn: function (_ref2) {
        var option = _ref2.option;
        return [_c('span', {
          staticClass: "ml-2"
        }, [_vm._v(_vm._s(_vm._f("get_property")(option, 'name')))]), _c('div', {
          staticClass: "smallColoredTag",
          style: `background-color: ${option.color};`
        })];
      }
    }]),
    model: {
      value: _vm.forms.update_task.group_id,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_task, "group_id", $$v);
      },
      expression: "forms.update_task.group_id"
    }
  })], 1), _c('div', {
    staticClass: "d-inline-block ml-4 w-50"
  }, [_c('span', {
    staticClass: "lightGreyText"
  }, [_vm._v("Linked tasks:")]), _vm._l(_vm.linkedTasks, function (linkedTask) {
    return _c('span', {
      key: linkedTask.id,
      on: {
        "click": function ($event) {
          return _vm.getTask({
            task_id: linkedTask.task_link_id
          });
        }
      }
    }, [_vm._v(" " + _vm._s(_vm._f("get_property")(linkedTask, 'tasklistlink.spaces.prefix')) + _vm._s(linkedTask.task_link_id) + " , ")]);
  })], 2)]), _c('div', {
    staticClass: "w-100 d-flex align-items-end mt-45px mb-32px"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-start mr-20px"
  }, [_c('div', {
    staticClass: "lightGreyText-labelType"
  }, [_vm._v("Status")]), _c('vb-select', {
    staticClass: "unique-dropDown",
    attrs: {
      "textElStyle": `background-color: ${(_vm$selecetdStatus$co2 = _vm.selecetdStatus.color) !== null && _vm$selecetdStatus$co2 !== void 0 ? _vm$selecetdStatus$co2 : ''};`,
      "disabled": _vm.api.update_task.send || _vm.api.status.send || _vm.api.remove_task.send || _vm.response.task.deleted_status != 'no',
      "selected": _vm.forms.update_task.status_id,
      "options": _vm.statuses,
      "isCheckShow": true
    },
    on: {
      "change": function ($event) {
        _vm.forms.update_task.status_id = $event;
      }
    }
  }), _vm.forms.update_task.submitted && _vm.$v.forms.update_task.status_id.$invalid || _vm.api.update_task.validation_errors.status_id ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_task.status_id.required ? _c('span', [_vm._v(" Status is required")]) : _vm._e(), _vm._l(_vm.api.update_task.validation_errors.status_id, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex flex-column align-items-start mr-20px"
  }, [_c('div', {
    staticClass: "lightGreyText-labelType"
  }, [_vm._v("Due date")]), _c('div', {
    staticClass: "d-flex align-items-center mt-12px"
  }, [_c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "EditTaskModal-ckt-timeEditor",
    attrs: {
      "minDate": _vm.conditions.min_date,
      "disabled": _vm.api.update_task.send || _vm.api.remove_task.send || _vm.response.task.deleted_status != 'no',
      "format": 'YYYY-MM-DD HH:mm'
    },
    model: {
      value: _vm.forms.update_task.due_date,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_task, "due_date", $$v);
      },
      expression: "forms.update_task.due_date"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false)), _vm.api.update_task.validation_errors.due_date ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.update_task.validation_errors.due_date, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }), 0) : _vm._e()], 1)]), _c('div', {
    staticClass: "d-flex flex-column align-items-start mr-20px"
  }, [_c('div', {
    staticClass: "lightGreyText-labelType"
  }, [_vm._v("Priority")]), _c('vb-select', {
    staticClass: "unique-dropDown enterColor",
    attrs: {
      "textElStyle": `background-color: ${(_vm$selecetdPriority$2 = _vm.selecetdPriority.color) !== null && _vm$selecetdPriority$2 !== void 0 ? _vm$selecetdPriority$2 : ''};`,
      "disabled": _vm.api.update_task.send || _vm.api.remove_task.send || _vm.response.task.deleted_status != 'no',
      "selected": _vm.forms.update_task.priority,
      "options": _vm.priorities,
      "isCheckShow": true
    },
    on: {
      "change": function ($event) {
        _vm.forms.update_task.priority = $event;
      }
    }
  }), _vm.forms.update_task.submitted && _vm.$v.forms.update_task.priority.$invalid || _vm.api.update_task.validation_errors.priority ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_task.priority.required ? _c('span', [_vm._v(" Priority is required")]) : !_vm.$v.forms.update_task.priority.valid ? _c('span', [_vm._v(" Priority is invalid")]) : _vm._e(), _vm._l(_vm.api.update_task.validation_errors.priority, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1), _c('div', {
    staticClass: "mr-20px todo-smallGreyBox",
    on: {
      "click": function ($event) {
        _vm.api.update_task.send || _vm.api.remove_task.send || _vm.response.task.deleted_status != 'no' ? '' : _vm.forms.update_task.my_day = !_vm.forms.update_task.my_day;
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "sun-fill",
      "variant": _vm.forms.update_task.my_day ? 'warning' : 'dark',
      "width": "21.056px",
      "height": "21.052px"
    }
  })], 1), _c('div', {
    staticClass: "mr-20px todo-smallGreyBox",
    on: {
      "click": function ($event) {
        _vm.api.update_task.send || _vm.api.remove_task.send || _vm.response.task.deleted_status != 'no' ? '' : _vm.forms.update_task.is_important = !_vm.forms.update_task.is_important;
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "exclamation-lg",
      "variant": _vm.forms.update_task.is_important ? 'danger' : 'dark',
      "width": "21.056px",
      "height": "21.052px"
    }
  }), _vm.api.update_task.validation_errors.is_important ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.update_task.validation_errors.is_important, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }), 0) : _vm._e()], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD buttonHeightAuto ul-width-fit-content ml-auto mb-1"
  }, [_c('b-dropdown', {
    staticClass: "ico-filter",
    attrs: {
      "varient": "link",
      "no-caret": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          staticClass: "fillBlack",
          attrs: {
            "icon": "three-dots-vertical",
            "variant": "dark",
            "scale": "1.5"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "disabled": _vm.api.update_task.send || _vm.api.status.send || _vm.api.remove_task.send
    },
    on: {
      "click": function ($event) {
        _vm.forms.update_task.status_id = _vm.isClosed ? _vm.activeDefaultStatus.id : _vm.closedStatus.id;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "markAsComplete-icon",
      "width": "14.49px",
      "height": "10.431px"
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(" Mark as "), [_vm._v(_vm._s(_vm.isClosed ? 'incomplete' : 'complete'))]], 2)], 1), _c('b-dropdown-item', {
    attrs: {
      "disabled": _vm.response.task.deleted_status != 'no'
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-MoveTask`, {
          moved: true,
          payload: {
            task_id: _vm.taskID,
            flag: 'task'
          }
        });
      }
    }
  }, [_c('vb-icon', {
    staticClass: "fillBlack",
    attrs: {
      "icon": "MoveTask-icon",
      "width": "12.951px",
      "height": "10.638px"
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Move")])], 1), _c('b-dropdown-item', {
    attrs: {
      "disabled": _vm.api.remove_task.send || _vm.response.task.deleted_status != 'no'
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-LinkTask`, {
          task_id: _vm.taskID,
          task_ids: _vm.linkedTasksIds
        });
      }
    }
  }, [_c('vb-icon', {
    staticClass: "fillBlack",
    attrs: {
      "icon": "linkCallToTask-icon",
      "height": "14px",
      "width": "14px"
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Link task")])], 1), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.api.remove_task.send || _vm.response.task.deleted_status != 'no' ? '' : _vm.removeTask();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "fillBlack",
    attrs: {
      "icon": "trash-InTodoCheckList-icon",
      "height": "14px",
      "width": "14px"
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.response.task.deleted_status == 'yes' ? 'Un Archived' : 'Archived'))])], 1)], 1)], 1)]), _c('div', {
    staticClass: "w-100 d-flex align-items-end mt-45px mb-32px"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-start mr-20px"
  }, [_c('div', {
    staticClass: "lightGreyText-labelType"
  }, [_vm._v("Assigned to")]), _c('div', {
    staticClass: "d-flex align-items-center mt-12px"
  }, [_c('div', {
    staticClass: "allAssignedAvatarContainer"
  }, [_vm._l(_vm.assigneds.array, function (assigned, index) {
    return _c('div', {
      key: assigned.id,
      staticClass: "vb-avatar-container",
      style: `margin-left:-${_vm.assignedsMarginLeft}px;z-index:${(index + 1) * 1}`,
      attrs: {
        "aria-disabled": index + 1
      }
    }, [_c('vb-avatar', {
      attrs: {
        "is_blf": false,
        "id": assigned.assigned_to
      }
    })], 1);
  }), _vm.assigneds.count ? _c('div', [_vm._v("+" + _vm._s(_vm.assigneds.count))]) : _vm._e()], 2), _c('div', {
    staticClass: "todo-smallGreyBox pillowType mt-0 ml-3",
    on: {
      "click": function ($event) {
        _vm.api.remove_task.send || _vm.response.task.deleted_status != 'no' ? '' : _vm.$modal.show(`${_vm._uid}-AddRemoveUsersTask`, {
          task_id: _vm.taskID,
          type: 'task_assigned'
        });
      }
    }
  }, [_c('b-icon', {
    staticClass: "darker-chevron-down ml-0",
    attrs: {
      "icon": "plus-lg"
    }
  })], 1)])]), _c('div', {
    staticClass: "d-flex flex-column align-items-start mr-20px"
  }, [_c('div', {
    staticClass: "lightGreyText-labelType"
  }, [_vm._v("Task viewers")]), _c('div', {
    staticClass: "TaskViewers-box mt-12px"
  }, [_c('b-icon', {
    staticClass: "darker-chevron-down withoutPath grey mr-2",
    attrs: {
      "icon": "eye-fill",
      "font-weight": "1.3"
    }
  }), _c('div', {
    staticClass: "todo-smallGreyBox-text color-black mr-20px"
  }, [_vm._v("Watching")]), _c('div', {
    staticClass: "TaskViewers-innerBox",
    on: {
      "click": function ($event) {
        _vm.api.remove_task.send || _vm.response.task.deleted_status != 'no' ? '' : _vm.forms.update_task.is_watcher = !_vm.forms.update_task.is_watcher;
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": _vm.forms.update_task.is_watcher ? 'check-lg' : 'x-lg'
    }
  }), _vm.api.update_task.validation_errors.is_watcher ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.update_task.validation_errors.is_watcher, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }), 0) : _vm._e()], 1)], 1)]), _c('div', {
    staticClass: "d-flex flex-column align-items-start justify-content-end mr-20px"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mt-12px"
  }, [_c('div', {
    staticClass: "allAssignedAvatarContainer"
  }, [_vm._l(_vm.viewers.array, function (viewer, index) {
    return _c('div', {
      key: viewer.id,
      staticClass: "vb-avatar-container",
      style: `margin-left:-${_vm.viewersMarginLeft}px;z-index:${(index + 1) * 1}`,
      attrs: {
        "aria-disabled": index + 1
      }
    }, [_c('vb-avatar', {
      attrs: {
        "is_blf": false,
        "id": viewer.accountcode
      }
    })], 1);
  }), _vm.viewers.count ? _c('div', [_vm._v("+" + _vm._s(_vm.viewers.count))]) : _vm._e()], 2), _c('div', {
    staticClass: "todo-smallGreyBox pillowType mt-0 ml-3",
    on: {
      "click": function ($event) {
        _vm.api.remove_task.send || _vm.response.task.deleted_status != 'no' ? '' : _vm.$modal.show(`${_vm._uid}-AddRemoveUsersTask`, {
          task_id: _vm.taskID,
          type: 'task_viewer'
        });
      }
    }
  }, [_c('b-icon', {
    staticClass: "darker-chevron-down ml-0",
    attrs: {
      "icon": "plus-lg"
    }
  })], 1)])]), _c('Timer', {
    attrs: {
      "taskId": _vm.taskID,
      "seconds": _vm.tasktimer.time,
      "started": _vm.tasktimer.status == '1',
      "disabled": _vm.response.task.deleted_status != 'no'
    },
    on: {
      "update-timer": function ($event) {
        return _vm.updateActivitiesList();
      }
    }
  })], 1), _c('div', {
    staticClass: "d-flex flex-column align-items-start mt-45px"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "TODO-innerHeading"
  }, [_vm._v("Description")]), _vm.conditions.edit_desc ? _c('b-icon', {
    staticClass: "darker-chevron-down withoutPath mr-2",
    attrs: {
      "icon": "x",
      "font-scale": "1.4",
      "scale": "1.3"
    },
    on: {
      "click": function ($event) {
        _vm.response.task.deleted_status != 'no' ? '' : _vm.conditions.edit_desc = false;
      }
    }
  }) : _c('b-icon', {
    staticClass: "darker-chevron-down withoutPath mr-2",
    attrs: {
      "icon": "pencil-fill",
      "font-weight": "1.4"
    },
    on: {
      "click": function ($event) {
        _vm.response.task.deleted_status != 'no' ? '' : _vm.conditions.edit_desc = true;
      }
    }
  })], 1), _vm.conditions.edit_desc ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100 mt-12px"
  }, [_vm.conditions.edit_desc ? _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_task.note,
      expression: "forms.update_task.note"
    }],
    staticClass: "TODO-textArea w-100",
    attrs: {
      "rows": "auto",
      "disabled": _vm.api.update_task.send || _vm.response.task.deleted_status != 'no',
      "placeholder": "Write a description",
      "maxlength": _vm.$v.forms.update_task.note.$params.maxLength.max
    },
    domProps: {
      "value": _vm.forms.update_task.note
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_task, "note", $event.target.value);
      }
    }
  }) : _vm._e()]) : _c('div', {
    staticClass: "TODO-greyBox mt-12px"
  }, [_c('p', {
    staticClass: "w-75 todo-smallGreyBox-text mb-0"
  }, [_vm._v(" " + _vm._s(_vm.forms.update_task.note) + " ")])]), _vm.forms.update_task.submitted && _vm.$v.forms.update_task.note.$invalid || _vm.api.update_task.validation_errors.note ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_task.note.minLength ? _c('span', [_vm._v(" Note should be minimum " + _vm._s(_vm.$v.forms.update_task.note.$params.minLength.min) + " character")]) : !_vm.$v.forms.update_task.note.maxLength ? _c('span', [_vm._v(" Note can be maximum " + _vm._s(_vm.$v.forms.update_task.note.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.update_task.validation_errors.note, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()]), _c('Attachments', {
    attrs: {
      "task_id": _vm.taskID,
      "disabled": _vm.api.remove_task.send || _vm.response.task.deleted_status != 'no'
    },
    on: {
      "attachment-uploaded": function ($event) {
        return _vm.updateActivitiesList();
      },
      "attachment-removed": function ($event) {
        return _vm.updateActivitiesList();
      },
      "media-gallery-modal-show": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-MediaGalleryModal`, $event);
      }
    }
  }), _c('CallLog', {
    ref: "call_log",
    attrs: {
      "task_id": _vm.taskID,
      "disabled": _vm.response.task.deleted_status != 'no',
      "cdr_id": _vm.response.task.cdr_id
    },
    on: {
      "call-linking-modal-show": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-CallLinking`, $event);
      },
      "call-log-updated": function ($event) {
        return _vm.updateActivitiesList();
      }
    }
  }), _c('CheckList', {
    ref: "checklist",
    attrs: {
      "task_id": _vm.taskID,
      "disabled": _vm.api.remove_task.send || _vm.response.task.deleted_status != 'no'
    },
    on: {
      "select-voip-users-modal-show": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-EditTaskSelectVoipUsers`, $event);
      },
      "checklist-added": function ($event) {
        return _vm.updateActivitiesList();
      },
      "checklist-updated": function ($event) {
        return _vm.updateActivitiesList();
      },
      "checklist-removed": function ($event) {
        return _vm.updateActivitiesList();
      },
      "checklist-mark-change": function ($event) {
        return _vm.updateActivitiesList();
      }
    }
  }), _c('div', [_c('Comments', {
    ref: "comments",
    attrs: {
      "task_id": _vm.taskID,
      "disabled": _vm.api.remove_task.send || _vm.response.task.deleted_status != 'no'
    },
    on: {
      "comment-updated": function ($event) {
        return _vm.updateActivitiesList();
      },
      "comment-created": function ($event) {
        return _vm.updateActivitiesList();
      },
      "comment-removed": function ($event) {
        return _vm.updateActivitiesList();
      }
    }
  })], 1)]]], 2), _c('AddRemoveFromList', {
    attrs: {
      "modalName": `${_vm._uid}-AddRemoveUsersTask`
    },
    on: {
      "update-list": function ($event) {
        _vm.getTask({
          fill_form: false
        });
        _vm.$emit('update-list');
        _vm.updateActivitiesList();
      }
    }
  }), _c('SelectSpaceGroupAndStatusModal', {
    attrs: {
      "modalName": `${_vm._uid}-MoveTask`
    },
    on: {
      "moved": function ($event) {
        _vm.response.task = {};
        _vm.getTask();
        _vm.$emit('update-list');
        _vm.updateActivitiesList();
      }
    }
  }), _c('LinkCallModal', {
    attrs: {
      "modalName": `${_vm._uid}-CallLinking`
    },
    on: {
      "updated": function ($event) {
        return _vm.$refs.call_log.onUpdatedCallLink($event);
      }
    }
  }), _c('LinkTaskModal', {
    attrs: {
      "modalName": `${_vm._uid}-LinkTask`
    },
    on: {
      "updated": function ($event) {
        _vm.getTask();
        _vm.$emit('update-list');
        _vm.updateActivitiesList();
      }
    }
  }), _c('SelectVoipUsersModal', {
    attrs: {
      "modalName": `${_vm._uid}-EditTaskSelectVoipUsers`
    },
    on: {
      "select": function ($event) {
        return _vm.$refs['checklist'].assignUser($event);
      }
    }
  }), _c('MediaGalleryModal', {
    attrs: {
      "modalName": `${_vm._uid}-MediaGalleryModal`
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }